import { IsString } from 'class-validator'

import { Entity } from '@domain/common/entity'

import type {
  ICommissionEntity,
  ICommissionDTO,
  ICreateCommissionEntityPayload
} from '@domain/stocks/interfaces/commission'

class BinanceCommissionEntity extends Entity<string> implements ICommissionEntity {

  @IsString()
  private _discount: string

  @IsString()
  private _discountAsset: string

  private _list: ICommissionDTO[]

  private constructor (payload: ICreateCommissionEntityPayload) {
    super(payload.id)

    this._discount = payload.discount
    this._discountAsset = payload.discountAsset
    this._list = payload.commission
  }

  get discount (): string {
    return this._discount
  }

  get discountAsset (): string {
    return this._discountAsset
  }

  get commission (): ICommissionDTO[] {
    return this._list
  }

  public getCommissionByTicker (value: string): ICommissionDTO | undefined {
    return this._list.find((item) => item.symbol === value)
  }

  public getCommissionByTickerWithDiscount (value: string): ICommissionDTO | undefined {
    const commission = this.getCommissionByTicker(value)

    if (commission) {
      return {
        symbol: commission.symbol,
        commission: {
          taker: (Number(commission.commission.taker) * (100 - Number(this._discount))).toString(),
          maker: (Number(commission.commission.maker) * (100 - Number(this._discount))).toString(),
        }
      }
    }

    return commission
  }

  public async clearCommission (): Promise<void> {
    this._list = []

    await this.validate()
  }

  public static async new (payload: ICreateCommissionEntityPayload): Promise<BinanceCommissionEntity> {
    const entity = new BinanceCommissionEntity(payload)
    await entity.validate()

    return entity
  }

}

export { BinanceCommissionEntity }
