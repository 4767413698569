import { Controller } from 'react-hook-form'

/* eslint-disable react/forbid-component-props  */
import { useTheme } from '@mui/material'

import { CHECKBOX_STYLES as Component } from '@app/components/ui/inputs/input-checkbox/_styles'
import { ReactComponent as CheckedIcon } from '@app/asset/icon/checkbox/checked.svg'

import TransitionElements from '@app/components/tools/components/transition-elements'

import type { ChangeEvent as ReactChangeEvent } from 'react'

import type { IBaseControlledInput } from '@app/components/ui/inputs/types/base-controlled-input'
import type { CheckboxProps as MUICheckboxProps } from '@mui/material'

type ChangeEvent = ReactChangeEvent<HTMLInputElement>

const InputCheckbox = <TControl, >(props: CheckboxProps<TControl>): JSX.Element => {
  const theme = useTheme()

  const handleChange = (callback: (event: ChangeEvent) => void) => (event: ChangeEvent) => {
    callback(event)
    if (props.onChange !== undefined) props.onChange(event, event.target.checked)
  }

  return (
    <Controller
      control={ props.control }
      name={ props.name }
      render={ ({ field: { onChange: handleOnChange, value }}): JSX.Element => (
        <Component.Checkbox
          { ...props }
          onChange={ handleChange(handleOnChange) }
          checked={ value as boolean ?? false }
          icon={ (
            <TransitionElements
              light={ <Component.UnCheckedIcon style={{ maxWidth: '18px' }} /> }
              dark={ <Component.UnCheckedDarkIcon style={{ maxWidth: '18px' }} /> }
            />
          ) }
          checkedIcon={ props.disabled === true
            ? <CheckedIcon fill={ theme.palette.button.secondary.active } style={{ maxWidth: '18px' }} />
            : <CheckedIcon fill={ theme.palette.primary.main } style={{ maxWidth: '18px' }} /> }
        />
      ) }
    />
  )
}

export type CheckboxProps<TControl> = IBaseControlledInput<TControl> & MUICheckboxProps

export default InputCheckbox
