import type { IGetPairListUseCase, IPairDTO, IPairRepository } from '../../../../common'

class GetPairListUseCase implements IGetPairListUseCase {

  protected repository: IPairRepository

  constructor (repository: IPairRepository) {
    this.repository = repository
  }

  public async execute (): Promise<IPairDTO[]> {
    return this.repository.getPairList()
  }

}

export { GetPairListUseCase }
