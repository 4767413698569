import { RequiredValidator, ValidationChain } from '@domain/common/utils/validators'

import { RangeValidation } from '@domain/common/utils/validators/range-validator'

import type { IFieldValidate, IValidationSpecification } from '@domain/setting-profile/specification'
/* eslint-disable @typescript-eslint/no-magic-numbers */
class ValidationSpecification implements IValidationSpecification {

  public validate (): IFieldValidate {
    return {
      countPartsDealDivision: new ValidationChain([
        new RequiredValidator(),
        new RangeValidation({ min: 2, max: 10 })
      ]),
      distanceDealDivision: new ValidationChain([
        new RequiredValidator(true),
        new RangeValidation({ min: 0.01, max: 2 }),
      ]),
      activeZoneDealDivision: new ValidationChain([
        new RequiredValidator(),
        new RangeValidation({ min: 10, max: 30 })
      ]),
      toolType: new ValidationChain([new RequiredValidator()]),
      orderType: new ValidationChain([new RequiredValidator()]),
      orderSizeButtonsCount: new ValidationChain([
        new RequiredValidator(),
        new RangeValidation({ min: 3, max: 8 })
      ]),
      selectedOrderSizeButton: new ValidationChain([new RequiredValidator()]),
      orderSizeButtons: undefined,
      orderSizePercentButtonsCount: new ValidationChain([
        new RequiredValidator(),
        new RangeValidation({ min: 3, max: 8 })
      ]),
      selectedOrderSizePercentButton: new ValidationChain([
        new RequiredValidator(),
        new RangeValidation({ min: 0, max: 100 })
      ]),
      orderSizePercentButtons: undefined,
      stopPricePercent: new ValidationChain([
        new RequiredValidator(true),
        new RangeValidation({ min: 0, max: 100 }),
      ]),
      isIncludeIceberg: new ValidationChain([new RequiredValidator()]),
      isIncludeMultiOrders: new ValidationChain([new RequiredValidator()])
    }
  }

}

export { ValidationSpecification }
