import { ValidationCode } from '@domain/common/enums'

import { AbstractValidator } from '@domain/common/utils/validators/abstract-validator'

import { ExceptedFKeyboard } from '@domain/setting-profile/hot-keys/use-case/update-hot-keys/_excepted-values'

import { HotKeyRules, OTHER_KEY } from '@app/pages/profile/settings/keyboard/components/_setting'

import {
  COMPARISON_DOUBLE_MOSE,
  SINGLE_MOSE
} from '@app/components/ui/inputs/input-keyboard/_setting'

import type {
  KeyMoseDouble,
  KeyMoseSingle } from '@app/components/ui/inputs/input-keyboard/_setting'

import type { IValidationError } from '@domain/common/utils/validators'

/* eslint-disable @typescript-eslint/no-magic-numbers */
class CombinationValidationHotKey extends AbstractValidator {

  public _rules: HotKeyRules

  public _lenghtKey: number

  constructor (rules: HotKeyRules, lenghtKey: number) {
    super()
    this._rules = rules
    this._lenghtKey = lenghtKey
  }

  public override validate (value: string[]): IValidationError {
    if (value.length === 0) return super.validate(value)

    if (this._validateKey(value)) return this._return()

    return super.validate(value)
  }

  private _validateKey (value: (string | KeyMoseSingle | KeyMoseDouble)[]): boolean {
    const _findF = value.find((tt) => tt.match(ExceptedFKeyboard))
    if (_findF !== undefined) return true

    const _lengthKey = value.filter((tt) => tt.match(/Key|Digit/)).length
    const _lengthMouse = value.filter((tt) => tt === SINGLE_MOSE[tt as KeyMoseSingle] ||
      COMPARISON_DOUBLE_MOSE[tt as KeyMoseDouble]).length

    switch (this._rules) {
      case HotKeyRules.ALL:
        return _lengthMouse === 0
      case HotKeyRules.ALL_REQUIRE:
        if (_lengthMouse === 0) return true
        return _lengthKey + this._validateCombination(value) < 1
      case HotKeyRules.ONLY_KEYBOARD:
        if (_lengthMouse !== 0) return true
        return _lengthKey + this._validateCombination(value) < 1
      default:
        return false
    }
  }

  private _validateCombination (value: string[]): number {
    return value.filter((tt) => OTHER_KEY.find((ss) => ss === tt)).length
  }

  private _return (): IValidationError {
    return {
      isSuccess: false,
      errors: {
        code: ValidationCode.INVALID_EXCEPTED_VALUE
      }
    }
  }

}

export { CombinationValidationHotKey }
