import { InternalCode } from '@domain/common/enums'

import { ExceptionService } from '@domain/common/services'

import { RequiredValidator, ValidationChain } from '@domain/common/utils/validators'

import {
  HotKeyEvents,
  HotKeyEventsInclude,
  HotKeyEventsOption
} from '@domain/setting-profile/hot-keys'

import { RangeValidation } from '@domain/common/utils/validators/range-validator'

import { TRANSFORM_REQUEST_MOUSE } from '@app/components/ui/inputs/input-keyboard/_setting'

import type { KeyMoseDouble, KeyMoseSingle } from '@app/components/ui/inputs/input-keyboard/_setting'

import type { IIKeyboard } from '@app/pages/profile/settings/keyboard/components/_validation'

import type {
  IHotKeysErrors,
  IHotKeysRepository,
  IUpdateHotKeysPort,
  IUpdateHotKeysUseCase,
  IHotKey,
  IHotKeyEntity,
  IMultipleEvent,
  IValidationHotKey
  ,
  IHotKeysErrorsData
} from '@domain/setting-profile/hot-keys'

/* eslint-disable max-len */

interface IMultipleKeys {
  event: HotKeyEvents
  include: HotKeyEventsInclude
  options: HotKeyEventsOption
}

class UpdateHotKeysUseCase implements IUpdateHotKeysUseCase {

  private _repository: IHotKeysRepository

  private _validation: IValidationHotKey

  constructor (repository: IHotKeysRepository, validation: IValidationHotKey) {
    this._repository = repository
    this._validation = validation
  }

  public async execute (port: IIKeyboard): Promise<IHotKeyEntity[]> {
    this._throwErrors(port)
    await this._repository.update(this._transformRequestPort(port))
    return this._transformResponsePort(port)
  }

  private _transformRequestCombination = (combination: (string | KeyMoseSingle | KeyMoseDouble)[]): string => {
    let result: string[] = []
    combination.forEach((item) => {
      const _find = TRANSFORM_REQUEST_MOUSE[item as KeyMoseSingle | KeyMoseDouble]
      if (_find === undefined) result = [...result, item]
      else result = [...result, ..._find]
    })
    return result.join(';')
  }

  private _transformRequestPort (port: IIKeyboard): IUpdateHotKeysPort {
    const keys = Object.entries(HotKeyEvents)
    const result: IUpdateHotKeysPort = []

    for (const [_, key] of keys) {
      if (HotKeyEvents.CHANGE_SCALE === key || HotKeyEvents.ORDER_SIZE === key) continue
      result.push({
        event: key,
        command: this._transformRequestCombination(port[key]),
        option: port[`${key}_option`],
        isInclude: port[`${key}_is_include`]
      })
    }

    const transformedChangeScale = this._transformMultipleItemToPort(port, {
      event: HotKeyEvents.CHANGE_SCALE,
      include: HotKeyEventsInclude.CHANGE_SCALE,
      options: HotKeyEventsOption.CHANGE_SCALE
    })

    const transformedEventSizes = this._transformMultipleItemToPort(port, {
      event: HotKeyEvents.ORDER_SIZE,
      include: HotKeyEventsInclude.ORDER_SIZE,
      options: HotKeyEventsOption.ORDER_SIZE
    })

    return [
      ...result,
      ...transformedChangeScale,
      ...transformedEventSizes,
    ]
  }

  private _transformMultipleItemToPort (port: IIKeyboard, keys: IMultipleKeys): IHotKey[] {
    const result: IHotKey[] = []
    const { event, include, options } = keys

    for (const [index, item] of port[event].entries()) {
      result.push({
        event: `${event}_${index + 1}`,
        option: port[options][index],
        isInclude: (port[include] as boolean[])[index],
        command: (item as string[]).join(';')
      })
    }

    return result
  }

  private _transformResponsePort (port: IIKeyboard): IHotKeyEntity[] {
    const keys = Object.entries(HotKeyEvents)
    const result: IUpdateHotKeysPort = []

    for (const [_, key] of keys) {
      if (HotKeyEvents.CHANGE_SCALE === key || HotKeyEvents.ORDER_SIZE === key) continue
      result.push({
        event: key,
        command: this._transformRequestCombination(port[key]),
        option: port[`${key}_option`],
        isInclude: port[`${key}_is_include`]
      })
    }

    const transformedChangeScaleData = this._transformMultipleItemToResponse(port, {
      event: HotKeyEvents.CHANGE_SCALE,
      include: HotKeyEventsInclude.CHANGE_SCALE,
      options: HotKeyEventsOption.CHANGE_SCALE
    })

    const transformedEventSizesData = this._transformMultipleItemToResponse(port, {
      event: HotKeyEvents.ORDER_SIZE,
      include: HotKeyEventsInclude.ORDER_SIZE,
      options: HotKeyEventsOption.ORDER_SIZE
    })

    return [
      ...result,
      { event: HotKeyEvents.CHANGE_SCALE, data: transformedChangeScaleData, countButton: transformedChangeScaleData.length },
      { event: HotKeyEvents.ORDER_SIZE, data: transformedEventSizesData }
    ]
  }

  private _transformMultipleItemToResponse (port: IIKeyboard, keys: IMultipleKeys): IMultipleEvent[] {
    const result: IMultipleEvent[] = []
    const { event, include, options } = keys

    for (const [index, item] of port[event].entries()) {
      result.push({
        option: port[options][index],
        isInclude: (port[include] as boolean[])[index],
        command: (item as string[]).join(';')
      })
    }

    return result
  }

  private _throwErrors (port: IIKeyboard): void {
    const errors = this._validatePort(port)
    if (Object.keys(errors).length) {
      throw ExceptionService.new({
        status: {
          code: InternalCode.VALIDATION_ERROR,
          message: 'Validation throw errors'
        },
        data: errors
      })
    }
  }

  private _validatePort (port: IIKeyboard): IHotKeysErrors {
    const errors: IHotKeysErrors = {}

    const keys = Object.entries((Object.values(HotKeyEvents) as HotKeyEvents[])
      .reduce((prev, key) => ({
        ...prev,
        [key]: port[key]
      }), {}) as Record<HotKeyEvents, string[]>) as [HotKeyEvents, string[]][]

    for (const [key, value] of keys) {
      if (key === HotKeyEvents.CHANGE_SCALE) {
        const result = this._validateMultipleItem(key, value, port)
        if (result !== null) {
          errors[HotKeyEvents.CHANGE_SCALE] = result
        }
      } else if (key === HotKeyEvents.ORDER_SIZE) {
        const result = this._validateMultipleItem(key, value, port)
        if (result !== null) {
          errors[HotKeyEvents.ORDER_SIZE] = result
        }
      } else {
        const result = this._validation.validate(port)[key]?.run(value).errors
        if (result !== undefined) errors[key] = result
      }
    }

    const keysChangeScale = Object.entries({
      [HotKeyEventsOption.CHANGE_SCALE]: port[HotKeyEventsOption.CHANGE_SCALE]
    }) as [HotKeyEventsOption, string[]][]

    for (const [key, value] of keysChangeScale) {
      if (key === HotKeyEventsOption.CHANGE_SCALE) {
        const _chainChangeScale = new ValidationChain([
          new RequiredValidator(),
          new RangeValidation({ min: 1, max: 1000 })
        ])
        value.forEach((tt, index) => {
          const result = _chainChangeScale.run(tt).errors
          if (result !== undefined) errors[HotKeyEventsOption.CHANGE_SCALE] = { ...errors[key], [index]: result }
        })
      }
    }

    const _chainCountGraphic = new ValidationChain([
      new RequiredValidator(),
      new RangeValidation({ min: 3, max: 5 })
    ])
    const _result = _chainCountGraphic.run(port.countButton).errors
    if (_result !== undefined) errors.countButton = _result
    return errors
  }

  private _validateMultipleItem (key: HotKeyEvents, value: string[], port: IIKeyboard): Record<number, IHotKeysErrorsData> | null {
    let errors: Record<number, IHotKeysErrorsData> = {}

    for (const [index, val] of value.entries()) {
      const validationArray = this._validation.validate(port, index)[key] as Record<number, ValidationChain | undefined>
      if (validationArray[index]) {
        const result = validationArray[index]?.run(val).errors
        if (result !== undefined) errors = { ...errors, [index]: result }
      }
    }

    if (Object.keys(errors).length > 0) return errors
    return null
  }

}

export { UpdateHotKeysUseCase }
