import { useState, type MouseEventHandler, type TouchEventHandler } from 'react'

import type { MouseEvent as ReactMouseEvent, TouchEvent as ReactTouchEvent } from 'react'

interface IGlowComponentProps {
  coordinates: Record<'x' | 'y', number | null>
  onMouseMove: MouseEventHandler
  onTouchStart: TouchEventHandler
  onTouchMove: TouchEventHandler
  onTouchEnd: TouchEventHandler
}

const createGlowComponentStyles = ({ x, y }: Record<'x' | 'y', number>): string => `
  &:hover::before {
    opacity: 1;
  }

  &::before, &::after {
    border-radius: inherit;
    content: "";
    height: 100%;
    left: 0px;
    opacity: 0;
    position: absolute;
    top: 0px;
    transition: opacity 500ms;
    width: 100%;
  }

  &::before {
    background: radial-gradient(
      800px circle at ${`${x}px`} ${`${y}px`}, 
      rgba(255, 255, 255, 0.06),
      transparent 40%
    );
    z-index: 3;
  }

  &::after {  
    background: radial-gradient(
      600px circle at ${`${x}px`} ${`${y}px`}, 
      rgba(255, 255, 255, 0.4),
      transparent 40%
    );
    z-index: 1;
  }
`

const PREVENT_FIRES_TOUCH_EVENTS = 500
let lastTouchEventTimestamp = 0

const isFiresTouchEvent = (event: MouseEvent): boolean => {
  if (event.sourceCapabilities?.firesTouchEvents !== undefined) {
    return event.sourceCapabilities.firesTouchEvents
  }

  return getEventTimestamp(event) < lastTouchEventTimestamp + PREVENT_FIRES_TOUCH_EVENTS
}

const getEventTimestamp = (event: TouchEvent | MouseEvent): number => {
  return event.timeStamp || performance.now()
}

const useGlowEffect = (): IGlowComponentProps => {
  const [x, setX] = useState<number | null>(null)
  const [y, setY] = useState<number | null>(null)

  const handleMouseMove = (e: ReactMouseEvent): void => {
    if (isFiresTouchEvent(e as unknown as MouseEvent)) return

    const rect = e.currentTarget.getBoundingClientRect()

    setX(e.clientX - rect.left)
    setY(e.clientY - rect.top)
  }

  const handleTouchEvent = (event: ReactTouchEvent): void => {
    lastTouchEventTimestamp = getEventTimestamp(event as unknown as TouchEvent)
  }

  return {
    coordinates: { x, y },
    onMouseMove: handleMouseMove,
    onTouchStart: handleTouchEvent,
    onTouchMove: handleTouchEvent,
    onTouchEnd: handleTouchEvent
  }
}

export type { IGlowComponentProps }
export { createGlowComponentStyles, useGlowEffect }
