import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'

import { useQuery } from '@tanstack/react-query'

import { InternalCode } from '@domain/common/enums'
import { ERROR_MESSAGES } from '@app/services/i18n/_messages'

import { TakeProfitRepository } from '@data/repository/setting-profile/take-profit'

import { GetTakeProfitUseCase } from '@domain/setting-profile/take-profit'
import { TakeProfitValidation } from '@domain/setting-profile/take-profit/use-case/update-take-profit/validation'

import type { ITakeProfitDTO } from '@domain/setting-profile/take-profit'
import type { ExceptionService } from '@domain/common/services'

import type { Dispatch, SetStateAction } from 'react'

import type { UseQueryResult } from '@tanstack/react-query'

type IUseGetTakeProfitResults = UseQueryResult<ITakeProfitDTO, ExceptionService<never>>

const useGetTakeProfit = (setter: Dispatch<SetStateAction<ITakeProfitDTO>>): IUseGetTakeProfitResults => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const repository = new TakeProfitRepository()
  const validation = new TakeProfitValidation()
  const useCase = new GetTakeProfitUseCase(repository, validation)

  const callback = async (): Promise<ITakeProfitDTO> => {
    return useCase.execute()
  }

  return useQuery(
    ['get take profit'],
    callback,
    {
      onSuccess: (value) => {
        setter(value)
      },
      onError: (error) => {
        if (error.code === InternalCode.SERVER_ERROR) {
          enqueueSnackbar(t(ERROR_MESSAGES[InternalCode.SERVER_ERROR]), { variant: 'error' })
        }
      },
      enabled: false
    }
  )
}

export { useGetTakeProfit }
