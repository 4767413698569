import type { IDeleteApiKeyPort, IDeleteApiKeyUseCase, IApiKeyRepository } from '../../../../common'

import type { IUseCasePortValidationError, IUseCasePortValidator } from '@domain/common/interfaces/use-case'
import type { Entries } from '@domain/common/utils'

abstract class DeleteApiKeyUseCase implements IDeleteApiKeyUseCase {

  protected repository: IApiKeyRepository

  protected validator: IUseCasePortValidator<IDeleteApiKeyPort>

  constructor (repository: IApiKeyRepository, validator: IUseCasePortValidator<IDeleteApiKeyPort>) {
    this.repository = repository
    this.validator = validator
  }

  public abstract execute (port: IDeleteApiKeyPort): Promise<boolean>

  protected validatePort (port: IDeleteApiKeyPort): IUseCasePortValidationError<IDeleteApiKeyPort> {
    const errors: IUseCasePortValidationError<IDeleteApiKeyPort> = {}
    const keys = Object.entries(port) as Entries<IDeleteApiKeyPort>

    for (const [key, value] of keys) {
      const _error = this.validator[key]?.run(value.trim())

      if (_error?.errors !== undefined) errors[key] = _error.errors
    }

    return errors
  }

}

export { DeleteApiKeyUseCase }
