import { UserPairListType } from '../../enums'
import { UserPairListEntity } from '../../entity'

import type { IUserPairListRepository, IUserPairListEntity, IGetAllUserPairListUseCase } from '../../interfaces'

class GetUserPairListUseCase implements IGetAllUserPairListUseCase {

  private _repository: IUserPairListRepository

  constructor (repository: IUserPairListRepository) {
    this._repository = repository
  }

  public async execute (): Promise<IUserPairListEntity> {
    const response = await this._repository.getLists()

    const list = response.filter((_list) => _list.type === UserPairListType.CUSTOM)
    const whiteList = response.filter((_list) => _list.type === UserPairListType.WHITE)[0]
    const blackList = response.filter((_list) => _list.type === UserPairListType.BLACK)[0]

    return UserPairListEntity.new({ id: '', list, whiteList, blackList })
  }

}

export { GetUserPairListUseCase }
