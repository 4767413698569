import { Stocks } from '@domain/common/enums'

import { BinanceOrderSocket } from '../stocks'

import type { IPairDTO } from '@domain/stocks/pair'

import type { IOrderSocket } from '../interfaces'

interface IPayload {
  stock: Stocks
  list: IPairDTO[]
}

const initOrderSocket = (payload: IPayload): IOrderSocket => {
  switch (payload.stock) {
    case Stocks.BINANCE: return new BinanceOrderSocket(payload.stock, payload.list)

    default: return new BinanceOrderSocket(payload.stock, payload.list)
  }
}

export { initOrderSocket }
