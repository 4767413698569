import { Intervals } from '@domain/stocks/trade-history'

import type { ResolutionString } from '@/charting_library'

const twResolutionToCandleInterval = (resolution: ResolutionString): Intervals => {
  switch (resolution) {
    case '1': return Intervals.m1
    case '3': return Intervals.m3
    case '5': return Intervals.m5
    case '15': return Intervals.m15
    case '30': return Intervals.m30
    case '60': return Intervals.h1
    case '120': return Intervals.h2
    case '240': return Intervals.h4
    case '360': return Intervals.h6
    case '480': return Intervals.h8
    case '720': return Intervals.h12
    case '1D': return Intervals.d1
    case '3D': return Intervals.d3
    case '1W': return Intervals.w1
    case '1M': return Intervals.M1
    default: return Intervals.m1
  }
}

const INTERVAL_ASSOCIATION: Record<string, string> = {
  '1s': '1S',
  '1m': '1',
  '3m': '3',
  '5m': '5',
  '15m': '15',
  '30m': '30',
  '1h': '60',
  '2h': '120',
  '4h': '240',
  '6h': '360',
  '8h': '480',
  '12h': '720',
  '1d': '1D',
  '3d': '3D',
  '1w': '1W',
  '1M': '1M'
}

export { twResolutionToCandleInterval, INTERVAL_ASSOCIATION }
