import { StorageService } from '@data/common/services/storages/storage-service'

import type { LocalStorageValues } from '@data/common/enums'
import type { IStorage } from '@data/common/interfaces'

class LocalStorageService
  extends StorageService<LocalStorageValues>
  implements IStorage<LocalStorageValues> {

  public override get<Response> (name: LocalStorageValues): Response {
    return super._get<Response>(name, localStorage)
  }

  public override set<Payload> (name: LocalStorageValues, payload: Payload): void {
    super._set<Payload>(name, payload, localStorage)
  }

  public override remove (name: LocalStorageValues): void {
    super._remove(name, localStorage)
  }

  public override clear (): void {
    super._clear(localStorage)
  }

}

export default new LocalStorageService()
