import { createContext, useContext, useMemo } from 'react'

import { useLicenseList, useUserLicenseList } from './hooks'

import type { ILicenseDTO, IUserLicenseDTO } from '@domain/license'

import type { FC, PropsWithChildren, Dispatch, SetStateAction } from 'react'

interface ILicenseContext {
  licenseList: ILicenseDTO[]
  setLicenseList: Dispatch<SetStateAction<ILicenseDTO[]>>
  userLicenseList: IUserLicenseDTO[]
  setUserLicenseList: Dispatch<SetStateAction<IUserLicenseDTO[]>>
}

const NotificationsContext = createContext<ILicenseContext>({
  licenseList: [],
  setLicenseList: (): void => {
    //
  },
  userLicenseList: [],
  setUserLicenseList: (): void => {
    //
  }
})

const useLicenseContext = (): ILicenseContext => useContext(NotificationsContext)

const LicenseProvider: FC<PropsWithChildren> = ({ children }) => {
  const { licenseList, setLicenseList } = useLicenseList()
  const { userLicenseList, setUserLicenseList } = useUserLicenseList()

  const context = useMemo<ILicenseContext>(() => ({
    licenseList,
    userLicenseList,
    setLicenseList,
    setUserLicenseList
  }), [
    licenseList,
    userLicenseList,
    setLicenseList,
    setUserLicenseList
  ])

  return (
    <NotificationsContext.Provider value={ context }>
      { children }
    </NotificationsContext.Provider>
  )
}

export { useLicenseContext, LicenseProvider }
