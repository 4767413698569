import { createContext, useContext, useMemo, useState } from 'react'

import { SnackbarProvider } from 'notistack'

import { Box } from '@mui/material'

import NotistackBar from '@app/components/tools/components/notistack-bar'

import { useResizePlatform } from '@app/pages/trade/components/trade-window/use-resize-platform'

import type { Dispatch, SetStateAction, FC, PropsWithChildren } from 'react'

const FullscreenContext = createContext<IFullscreenContext>({
  notistackRef: null,
  headerRef: null,
  isFullscreen: false,
  setNotistackRef: () => {
    //
  },
  setHeaderRef: () => {
    //
  },
  setIsFullscreen: () => {
    //
  }
})

const useFullscreenContext = (): IFullscreenContext => useContext(FullscreenContext)

const FullscreenProvider: FC<PropsWithChildren> = ({ children }) => {
  const [notistackRef, setNotistackRef] = useState<HTMLElement | null>(null)
  const [headerRef, setHeaderRef] = useState<HTMLElement | null>(null)
  const [isFullscreen, setIsFullscreen] = useState(false)

  const { isMobile } = useResizePlatform()

  const context = useMemo(() => ({
    notistackRef,
    isFullscreen,
    headerRef,
    setNotistackRef,
    setHeaderRef,
    setIsFullscreen
  }), [notistackRef, headerRef])

  return (
    <FullscreenContext.Provider value={ context }>
      <Box
        sx={{
          height: '100%',
          '.notistack-SnackbarContainer': {
            bottom: isMobile ? 'initial' : '26px',
            top: isMobile ? '20px' : 0
          }}}
      >
        <SnackbarProvider
          maxSnack={ 5 }
          autoHideDuration={ 3500 }
          domRoot={ notistackRef === null ? undefined : notistackRef }
          Components={{
            success: NotistackBar,
            error: NotistackBar,
            warning: NotistackBar,
            info: NotistackBar
          }}
        >
          { children }
        </SnackbarProvider>
      </Box>
    </FullscreenContext.Provider>
  )
}

interface IFullscreenContext {
  notistackRef: HTMLElement | null
  headerRef: HTMLElement | null
  isFullscreen: boolean
  setNotistackRef: Dispatch<SetStateAction<HTMLElement | null>>
  setHeaderRef: Dispatch<SetStateAction<HTMLElement | null>>
  setIsFullscreen: Dispatch<SetStateAction<boolean>>
}

export { useFullscreenContext }

export default FullscreenProvider
