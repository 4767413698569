import { BrowserList, BrowserPlatformList } from '@data/common/enums/browser'

import { ExceptionService } from '@domain/common/services'
import { InternalCode } from '@domain/common/enums'

import type { IBrowserInfo, IBrowserVersion } from '@data/common/interfaces'

class BrowserInfoService {

  private _family: BrowserList | null

  private _version: IBrowserVersion | null

  private _platform: BrowserPlatformList | null

  constructor () {
    this._family = null
    this._version = null
    this._platform = null
  }

  public getFamily (): BrowserList {
    if (this._family === null) this._init()

    return this._family!
  }

  public getVersion (): number {
    if (this._version === null) this._init()

    return this._version!.major
  }

  public getFullVersion (): string {
    if (this._version === null) this._init()

    return this._version!.full
  }

  public getPlatform (): BrowserPlatformList {
    if (this._platform === null) this._init()

    return this._platform!
  }

  private _init (): void {
    const { family, version, platform } = BrowserInfoService._getBrowserInfo()

    this._family = family
    this._version = version
    this._platform = platform
  }

  private static _getBrowserInfo (): IBrowserInfo {
    const user = window.navigator.userAgent.toLowerCase()

    const family = BrowserInfoService._getBrowserFamily(user)

    return {
      family,
      version: BrowserInfoService._getBrowserVersion(user, family),
      platform: BrowserInfoService._getBrowserPlatform(user)
    }
  }

  private static _getBrowserFamily (user: string): BrowserList {
    if (user.includes(BrowserList.YA_BROWSER)) return BrowserList.YA_BROWSER
    else if (user.includes(BrowserList.FIREFOX)) return BrowserList.FIREFOX
    else if (user.includes(BrowserList.FIREFOX_IOS)) return BrowserList.FIREFOX_IOS
    else if (user.includes(BrowserList.CHROME)) return BrowserList.CHROME
    else if (user.includes(BrowserList.CHROME_IOS)) return BrowserList.CHROME_IOS
    else if (user.includes(BrowserList.OPERA)) return BrowserList.OPERA
    else if (user.includes(BrowserList.SAFARI)) return BrowserList.SAFARI
    else if (user.includes(BrowserList.MICROSOFT_EDGE)) return BrowserList.MICROSOFT_EDGE
    else if (user.includes(BrowserList.INTERNET_EXPLORER)) return BrowserList.INTERNET_EXPLORER

    throw ExceptionService.new({
      status: {
        code: InternalCode.PROPERTY_IS_UNSUPPORTED,
        message: `Current message unsupported in ${this.constructor.name}`
      }
    })
  }

  private static _getBrowserPlatform (user: string): BrowserPlatformList {
    if (user.match(/android/i)) return BrowserPlatformList.ANDROID
    else if (user.match(/iphone/i) || user.match(/ipad/i)) return BrowserPlatformList.IOS
    return BrowserPlatformList.DESKTOP
  }

  private static _getBrowserVersion (user: string, browser: BrowserList): IBrowserVersion {
    const _version = user.split(' ').find((item) => item.match(browser))?.split('/')[1]

    if (_version !== undefined) {
      return {
        major: parseInt(_version, 10),
        full: _version
      }
    }

    return {
      major: 0,
      full: '0'
    }
  }

}

export default new BrowserInfoService()
