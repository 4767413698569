import type { Theme } from '@mui/material'

import type { Components } from '@mui/material/styles/components'

const DIALOG_DEFAULT_STYLES: Components<Theme>['MuiDialog'] = {
  styleOverrides: {
    paper: ({ theme }) => ({
      background: theme.palette.bg.secondary,
    })
  }
}

export { DIALOG_DEFAULT_STYLES }
