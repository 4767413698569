import { RequiredValidator, ValidationChain } from '@domain/common/utils/validators'

import type { IDealValidation, IFieldValidation } from '@domain/stocks/interfaces/deal'

class DealValidation implements IDealValidation {

  public validate (): IFieldValidation {
    return {
      id: new ValidationChain([new RequiredValidator()]),
      pair: new ValidationChain([new RequiredValidator()]),
      action: new ValidationChain([new RequiredValidator()]),
      price: new ValidationChain([new RequiredValidator()]),
      amount: new ValidationChain([new RequiredValidator()]),
      takeProfit: new ValidationChain([new RequiredValidator()]),
      stopLoss: new ValidationChain([new RequiredValidator()]),
      trailingStop: undefined
    }
  }

}

export { DealValidation }
