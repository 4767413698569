import { BaseProfileUseCase } from '@domain/user/common/use-case'

import type { UserResponse, IGetProfileInfoUseCase } from '@domain/user/interfaces'

class GetProfileInfoUseCase extends BaseProfileUseCase implements IGetProfileInfoUseCase {

  public async execute (): Promise<UserResponse> {
    return this._repository.getInfo()
  }

}

export { GetProfileInfoUseCase }
