import type { PaletteOptions } from '@mui/material/styles/createPalette'

const LIGHT_PALETTE: PaletteOptions = {
  mode: 'light',
  primary: {
    main: '#66CC33'
  },
  text: {
    primary: '#031424',
    secondary: '#9696A5',
  },
  secondary: {
    main: 'rgb(219, 219, 219)'
  },
  error: {
    main: '#F03A27'
  },
  warning: {
    main: '#FF952D'
  },
  bg: {
    default: '#F9F5F5',
    secondary: '#FAFAFA',
    accent: '#EBEBEB'
  },
  button: {
    primary: {
      default: '#66CC33',
      hover: '#66CC33',
      active: '#4A872B',
      disabled: '#b2e599',
      color: '#031424',
    },
    secondary: {
      default: '#DBDBDB',
      hover: '#DBDBDB',
      active: '#DBDBDB',
      disabled: '#EAEAEA',
      color: '#031424',
    },
    blue: {
      default: '#379BE7',
      hover: '#379BE7',
      active: '#377EB5',
      disabled: '#B1D5F0',
      color: '#031424',
    },
    orange: {
      default: '#FF952D',
      hover: '#FF952D',
      active: '#CA7A2D',
      disabled: '#FCC792',
      color: '#031424',
    },
    success: {
      default: '#66CC33',
      hover: '#66CC33',
      active: '#4A872B',
      disabled: '#9DE877',
      color: '#031424',
    },
    error: {
      default: '#F03A27',
      hover: '#F03A27',
      active: '#921515',
      disabled: '#F3AEA6',
      color: '#031424',
    }
  },
  favoriteStar: {
    background: '#FAFAFA',
    icon: '#031424',
    active: '#66CC33'
  },
  side: {
    buy: '#66CC33',
    sell: '#F03A27',
    color: '#031424'
  },
  chart: {
    buy: '#66CC33',
    sell: '#F03A27',
    buyShadow: 'rgba(102, 204, 51, 0.5)',
    sellShadow: 'rgba(236, 57, 39, 0.5)',
    priceLine: '#379BE7',
    takeProfitLine: '#379BE7',
    stopLossLine: '#FFF100',
    canceledLine: '#4d4d4d',
    gridLine: '#EBEBEB',
    cursorLine: '#031424',
    paintCursorLine: '#2962ff',
    volumeAxis: {
      color: '#031424',
      tag: {
        color: '#031424',
        border: '#031424',
        background: '#F9F5F5'
      }
    },
    axis: {
      color: '#031424',
      tag: {
        color: '#FAFAFA',
        border: '#031424',
        background: '#031424'
      }
    },
    historyLoader: {
      backgroundColor: '#031424',
      boxShadow: 'rgba(0,0,0,0.1)'
    },
    tradingView: {
      orderLine: {
        buyTagText: '#031424',
        sellTagText: '#ffffff',
        takeProfitTagText: '#ffffff',
        stopLossTagText: '#031424'
      },
      baseLine: {
        buyFillLine: { top: 'rgba(102, 204, 51, 0.24)', bottom: 'rgba(102, 204, 51, 0.05)' },
        sellFillLine: { top: 'rgba(240, 58, 39, 0.24)', bottom: 'rgba(240, 58, 39, 0.05)' },
      }
    }
  }
}

export { LIGHT_PALETTE }
