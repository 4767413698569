import type { IDeleteDeltaPort, IDeleteDetectDeltaUseCase, IDetectSettingRepository } from '../../interface'

class DeleteDetectDeltaUseCase implements IDeleteDetectDeltaUseCase {

  private _repository: IDetectSettingRepository

  constructor (repository: IDetectSettingRepository) {
    this._repository = repository
  }

  public async execute (port: IDeleteDeltaPort): Promise<boolean> {
    for (const id of port.ids) {
      await this._repository.deleteDetectDelta({ id })
    }

    return true
  }

}

export { DeleteDetectDeltaUseCase }
