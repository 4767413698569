enum SpecificationOrderType {
  LIMIT = 'limit',
  MARKET = 'market',
  POST_ONLY = 'post_only',
  STOP_LIMIT = 'stop_limit',
  STOP_MARKET = 'stop_market'
}

enum SpecificationToolType {
  BUTTONS = 'buttons',
  PERCENT_BUTTONS = 'percent-buttons'
}

export { SpecificationOrderType, SpecificationToolType }
