import { HttpServiceV1 } from '@data/common/services'

import type { ILimitRepository, ILimitDTO } from '@domain/stocks/interfaces'

import type { ILimitResponse } from '../interfaces'

class BinanceLimitRepository implements ILimitRepository {

  private _provider: string

  constructor (provider: string) {
    this._provider = provider
  }

  public async getLimit (): Promise<ILimitDTO> {
    return HttpServiceV1.get<ILimitResponse>(`${this._provider}/binance-limits`)
      .then((response) => {
        return {
          orderPercent: Math.floor(response.order10S * 100 / response.order10SMax),
          orderPerTenSeconds: response.order10S,
          orderPerDay: response.order1D,
          maxOrderPerTenSeconds: response.order10SMax,
          maxOrderPerDay: response.order1DMax
        }
      })
  }

}

export { BinanceLimitRepository }
