import { useMutation } from '@tanstack/react-query'

import { CreateCommissionUseCase } from '@domain/stocks/controllers'

import { useAuthContext } from '@app/common/auth'
import { useTradeContext } from '@app/pages/trade/trade-context'

import type { IPairDTO } from '@domain/stocks/pair'
import type { ICommissionEntity } from '@domain/stocks/interfaces/commission'

import type { UseMutationResult } from '@tanstack/react-query'

const useGetCommissionRequest = (): UseMutationResult<ICommissionEntity, unknown, IPairDTO[]> => {
  const { stock } = useAuthContext()
  const { setCommissionEntity } = useTradeContext()

  const callback = async (list: IPairDTO[]): Promise<ICommissionEntity> => {
    return CreateCommissionUseCase.new(stock, list).execute()
      .then((response) => {
        setCommissionEntity(response)

        return response
      })
  }

  return useMutation(['getCommission'], callback)
}

export { useGetCommissionRequest }
