import { INIT_STATE_CONTENT } from '../../constant'

import type { IContentDto, IContentRepository, IGetContentUseCase, IContentValidation } from '../../interface'
/* eslint-disable @typescript-eslint/ban-ts-comment, @typescript-eslint/no-extra-parens */
class GetContentUseCase implements IGetContentUseCase {

  private _repository: IContentRepository

  private _validation: IContentValidation

  constructor (repository: IContentRepository, validation: IContentValidation) {
    this._repository = repository
    this._validation = validation
  }

  public async execute (): Promise<IContentDto> {
    return this._repository.getContent()
      .then((response) => {
        const keys = Object.entries(response) as [keyof IContentDto, unknown][]

        for (const [key, value] of keys) {
          const result = this._validation.validate()[key].run(value).errors?.code

          if (result !== undefined || (key === 'defaultScale' && value === 0)) {
            // @ts-expect-error
            response[key] = INIT_STATE_CONTENT[key]
          }
        }

        return response
      })
  }

}

export { GetContentUseCase }
