enum BrowserList {
  FIREFOX = 'firefox',
  FIREFOX_IOS = 'fxios',

  CHROME = 'chrome',
  CHROME_IOS = 'crios',

  YA_BROWSER = 'yabrowser',
  OPERA = 'opera',
  SAFARI = 'safari',
  INTERNET_EXPLORER = 'msie',
  MICROSOFT_EDGE = 'edge',
}

enum BrowserPlatformList {
  ANDROID = 'android',
  IOS = 'ios',
  DESKTOP = 'desktop',
}

export { BrowserList, BrowserPlatformList }
