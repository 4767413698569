import type { Theme } from '@mui/material'

import type { Components } from '@mui/material/styles/components'

const SELECT_DEFAULT_STYLES: Components<Theme>['MuiSelect'] = {
  styleOverrides: {
    select: ({ theme }) => ({
      transition: theme.transitions.create(['color'], { duration: theme.transitions.duration.standard }),
      '& .Mui-disabled': {
        transition: theme.transitions.create(['color'], { duration: theme.transitions.duration.standard }),
      }
    }),
    icon: ({ theme }) => ({
      color: theme.palette.text.primary,
      fontSize: '20px !important'
    })
  }
}

export { SELECT_DEFAULT_STYLES }
