import { useTranslation } from 'react-i18next'

import { useMemo, type MouseEvent } from 'react'

import { BrowserInfoService } from '@data/common/services'
import { BrowserPlatformList } from '@data/common/enums/browser'

import { Component } from './_styles'

import { useGlowEffect } from '../../glow-effect'

import type { TouchEvent } from 'react'

interface ICardProps {
  icon: JSX.Element
  title: string
  text: string
}

let isTouchMoved = false

const Card = ({ icon, title, text }: ICardProps): JSX.Element => {
  const { t } = useTranslation()
  const glowProps = useGlowEffect()

  const isDesktop = useMemo(() => BrowserInfoService.getPlatform() === BrowserPlatformList.DESKTOP, [])

  const handlers = {
    onTouchMove: (): void => {
      isTouchMoved = true
    },
    onTouchEnd: (e: TouchEvent): void => {
      if (!isTouchMoved) e.currentTarget.classList.toggle('is-hovered')

      isTouchMoved = false
    },
    onMouseOver: (e: MouseEvent): void => {
      if (isDesktop) e.currentTarget.classList.add('is-hovered')
    },
    onMouseOut: (e: MouseEvent): void => {
      if (isDesktop) e.currentTarget.classList.remove('is-hovered')
    }
  }

  return (
    <Component.Container { ...handlers }>
      <Component.ElementGlowContainer { ...glowProps }>
        <Component.Element className="element-inner">
          <Component.ElementFront>
            <Component.Icon>{ icon }</Component.Icon>

            <Component.Text>{ t(title) }</Component.Text>
          </Component.ElementFront>

          <Component.ElementBack>
            <Component.Text>{ t(text) }</Component.Text>
          </Component.ElementBack>
        </Component.Element>
      </Component.ElementGlowContainer>
    </Component.Container>
  )
}

export { Card }
