import { Badge, Box, styled, useTheme } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { ReactComponent as BinanceLogo } from '@app/asset/icon/landing/binance.svg'
import { ReactComponent as OKXLogo } from '@app/asset/icon/landing/full-okx-logo.svg'

import { createGlowComponentStyles, useGlowEffect } from '../glow-effect'

import type { IGlowComponentProps } from '../glow-effect'

const Container = styled(Box)<IGlowComponentProps>`
  border-radius: 12px;
  background-color: ${({ theme }): string => theme.palette.bg.secondary};
  padding: 40px 60px;
  margin-top: 15px;
  width: 100%;
  max-width: 1110px;
  gap: 50px;
  position: relative;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));

  & > * {
      width: 100%;
  }

  ${({ coordinates }): string => coordinates.x === null || coordinates.y === null ? '' : createGlowComponentStyles({ x: coordinates.x, y: coordinates.y })}
`

const ExchangesContainer = (): JSX.Element => {
  const glowProps = useGlowEffect()
  const { t } = useTranslation()
  const { palette: { mode }} = useTheme()

  return (
    <Container { ...glowProps }>
      <BinanceLogo />

      <Box sx={{ height: '45px', display: 'flex', justifyContent: 'center' }}>
        <Badge badgeContent={ t('default-page/badge-soon') } color="primary">
          <OKXLogo height="100%" fill={ mode === 'dark' ? '#fff' : '#000' } />
        </Badge>
      </Box>
    </Container>
  )
}

export { ExchangesContainer }
