import { Stocks } from '@domain/common/enums'

import { BinanceDealPanicUseCase } from '@domain/stocks/binance/deal'
import { DealRepository } from '@data/repository/stocks/controllers/deal'

import type { IDealPanicUseCase } from '@domain/stocks/interfaces/deal'

class DealPanicUseCase {

  private readonly _useCases: Record<Stocks, IDealPanicUseCase> = {
    [Stocks.BINANCE]: new BinanceDealPanicUseCase(DealRepository.new(Stocks.BINANCE))
  }

  public static new (stock: Stocks): IDealPanicUseCase {
    return new DealPanicUseCase()._useCases[stock]
  }

}

export { DealPanicUseCase }
