import { GetCommonReportListUseCase } from '../../stocks'

import type { Stocks } from '@domain/common/enums'

import type { IReportRepository, IGetCommonReportListUseCase } from '../../common'

interface IPayload {
  stock: Stocks
  repository: IReportRepository
}

const initGetCommonReportListUseCase = (payload: IPayload): IGetCommonReportListUseCase => {
  return new GetCommonReportListUseCase(payload.repository)
}

export { initGetCommonReportListUseCase }
