import { GetLastReportUseCase } from '../../stocks'

import type { Stocks } from '@domain/common/enums'

import type { IReportRepository, IGetLastReportUseCase } from '../../common'

interface IPayload {
  stock: Stocks
  repository: IReportRepository
}

const initGetLastReportUseCase = (payload: IPayload): IGetLastReportUseCase => {
  return new GetLastReportUseCase(payload.repository)
}

export { initGetLastReportUseCase }
