import { INIT_STATE_AUTOBUY_BNB } from '../../constant'

import type {
  IAutoBuyValidators,
  IAutoBuyBNBRepository,
  IGetAutoBuyBNBUseCase,
  IAutoBuySettingsDTO
} from '../../interfaces'

class GetAutoBuyBNBUseCase implements IGetAutoBuyBNBUseCase {

  private _repository: IAutoBuyBNBRepository

  private _validation: IAutoBuyValidators

  constructor (repository: IAutoBuyBNBRepository, validation: IAutoBuyValidators) {
    this._repository = repository
    this._validation = validation
  }

  public async execute (): Promise<IAutoBuySettingsDTO> {
    return this._repository.getAutoBuySettings()
      .then((response) => {
        const keys = Object.entries(response) as [keyof IAutoBuySettingsDTO, unknown][]

        for (const [key, value] of keys) {
          const result = this._validation[key].run(value).errors?.code

          if (result !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            response[key] = INIT_STATE_AUTOBUY_BNB[key]
          }
        }

        if (response.buyAmount === 0) response.buyAmount = INIT_STATE_AUTOBUY_BNB.buyAmount
        if (response.minAmount === 0) response.minAmount = INIT_STATE_AUTOBUY_BNB.minAmount

        return response
      })
  }

}

export { GetAutoBuyBNBUseCase }
