import { useMutation } from '@tanstack/react-query'

import { useTradeContext } from '@app/pages/trade/trade-context'

import { GetUserPairListUseCase } from '@domain/user-pair-list'
import { UserPairListRepository } from '@data/repository/user-pair-list'

import type { UseMutationResult } from '@tanstack/react-query'

import type { IUserPairListEntity } from '@domain/user-pair-list'

const repository = new UserPairListRepository()
const useCase = new GetUserPairListUseCase(repository)

const useGetUserPairListRequest = (): UseMutationResult<IUserPairListEntity> => {
  const { setUserPairListEntity } = useTradeContext()

  const callback = async (): Promise<IUserPairListEntity> => {
    return useCase.execute()
      .then((response) => {
        setUserPairListEntity(response)

        return response
      })
  }

  return useMutation(['get user pair list'], callback)
}

export { useGetUserPairListRequest }
