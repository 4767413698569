import { Centrifuge } from 'centrifuge'

import { ExceptionService } from '@domain/common/services'
import { InternalCode } from '@domain/common/enums'

const createConnection = (connection: string): Centrifuge => {
  const env = process.env[connection]

  if (env === undefined) {
    throw ExceptionService.new({
      status: {
        code: InternalCode.PROPERTY_NOT_FOUND,
        message: `env ${connection} is undefined in createConnection`
      }
    })
  }

  return new Centrifuge(env)
}

export { createConnection }
