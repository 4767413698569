import { Controller } from 'react-hook-form'

import { useTranslation } from 'react-i18next'

import { TextField } from '@mui/material'

import { messageErrorTranslate } from '@app/services/i18n'

import type { IBaseControlledInput } from '@app/components/ui/inputs/types/base-controlled-input'
import type { TextFieldProps } from '@mui/material'
import type { ICleaveOnInputEvent } from '@app/components/ui'

const Input = <TControl, >({ control, name, ...props }: InputProps<TControl>): JSX.Element => {
  const { t } = useTranslation()
  props.label = t(String(props.label)) === 'undefined' ? props.label : t(String(props.label))
  props.placeholder = t(String(props.placeholder)) === 'undefined' ? props.placeholder : t(String(props.placeholder))

  const handleChange = (onChange: (v: string) => void) => (event: ICleaveOnInputEvent) => {
    onChange(props.isCleaveInput === true ? event.target.rawValue : event.target.value)
  }

  return (
    <Controller
      name={ name }
      control={ control }
      render={ ({ fieldState: { error }, field: { onChange: handleOnChange, value = '' }}): JSX.Element => {
        const isError = error?.message !== undefined
        const errorObject = isError ? JSON.parse(String(error.message)) as Record<string, string> : {}

        return (
          <TextField
            error={ !!error }
            helperText={ isError
              ? t(errorObject.message, messageErrorTranslate(errorObject))
              : '' }
            value={ value as unknown }
            onChange={ handleChange(handleOnChange) }
            { ...props }
          />
        )
      } }
    />
  )
}

const makeBaseInputStyles = (
  label?: string,
  type = 'text',
  placeholder?: string,
  isShrink: undefined | boolean = undefined,
  margin: 'none' | 'dense' | 'normal' | undefined = 'dense'
  // eslint-disable-next-line max-params
): Omit<Partial<InputProps<unknown>>, 'control' | 'name'> => (
  { label, placeholder, fullWidth: true, margin, type, InputLabelProps: { shrink: isShrink }}
)

type InputProps<TControl> =
  IBaseControlledInput<TControl> &
  Omit<TextFieldProps, 'value' | 'onChange'> &
  {
    isCleaveInput?: boolean
  }

export type { InputProps }

export { makeBaseInputStyles }

export default Input
