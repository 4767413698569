import { Stocks } from '@domain/common/enums'

import { BinanceMarketOverviewBySymbolSocket } from '../stocks'

import type { IMarketOverviewBySymbolSocket } from '../interfaces'

interface IPayload {
  stock: Stocks
}

const initMarketOverviewBySymbolSocket = (payload: IPayload): IMarketOverviewBySymbolSocket => {
  switch (payload.stock) {
    case Stocks.BINANCE: return new BinanceMarketOverviewBySymbolSocket()

    default: return new BinanceMarketOverviewBySymbolSocket()
  }
}

export { initMarketOverviewBySymbolSocket }
