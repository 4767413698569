import type { IPaymentLicenseDTO, IGiftLicenseUseCase, IGiftLicensePort, ILicenseRepository } from '../../interfaces'

class GiftLicenseUseCase implements IGiftLicenseUseCase {

  private _repository: ILicenseRepository

  constructor (repository: ILicenseRepository) {
    this._repository = repository
  }

  public async execute (port: IGiftLicensePort): Promise<IPaymentLicenseDTO> {
    return this._repository.giftLicense(port)
  }

}

export { GiftLicenseUseCase }
