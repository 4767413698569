import type { Components } from '@mui/material/styles/components'
import type { Theme } from '@mui/material'

const TABLE_CELL_STYLES: Components<Theme>['MuiTableCell'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderBottom: `1px solid ${theme.palette.bg.accent}`,
      transition: theme.transitions.create(['color', 'border'], { duration: theme.transitions.duration.standard }),
      '.MuiSvgIcon-root': {
        transition: 'none'
      }
    }),
    head: {
      '.MuiButtonBase-root': {
        transition: 'none'
      },
    }
  }
}

export { TABLE_CELL_STYLES }
