import { Box, styled, Typography } from '@mui/material'
import { CheckCircle } from '@mui/icons-material'

import { ButtonSecondary } from '@app/components/ui/theme/ui/buttons'

const Components = {
  Wrapper: styled(Box)`
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 15px;
    background: ${({ theme }): string => theme.palette.bg.secondary};
    padding: 20px;
    min-height: 400px;
    max-width: 300px;
    width: 100%;
    user-select: none;
    position: relative;
  `,
  DiscountContainer: styled(Box)`
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    font-size: 20px;
    padding: 5px 10px;
    border-radius: 10px;
    background: ${({ theme }): string => theme.palette.primary.main};
  `,
  DiscountTimer: styled(Box)`
    font-size: 14px;
  `,
  Title: {
    Wrapper: styled(Typography)`
      padding: 10px 0 20px;
    `,
    Title: styled(Typography)`
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      margin: 0 0 5px;
      color: ${({ theme }): string => theme.palette.text.primary};
    `,
    PriceContainer: styled(Box)`
      display: flex;
      align-items: flex-start;
      column-gap: 5px;
    `,
    Price: styled(Typography)`
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      margin: 0 0 5px;
      color: ${({ theme }): string => theme.palette.text.primary};
    `,
    OldPrice: styled(Typography)`
      font-size: 20px;
      text-decoration: line-through;
      color: ${({ theme }): string => theme.palette.text.primary};
    `,
    DiscountInfoContainer: styled(Box)`
      display: flex;
      flex-direction: column;
      font-size: 10px;
      color: ${({ theme }): string => theme.palette.text.secondary};
    `,
  },
  List: {
    Wrapper: styled(Box)`
      display: flex;
      flex-direction: column;
      grid-gap: 10px;
      padding: 0 0 20px;
    `,
    Benefits: {
      Wrapper: styled(Box)`
        display: flex;
        align-items: center;
        grid-gap: 10px;
      `,
      Icon: styled(CheckCircle)`
        font-weight: 700;
        color: ${({ theme }): string => theme.palette.text.secondary};
      `,
      Title: styled(Typography)`
        font-weight: 500;
        font-size: 14px;
        color: ${({ theme }): string => theme.palette.text.secondary};
      `
    }
  },
  Button: styled(ButtonSecondary)`
  `
}

export { Components }
