import { AbstractValidator } from '@domain/common/utils/validators/abstract-validator'

import { ValidationCode } from '@domain/common/enums'

import type { IValidationError } from '@domain/common/utils/validators'

class NotEqualValidationHotKey extends AbstractValidator {

  private readonly _exceptValues: string[][]

  constructor (exceptValue: string[][]) {
    super()
    this._exceptValues = exceptValue
  }

  public override validate (value: string[]): IValidationError {
    if (this._validate(value.filter((tt) => tt !== '0' && tt !== '1' && tt !== '2'))) {
      return {
        isSuccess: false,
        errors: {
          code: ValidationCode.INVALID_EXCEPTED_VALUE
        }
      }
    }
    return super.validate(value)
  }

  private _validate (value: string[]): boolean {
    let isInvalid = false
    for (const item of this._exceptValues) {
      if (item.length !== value.length) continue
      const diff = item.filter((x) => value.includes(x))
      if (diff.length === value.length) {
        isInvalid = true
        break
      }
    }
    return isInvalid
  }

}

export { NotEqualValidationHotKey }
