enum ChartTypes {
  MARKER = 'tick',
  CANDLESTICK = 'candle',
  TW_CANDLESTICK = 'tw-candle',
}

enum CandlestickChartVariant {
  DEFAULT = 'default',
  EMPTY = 'empty',
  BARS = 'bars',
  HA = 'heikin-ashi',
  BASE_LINE = 'base-line'
}

enum TickChartVariant {
  RECT = 'rect',
  CROSS = 'cross',
  VOLUME_CIRCLE = 'volume-circle'
}

export { ChartTypes, CandlestickChartVariant, TickChartVariant }
