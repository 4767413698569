import { useMutation } from '@tanstack/react-query'

import { CookiesAcceptRepository } from '@data/repository/cookies-accept'
import { SaveCookiesAcceptUseCase } from '@domain/cookies-accept'

import type { UseMutationResult } from '@tanstack/react-query'

type UseSetCookiesAcceptResponse = UseMutationResult

const useSetCookiesAccept = (): UseSetCookiesAcceptResponse => {
  const repository = new CookiesAcceptRepository()
  const useCase = new SaveCookiesAcceptUseCase(repository)

  const callback = async (): Promise<void> => {
    return useCase.execute()
  }

  return useMutation(callback)
}

export { useSetCookiesAccept }
