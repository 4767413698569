import type { IMarketOverviewBySymbolSocket } from '../../../interfaces'

import type { CentrifugeService } from '@data/common/services'
import type { Stocks } from '@domain/common/enums'
import type { IPairDTO } from '@domain/stocks/pair'
import type { IMarketOverviewDTO } from '@domain/stocks/market-overview'

abstract class MarketOverviewBySymbolSocket<Data> implements IMarketOverviewBySymbolSocket {

  protected socket: CentrifugeService<Data> | null

  protected pair: IPairDTO | null

  protected channel: string

  constructor (channel: string) {
    this.channel = channel

    this.socket = null
    this.pair = null
  }

  public abstract subscribe (pair: IPairDTO, stock: Stocks): void

  public unsubscribe (): void {
    this.socket?.close()
  }

  public abstract onMessage (callback: (message: IMarketOverviewDTO) => void): void

}

export { MarketOverviewBySymbolSocket }
