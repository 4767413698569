import { memo, Suspense } from 'react'

import { BrowserRouter, Navigate, Outlet, useRoutes } from 'react-router-dom'

import AppInstallHandlerProvider from '@app/components/tools/components/handlers/app-install-handler'

import CookiesAcceptHandler from '@app/components/tools/components/handlers/cookies-accept-handler'

import { useAuthContext } from './auth'

import type { RouteObject } from 'react-router-dom'

import type { FC, NamedExoticComponent } from 'react'

/**
 * Router provider
 */

interface IRouterProps {
  routes: RouteObject[]
}

const MakeRoutes: FC<Pick<IRouterProps, 'routes'>> = ({ routes }) => <>{ useRoutes(routes) }</>

// eslint-disable-next-line react/no-multi-comp
const RouterProvider: FC<IRouterProps> = ({ routes }) => {
  return (
    <BrowserRouter>
      <AppInstallHandlerProvider>
        <CookiesAcceptHandler />

        <MakeRoutes routes={ routes } />
      </AppInstallHandlerProvider>
    </BrowserRouter>
  )
}

/**
 * Route guards
 */

// eslint-disable-next-line react/no-multi-comp
const AuthGuard: FC<{ navigateToFail?: string }> = ({ navigateToFail = '/' }) => {
  const { isAuth } = useAuthContext()
  if (isAuth) return <Outlet />
  return <Navigate to={ navigateToFail } />
}

// eslint-disable-next-line react/no-multi-comp
const RoleGuard: FC<{ role: string | string[]; navigateToFail?: string }> = ({ role, navigateToFail = '/' }) => {
  const { user } = useAuthContext()
  if (user?.role !== undefined && [role].flat().includes(user.role)) return <Outlet />
  return <Navigate to={ navigateToFail } />
}

// eslint-disable-next-line react/no-multi-comp
const GuestGuard: FC<{ navigateToFail?: string }> = ({ navigateToFail = '/' }) => {
  const { isAuth } = useAuthContext()
  if (!isAuth) return <Outlet />
  return <Navigate to={ navigateToFail } />
}

// eslint-disable-next-line react/no-multi-comp
const TestGuard: FC<{ navigateToFail?: string }> = ({ navigateToFail = '/' }) => {
  if (['LOCAL', 'OFFICE'].includes(process.env.REACT_APP_STAGE ?? '')) return <Outlet />
  return <Navigate to={ navigateToFail } />
}

const GUARDS = {
  AuthGuard,
  RoleGuard,
  GuestGuard,
  TestGuard
}

/**
 * Lazy loading components
 *
 * @param Component
 * @constructor
 */

// eslint-disable-next-line react/display-name,react/no-multi-comp
const LoadComponent = (Component: FC): NamedExoticComponent<any> => memo((props: object) => (
  <Suspense fallback={ <div /> }>
    <Component { ...props } />
  </Suspense>
))

export {
  LoadComponent,
  RouterProvider,
  GUARDS
}
