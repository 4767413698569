import type { IStopLossDto } from '../interface'

const INIT_STATE_STOP_LOSE: IStopLossDto = {
  isIncludeSl: true,
  trailing: false,
  trailingSpread: 0,
  delay: 0,
  percentSl: 3,
  spread: 0.5,
  orderTypeSl: 'market'
}

export { INIT_STATE_STOP_LOSE }
