import type { IGetDealListPort, IDealDTO, IDealGetUseCase, IDealRepository } from '@domain/stocks/interfaces/deal'

class BinanceDealGetUseCase implements IDealGetUseCase {

  private _repository: IDealRepository

  constructor (repository: IDealRepository) {
    this._repository = repository
  }

  public async execute (port: IGetDealListPort): Promise<IDealDTO[]> {
    return this._repository.getDeals(port)
  }

}

export { BinanceDealGetUseCase }
