import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'

import { useQuery } from '@tanstack/react-query'

import { InternalCode } from '@domain/common/enums'
import { ERROR_MESSAGES } from '@app/services/i18n/_messages'

import { GetContentUseCase } from '@domain/setting-profile/content'

import { ContentRepository } from '@data/repository/setting-profile/content'

import { ContentValidation } from '@domain/setting-profile/content/use-case/update-content/validation'

import type { IContentMutate } from '@app/common/settings/content/interface'

import type { Dispatch, SetStateAction } from 'react'

import type { IContentDto } from '@domain/setting-profile/content'

type SetterContent = Dispatch<SetStateAction<IContentDto>>
type SetterLoaded = Dispatch<SetStateAction<boolean>>

const useGetContent = (setterContent: SetterContent, setterLoaded: SetterLoaded): IContentMutate => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const repository = new ContentRepository()
  const validation = new ContentValidation()
  const useCase = new GetContentUseCase(repository, validation)

  const callback = async (): Promise<IContentDto> => {
    return useCase.execute()
  }

  return useQuery(
    ['get content'],
    callback,
    {
      onSuccess: (value) => {
        setterContent(value)
        setterLoaded(true)
      },
      onError: (error) => {
        if (error.code === InternalCode.SERVER_ERROR) {
          enqueueSnackbar(t(ERROR_MESSAGES[InternalCode.SERVER_ERROR]), { variant: 'error' })
        }
      },
      enabled: false
    }
  )
}

export { useGetContent }
