import { BaseLicenseUseCase } from '@domain/license/common/use-case'

import type { ILicensePortWithId, IActivateLicenseUseCase } from '@domain/license'

class ActivateLicenseUseCase extends BaseLicenseUseCase implements IActivateLicenseUseCase {

  public async execute (port: ILicensePortWithId): Promise<void> {
    await this._repository.activateLicense(port)
  }

}

export { ActivateLicenseUseCase }
