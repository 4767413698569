import type { IUseCase } from '@domain/common/interfaces'

import type { IRepositoryCookiesAccept } from '@domain/cookies-accept'

class SaveCookiesAcceptUseCase implements IUseCase<never, void> {

  private _repository: IRepositoryCookiesAccept

  constructor (repository: IRepositoryCookiesAccept) {
    this._repository = repository
  }

  public async execute (): Promise<void> {
    return this._repository.setCookiesAccept()
  }

}

export { SaveCookiesAcceptUseCase }
