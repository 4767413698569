import type { Theme } from '@mui/material'

import type { Components } from '@mui/material/styles/components'

const TOOLTIP_DEFAULT_STYLES: Components<Theme>['MuiTooltip'] = {
  styleOverrides: {
    tooltip: ({ theme }) => ({
      background: theme.palette.bg.secondary,
      color: theme.palette.text.primary,
      boxShadow: '0 0 5px rgba(0,0,0,0.2)'
    })
  }
}

export { TOOLTIP_DEFAULT_STYLES }
