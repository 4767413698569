import type {
  IGetOpenOrderListPort,
  ICreateOrderPort,
  IUpdateOrderPort,
  ICancelOrderPort,
  IOrderDTO,
  IOrderRepository
} from '@domain/stocks/order'

abstract class OrderRepository implements IOrderRepository {

  protected provider: string

  constructor (provider: string) {
    this.provider = provider
  }

  public abstract getOpenOrderList (port: IGetOpenOrderListPort): Promise<IOrderDTO[]>

  public abstract createOrder (port: ICreateOrderPort): Promise<boolean>

  public abstract updateOrder (port: IUpdateOrderPort): Promise<boolean>

  public abstract cancelOrder (port: ICancelOrderPort): Promise<boolean>

}

export { OrderRepository }
