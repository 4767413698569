import type {
  IGetCandleHistoryListUseCase,
  ITradeHistoryRepository,
  IGetCandleHistoryListPort,
  ICandleDTO
} from '../../../../common'

class GetCandleHistoryListUseCase implements IGetCandleHistoryListUseCase {

  protected readonly repository: ITradeHistoryRepository

  constructor (repository: ITradeHistoryRepository) {
    this.repository = repository
  }

  public async execute (port: IGetCandleHistoryListPort): Promise<ICandleDTO[]> {
    return this.repository.getCandleList(port)
  }

}

export { GetCandleHistoryListUseCase }
