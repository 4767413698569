import { Stocks } from '@domain/common/enums'

import { UpdateBinanceOrderUseCase } from '../../stocks'

import type { IOrderRepository, IUpdateOrderUseCase } from '../../common'

interface IPayload {
  stock: Stocks
  repository: IOrderRepository
}

const initUpdateOrderUseCase = (payload: IPayload): IUpdateOrderUseCase => {
  switch (payload.stock) {
    case Stocks.BINANCE: return new UpdateBinanceOrderUseCase(payload.repository)

    default: return new UpdateBinanceOrderUseCase(payload.repository)
  }
}

export { initUpdateOrderUseCase }
