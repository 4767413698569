import { HttpServiceV1 } from '@data/common/services'

import { ExceptionService } from '@domain/common/services'
import { InternalCode } from '@domain/common/enums'

import { HotKeyEvents } from '@domain/setting-profile/hot-keys'

import type {
  IChangeScale,
  IGetHotKeysDto,
  IHotKeysErrors,
  IHotKeysRepository,
  IUpdateHotKeysPort
  , IResponseHotKeysDto, IHotKeyMultipleEntity } from '@domain/setting-profile/hot-keys'

import type { IHotKeysResponseErrors, IHotKeysServerError } from '@data/repository/setting-profile/hot-keys/interface'

import type { IHttpError } from '@data/common/interfaces'

class HotKeysRepository implements IHotKeysRepository {

  public async getAll (): Promise<IGetHotKeysDto> {
    return HttpServiceV1.get<IResponseHotKeysDto>('hot-key/list')
      .then((res) => {
        const changeScale: IChangeScale = { event: HotKeyEvents.CHANGE_SCALE, countButton: 0, data: []}
        const orderSize: IHotKeyMultipleEntity = { event: HotKeyEvents.ORDER_SIZE, data: []}
        const data = res.sort((a, b) => a.event > b.event ? 1 : -1).map((item) => {
          if (item.event.match(/change_scale/)) {
            changeScale.data.push({ command: item.command, option: item.option, isInclude: item.isInclude })
          }
          if (item.event.match(/order_size/)) {
            orderSize.data.push({ command: item.command, option: item.option, isInclude: item.isInclude })
          }
          return item
        })
        return [
          ...data.filter((tt) => tt.event.match(/change_scale/) === null && tt.event.match(/order_size/) === null),
          { ...changeScale, countButton: changeScale.data.length },
          orderSize
        ]
      })
      .catch(() => {
        throw ExceptionService.new({
          status: {
            code: InternalCode.SERVER_ERROR,
            message: 'Server return bad request'
          }
        })
      })
  }

  public async update (port: IUpdateHotKeysPort): Promise<boolean> {
    return HttpServiceV1.put('hot-key/update', { body: port })
      .then(() => true)
      .catch((error: IHttpError<IHotKeysResponseErrors | IHotKeysServerError>) => {
        throw this._throwError(error)
      })
  }

  // eslint-disable-next-line max-len
  private _throwError (error: IHttpError<IHotKeysResponseErrors | IHotKeysServerError>): ExceptionService<IHotKeysErrors> {
    const code = error.errors === 'server_error' ? InternalCode.SERVER_ERROR : InternalCode.VALIDATION_ERROR
    const message = 'Backand return errors'

    return ExceptionService.new({
      status: {
        code,
        message
      },
      data: {
        logical: error.errors === 'server_error'
          ? InternalCode.SERVER_ERROR
          : +error.errors.code
      }
    })
  }

}

export { HotKeysRepository }
