import { ExceptionService } from '@domain/common/services'
import { InternalCode } from '@domain/common/enums'

import type { ISocketService, IClosePayload } from '@data/common/interfaces'

class WebsocketService<Message> implements ISocketService<Event, CloseEvent, Message, Event> {

  private _socket: WebSocket

  constructor (connection: string, channel: string, protocols?: string | string[]) {
    const env = process.env[connection]

    if (env === undefined) {
      throw ExceptionService.new({
        status: {
          code: InternalCode.PROPERTY_NOT_FOUND,
          message: `env ${connection} is undefined in ${this.constructor.name}`
        }
      })
    }

    this._socket = new WebSocket(`${env}/${channel}`, protocols)
  }

  public send (data: string | ArrayBufferLike | Blob | ArrayBufferView): void {
    this._socket.send(data)
  }

  public close (payload?: IClosePayload): void {
    this._socket.close(payload?.code, payload?.reason)
  }

  public onConnect (callback: (message: Event) => void): void {
    this._socket.addEventListener('open', (event: Event) => {
      callback(event)
    })
  }

  public onClose (callback: (message: CloseEvent) => void): void {
    this._socket.addEventListener('close', (event: CloseEvent) => {
      callback(event)
    })
  }

  public onMessage (callback: (message: Message) => void): void {
    this._socket.addEventListener('message', (event: MessageEvent<string>) => {
      callback(JSON.parse(event.data) as Message)
    })
  }

  public onError (callback: (message: Event) => void): void {
    this._socket.addEventListener('error', (event: Event) => {
      callback(event)
    })
  }

}

export { WebsocketService }
