import { DEFAULT_PAIR } from '../../../../common'

import type { IGetSelectedPairUseCase, IPairDTO, IPairRepository } from '../../../../common'

class GetSelectedPairUseCase implements IGetSelectedPairUseCase {

  protected repository: IPairRepository

  constructor (repository: IPairRepository) {
    this.repository = repository
  }

  public async execute (port: IPairDTO[]): Promise<IPairDTO> {
    const dto = await this.repository.getSelectedPair()

    const selectedPairBySymbol = port.find((_pair) => _pair.ticker.symbol === dto.symbol)
    const selectedPairByPair = port.find((_pair) => _pair.ticker.symbol === dto.pair.ticker.symbol)
    const selectedDefaultPair = port.find((_pair) => _pair.ticker.symbol === DEFAULT_PAIR.ticker.symbol)

    if (selectedPairBySymbol) return selectedPairBySymbol
    if (selectedPairByPair) return selectedPairByPair
    if (selectedDefaultPair) return selectedDefaultPair

    return DEFAULT_PAIR
  }

}

export { GetSelectedPairUseCase }
