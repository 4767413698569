import Cleave from 'cleave.js/react'

import { useMemo, useRef } from 'react'

import Input from '@app/components/ui/inputs/input'

import { getLanguage } from '@app/services/i18n'

import { LanguageResources } from '@app/services/i18n/language'

import type { Props } from 'cleave.js/react/props'

import type { ChangeEvent, FC, RefObject } from 'react'

import type { InputBaseComponentProps } from '@mui/material'

import type { CleaveOptions } from 'cleave.js/options'

import type { InputProps } from '@app/components/ui/inputs/input'

type InputCleaveProps<TControl> = Omit<InputProps<TControl>, 'type' | 'onInput'> & {
  options?: CleaveOptions & { rerender?: boolean }
  onInput? (event: ICleaveOnInputEvent): void
  onChange? (event: ICleaveOnInputEvent): void
}

interface ICleaveOnInputEvent<T = HTMLInputElement> extends ChangeEvent<T> {
  target: { rawValue: string } & EventTarget & T
}

const switchLang = (lang: LanguageResources): string => {
  switch (lang) {
    case LanguageResources.EN:
      return ','
    case LanguageResources.RU:
      return ' '
    default:
      return ','
  }
}

/* eslint-disable */

const CleaveInput: FC<InputBaseComponentProps & Props> = (props) => {
  const { ...other } = props
  const lang = getLanguage()

  const cleaveOptions = useMemo(() => ({
      delimiter: other?.options?.numeral ? switchLang(lang) : undefined,
      ...other.options
  }), [lang, other.options])

  const key = useMemo(() => {
      return `${JSON.stringify(cleaveOptions)}`
  }, [cleaveOptions])

  const inputRef = useRef<RefObject<HTMLInputElement> | null>(null)

  return <Cleave {...other} key={ key } options={ cleaveOptions } htmlRef={(ref) => { inputRef.current = ref }} />;
};


const InputCleave = <TControl,>(props: InputCleaveProps<TControl>): JSX.Element => {
  const { options, InputProps,  ...other } = props
  return (
    <Input
      { ...other }
      isCleaveInput={ true }
      InputProps={{
        ...InputProps,
        inputComponent: CleaveInput as any,
        inputProps: {
          ...InputProps?.inputProps,
          options
        },
      }}
    />
  )
}

export default InputCleave

export type { ICleaveOnInputEvent }
