import { styled } from '@mui/material'

import { ButtonSecondary } from '@app/components/ui/theme/ui/buttons'

const Components = {
  Button: styled(ButtonSecondary)`
    min-width: 150px;
  `
}

export { Components }
