import { AddToUserPairListUseCase } from '../add-to-list-use-case'

import type { IAddToDefaultUserPairListPort } from '../../interfaces'

class AddUserFavoritePairUseCase extends AddToUserPairListUseCase {

  protected override async runActions (port: IAddToDefaultUserPairListPort): Promise<void> {
    for (const symbol of port.symbols) {
      await this.repository.addPair({ id: this.entity.whiteList.id, symbol: symbol })
      await this.entity.addPairToWhiteList(symbol)
    }
  }

}

export { AddUserFavoritePairUseCase }
