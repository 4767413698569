import { Stocks } from '@domain/common/enums'

import { BinanceDealRepository } from '@data/repository/stocks/binance/deal'

import type { IDealRepository } from '@domain/stocks/interfaces/deal'

class DealRepository {

  private readonly _repositories: Record<Stocks, IDealRepository> = {
    [Stocks.BINANCE]: new BinanceDealRepository('binance-order'),
  }

  public static new (stock: Stocks): IDealRepository {
    return new DealRepository()._repositories[stock]
  }

}

export { DealRepository }
