import { ExceptionService } from '@domain/common/services'
import { InternalCode, ValidationCode } from '@domain/common/enums'
import { OrderSide } from '@domain/stocks/order'

import type { IDealErrorsUpdate, IDealsUpdatePort, IDealValidation } from '@domain/stocks/interfaces/deal'

class BaseBinanceDealUpdateUseCase {

  private _validation: IDealValidation

  constructor (validation: IDealValidation) {
    this._validation = validation
  }

  protected throwErrors (port: IDealsUpdatePort): void {
    const errors = this.validatePort(port)

    if (port.takeProfit.percent !== port.takeProfit.oldPercent || port.takeProfit.isInclude) {
      const validateTakeProfitPercentData = this.validatePercent('takeProfit', port)

      if (validateTakeProfitPercentData !== undefined) errors.takeProfit = validateTakeProfitPercentData
    }

    if (port.stopLoss.percent !== port.stopLoss.oldPercent || port.stopLoss.isInclude) {
      const validateStopLossPercentData = this.validatePercent('stopLoss', port)

      if (validateStopLossPercentData !== undefined) errors.stopLoss = validateStopLossPercentData
    }

    if (Object.keys(errors).length) {
      throw ExceptionService.new({
        status: {
          code: InternalCode.VALIDATION_ERROR,
          message: `${this.constructor.name} validation port error`
        },
        data: errors
      })
    }
  }

  protected validatePort (port: IDealsUpdatePort): IDealErrorsUpdate {
    const keys = Object.entries(port) as [keyof IDealsUpdatePort, unknown][]

    const errors: IDealErrorsUpdate = {}
    const validation = this._validation.validate()

    for (const [key, value] of keys) {
      const result = validation[key]?.run(value).errors?.code
      if (result !== undefined) errors[key] = result
    }

    return errors
  }

  protected validatePercent (target: 'takeProfit' | 'stopLoss', port: IDealsUpdatePort): ValidationCode | undefined {
    const highPrice = Number(port.price) * (100 + Number(port[target].percent)) / 100
    const lowPrice = Number(port.price) * (100 - Number(port[target].percent)) / 100

    const isBuyTakeProfit = port.action === OrderSide.BUY && target === 'takeProfit'
    const isSellStopLoss = port.action === OrderSide.SELL && target === 'stopLoss'

    const price = isBuyTakeProfit || isSellStopLoss ? lowPrice : highPrice

    if (price * Number(port.amount) < Number(port.pair.limits.limit.last.min)) return ValidationCode.INVALID_LIMIT

    return undefined
  }

}

export { BaseBinanceDealUpdateUseCase }
