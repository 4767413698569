import type { IGetUnpaidRequestUseCase, ILicenseRepository, IUnpaidLicenseDTO } from '../../interfaces'

class GetUnpaidLicenseUseCase implements IGetUnpaidRequestUseCase {

  private _repository: ILicenseRepository

  constructor (repository: ILicenseRepository) {
    this._repository = repository
  }

  public async execute (): Promise<IUnpaidLicenseDTO[]> {
    return this._repository.getUnpaidLicense()
  }

}

export { GetUnpaidLicenseUseCase }
