import { RequiredValidator, ValidationChain } from '@domain/common/utils/validators'

import type { IFieldValidate, IStopLossValidation } from '@domain/setting-profile/stop-loss'

class StopLossValidation implements IStopLossValidation {

  public validate (): IFieldValidate {
    return {
      isIncludeSl: new ValidationChain([new RequiredValidator()]),
      delay: new ValidationChain([new RequiredValidator()]),
      percentSl: new ValidationChain([new RequiredValidator(true)]),
      spread: new ValidationChain([new RequiredValidator()]),
      orderTypeSl: new ValidationChain([new RequiredValidator()]),
      trailing: new ValidationChain([new RequiredValidator()]),
      trailingSpread: new ValidationChain([new RequiredValidator()])
    }
  }

}
export { StopLossValidation }
