import { ValidateNested } from 'class-validator'

import { Entity } from '@domain/common/entity'

import type { IPairEntity, IPairDTO, ICreatePairEntityPayload, IAsset } from '../interfaces'

class PairEntity extends Entity<string> implements IPairEntity {

  @ValidateNested()
  public pair: IPairDTO

  public list: IPairDTO[]

  private constructor (payload: ICreatePairEntityPayload) {
    super(payload.id)

    this.pair = payload.pair
    this.list = payload.list
  }

  get mainAsset (): IAsset {
    return this.pair.mainAsset
  }

  get lastAsset (): IAsset {
    return this.pair.lastAsset
  }

  public getPairByTicker (value: string, target: keyof IPairDTO['ticker']): IPairDTO | undefined {
    return this.list.find((pair) => pair.ticker[target] === value)
  }

  public async clearPairList (): Promise<void> {
    this.list = []

    await this.validate()
  }

  public async changePair (value: IPairDTO): Promise<void> {
    this.pair = value

    await this.validate()
  }

  public static async new (payload: ICreatePairEntityPayload): Promise<IPairEntity> {
    const pair = new PairEntity(payload)
    await pair.validate()

    return pair
  }

}

export { PairEntity }
