import { useEffect, useState } from 'react'

import { INIT_STATE_TAKE_PROFIT } from '@domain/setting-profile/take-profit/constant'

import { useAuthContext } from '@app/common/auth'

import { useGetTakeProfit } from '@app/common/settings/take-profit/request'

import type { ITakeProfitResult } from '@app/common/settings/take-profit/interface'

import type { ITakeProfitDTO } from '@domain/setting-profile/take-profit'

const useTakeProfit = (): ITakeProfitResult => {
  const [takeProfit, setTakeProfit] = useState<ITakeProfitDTO>(INIT_STATE_TAKE_PROFIT)
  const { refetch } = useGetTakeProfit(setTakeProfit)
  const { isAuth } = useAuthContext()

  useEffect(() => {
    if (isAuth) void refetch()
    else setTakeProfit(INIT_STATE_TAKE_PROFIT)
  }, [isAuth])

  return {
    takeProfit,
    setTakeProfit,
    refetch
  }
}

export { useTakeProfit }
