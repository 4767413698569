import { createContext, useContext, useMemo, useState } from 'react'

import type { PartialRecord } from '@domain/common/utils'

import type { ITableBody } from '@app/pages/trade/trade-tables/tables/deal-table/_settings'
import type { IClosedDealDTO, IDealDTO } from '@domain/stocks/interfaces/deal'
import type { Dispatch, FC, PropsWithChildren, SetStateAction } from 'react'

interface IDealContext {
  deals: IDealDTO[]
  setDeals: Dispatch<SetStateAction<IDealDTO[]>>
  closedDeals: IClosedDealDTO[]
  tableRows: ITableBody[]
  currentDealPrice: PartialRecord<string, string>
  setClosedDeals: Dispatch<SetStateAction<IClosedDealDTO[]>>
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
  selectedDealIds: number[]
  setSelectedDealIds: Dispatch<SetStateAction<number[]>>
  setTableRows: Dispatch<SetStateAction<ITableBody[]>>
  setCurrentDealPrice: Dispatch<SetStateAction<PartialRecord<string, string>>>
}

const DealContext = createContext<IDealContext>({
  deals: [],
  closedDeals: [],
  selectedDealIds: [],
  tableRows: [],
  currentDealPrice: {},
  isLoading: false,
  setDeals: () => {
    //
  },
  setClosedDeals: () => {
    //
  },
  setIsLoading: () => {
    //
  },
  setSelectedDealIds: () => {
    //
  },
  setTableRows: () => {
    //
  },
  setCurrentDealPrice: () => {
    //
  }
})

const useDealContext = (): IDealContext => useContext(DealContext)

const DealProvider: FC<PropsWithChildren> = ({ children }) => {
  const [deals, setDeals] = useState<IDealDTO[]>([])
  const [closedDeals, setClosedDeals] = useState<IClosedDealDTO[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectedDealIds, setSelectedDealIds] = useState<number[]>([])
  const [tableRows, setTableRows] = useState<ITableBody[]>([])
  const [currentDealPrice, setCurrentDealPrice] = useState<PartialRecord<string, string>>({})

  const context = useMemo(() => ({
    deals,
    setDeals,
    closedDeals,
    tableRows,
    isLoading,
    currentDealPrice,
    setIsLoading,
    selectedDealIds,
    setClosedDeals,
    setSelectedDealIds,
    setTableRows,
    setCurrentDealPrice
  }), [
    deals,
    closedDeals,
    tableRows,
    currentDealPrice,
    setClosedDeals,
    setSelectedDealIds,
    isLoading,
    selectedDealIds,
    setTableRows,
    setCurrentDealPrice
  ])

  return (
    <DealContext.Provider value={ context }>
      { children }
    </DealContext.Provider>
  )
}

export {
  DealProvider,
  useDealContext
}
