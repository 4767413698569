import { Stocks } from '@domain/common/enums'

import { SetBinanceActiveApiKeyUseCase } from '../../stocks'

import type { IApiKeyRepository, ISetActiveApiKeyUseCase } from '../../common'

interface IPayload {
  stock: Stocks
  repository: IApiKeyRepository
}

const initSetActiveApiKeyUseCase = (payload: IPayload): ISetActiveApiKeyUseCase => {
  switch (payload.stock) {
    case Stocks.BINANCE: return new SetBinanceActiveApiKeyUseCase(payload.repository)

    default: return new SetBinanceActiveApiKeyUseCase(payload.repository)
  }
}

export { initSetActiveApiKeyUseCase }
