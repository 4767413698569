import { ExceptionService } from '@domain/common/services'
import { InternalCode } from '@domain/common/enums'

import { SetActiveApiKeyUseCase } from '../../../_'
import { SET_ACTIVE_BINANCE_API_KEY_VALIDATOR } from '../../validators'

import type { ISetActiveApiKeyUseCase, ISetActiveApiKeyPort, IApiKeyRepository } from '../../../../common'

class SetBinanceActiveApiKeyUseCase extends SetActiveApiKeyUseCase implements ISetActiveApiKeyUseCase {

  constructor (repository: IApiKeyRepository) {
    super(repository, SET_ACTIVE_BINANCE_API_KEY_VALIDATOR)
  }

  public override async execute (port: ISetActiveApiKeyPort): Promise<boolean> {
    const errors = this.validatePort(port)

    if (Object.keys(errors).length) {
      throw ExceptionService.new({
        status: {
          code: InternalCode.VALIDATION_ERROR,
          message: 'DeleteBinanceApiKeyUseCase port validation error'
        },
        data: errors
      })
    }

    return this.repository.setActiveApiKey(port)
  }

}

export { SetBinanceActiveApiKeyUseCase }
