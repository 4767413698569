import { INIT_STATE_TAKE_PROFIT } from '../constant'

import type { ITakeProfitValidation, IGetTakeProfitUseCase, ITakeProfitDTO, ITakeProfitRepository } from '../interface'

class GetTakeProfitUseCase implements IGetTakeProfitUseCase {

  private _repository: ITakeProfitRepository

  private _validation: ITakeProfitValidation

  constructor (repository: ITakeProfitRepository, validation: ITakeProfitValidation) {
    this._repository = repository
    this._validation = validation
  }

  public async execute (): Promise<ITakeProfitDTO> {
    return this._repository.getTakeProfit()
      .then((response) => {
        const keys = Object.entries(response) as [keyof ITakeProfitDTO, unknown][]

        for (const [key, value] of keys) {
          const result = this._validation.validate()[key].run(value).errors?.code

          if (result !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            response[key] = INIT_STATE_TAKE_PROFIT[key]
          }
        }

        return response
      })
  }

}

export { GetTakeProfitUseCase }
