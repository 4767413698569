import { useMutation } from '@tanstack/react-query'

import { useAuthContext } from '@app/common/auth'
import { useReportContext } from '@app/pages/trade/trade-tables/tables/report-table/report-context'

import { initReportRepository } from '@data/repository/stocks/report'
import { initGetCommonReportListUseCase } from '@domain/stocks/report'

import type { ICommonReportDTO, IGetListReportPort } from '@domain/stocks/report'

import type { UseMutationResult } from '@tanstack/react-query'

type UseDetailReportListRequest = UseMutationResult<ICommonReportDTO[], unknown, IGetListReportPort>

const useGetCommonReportListRequest = (): UseDetailReportListRequest => {
  const { stock } = useAuthContext()
  const { setReportList } = useReportContext()

  const callback = async (port: IGetListReportPort): Promise<ICommonReportDTO[]> => {
    const repository = initReportRepository({ stock })
    const useCase = initGetCommonReportListUseCase({ stock, repository })

    return useCase.execute(port)
  }

  return useMutation<ICommonReportDTO[], unknown, IGetListReportPort>(['getCommonReportList'], callback, {
    onSuccess: (response) => {
      setReportList(response)
    },
    onError: () => {
      setReportList([])
    }
  })
}

export { useGetCommonReportListRequest }
