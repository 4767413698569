import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { Stocks } from '@domain/common/enums'

import { useWatch } from '@app/services/utils'

import type { FC, PropsWithChildren, Dispatch, SetStateAction } from 'react'
import type { IUserEntity } from '@domain/user/interfaces'

/**
 * Auth Context
 */

interface IRouterContextProps {
  isAuth: boolean
  user: null | IUserEntity
  stock: Stocks
  setAuthData: SetAuthData
  setUser: Dispatch<SetStateAction<IRouterContextProps['user']>>
  setIsAuth: Dispatch<SetStateAction<boolean>>
  setStock: Dispatch<SetStateAction<Stocks>>
}

type SetAuthData = (auth: IRouterContextProps['isAuth'], user: IRouterContextProps['user']) => void

const defaultContextValue = {
  isAuth: false,
  user: null,
  stock: Stocks.BINANCE,
  setUser: (): void => {
    //
  },
  setIsAuth: (): void => {
    //
  },
  setAuthData: (): void => {
    //
  },
  setStock: (): void => {
    //
  }
}

const AuthContext = createContext<IRouterContextProps>(defaultContextValue)

const useAuthContext = (): IRouterContextProps => useContext(AuthContext)

interface IAuthProviderProps {
  useAuthConfirm: UseAuthConfirmFn
}

const AuthProvider = ({ children, useAuthConfirm }: PropsWithChildren<IAuthProviderProps>): JSX.Element | null => {
  const [isAuth, setIsAuth] = useState(defaultContextValue.isAuth)
  const [user, setUser] = useState<IRouterContextProps['user']>(defaultContextValue.user)
  const [stock, setStock] = useState<IRouterContextProps['stock']>(defaultContextValue.stock)
  const [isLoaderShow, setIsLoaderShow] = useState(true)

  useWatch(user!, [
    'firstName',
    'lastName',
    'middleName',
    'role',
    'include2Fa',
    'lang',
    'apiKeys',
    'license'
  ])

  const setAuthData = useCallback((
    auth: IRouterContextProps['isAuth'],
    userPayload: IRouterContextProps['user']
  ): void => {
    setIsAuth(auth)
    setUser(userPayload)
  }, [])

  const { LoaderComponent, isLoading } = useAuthConfirm({ setIsAuth, setUser, setStock, isAuth })

  useEffect((): void | (() => void) => {
    if (!isLoading) {
      const DELAY = 500
      const timeoutAction = setTimeout(() => {
        setIsLoaderShow(false)
      }, DELAY)

      return () => {
        clearTimeout(timeoutAction)
      }
    }
  }, [isLoading])

  const newContext = useMemo(() => ({
    isAuth,
    user,
    stock,
    setAuthData,
    setUser,
    setIsAuth,
    setStock
  }), [
    isAuth,
    user,
    user?.id,
    user?.firstName,
    user?.lastName,
    user?.middleName,
    user?.role,
    user?.include2Fa,
    user?.lang,
    user?.apiKeys,
    user?.license,
    stock,
    setAuthData,
    setIsAuth,
    setUser,
    setStock
  ])

  return (
    <AuthContext.Provider value={ newContext }>
      { isLoaderShow && !!LoaderComponent && <LoaderComponent isVisible={ isLoading } /> }

      { !isLoading && children }
    </AuthContext.Provider>
  )
}

interface IAuthConfirmOptions {
  setIsAuth: IRouterContextProps['setIsAuth']
  setUser: IRouterContextProps['setUser']
  setStock: IRouterContextProps['setStock']
  isAuth: IRouterContextProps['isAuth']
}

interface IAuthConfirmResult {
  isLoading: boolean
  LoaderComponent: FC<{ isVisible: boolean }> | null
}

type UseAuthConfirmFn = (options: IAuthConfirmOptions) => IAuthConfirmResult

export type { UseAuthConfirmFn, SetAuthData, IRouterContextProps }

export {
  AuthProvider,
  useAuthContext
}
