import { createContext, useContext, useMemo } from 'react'

import { useDetectNotifications } from './hooks'

import type { FC, PropsWithChildren, Dispatch, SetStateAction } from 'react'

import type { IExecutedDetectDTO } from '@domain/stocks/interfaces'

interface INotificationsContext {
  executedDetectList: IExecutedDetectDTO[]
  setExecutedDetectList: Dispatch<SetStateAction<IExecutedDetectDTO[]>>
  countUnreadMessage: number
  setCountUnreadMessage: Dispatch<SetStateAction<number>>
  isOpenNotificationModal: boolean
  setIsOpenNotificationModal: Dispatch<SetStateAction<boolean>>
}

const NotificationsContext = createContext<INotificationsContext>({
  executedDetectList: [],
  setExecutedDetectList: (): void => {
    //
  },
  countUnreadMessage: 0,
  setCountUnreadMessage: (): void => {
    //
  },
  isOpenNotificationModal: false,
  setIsOpenNotificationModal: (): void => {
    //
  }
})

const useNotificationsContext = (): INotificationsContext => useContext(NotificationsContext)

const NotificationProvider: FC<PropsWithChildren> = ({ children }) => {
  const detectNotifications = useDetectNotifications()

  const context = useMemo<INotificationsContext>(() => ({
    executedDetectList: detectNotifications.executedDetectList,
    setExecutedDetectList: detectNotifications.setExecutedDetectList,
    countUnreadMessage: detectNotifications.countUnreadMessage,
    setCountUnreadMessage: detectNotifications.setCountUnreadMessage,
    isOpenNotificationModal: detectNotifications.isOpenNotificationModal,
    setIsOpenNotificationModal: detectNotifications.setIsOpenNotificationModal,
  }), [
    detectNotifications.executedDetectList,
    detectNotifications.setExecutedDetectList,
    detectNotifications.countUnreadMessage,
    detectNotifications.setCountUnreadMessage,
    detectNotifications.isOpenNotificationModal,
    detectNotifications.setIsOpenNotificationModal
  ])

  return (
    <NotificationsContext.Provider value={ context }>
      { children }
    </NotificationsContext.Provider>
  )
}

export { useNotificationsContext, NotificationProvider }
