import { HttpServiceV1 } from '@data/common/services'

import type { IUserProfitResponse } from '../interfaces'

import type { IUserProfitDTO, IUserProfitRepository } from '@domain/user-profit'

class UserProfitRepository implements IUserProfitRepository {

  public async getUserProfit (): Promise<IUserProfitDTO> {
    return HttpServiceV1.get<IUserProfitResponse>('/binance-order/profit')
      .then((response) => {
        return {
          all: { value: response.common, percent: response.commonPercent },
          today: { value: response.today, percent: response.todayPercent },
        }
      })
  }

}

export { UserProfitRepository }
