import { useEffect, useState } from 'react'

import { INIT_STATE_STOP_LOSE } from '@domain/setting-profile/stop-loss'

import { useAuthContext } from '@app/common/auth'

import { useGetStopLoss } from '@app/common/settings/stop-loss/request'

import type { IStopLossDto } from '@domain/setting-profile/stop-loss'
import type { IStopLossResult } from '@app/common/settings/stop-loss/interface'

const useStopLoss = (): IStopLossResult => {
  const [stopLoss, setStopLoss] = useState<IStopLossDto>(INIT_STATE_STOP_LOSE)
  const { refetch } = useGetStopLoss(setStopLoss)
  const { isAuth } = useAuthContext()

  useEffect(() => {
    if (isAuth) void refetch()
    else setStopLoss(INIT_STATE_STOP_LOSE)
  }, [isAuth])

  return {
    stopLoss,
    setStopLoss,
    refetch
  }
}

export { useStopLoss }
