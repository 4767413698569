/* eslint-disable @typescript-eslint/no-magic-numbers */
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { formatNumber } from '@app/services/utils'

import { Components } from './_styles'

import type { ILicenseCard } from '../../../interfaces'

import type { MouseEvent } from 'react'

interface ILicenseCardProps {
  item: ILicenseCard
  onClick (id: number, type: 'buy' | 'gift'): void
}

const LicenseCard = (props: ILicenseCardProps): JSX.Element => {
  const { t, i18n } = useTranslation()

  const [time, setTime] = useState(Date.now())

  const formatValue = useCallback((value: number | string, min?: number): string => {
    return formatNumber(value, { lang: i18n.language, min: min, max: 2 })
  }, [i18n.language])

  const formatDiscountDate = useMemo((): { startDate: string; endDate: string } => {
    return {
      startDate: new Date(props.item.discountStartDate).toLocaleString(i18n.language, { dateStyle: 'short' }),
      endDate: new Date(props.item.discountEndDate).toLocaleString(i18n.language, { dateStyle: 'short' })
    }
  }, [i18n.language, props.item.discountEndDate, props.item.discountStartDate])

  const isShowDiscount = useMemo(() => {
    return props.item.discountStartDate <= time && time <= props.item.discountEndDate
  }, [time, props.item.discountEndDate, props.item.discountStartDate])

  const isLessThanADay = useMemo(() => {
    const oneDay = 86400000
    return props.item.discountEndDate - time < oneDay
  }, [time, props.item.discountEndDate])

  const formatTimeRemaining = (endTime: number): string => {
    const totalSeconds = Math.max((endTime - time) / 1000, 0)
    const hours = Math.floor(totalSeconds / 3600)
    const minutes = Math.floor(totalSeconds % 3600 / 60)
    const seconds = Math.floor(totalSeconds % 60)
    return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
  }

  const discountPrice = useMemo(() => {
    return isShowDiscount
      ? formatValue((props.item.price - props.item.discountPrice).toFixed(2), 2)
      : formatValue(props.item.price, 2)
  }, [formatValue, isShowDiscount, props.item.discountPrice, props.item.price])

  const handleBuyClick = (event: MouseEvent<HTMLButtonElement>): void => {
    props.onClick(props.item.id, 'buy')

    event.currentTarget.blur()
  }

  const handleGiftClick = (event: MouseEvent<HTMLButtonElement>): void => {
    props.onClick(props.item.id, 'gift')

    event.currentTarget.blur()
  }

  useEffect(() => {
    const timerId = setInterval(() => {
      setTime(Date.now())
    }, 1000)

    return () => {
      clearInterval(timerId)
    }
  }, [])

  return (
    <Components.Wrapper>
      { isShowDiscount && (
        <Components.DiscountContainer>
          { `-${formatValue(props.item.discountPercent)}%` }

          { isLessThanADay && (
            <Components.DiscountTimer>
              { formatTimeRemaining(props.item.discountEndDate) }
            </Components.DiscountTimer>
          ) }
        </Components.DiscountContainer>
      ) }

      <Box>
        <Components.Title.Wrapper>
          <Components.Title.Title>
            { props.item.name }
          </Components.Title.Title>

          <Components.Title.PriceContainer>
            <Components.Title.Price>
              { `${discountPrice}$` }
            </Components.Title.Price>

            { isShowDiscount && (
              <Components.Title.OldPrice>
                { `${formatValue(props.item.price, 2)}$` }
              </Components.Title.OldPrice>
            ) }
          </Components.Title.PriceContainer>

          { isShowDiscount && (
            <Components.Title.DiscountInfoContainer>
              { `${t('license-container/period')}: ${t('common/from')} ${formatDiscountDate.startDate} ${t('common/to')} ${formatDiscountDate.endDate}` }
            </Components.Title.DiscountInfoContainer>
          ) }
        </Components.Title.Wrapper>

        <Components.List.Wrapper>
          { props.item.settings.map((item) => (
            <Components.List.Benefits.Wrapper key={ item }>
              <Components.List.Benefits.Icon />

              <Components.List.Benefits.Title>
                { item }
              </Components.List.Benefits.Title>
            </Components.List.Benefits.Wrapper>
          )) }
        </Components.List.Wrapper>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '10px' }}>
        <Components.Button size="large" onClick={ handleBuyClick }>
          { t('license-container/benefit/buy') }
        </Components.Button>

        <Components.Button size="large" onClick={ handleGiftClick }>
          { t('license-container/benefit/gift') }
        </Components.Button>
      </Box>
    </Components.Wrapper>
  )
}

export { LicenseCard }
