import type { IBalanceDTO, IAccountDTO, IAccountRepository } from '@domain/stocks/account'

abstract class AccountRepository implements IAccountRepository {

  protected provider: string

  constructor (provider: string) {
    this.provider = provider
  }

  public abstract getAccount (): Promise<IAccountDTO>

  public abstract getBalance (): Promise<IBalanceDTO>

}

export { AccountRepository }
