import { formatNumber } from '@app/services/utils'

interface ISuffixPrice {
  suffixValue: string
  suffix: string
  value: number
}

/* eslint-disable @typescript-eslint/no-magic-numbers */
const getSuffix = (number: number | string, digits: number, lang: string): ISuffixPrice => {
  if (typeof number === 'string') {
    number = Number(number)
  }
  const abbrev = ['', 'K', 'M', 'B', 'T']
  const unRangifyOrder = Math.floor(Math.log10(Math.abs(number)) / 3)
  const order = Math.max(0, Math.min(unRangifyOrder, abbrev.length - 1))
  const suffix = abbrev[order]

  return {
    suffixValue: `${formatNumber(number / 10 ** (order * 3), { lang: lang, min: digits, max: digits })}${suffix}`,
    suffix: suffix,
    value: number,
  }
}

export { getSuffix }
