import type { IDeleteDetectPort, IDeleteDetectUseCase, IDetectSettingRepository } from '../../interface'

class DeleteDetectUseCase implements IDeleteDetectUseCase {

  private _repository: IDetectSettingRepository

  constructor (repository: IDetectSettingRepository) {
    this._repository = repository
  }

  public async execute (port: IDeleteDetectPort): Promise<boolean> {
    return this._repository.deleteDetect(port)
  }

}

export { DeleteDetectUseCase }
