import { useEffect, useState } from 'react'

import { INIT_STATE_SPECIFICATION } from '@domain/setting-profile/specification'

import { useAuthContext } from '@app/common/auth'
import { useGetSpecification } from '@app/common/settings/specification/request'

import type { ISpecificationDTO } from '@domain/setting-profile/specification'

import type { IUseSpecificationResult } from '@app/common/settings/specification/interface'

const useSpecification = (): IUseSpecificationResult => {
  const [specification, setSpecification] = useState<ISpecificationDTO>(INIT_STATE_SPECIFICATION)
  const { refetch } = useGetSpecification(setSpecification)
  const { isAuth } = useAuthContext()

  useEffect(() => {
    if (isAuth) void refetch()
    else setSpecification(INIT_STATE_SPECIFICATION)
  }, [isAuth])

  return {
    specification,
    setSpecification,
    refetch
  }
}

export { useSpecification }
