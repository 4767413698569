import { HttpServiceV1, LocalStorageService } from '@data/common/services'
import { HttpCode, LocalStorageValues } from '@data/common/enums'

import { ExceptionService } from '@domain/common/services'
import { DEFAULT_PAIR } from '@domain/stocks/pair'

import type { IGetPairPriceResponse } from '../interfaces'

import type {
  IPairDTO,
  ISelectedPairDTO,
  IGetPairPort,
  IChangePairPort,
  IPairRepository,
  IGetPairPricePort,
  IPairPriceDTO
} from '@domain/stocks/pair'

abstract class PairRepository implements IPairRepository {

  protected provider: string

  constructor (provider: string) {
    this.provider = provider
  }

  public abstract getPairList (): Promise<IPairDTO[]>

  public abstract getPair (port: IGetPairPort): Promise<IPairDTO>

  public async getPairPrice (port: IGetPairPricePort): Promise<IPairPriceDTO> {
    return HttpServiceV1.get<IGetPairPriceResponse[]>(`/${this.provider}/price`, { params: port })
      .then((response) => {
        if (response.length === 0) {
          return {
            symbol: port.symbol,
            price: '0'
          }
        }

        return {
          symbol: response[0].symbol,
          price: response[0].price,
        }
      })
      .catch(() => {
        throw ExceptionService.new({
          status: {
            code: HttpCode.BAD_REQUEST,
            message: `${this.constructor.name} http error`
          }
        })
      })
  }

  public async getSelectedPair (): Promise<ISelectedPairDTO> {
    const path = window.location.pathname.split('/')
    const under = path[path.length - 1]
    const symbol = under.includes('_') ? under.split('_').join('') : undefined

    try {
      const pair = LocalStorageService.get<IPairDTO>(LocalStorageValues.PAIR)

      return { pair, symbol }
    } catch {
      return Promise.resolve({ pair: DEFAULT_PAIR, symbol })
    }
  }

  public async changePair (port: IChangePairPort): Promise<void> {
    LocalStorageService.set(LocalStorageValues.PAIR, port.pair)

    return Promise.resolve()
  }

}

export { PairRepository }
