import { LICENSE_LIST_STYLE as Component } from './_styles'

import { LicenseCard } from '../card'

import type { ILicenseCard } from '../../../interfaces'

interface ILicenseListProps {
  cards: ILicenseCard[]
  onCardClick (id: number, type: 'buy' | 'gift'): void
}

const LicenseList = (props: ILicenseListProps): JSX.Element => {
  const handleCardClick = (id: number, type: 'buy' | 'gift'): void => {
    props.onCardClick(id, type)
  }

  return (
    <Component.Wrapper>
      { props.cards.map((item) => <LicenseCard key={ item.id } item={ item } onClick={ handleCardClick } />) }
    </Component.Wrapper>
  )
}

export { LicenseList }
export type { ILicenseListProps }
