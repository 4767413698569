import { RequiredValidator, ValidationChain } from '@domain/common/utils/validators'

import type { IFieldValidate, ITakeProfitValidation } from '@domain/setting-profile/take-profit'

class TakeProfitValidation implements ITakeProfitValidation {

  public validate (): IFieldValidate {
    return {
      isIncludeTp: new ValidationChain([new RequiredValidator()]),
      percentTp: new ValidationChain([new RequiredValidator(true)]),
      orderTypeTp: new ValidationChain([new RequiredValidator()]),
      iceberg: new ValidationChain([new RequiredValidator()]),
      autoDowngrade: new ValidationChain([new RequiredValidator()]),
      timerDowngrade: new ValidationChain([new RequiredValidator()]),
      moveDowngrade: new ValidationChain([new RequiredValidator()]),
      limitDowngrade: new ValidationChain([new RequiredValidator()])
    }
  }

}

export { TakeProfitValidation }
