import { ExceptionService } from '@domain/common/services'
import { InternalCode } from '@domain/common/enums'

import { CreateApiKeyUseCase } from '../../../_'
import { ApiKeyError } from '../../../../common'
import { CREATE_BINANCE_API_KEY_VALIDATOR } from '../../validators'

import type { ICreateApiKeyPort, ICreateApiKeyUseCase, IApiKeyRepository } from '../../../../common'
import type { IUseCasePortValidationError } from '@domain/common/interfaces/use-case'

class CreateBinanceApiKeyUseCase extends CreateApiKeyUseCase implements ICreateApiKeyUseCase {

  constructor (repository: IApiKeyRepository) {
    super(repository, CREATE_BINANCE_API_KEY_VALIDATOR)
  }

  public override async execute (port: ICreateApiKeyPort): Promise<boolean> {
    const errors = this.validatePort(port)

    if (Object.keys(errors).length) {
      throw ExceptionService.new({
        status: {
          code: InternalCode.VALIDATION_ERROR,
          message: `${this.constructor.name} validation port error`
        },
        data: errors
      })
    }

    return this.repository.createApiKey({
      name: port.name,
      publicKey: port.publicKey,
      privateKey: this.encrypt(port.privateKey),
    })
  }

  protected override validatePort (port: ICreateApiKeyPort): IUseCasePortValidationError<ICreateApiKeyPort> {
    let errors: IUseCasePortValidationError<ICreateApiKeyPort> = {}

    const isValidPublicKey = this._validateKey(port.publicKey)
    const isValidPrivateKey = this._validateKey(port.privateKey)

    if (!isValidPublicKey) errors.publicKey = { code: ApiKeyError.INVALID_API_KEY }
    if (!isValidPrivateKey) errors.privateKey = { code: ApiKeyError.INVALID_API_KEY }

    errors = { ...errors, ...super.validatePort(port) }

    return errors
  }

  private _validateKey (value: string | undefined): boolean {
    if (value === undefined) return true

    return /[a-z]/g.test(value) && /[A-Z]/g.test(value) && /[0-9]/g.test(value)
  }

}

export { CreateBinanceApiKeyUseCase }
