import type { ITakeProfitDTO } from '../interface'

const INIT_STATE_TAKE_PROFIT: ITakeProfitDTO = {
  isIncludeTp: true,
  iceberg: false,
  autoDowngrade: false,
  timerDowngrade: 0,
  percentTp: 1,
  moveDowngrade: 0,
  limitDowngrade: 0,
  orderTypeTp: 'limit'
}

export { INIT_STATE_TAKE_PROFIT }
