import { useEffect, useState } from 'react'

import { useGetUserLicenseRequest } from '@app/common/license/requests'
import { useInternetConnectionContext } from '@app/components/tools/components/internet-connection'
import { useAuthContext } from '@app/common/auth'

import type { Dispatch, SetStateAction } from 'react'

import type { IUserLicenseDTO } from '@domain/license'

interface IUserLicenseListResult {
  userLicenseList: IUserLicenseDTO[]
  setUserLicenseList: Dispatch<SetStateAction<IUserLicenseDTO[]>>
}

const useUserLicenseList = (): IUserLicenseListResult => {
  const [userLicenseList, setUserLicenseList] = useState<IUserLicenseDTO[]>([])

  const { status } = useInternetConnectionContext()
  const { isAuth } = useAuthContext()

  const { refetch } = useGetUserLicenseRequest(setUserLicenseList)

  useEffect(() => {
    if (status === 'online' && isAuth) void refetch()
  }, [status, isAuth])

  return { userLicenseList, setUserLicenseList }
}

export { useUserLicenseList }
