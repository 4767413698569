import { Box, styled, Typography } from '@mui/material'

const TV_CONTAINER_STYLE = {
  Container: styled(Box)`
    max-width: 1250px;
    width: 100%;
    margin: 40px 0 0;
    padding: 20px;
    border-radius: 15px;
    background: ${({ theme }): string => theme.palette.bg.secondary};
    transition: ${({ theme }): string => theme.transitions.create(['background'], { duration: theme.transitions.duration.standard })};
  `,
  Text: styled(Typography)`
    display: inline;
  `,
  Link: styled('a')`
    color: ${({ theme }): string => theme.palette.primary.main};
    text-decoration: none;
    border-bottom: 1px dashed transparent;
    transition: ${({ theme }): string => theme.transitions.create(['border'], { duration: theme.transitions.duration.standard })};

    &:hover {
      border-bottom: ${({ theme }): string => `1px dashed ${theme.palette.primary.main}`};
    }
  `
}

export { TV_CONTAINER_STYLE }
