import { CsvService, PdfService, XlsxService } from '@data/common/services'

import type {
  IReportRepository,
  IGetListReportPort,
  ICommonReportDTO,
  IDetailReportDTO,
  IGenerateCsvReportPort,
  IGeneratePdfReportPort,
  IGenerateXlsxReportPort
} from '@domain/stocks/report'

abstract class ReportRepository implements IReportRepository {

  protected provider: string

  constructor (provider: string) {
    this.provider = provider
  }

  public abstract getCommonReportList (port: IGetListReportPort): Promise<ICommonReportDTO[]>

  public abstract getDetailReportList (port: IGetListReportPort): Promise<IDetailReportDTO[]>

  public async generateFileCSV (port: IGenerateCsvReportPort): Promise<void> {
    const service = new CsvService()

    service.generate({
      fileName: `Report_${new Date().toLocaleString(port.locale)}`,
      columns: port.columnNames,
      rows: port.rows
    })

    service.download()

    return Promise.resolve()
  }

  public async generateFilePDF (port: IGeneratePdfReportPort): Promise<void> {
    const service = new PdfService()

    const headerRow = port.columnNames.map((item) => {
      return { text: item, style: 'tableHeader' }
    })

    const tableRow = port.rows.map((item) => {
      return item.map((_item) => {
        return { text: _item, style: 'tableCell' }
      })
    })

    service.generate({
      orientation: 'landscape',
      fileName: `Report_${new Date().toLocaleString(port.locale)}`,
      content: [
        {
          layout: 'lightHorizontalLines',
          table: {
            body: [headerRow, ...tableRow]
          }
        }
      ],
      styles: {
        tableHeader: {
          bold: true,
          fontSize: 7,
          color: 'black',
        },
        tableCell: {
          fontSize: 7
        }
      }
    })

    service.download()

    return Promise.resolve()
  }

  public async generateFileXLSX (port: IGenerateXlsxReportPort): Promise<void> {
    const service = new XlsxService()

    const config = {
      columnNames: port.columnNames,
      keys: port.keys,
      fileName: `Report_${new Date().toLocaleString(port.locale)}`,
      sheetName: 'Reports'
    }

    const rows = port.rows.map((row) => {
      const mRow: Record<string, string | undefined> = {}

      for (const key of port.keys) {
        mRow[key] = row[key]
      }

      return mRow
    })

    service.generate(rows, config)
    service.download()

    return Promise.resolve()
  }

}

export { ReportRepository }
