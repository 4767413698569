import { InternalCode, ValidationCode } from '@domain/common/enums'

export const ERROR_MESSAGES: Record<number, string> = {
  [InternalCode.PROPERTY_IS_REQUIRED]: 'error/required',
  [InternalCode.PROPERTY_NOT_FOUND]: 'error/user_not_found',
  [InternalCode.PROPERTY_ALREADY_EXIST]: 'error/validation_error',
  [ValidationCode.INVALID_EMAIL]: 'error/invalid_email',
  [ValidationCode.INVALID_LENGTH]: 'error/invalid_length',
  [ValidationCode.INVALID_LENGTH_MIN]: 'error/invalid_length_min',
  [ValidationCode.INVALID_LENGTH_MAX]: 'error/invalid_length_max',
  [ValidationCode.INVALID_NUMBERS]: 'error/invalid_number',
  [ValidationCode.INVALID_SYMBOL]: 'error/invalid_symbol',
  [ValidationCode.NEED_SYMBOL]: 'error/need_special_symbol',
  [ValidationCode.INVALID_LETTER_UPPER]: 'error/invalid_letter_upper',
  [ValidationCode.INVALID_LETTER_LOWER]: 'error/invalid_letter_lower',
  [ValidationCode.INVALID_CYRILLIC]: 'error/invalid_cyrillic',
  [InternalCode.SERVER_ERROR]: 'error/server_error',
  [ValidationCode.INVALID_REGEX]: 'error/invalid_regex',
  [ValidationCode.INVALID_EXCEPTED_VALUE]: 'error/invalid_excepted_value',
}
