import { isExponent, isNotDefined } from '../helpers'

const getNoExponentValue = (value: string | number): string => {
  if (isNotDefined(value)) return '0'
  if (!isExponent(value)) return value.toString()

  const arrayFromValue = value.toString().split('e')
  const indicatorExp = Number(arrayFromValue[1])
  const isFloatValue = !!(arrayFromValue[0].indexOf('.') + 1)
  const isNegativeValue = arrayFromValue[0].split('')[0] === '-'
  const numberPart = arrayFromValue[0].split('').filter((item) => item !== '-')

  let countZero = getCountZero(numberPart, indicatorExp, isFloatValue)

  if (indicatorExp > 0) {
    while (countZero !== 0) {
      numberPart.push('0')
      countZero -= 1
    }
  } else {
    while (countZero !== 0) {
      numberPart.unshift('0')
      countZero -= 1
    }
  }

  movePoint(numberPart, indicatorExp, isFloatValue)

  if (isNegativeValue) {
    numberPart.unshift('-')
  }

  return numberPart.join('').replace(/\.+$/, '')
}

const getCountZero = (numberPart: string[], indicatorExp: number, isFloatValue: boolean): number => {
  const tmp = numberPart.join('')
  const lengthFloatPart = isFloatValue ? tmp.split('.')[1].length : 0
  const lengthIntegerPart = isFloatValue ? tmp.split('.')[0].length : tmp.length

  const countZero = !isFloatValue && indicatorExp > 0
    ? Math.abs(indicatorExp)
    : indicatorExp > 0
      ? indicatorExp - lengthFloatPart
      : Math.abs(indicatorExp) - lengthIntegerPart + 1

  return countZero < 0 ? 0 : countZero
}

const movePoint = (numberPart: string[], indicatorExp: number, isFloatValue: boolean): void => {
  const pointIndex = isFloatValue
    ? numberPart.indexOf('.')
    : 0

  const newPointIndex = !isFloatValue && indicatorExp > 0
    ? numberPart.length
    : indicatorExp > 0
      ? pointIndex + Math.abs(indicatorExp)
      : pointIndex - Math.abs(indicatorExp)

  const replacePoint = isFloatValue
    ? numberPart.splice(pointIndex, 1)[0]
    : '.'

  numberPart.splice(newPointIndex, 0, replacePoint)
}

export { getNoExponentValue }
