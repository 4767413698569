import CryptoJS from 'crypto-js'

import { ExceptionService } from '@domain/common/services'
import { InternalCode } from '@domain/common/enums'

import type { Entries } from '@domain/common/utils'
import type { IUseCasePortValidationError, IUseCasePortValidator } from '@domain/common/interfaces/use-case'

import type { ICreateApiKeyPort, ICreateApiKeyUseCase, IApiKeyRepository } from '../../../../common'

abstract class CreateApiKeyUseCase implements ICreateApiKeyUseCase {

  protected repository: IApiKeyRepository

  protected validator: IUseCasePortValidator<ICreateApiKeyPort>

  constructor (repository: IApiKeyRepository, validator: IUseCasePortValidator<ICreateApiKeyPort>) {
    this.repository = repository
    this.validator = validator
  }

  public abstract execute (port: ICreateApiKeyPort): Promise<boolean>

  protected validatePort (port: ICreateApiKeyPort): IUseCasePortValidationError<ICreateApiKeyPort> {
    const errors: IUseCasePortValidationError<ICreateApiKeyPort> = {}
    const keys = Object.entries(port) as Entries<ICreateApiKeyPort>

    for (const [key, value] of keys) {
      const _error = this.validator[key]?.run(value?.trim())

      if (_error?.errors !== undefined) errors[key] = _error.errors
    }

    return errors
  }

  protected encrypt (value: string): string {
    if (process.env.REACT_APP_API_KEY_SECRET === undefined) {
      throw ExceptionService.new({
        status: {
          code: InternalCode.PROPERTY_NOT_FOUND,
          message: `REACT_APP_API_KEY_SECRET is undefined in ${this.constructor.name}`
        }
      })
    }

    return CryptoJS.AES.encrypt(value, process.env.REACT_APP_API_KEY_SECRET).toString()
  }

}

export { CreateApiKeyUseCase }
