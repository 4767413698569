import { Stocks } from '@domain/common/enums'

import { CreateBinanceAccountUseCase } from '../../stock'

import type { IAccountRepository, ICreateAccountUseCase } from '../../common'

interface IPayload {
  stock: Stocks
  repository: IAccountRepository
}

const initCreateAccountUseCase = (payload: IPayload): ICreateAccountUseCase => {
  switch (payload.stock) {
    case Stocks.BINANCE: return new CreateBinanceAccountUseCase(payload.repository)

    default: return new CreateBinanceAccountUseCase(payload.repository)
  }
}

export { initCreateAccountUseCase }
