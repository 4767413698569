import { Intervals } from '@domain/stocks/trade-history'

import { CandlestickChartVariant, ChartTypes, TickChartVariant } from '../enums'

import type { IContentDto } from '../interface'

const INIT_STATE_CONTENT: IContentDto = {
  bestAsk: true,
  bestBid: true,
  tickPrice: true,
  showForm: true,
  widthVolume: 25,
  heightVolume: 25,
  tickSize: 5,
  defaultScale: 1,
  axisVolume: 'left',
  sideHorizontalVolume: 'left',
  defaultGraphType: ChartTypes.CANDLESTICK,
  tickVariant: TickChartVariant.RECT,
  tickVolumeMode: 'absolute',
  candleVariant: CandlestickChartVariant.DEFAULT,
  candleInterval: Intervals.m1,
  isShowHorizontalVolume: false,
  isShowVerticalVolume: true,
  isMultiplierScale: false,
  isInputOutputLines: true,
  showMeasureTool: false,
  measureToolInterval: 1,
  measureToolOpacity: 50,
  orderLevelMode: 'absolute'
}

export { INIT_STATE_CONTENT }
