import { BalanceSocket } from '../../_'

import type { Stocks } from '@domain/common/enums'

import type { IBinanceBalanceSocketResponse } from '../interfaces'
import type { IBalanceSocket } from '../../../interfaces'

import type { IBalance } from '@domain/stocks/account'

class BinanceBalanceSocket extends BalanceSocket<IBinanceBalanceSocketResponse> implements IBalanceSocket {

  constructor (stock: Stocks) {
    super('userBalance_', stock)
  }

  public override onMessage (callback: (message: IBalance[]) => void): void {
    this.socket?.onMessage((message) => {
      callback([{
        asset: message.ticker,
        free: message.free,
        locked: message.locked,
        all: message.total
      }])
    })
  }

}

export { BinanceBalanceSocket }
