import { UserProfitEntity } from '../../entity'

import type { IUserProfitRepository, IUserProfitEntity } from '../../interfaces'

class GetUserProfitUseCase {

  private _repository: IUserProfitRepository

  constructor (repository: IUserProfitRepository) {
    this._repository = repository
  }

  public async execute (): Promise<IUserProfitEntity> {
    const response = await this._repository.getUserProfit()

    return UserProfitEntity.new({ id: '', profit: response })
  }

}

export { GetUserProfitUseCase }
