/* eslint-disable max-len */
import { HttpServiceV1 } from '@data/common/services'

import { ExceptionService } from '@domain/common/services'

import { InternalCode, ValidationCode } from '@domain/common/enums'

import type { IHttpError } from '@data/common/interfaces'

import type { IHttpCreateUserPairListErrors, IPairActionPayload, IUserPairListResponse } from '../interfaces'

import type {
  IUserPairListDTO,
  IUpdateUserCustomPairListPort,
  IUserPairListRepository,
  UserPairListType,
  ICreateUserPairListPort,
  ICreateListOfPairsDTO,
  ICreateUserPairListErrors,
  IDeleteUserPairListPort
} from '@domain/user-pair-list'

class UserPairListRepository implements IUserPairListRepository {

  private _prefix = '/binance-order/list'

  public async getLists (): Promise<IUserPairListDTO[]> {
    return HttpServiceV1.get<IUserPairListResponse[]>(this._prefix)
      .then((response) => {
        return response.map((item) => {
          return {
            id: item.id,
            type: item.type as UserPairListType,
            name: item.name,
            pairs: item.pairs ?? []
          }
        })
      })
  }

  public async createList (port: ICreateUserPairListPort): Promise<ICreateListOfPairsDTO> {
    return HttpServiceV1.post<ICreateListOfPairsDTO>(this._prefix, { body: port })
      .catch((error: IHttpError<IHttpCreateUserPairListErrors>) => {
        throw this._makeCreationError(error)
      })
  }

  public async deleteList ({ id }: IDeleteUserPairListPort): Promise<void> {
    await HttpServiceV1.delete(`${this._prefix}/${id}`)
  }

  public async addPair (payload: Required<IUpdateUserCustomPairListPort>): Promise<boolean> {
    return this._updatePairAction({ ...payload, add: true })
  }

  public async deletePair (payload: Required<IUpdateUserCustomPairListPort>): Promise<boolean> {
    return this._updatePairAction({ ...payload, add: false })
  }

  private async _updatePairAction (payload: IPairActionPayload): Promise<boolean> {
    return HttpServiceV1.post(`${this._prefix}/${payload.id}/pair-action`, { body: payload })
      .then(() => true)
  }

  private _makeCreationError (error: IHttpError<IHttpCreateUserPairListErrors>): ExceptionService<ICreateUserPairListErrors | undefined> {
    if (error.errors.code === '203') {
      return ExceptionService.new<ICreateUserPairListErrors>({
        status: {
          code: InternalCode.VALIDATION_ERROR,
          message: 'Backend throw validation errors'
        },
        data: { name: InternalCode.PROPERTY_ALREADY_EXIST }
      })
    }

    if (error.errors.Name === '202') {
      return ExceptionService.new<ICreateUserPairListErrors>({
        status: {
          code: InternalCode.VALIDATION_ERROR,
          message: 'Backend throw validation errors'
        },
        data: { name: ValidationCode.INVALID_REGEX }
      })
    }

    return ExceptionService.new({
      status: {
        code: InternalCode.SERVER_ERROR,
        message: 'Backend throw unprocessed error'
      },
    })
  }

}

export { UserPairListRepository }
