import { GetCandleHistoryListUseCase } from '../../stocks'

import type { Stocks } from '@domain/common/enums'

import type { ITradeHistoryRepository, IGetCandleHistoryListUseCase } from '../../common'

interface IPayload {
  stock: Stocks
  repository: ITradeHistoryRepository
}

const initGetCandleHistoryUseCase = (payload: IPayload): IGetCandleHistoryListUseCase => {
  return new GetCandleHistoryListUseCase(payload.repository)
}

export { initGetCandleHistoryUseCase }
