import { HttpServiceV1 } from '@data/common/services'
import { ExceptionService } from '@domain/common/services'
import { InternalCode } from '@domain/common/enums'

import type { IAutoBuySettingsResponse } from './interface'

import type { ISpecificationErrors } from '@domain/setting-profile/specification'

import type { IHttpError } from '@data/common/interfaces'

import type { IAutoBuySettingsDTO } from '@domain/setting-profile/auto-boy-bnb/interfaces/dto'

import type { IAutoBuyBNBRepository, IUpdateAutoBuySettingsPayload } from '@domain/setting-profile/auto-boy-bnb'
import type {
  ISpecificationResponseErrors,
  ISpecificationServerError
} from '@data/repository/setting-profile/specification/interface'

class AutoBuyBNBRepository implements IAutoBuyBNBRepository {

  public async getAutoBuySettings (): Promise<IAutoBuySettingsDTO> {
    return HttpServiceV1.get<IAutoBuySettingsResponse>('settings/autobuy-settings')
      .then((response) => {
        return {
          isInclude: response.autobuyInclude,
          minAmount: Number(response.minBnbBalance),
          buyAmount: Number(response.buyAmount)
        }
      })
      .catch(() => {
        throw ExceptionService.new({
          status: {
            code: InternalCode.SERVER_ERROR,
            message: 'Server return bad request'
          }
        })
      })
  }

  public async updateAutoBuySettings (payload: IUpdateAutoBuySettingsPayload): Promise<boolean> {
    return HttpServiceV1.put('settings/autobuy-settings', { body: payload })
      .then(() => true)
      .catch((error: IHttpError<ISpecificationResponseErrors | ISpecificationServerError>) => {
        throw this._throwError(error)
      })
  }

  // eslint-disable-next-line max-len
  private _throwError (error: IHttpError<ISpecificationResponseErrors | ISpecificationServerError>): ExceptionService<ISpecificationErrors> {
    const code = error.errors === 'server_error' ? InternalCode.SERVER_ERROR : InternalCode.VALIDATION_ERROR
    const message = 'Backand return errors'

    return ExceptionService.new({
      status: {
        code,
        message
      },
      data: {
        logical: error.errors === 'server_error'
          ? InternalCode.SERVER_ERROR
          : +error.errors.code
      }
    })
  }

}

export { AutoBuyBNBRepository }
