import { MenuItem, Select, styled } from '@mui/material'

const INPUT_SELECT_STYLES = {
  Select: styled(Select)`
    .MuiSelect-select {
      display: flex;
      align-items: center;
      grid-gap: 10px;
      padding-left: 14px;
      padding-top: 6px;
      padding-bottom: 6px;
      width: 100%;
      
      .MuiTypography-root {
        font-size: 14px;
      }
      .Mui-disabled {
        cursor: not-allowed !important;
      }
    }
    
    .MuiSvgIcon-root {
      font-size: 18px;
      transition: ${({ theme }): string => theme.transitions.create(['color', 'fill'], { duration: theme.transitions.duration.standard })};
    }
    
    &.MuiOutlinedInput-root {
      padding: 0;
    }
  `,
  Item: styled(MenuItem)`
    display: flex;
    align-items: center;
    grid-gap: 10px;
    
    .MuiSvgIcon-root {
      font-size: 18px;
    }
  `
}

export { INPUT_SELECT_STYLES }
