import { PairEntity } from '../../../../common'

import type { IChangePairPort, IChangePairUseCase, IPairEntity, IPairRepository } from '../../../../common'

class ChangePairUseCase implements IChangePairUseCase {

  protected repository: IPairRepository

  protected entity: IPairEntity

  constructor (repository: IPairRepository, entity: IPairEntity) {
    this.repository = repository
    this.entity = entity
  }

  public async execute (port: IChangePairPort): Promise<IPairEntity> {
    await this.repository.changePair(port)
    await this.entity.changePair(port.pair)

    return PairEntity.new({
      id: '',
      pair: this.entity.pair,
      list: this.entity.list
    })
  }

}

export { ChangePairUseCase }
