import { ExceptionService } from '@domain/common/services'
import { InternalCode } from '@domain/common/enums'

import { UpdateUserPairListUseCase } from '../update-list-use-case'

import type { ValidationChain } from '@domain/common/utils/validators'

import type {
  ICreateUserPairListErrors,
  ICreateUserPairListPort,
  ICreateUserPairListUseCase,
  IUserPairListRepository,
  IUserPairListEntity
} from '../../interfaces'

type Validator = Record<keyof ICreateUserPairListPort, ValidationChain>

class CreateListOfPairsUseCase extends UpdateUserPairListUseCase implements ICreateUserPairListUseCase {

  private readonly _validations: Validator

  constructor (repository: IUserPairListRepository, entity: IUserPairListEntity, validations: Validator) {
    super(repository, entity)

    this._validations = validations
  }

  public override async execute (port: ICreateUserPairListPort): Promise<IUserPairListEntity> {
    this._throwValidationErrors(port)

    const payload = await this.repository.createList(port)

    await this.entity.addList({ ...payload, pairs: []})

    return super.execute(port)
  }

  private _throwValidationErrors (port: ICreateUserPairListPort): void {
    const errors = this._validatePort(port)

    if (Object.keys(errors).length !== 0) {
      throw ExceptionService.new({
        status: {
          code: InternalCode.VALIDATION_ERROR,
          message: 'List of Pairs creation Validation Error'
        },
        data: errors
      })
    }
  }

  private _validatePort (port: ICreateUserPairListPort): ICreateUserPairListErrors {
    const results: Partial<Record<keyof ICreateUserPairListPort, number>> = {}

    for (const key in port) {
      if (key in port) {
        const validator: ValidationChain = this._validations[key as keyof ICreateUserPairListPort]
        const { errors } = validator.run(port[key as keyof ICreateUserPairListPort])

        if (errors) results[key as keyof ICreateUserPairListPort] = errors.code
      }
    }

    return results
  }

}

export { CreateListOfPairsUseCase }
