import { useQuery } from '@tanstack/react-query'

import { AutoBuyBNBRepository } from '@data/repository/setting-profile/auto-buy-bnb'

import { GetAutoBuyBNBUseCase } from '@domain/setting-profile/auto-boy-bnb/use-case/get-use-case'

import { RequiredValidator, ValidationChain } from '@domain/common/utils/validators'

import type { Dispatch, SetStateAction } from 'react'

import type { UseQueryResult } from '@tanstack/react-query'

import type { IAutoBuySettingsDTO } from '@domain/setting-profile/auto-boy-bnb/interfaces/dto'

type UseGetBNBSettingsResult = UseQueryResult<IAutoBuySettingsDTO>

const validation = {
  isInclude: new ValidationChain([new RequiredValidator()]),
  minAmount: new ValidationChain([new RequiredValidator()]),
  buyAmount: new ValidationChain([new RequiredValidator()])
}

const useGetBNBSettings = (setter: Dispatch<SetStateAction<IAutoBuySettingsDTO>>): UseGetBNBSettingsResult => {
  const repository = new AutoBuyBNBRepository()
  const useCase = new GetAutoBuyBNBUseCase(repository, validation)

  const callback = async (): Promise<IAutoBuySettingsDTO> => {
    return useCase.execute()
  }

  return useQuery(['get bnb'], callback, {
    onSuccess: (value) => {
      setter(value)
    },
    enabled: false
  })
}

export { useGetBNBSettings }
