import { ExceptionService } from '@domain/common/services'

import { InternalCode } from '@domain/common/enums'

import type {
  IDealsUnionPort,
  IDealUnionUseCase,
  IDealRepository,
  IDealErrorsIds
} from '@domain/stocks/interfaces/deal'

class BinanceDealUnionUseCase implements IDealUnionUseCase {

  private _repository: IDealRepository

  constructor (repository: IDealRepository) {
    this._repository = repository
  }

  public async execute (port: IDealsUnionPort): Promise<boolean> {
    this._throwErrors(port)

    return this._repository.unionDeal({ symbol: port.symbol, ids: port.dealsIds })
  }

  private _throwErrors (port: IDealsUnionPort): void {
    const errors = this._validatePort(port)

    if (Object.keys(errors).length) {
      throw ExceptionService.new({
        status: {
          code: InternalCode.VALIDATION_ERROR,
          message: `${this.constructor.name} validation port error`
        },
        data: errors
      })
    }
  }

  private _validatePort (port: IDealsUnionPort): IDealErrorsIds {
    const errors: IDealErrorsIds = {}

    port.dealsIds.forEach((tt) => {
      if (typeof tt !== 'number') errors.ids = InternalCode.PROPERTY_IS_INVALID
    })

    return errors
  }

}

export { BinanceDealUnionUseCase }
