import { useNavigate, useParams } from 'react-router-dom'
import { useCallback, useEffect } from 'react'

import { initPairRepository } from '@data/repository/stocks/pair'
import { initChangePairUseCase } from '@domain/stocks/pair'

import { useAuthContext } from '@app/common/auth'
import { useTradeContext } from '@app/pages/trade/trade-context'

import ROUTES_PATH from '@app/router/router-path'

const usePair = (): void => {
  const navigate = useNavigate()
  const { pair } = useParams()

  const { stock } = useAuthContext()
  const { isPairLoaded, PairEntity, setPairEntity } = useTradeContext()

  const changePair = useCallback(async (value: string | undefined): Promise<void> => {
    if (value === undefined) return

    const _pair = PairEntity.getPairByTicker(value, 'under')

    if (_pair) {
      const repository = initPairRepository({ stock })
      const useCase = initChangePairUseCase({ stock, repository, entity: PairEntity })

      const entity = await useCase.execute({ pair: _pair })

      setPairEntity(entity)
    }
  }, [stock, PairEntity])

  useEffect(() => {
    if (pair === undefined) {
      if (isPairLoaded) navigate(`${ROUTES_PATH.AuthRoute.TRADE}/${PairEntity.pair.ticker.under}`)
    } else if (pair !== PairEntity.pair.ticker.under) {
      void changePair(pair)
    }
  }, [pair, PairEntity.pair.ticker.under, isPairLoaded])
}

export { usePair }
