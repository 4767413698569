import type { Components } from '@mui/material/styles/components'
import type { Theme } from '@mui/material'

const FORM_CONTROL_STYLES: Components<Theme>['MuiFormControl'] = {
  styleOverrides: {
    root: {
      '.Mui-disabled': {
        cursor: 'not-allowed !important'
      }
    }
  }
}

export { FORM_CONTROL_STYLES }
