import { InternalCode } from '@domain/common/enums'

import { ApiKeyError } from '@domain/stocks/api-key'

import { ApiKeyRepositoryErrorCode, ApiKeyRepositoryErrors } from '../enums'

import type {
  IApiKeyListDTO,
  IApiKeyRepository,
  ICreateApiKeyPort,
  IDeleteApiKeyPort,
  ISetActiveApiKeyPort
} from '@domain/stocks/api-key'

abstract class ApiKeyRepository implements IApiKeyRepository {

  protected provider: string

  constructor (provider: string) {
    this.provider = provider
  }

  public abstract getApiKeyList (): Promise<IApiKeyListDTO>

  public abstract createApiKey (port: ICreateApiKeyPort): Promise<boolean>

  public abstract setActiveApiKey (port: ISetActiveApiKeyPort): Promise<boolean>

  public abstract deleteApiKey (port: IDeleteApiKeyPort): Promise<boolean>

  protected transformError (code: number, message?: string): InternalCode | ApiKeyError {
    if (code === ApiKeyRepositoryErrorCode.INVALID_API_KEY) return ApiKeyError.INVALID_API_KEY

    if (code === ApiKeyRepositoryErrorCode.API_KEY_EXIST) {
      switch (message) {
        case ApiKeyRepositoryErrors.API_KEY_EXIST: return ApiKeyError.ALREADY_EXIST_API_KEY
        case ApiKeyRepositoryErrors.DESCRIPTION_EXIST: return ApiKeyError.ALREADY_EXIST_NAME

        default: return ApiKeyError.ALREADY_EXIST
      }
    }

    return InternalCode.SERVER_ERROR
  }

}

export { ApiKeyRepository }
