import { ExceptionService } from '@domain/common/services'

import { InternalCode } from '@domain/common/enums'

import type { IStorage } from '@data/common/interfaces'

abstract class StorageService<Name extends string> implements IStorage<Name> {

  public abstract get<Response> (name: Name): Response

  public abstract set<Payload> (name: Name, payload: Payload): void

  public abstract remove (name: Name): void

  public abstract clear (): void

  protected _get<Response> (name: Name, storage: Storage): Response {
    const response = storage.getItem(name)

    if (response !== null) return JSON.parse(response) as Response

    throw ExceptionService.new({
      status: {
        code: InternalCode.PROPERTY_NOT_FOUND,
        message: `Property "${name}" not found in ${this.constructor.name}`
      }
    })
  }

  protected _set<Payload> (name: Name, payload: Payload, storage: Storage): void {
    storage.setItem(name, JSON.stringify(payload))
  }

  protected _remove (name: Name, storage: Storage): void {
    storage.removeItem(name)
  }

  protected _clear (storage: Storage): void {
    storage.clear()
  }

}

export { StorageService }
