import { useEffect } from 'react'

import { useTradeContext } from '@app/pages/trade/trade-context'

import { useGetCommissionRequest } from '@app/pages/trade/requests'

const useCommission = (): void => {
  const { PairEntity, CommissionEntity } = useTradeContext()

  const { mutateAsync } = useGetCommissionRequest()

  useEffect(() => {
    PairEntity.list.length === 0
      ? void CommissionEntity.clearCommission()
      : void mutateAsync(PairEntity.list)
  }, [PairEntity.list])
}

export { useCommission }
