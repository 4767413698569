import { useEffect, useState } from 'react'

import { useGetHotKeys } from '@app/common/settings/hot-keys/request'

import { useAuthContext } from '@app/common/auth'

import { INIT_STATE_HOT_KEY } from '@domain/setting-profile/hot-keys'

import type { IHotKeyEntity } from '@domain/setting-profile/hot-keys'

import type { IUseHotKeysResult } from '@app/common/settings'

const useHotKeys = (): IUseHotKeysResult => {
  const [hotKeys, setHotKeys] = useState<IHotKeyEntity[]>(INIT_STATE_HOT_KEY)
  const { refetch } = useGetHotKeys(setHotKeys)
  const { isAuth } = useAuthContext()

  useEffect(() => {
    if (isAuth) void refetch()
    else setHotKeys(INIT_STATE_HOT_KEY)
  }, [isAuth])

  return {
    hotKeys,
    setHotKeys,
    refetch
  }
}

export { useHotKeys }
