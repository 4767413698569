import type { IPaymentLicenseDTO, IBuyLicenseUseCase, IBuyLicensePort, ILicenseRepository } from '../../interfaces'

class BuyLicenseUseCase implements IBuyLicenseUseCase {

  private _repository: ILicenseRepository

  constructor (repository: ILicenseRepository) {
    this._repository = repository
  }

  public async execute (port: IBuyLicensePort): Promise<IPaymentLicenseDTO> {
    return this._repository.buyLicense(port)
  }

}

export { BuyLicenseUseCase }
