import { useCallback, useEffect } from 'react'

import { useDealContext } from '@app/pages/trade/trade-tables/tables/deal-table/_context'
import { useAuthContext } from '@app/common/auth'
import { useInternetConnectionContext } from '@app/components/tools/components/internet-connection'

import { DealsPairObserver } from '@app/pages/trade/trade-controller/observers'
import { useEventContext } from '@app/services/event'

import { DealsCurrentPriceService } from './service'

import type { IDealsPairObserverPayload } from '@app/pages/trade/trade-controller/observers'

const dealsCurrentPriceService = new DealsCurrentPriceService()

const updatePairService = (value: IDealsPairObserverPayload): void => {
  if (value.action === 'add') dealsCurrentPriceService.subscribe(value.pair)
  if (value.action === 'delete') dealsCurrentPriceService.unsubscribe(value.pair)
}

const dealsObserver = new DealsPairObserver(updatePairService)

const useDealsCurrentPrice = (): void => {
  const { stock } = useAuthContext()
  const { status } = useInternetConnectionContext()
  const { deals, setCurrentDealPrice } = useDealContext()
  const { subscribe, unsubscribe } = useEventContext()

  const updatePrice = useCallback((value: Record<string, string>): void => {
    setCurrentDealPrice(() => ({ ...value }))
  }, [setCurrentDealPrice])

  dealsCurrentPriceService.setUpdatePriceCallback(updatePrice)

  useEffect(() => {
    dealsCurrentPriceService.changeStock(stock)
  }, [stock])

  useEffect(() => {
    if (deals.length === 0 || status === 'offline') dealsCurrentPriceService.unsubscribeAll()
  }, [deals, status])

  useEffect(() => {
    subscribe(dealsObserver)

    return () => {
      dealsCurrentPriceService.unsubscribeAll()

      unsubscribe(dealsObserver)
    }
  }, [subscribe, unsubscribe])
}

export { useDealsCurrentPrice }
