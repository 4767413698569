import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'

import { useQuery } from '@tanstack/react-query'

import { InternalCode } from '@domain/common/enums'
import { ERROR_MESSAGES } from '@app/services/i18n/_messages'

import { GetStopLossUseCase } from '@domain/setting-profile/stop-loss'

import { StopLossRepository } from '@data/repository/setting-profile/stop-loss'

import { StopLossValidation } from '@domain/setting-profile/stop-loss/use-case/update-stop-loss/validation'

import type { IStopLossDto } from '@domain/setting-profile/stop-loss'

import type { Dispatch, SetStateAction } from 'react'

import type { ExceptionService } from '@domain/common/services'

import type { UseQueryResult } from '@tanstack/react-query'

type IUseGetTakeProfitResults = UseQueryResult<IStopLossDto, ExceptionService<never>>

const useGetStopLoss = (setter: Dispatch<SetStateAction<IStopLossDto>>):
IUseGetTakeProfitResults => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const repository = new StopLossRepository()
  const validation = new StopLossValidation()
  const useCase = new GetStopLossUseCase(repository, validation)

  const callback = async (): Promise<IStopLossDto> => {
    return useCase.execute()
  }

  return useQuery(
    ['get stop lose'],
    callback,
    {
      onSuccess: (value) => {
        setter(value)
      },
      onError: (error) => {
        if (error.code === InternalCode.SERVER_ERROR) {
          enqueueSnackbar(t(ERROR_MESSAGES[InternalCode.SERVER_ERROR]), { variant: 'error' })
        }
      },
      enabled: false
    }
  )
}

export { useGetStopLoss }
