enum BinanceOrderSide {
  BUY = 'BUY',
  SELL = 'SELL'
}

enum BinanceOrderStatus {
  COMPLETE = 'FILLED',
  CANCELED = 'CANCELED',
  TRADE = 'PARTIALLY-FILLED',
  NEW = 'ACTIVE',
  EXPIRED = 'EXPIRED'
}

enum BinanceOrderType {
  LIMIT = 'LIMIT',
  MARKET = 'MARKET',
  POST_ONLY = 'POST_ONLY',
  STOP_LIMIT = 'STOP_LOSS_LIMIT',
  STOP_MARKET = 'STOP_MARKET'
}

export { BinanceOrderSide, BinanceOrderStatus, BinanceOrderType }
