import { Checkbox, styled } from '@mui/material'

import { ReactComponent as UnCheckedIcon } from '@app/asset/icon/checkbox/un-checked.svg'
import { ReactComponent as UnCheckedDarkIcon } from '@app/asset/icon/checkbox/un-checked-dark.svg'

const CHECKBOX_STYLES = {
  Checkbox: styled(Checkbox)`
    padding: 0;
    margin-left: -3px;
  `,
  UnCheckedIcon: styled(UnCheckedIcon)`
    rect {
      stroke: ${({ theme }): string => theme.palette.bg.secondary};
      fill: ${({ theme }): string => theme.palette.bg.accent};
      transition: ${({ theme }): string => theme.transitions.create(['stroke', 'fill'], { duration: theme.transitions.duration.standard })};
    }
  `,
  UnCheckedDarkIcon: styled(UnCheckedDarkIcon)`
    rect {
      stroke: ${({ theme }): string => theme.palette.bg.secondary};
      fill: ${({ theme }): string => theme.palette.bg.accent};
      transition: ${({ theme }): string => theme.transitions.create(['stroke', 'fill'], { duration: theme.transitions.duration.standard })};
    }
  `
}

export { CHECKBOX_STYLES }
