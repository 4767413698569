import 'reflect-metadata'
import 'es6-shim'

import { createRoot } from 'react-dom/client'

import App from '@app/app'

import { register as registerServiceWorker } from './service-worker-registration'

import '@domain/new-chart/asset/styles/index.scss'

const container = document.getElementById('root')

if (container) {
  const root = createRoot(container)

  root.render(
    <App />
  )

  registerServiceWorker()
}
