import { Stocks } from '@domain/common/enums'

import { BinanceOrderRepository } from '../stocks'

import type { IOrderRepository } from '@domain/stocks/order'

interface IPayload {
  stock: Stocks
}

const initOrderRepository = (payload: IPayload): IOrderRepository => {
  switch (payload.stock) {
    case Stocks.BINANCE: return new BinanceOrderRepository('binance-order')

    default: return new BinanceOrderRepository('binance-provider-order')
  }
}

export { initOrderRepository }
