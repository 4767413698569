import { useMutation } from '@tanstack/react-query'

import { initPairRepository } from '@data/repository/stocks/pair'
import { initCreatePairUseCase } from '@domain/stocks/pair'

import { useAuthContext } from '@app/common/auth'
import { useTradeContext } from '@app/pages/trade/trade-context'

import type { IPairEntity } from '@domain/stocks/pair'

import type { UseMutationResult } from '@tanstack/react-query'

const useGetPairRequest = (): UseMutationResult<IPairEntity> => {
  const { stock } = useAuthContext()
  const { setPairEntity, setIsPairLoaded } = useTradeContext()

  const callback = async (): Promise<IPairEntity> => {
    const repository = initPairRepository({ stock })
    const useCase = initCreatePairUseCase({ stock, repository })

    return useCase.execute()
      .then((response) => {
        setPairEntity(response)
        setIsPairLoaded(true)

        return response
      })
  }

  return useMutation(['get pairs'], callback)
}

export { useGetPairRequest }
