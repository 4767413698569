import { useTranslation } from 'react-i18next'

import { TV_CONTAINER_STYLE as Component } from './_style'

const TVContainer = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Component.Container>
      <Component.Text>{ `${t('landing/tv/text-1')} ` }</Component.Text>

      <Component.Link
        href="https://www.tradingview.com/symbols/BTCUSD/"
        rel="noopener noreferrer"
        target="_blank"
      >
        { t('landing/tv/text-2') }
      </Component.Link>

      <Component.Text>{ t('landing/tv/text-3') }</Component.Text>
    </Component.Container>
  )
}

export { TVContainer }
