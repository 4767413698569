interface IMetaOptions {
  title?: string
  description?: string
  keywords?: string
}

const getMetaElement = (name: string): Element | null => {
  return document.querySelector(`meta[name=${name}]`)
}

const updateTitle = (titleText: string): void => {
  const title = document.head.getElementsByTagName('title')

  if (title.length === 0) {
    const titleElement = document.createElement('title')
    titleElement.innerText = titleText
    document.head.append(titleElement)
  } else {
    title[0].innerText = titleText
  }
}

const updateMetaElemet = (name: string, textElement: string): void => {
  const element = getMetaElement(name)

  if (element === null) {
    const newElement = document.createElement('meta')
    newElement.setAttribute('name', name)
    newElement.setAttribute('content', textElement)
    document.head.append(newElement)
  } else {
    element.setAttribute('content', textElement)
  }
}

const updateMeta = (options: IMetaOptions): void => {
  if (options.title !== undefined) {
    updateTitle(options.title)
  }

  if (options.description !== undefined) {
    updateMetaElemet('description', options.description)
  }

  if (options.keywords !== undefined) {
    updateMetaElemet('keywords', options.keywords)
  }
}

export { updateMeta }
