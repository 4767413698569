import { useMutation } from '@tanstack/react-query'

import { useAuthContext } from '@app/common/auth'
import { useReportContext } from '@app/pages/trade/trade-tables/tables/report-table/report-context'

import { initReportRepository } from '@data/repository/stocks/report'
import { initGetLastReportUseCase } from '@domain/stocks/report'

import type { IDetailReportDTO, IGetLastReportPort } from '@domain/stocks/report'

import type { UseMutationResult } from '@tanstack/react-query'

type UseLastProfitRequest = UseMutationResult<IDetailReportDTO | null, unknown, IGetLastReportPort>

const useGetLastProfitRequest = (): UseLastProfitRequest => {
  const { stock } = useAuthContext()
  const { setLastProfit } = useReportContext()

  const callback = async (port: IGetLastReportPort): Promise<IDetailReportDTO | null> => {
    const repository = initReportRepository({ stock })
    const useCase = initGetLastReportUseCase({ stock, repository })

    return useCase.execute(port)
  }

  return useMutation<IDetailReportDTO | null, unknown, IGetLastReportPort>(['getLastProfit'], callback, {
    onSuccess: (response) => {
      setLastProfit(response)
    },
    onError: () => {
      setLastProfit(null)
    }
  })
}

export { useGetLastProfitRequest }
