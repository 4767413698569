import { useEffect } from 'react'

import { useAuthContext } from '@app/common/auth'
import { useTradeContext } from '@app/pages/trade/trade-context'
import { useInternetConnectionContext } from '@app/components/tools/components/internet-connection'

import { useAccountRequest } from '@app/pages/trade/requests'

import { initBalanceSocket } from '@data/socket/stocks/balance'

const useAccount = (): void => {
  const { stock, user } = useAuthContext()
  const { AccountEntity } = useTradeContext()
  const { addNewDependencies, removeDependencies, status } = useInternetConnectionContext()

  const { refetch } = useAccountRequest()

  useEffect(() => {
    const balanceSocket = initBalanceSocket({ stock })

    if (status === 'online' && user) {
      balanceSocket.subscribe(user.id)

      balanceSocket.onMessage((message) => {
        AccountEntity.updateBalance(message)
      })
    }

    return () => {
      balanceSocket.unsubscribe()
    }
  }, [AccountEntity, stock, status, user])

  useEffect(() => {
    addNewDependencies(2, async () => {
      await refetch()
    })

    return () => {
      removeDependencies(2)
    }
  }, [])
}

export { useAccount }
