import { Stocks } from '@domain/common/enums'

import { MarketOverviewByLastAssetSocket } from '../stocks'

import type { IMarketOverviewByLastAssetSocket } from '../interfaces'

interface IPayload {
  stock: Stocks
}

const initMarketOverviewByLastAssetSocket = (payload: IPayload): IMarketOverviewByLastAssetSocket => {
  switch (payload.stock) {
    case Stocks.BINANCE: return new MarketOverviewByLastAssetSocket('marketWindow')

    default: return new MarketOverviewByLastAssetSocket('marketWindow')
  }
}

export { initMarketOverviewByLastAssetSocket }
