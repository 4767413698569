import { HotKeyEvents, HotKeyEventsInclude, HotKeyEventsOption } from '@domain/setting-profile/hot-keys'

import { KeyMoseDouble, KeyMoseSingle } from '@app/components/ui/inputs/input-keyboard/_setting'

import type { IIKeyboard } from '@app/pages/profile/settings/keyboard/components/_validation'
/* eslint-disable line-comment-position, no-inline-comments */
enum TypeMoveOrder {
  ALL = 'all_orders', //С сохранением расстояния
  MAX = 'max_volume', //Самый большой объем
  MIN = 'min_volume', //Самый маленький объем
  MAX_PROFIT = 'max_profit', //Самый большой профит
  CURSOR_PRICE = 'cursor_price', //Все в курсор
  LAST_DELIVERED = 'last_delivered', //Последний выставленный
  // LAST_EXCHANGE = 'last_exchange_delivered'
}

enum KeyboardChapter {
  ORDERS = 'orders',
  DEALS = 'deals',
  SCREENSHOT = 'screenshot',
  GRAPHIC = 'graphic'
}

enum HotKeyRules {
  ALL = 'all',
  ALL_REQUIRE = 'all_require',
  ONLY_KEYBOARD = 'only_keyboard'
}

const OTHER_KEY = [
  'ShiftLeft', 'ShiftRight', 'AltLeft',
  'AltRight', 'ControlLeft', 'ControlRight',
  'ArrowUp', 'ArrowDown', 'ArrowRight', 'ArrowLeft', 'Space'
]

const EXCEPTED_KEY: string[][] = [['MetaRight'], ['MetaLeft'], ['OsLeft'], ['OsRight'], ['F1'], ['F5'], ['Tab']]

const HOTKEY_IN_CHAPTER: Record<HotKeyEvents, KeyboardChapter> = {
  [HotKeyEvents.ORDER_BUY]: KeyboardChapter.ORDERS,
  [HotKeyEvents.ORDER_SALE]: KeyboardChapter.ORDERS,
  [HotKeyEvents.ORDER_CANCEL]: KeyboardChapter.ORDERS,
  [HotKeyEvents.ORDER_CANCEL_ALL]: KeyboardChapter.ORDERS,
  [HotKeyEvents.ORDER_SIZE]: KeyboardChapter.ORDERS,
  [HotKeyEvents.DEALS_PANIC]: KeyboardChapter.DEALS,
  [HotKeyEvents.DEALS_DIVISION]: KeyboardChapter.DEALS,
  [HotKeyEvents.DEALS_CANCEL]: KeyboardChapter.DEALS,
  [HotKeyEvents.DEALS_UNION]: KeyboardChapter.DEALS,
  [HotKeyEvents.DEALS_ZERO_PROFIT]: KeyboardChapter.DEALS,
  [HotKeyEvents.SCREEN_TAB]: KeyboardChapter.SCREENSHOT,
  [HotKeyEvents.SCREEN_SAVE]: KeyboardChapter.SCREENSHOT,
  [HotKeyEvents.SCREEN_COPY]: KeyboardChapter.SCREENSHOT,
  [HotKeyEvents.MOVE_BUY_ORDER]: KeyboardChapter.ORDERS,
  [HotKeyEvents.MOVE_SALE_ORDER]: KeyboardChapter.ORDERS,
  [HotKeyEvents.ALTERNATIVE_MOVE_BUY_ORDER]: KeyboardChapter.ORDERS,
  [HotKeyEvents.ALTERNATIVE_MOVE_SELL_ORDER]: KeyboardChapter.ORDERS,
  [HotKeyEvents.MOVE_TP_DEAL]: KeyboardChapter.DEALS,
  [HotKeyEvents.MOVE_SL_DEAL]: KeyboardChapter.DEALS,
  [HotKeyEvents.ALTERNATIVE_MOVE_TP_DEAL]: KeyboardChapter.DEALS,
  [HotKeyEvents.ALTERNATIVE_MOVE_SL_DEAL]: KeyboardChapter.DEALS,
  [HotKeyEvents.CHANGE_SCALE]: KeyboardChapter.GRAPHIC,
  [HotKeyEvents.HORISONTAL_VOLUMES]: KeyboardChapter.GRAPHIC,
  [HotKeyEvents.VERTICAL_VOLUMES]: KeyboardChapter.GRAPHIC,
}

const HOTKEYS_KEY_LENGHT: Record<HotKeyEvents, number> = {
  [HotKeyEvents.ORDER_BUY]: 3,
  [HotKeyEvents.ORDER_SALE]: 3,
  [HotKeyEvents.ORDER_CANCEL]: 3,
  [HotKeyEvents.ORDER_CANCEL_ALL]: 3,
  [HotKeyEvents.ORDER_SIZE]: 4,
  [HotKeyEvents.DEALS_PANIC]: 4,
  [HotKeyEvents.DEALS_DIVISION]: 4,
  [HotKeyEvents.DEALS_CANCEL]: 4,
  [HotKeyEvents.DEALS_UNION]: 4,
  [HotKeyEvents.DEALS_ZERO_PROFIT]: 4,
  [HotKeyEvents.SCREEN_TAB]: 4,
  [HotKeyEvents.SCREEN_SAVE]: 4,
  [HotKeyEvents.SCREEN_COPY]: 4,
  [HotKeyEvents.MOVE_BUY_ORDER]: 3,
  [HotKeyEvents.MOVE_SALE_ORDER]: 3,
  [HotKeyEvents.ALTERNATIVE_MOVE_BUY_ORDER]: 3,
  [HotKeyEvents.ALTERNATIVE_MOVE_SELL_ORDER]: 3,
  [HotKeyEvents.MOVE_TP_DEAL]: 3,
  [HotKeyEvents.MOVE_SL_DEAL]: 3,
  [HotKeyEvents.ALTERNATIVE_MOVE_TP_DEAL]: 3,
  [HotKeyEvents.ALTERNATIVE_MOVE_SL_DEAL]: 3,
  [HotKeyEvents.CHANGE_SCALE]: 4,
  [HotKeyEvents.HORISONTAL_VOLUMES]: 4,
  [HotKeyEvents.VERTICAL_VOLUMES]: 4,
}

const HOTKEYS_RULES_EVENT: Record<HotKeyEvents, HotKeyRules> = {
  [HotKeyEvents.ORDER_BUY]: HotKeyRules.ALL,
  [HotKeyEvents.ORDER_SALE]: HotKeyRules.ALL,
  [HotKeyEvents.ORDER_CANCEL]: HotKeyRules.ALL,
  [HotKeyEvents.ORDER_CANCEL_ALL]: HotKeyRules.ONLY_KEYBOARD,
  [HotKeyEvents.ORDER_SIZE]: HotKeyRules.ONLY_KEYBOARD,
  [HotKeyEvents.DEALS_PANIC]: HotKeyRules.ONLY_KEYBOARD,
  [HotKeyEvents.DEALS_DIVISION]: HotKeyRules.ONLY_KEYBOARD,
  [HotKeyEvents.DEALS_CANCEL]: HotKeyRules.ONLY_KEYBOARD,
  [HotKeyEvents.DEALS_UNION]: HotKeyRules.ONLY_KEYBOARD,
  [HotKeyEvents.DEALS_ZERO_PROFIT]: HotKeyRules.ONLY_KEYBOARD,
  [HotKeyEvents.SCREEN_TAB]: HotKeyRules.ONLY_KEYBOARD,
  [HotKeyEvents.SCREEN_SAVE]: HotKeyRules.ONLY_KEYBOARD,
  [HotKeyEvents.SCREEN_COPY]: HotKeyRules.ONLY_KEYBOARD,
  [HotKeyEvents.MOVE_BUY_ORDER]: HotKeyRules.ALL_REQUIRE,
  [HotKeyEvents.MOVE_SALE_ORDER]: HotKeyRules.ALL_REQUIRE,
  [HotKeyEvents.ALTERNATIVE_MOVE_BUY_ORDER]: HotKeyRules.ALL_REQUIRE,
  [HotKeyEvents.ALTERNATIVE_MOVE_SELL_ORDER]: HotKeyRules.ALL_REQUIRE,
  [HotKeyEvents.MOVE_TP_DEAL]: HotKeyRules.ALL_REQUIRE,
  [HotKeyEvents.MOVE_SL_DEAL]: HotKeyRules.ALL_REQUIRE,
  [HotKeyEvents.ALTERNATIVE_MOVE_TP_DEAL]: HotKeyRules.ALL_REQUIRE,
  [HotKeyEvents.ALTERNATIVE_MOVE_SL_DEAL]: HotKeyRules.ALL_REQUIRE,
  [HotKeyEvents.CHANGE_SCALE]: HotKeyRules.ONLY_KEYBOARD,
  [HotKeyEvents.HORISONTAL_VOLUMES]: HotKeyRules.ONLY_KEYBOARD,
  [HotKeyEvents.VERTICAL_VOLUMES]: HotKeyRules.ONLY_KEYBOARD,
}

const EXCEPTED_HOTKEY: Record<HotKeyEvents, string[][]> = {
  [HotKeyEvents.ORDER_BUY]: [],
  [HotKeyEvents.ORDER_SALE]: [],
  [HotKeyEvents.ORDER_CANCEL]: [],
  [HotKeyEvents.ORDER_CANCEL_ALL]: [],
  [HotKeyEvents.ORDER_SIZE]: [],
  [HotKeyEvents.DEALS_PANIC]: [],
  [HotKeyEvents.DEALS_DIVISION]: [],
  [HotKeyEvents.DEALS_CANCEL]: [],
  [HotKeyEvents.DEALS_UNION]: [],
  [HotKeyEvents.DEALS_ZERO_PROFIT]: [],
  [HotKeyEvents.SCREEN_TAB]: [],
  [HotKeyEvents.SCREEN_SAVE]: [],
  [HotKeyEvents.SCREEN_COPY]: [],
  [HotKeyEvents.MOVE_BUY_ORDER]: [[KeyMoseSingle.LKM], [KeyMoseSingle.RKM]],
  [HotKeyEvents.MOVE_SALE_ORDER]: [[KeyMoseSingle.LKM], [KeyMoseSingle.RKM]],
  [HotKeyEvents.ALTERNATIVE_MOVE_BUY_ORDER]: [[KeyMoseSingle.LKM], [KeyMoseSingle.RKM]],
  [HotKeyEvents.ALTERNATIVE_MOVE_SELL_ORDER]: [[KeyMoseSingle.LKM], [KeyMoseSingle.RKM]],
  [HotKeyEvents.MOVE_TP_DEAL]: [[KeyMoseSingle.LKM], [KeyMoseSingle.RKM]],
  [HotKeyEvents.MOVE_SL_DEAL]: [[KeyMoseSingle.LKM], [KeyMoseSingle.RKM]],
  [HotKeyEvents.ALTERNATIVE_MOVE_TP_DEAL]: [[KeyMoseSingle.LKM], [KeyMoseSingle.RKM]],
  [HotKeyEvents.ALTERNATIVE_MOVE_SL_DEAL]: [[KeyMoseSingle.LKM], [KeyMoseSingle.RKM]],
  [HotKeyEvents.CHANGE_SCALE]: [],
  [HotKeyEvents.HORISONTAL_VOLUMES]: [],
  [HotKeyEvents.VERTICAL_VOLUMES]: [],
}

const REQUIRED_HOTKEY: Record<HotKeyEvents, boolean> = {
  [HotKeyEvents.ORDER_BUY]: true,
  [HotKeyEvents.ORDER_SALE]: true,
  [HotKeyEvents.ORDER_CANCEL]: true,
  [HotKeyEvents.ORDER_CANCEL_ALL]: true,
  [HotKeyEvents.ORDER_SIZE]: true,
  [HotKeyEvents.DEALS_PANIC]: true,
  [HotKeyEvents.DEALS_DIVISION]: true,
  [HotKeyEvents.DEALS_CANCEL]: true,
  [HotKeyEvents.DEALS_UNION]: true,
  [HotKeyEvents.DEALS_ZERO_PROFIT]: false,
  [HotKeyEvents.SCREEN_TAB]: true,
  [HotKeyEvents.SCREEN_SAVE]: true,
  [HotKeyEvents.SCREEN_COPY]: true,
  [HotKeyEvents.MOVE_BUY_ORDER]: true,
  [HotKeyEvents.MOVE_SALE_ORDER]: true,
  [HotKeyEvents.ALTERNATIVE_MOVE_BUY_ORDER]: true,
  [HotKeyEvents.ALTERNATIVE_MOVE_SELL_ORDER]: true,
  [HotKeyEvents.MOVE_TP_DEAL]: true,
  [HotKeyEvents.MOVE_SL_DEAL]: true,
  [HotKeyEvents.ALTERNATIVE_MOVE_TP_DEAL]: true,
  [HotKeyEvents.ALTERNATIVE_MOVE_SL_DEAL]: true,
  [HotKeyEvents.CHANGE_SCALE]: true,
  [HotKeyEvents.HORISONTAL_VOLUMES]: false,
  [HotKeyEvents.VERTICAL_VOLUMES]: false,
}

const DEFAULT_VALUE_ORDER = {
  [HotKeyEvents.ORDER_BUY]: [KeyMoseDouble.D_LKM],
  [HotKeyEvents.ORDER_SALE]: [KeyMoseDouble.D_RKM],
  [HotKeyEvents.ORDER_CANCEL]: ['AltLeft', KeyMoseDouble.D_LKM],
  [HotKeyEvents.ORDER_CANCEL_ALL]: ['Space'],
  [HotKeyEvents.ORDER_SIZE]: [['KeyQ'], ['KeyW'], ['KeyE'], ['KeyR']],
}

const DEFAULT_VALUE_ORDER_INCLUDE = {
  [HotKeyEventsInclude.ORDER_BUY]: true,
  [HotKeyEventsInclude.ORDER_SALE]: true,
  [HotKeyEventsInclude.ORDER_CANCEL]: true,
  [HotKeyEventsInclude.ORDER_CANCEL_ALL]: true,
  [HotKeyEventsInclude.ORDER_SIZE]: [true, true, true, true]
}

const DEFAULT_VALUE_ORDER_OPTION = {
  [HotKeyEventsOption.ORDER_BUY]: '',
  [HotKeyEventsOption.ORDER_SALE]: '',
  [HotKeyEventsOption.ORDER_CANCEL]: '',
  [HotKeyEventsOption.ORDER_CANCEL_ALL]: '',
  [HotKeyEventsOption.ORDER_SIZE]: ['0', '1', '2', '3']
}

const DEFAULT_VALUE_DEALS = {
  [HotKeyEvents.DEALS_PANIC]: [],
  [HotKeyEvents.DEALS_DIVISION]: ['KeyB'],
  [HotKeyEvents.DEALS_UNION]: ['KeyN'],
  [HotKeyEvents.DEALS_CANCEL]: ['KeyM'],
  [HotKeyEvents.DEALS_ZERO_PROFIT]: [],
}

const DEFAULT_VALUE_DEALS_INCLUDE = {
  [HotKeyEventsInclude.DEALS_PANIC]: false,
  [HotKeyEventsInclude.DEALS_DIVISION]: true,
  [HotKeyEventsInclude.DEALS_UNION]: true,
  [HotKeyEventsInclude.DEALS_CANCEL]: true,
  [HotKeyEventsInclude.DEALS_ZERO_PROFIT]: false,
}

const DEFAULT_VALUE_DEALS_OPTION = {
  [HotKeyEventsOption.DEALS_PANIC]: '',
  [HotKeyEventsOption.DEALS_DIVISION]: '',
  [HotKeyEventsOption.DEALS_UNION]: '',
  [HotKeyEventsOption.DEALS_CANCEL]: '',
  [HotKeyEventsOption.DEALS_ZERO_PROFIT]: '',
}

const DEFAULT_VALUE_SCREEN = {
  [HotKeyEvents.SCREEN_TAB]: ['KeyO'],
  [HotKeyEvents.SCREEN_SAVE]: ['KeyI'],
  [HotKeyEvents.SCREEN_COPY]: ['KeyU'],
}

const DEFAULT_VALUE_SCREEN_INCLUDE = {
  [HotKeyEventsInclude.SCREEN_TAB]: true,
  [HotKeyEventsInclude.SCREEN_SAVE]: true,
  [HotKeyEventsInclude.SCREEN_COPY]: true,
}

const DEFAULT_VALUE_SCREEN_OPTION = {
  [HotKeyEventsOption.SCREEN_TAB]: '',
  [HotKeyEventsOption.SCREEN_SAVE]: '',
  [HotKeyEventsOption.SCREEN_COPY]: '',
}

const DEFAULT_VALUE_MOVE_ORDER = {
  [HotKeyEvents.MOVE_BUY_ORDER]: ['ShiftLeft', KeyMoseDouble.D_LKM],
  [HotKeyEvents.MOVE_SALE_ORDER]: ['ControlLeft', KeyMoseDouble.D_LKM],
  [HotKeyEvents.ALTERNATIVE_MOVE_BUY_ORDER]: ['ShiftLeft', KeyMoseDouble.D_RKM],
  [HotKeyEvents.ALTERNATIVE_MOVE_SELL_ORDER]: ['ControlLeft', KeyMoseDouble.D_RKM],
}

const DEFAULT_VALUE_MOVE_ORDER_INCLUDE = {
  [HotKeyEventsInclude.MOVE_BUY_ORDER]: true,
  [HotKeyEventsInclude.MOVE_SALE_ORDER]: true,
  [HotKeyEventsInclude.ALTERNATIVE_MOVE_BUY_ORDER]: true,
  [HotKeyEventsInclude.ALTERNATIVE_MOVE_SELL_ORDER]: true,
}

const DEFAULT_VALUE_MOVE_ORDER_OPTION = {
  [HotKeyEventsOption.MOVE_BUY_ORDER]: TypeMoveOrder.MIN,
  [HotKeyEventsOption.MOVE_SALE_ORDER]: TypeMoveOrder.MAX,
  [HotKeyEventsOption.ALTERNATIVE_MOVE_BUY_ORDER]: TypeMoveOrder.CURSOR_PRICE,
  [HotKeyEventsOption.ALTERNATIVE_MOVE_SELL_ORDER]: TypeMoveOrder.CURSOR_PRICE,
}

const DEFAULT_VALUE_MOVE_DEALS = {
  [HotKeyEvents.MOVE_TP_DEAL]: ['KeyZ', KeyMoseDouble.D_LKM],
  [HotKeyEvents.MOVE_SL_DEAL]: ['KeyX', KeyMoseDouble.D_LKM],
  [HotKeyEvents.ALTERNATIVE_MOVE_TP_DEAL]: ['KeyZ', KeyMoseDouble.D_RKM],
  [HotKeyEvents.ALTERNATIVE_MOVE_SL_DEAL]: ['KeyX', KeyMoseDouble.D_RKM],
}

const DEFAULT_VALUE_MOVE_DEALS_INCLUDE = {
  [HotKeyEventsInclude.MOVE_TP_DEAL]: true,
  [HotKeyEventsInclude.MOVE_SL_DEAL]: true,
  [HotKeyEventsInclude.ALTERNATIVE_MOVE_TP_DEAL]: true,
  [HotKeyEventsInclude.ALTERNATIVE_MOVE_SL_DEAL]: true,
}

const DEFAULT_VALUE_MOVE_DEALS_OPTION = {
  [HotKeyEventsOption.MOVE_TP_DEAL]: TypeMoveOrder.MIN,
  [HotKeyEventsOption.MOVE_SL_DEAL]: TypeMoveOrder.MAX,
  [HotKeyEventsOption.ALTERNATIVE_MOVE_TP_DEAL]: TypeMoveOrder.CURSOR_PRICE,
  [HotKeyEventsOption.ALTERNATIVE_MOVE_SL_DEAL]: TypeMoveOrder.CURSOR_PRICE,
}

const DEFAULT_VALUE_CHANGE_SCALE = {
  [HotKeyEvents.CHANGE_SCALE]: [
    ['Digit1'],
    ['Digit2'],
    ['Digit3'],
  ]
}

const DEFAULT_VALUE_CHANGE_SCALE_INCLUDE = {
  [HotKeyEventsInclude.CHANGE_SCALE]: [
    Boolean(true),
    Boolean(true),
    Boolean(true),
  ]
}

const DEFAULT_VALUE_CHANGE_SCALE_OPTION = {
  [HotKeyEventsOption.CHANGE_SCALE]: ['5', '20', '100']
}

const DEFAULT_VALUE_VOLUMES = {
  [HotKeyEvents.HORISONTAL_VOLUMES]: [],
  [HotKeyEvents.VERTICAL_VOLUMES]: [],
}

const DEFAULT_VALUE_VOLUMES_INCLUDE = {
  [HotKeyEventsInclude.HORISONTAL_VOLUMES]: false,
  [HotKeyEventsInclude.VERTICAL_VOLUMES]: false,
}

const DEFAULT_VALUE_VOLUMES_OPTION = {
  [HotKeyEventsOption.HORISONTAL_VOLUMES]: '',
  [HotKeyEventsOption.VERTICAL_VOLUMES]: '',
}

const DEFAULT_VALUE_KEYBOARD: IIKeyboard = {
  ...DEFAULT_VALUE_ORDER,
  ...DEFAULT_VALUE_ORDER_INCLUDE,
  ...DEFAULT_VALUE_ORDER_OPTION,
  ...DEFAULT_VALUE_DEALS,
  ...DEFAULT_VALUE_DEALS_INCLUDE,
  ...DEFAULT_VALUE_DEALS_OPTION,
  ...DEFAULT_VALUE_SCREEN,
  ...DEFAULT_VALUE_SCREEN_INCLUDE,
  ...DEFAULT_VALUE_SCREEN_OPTION,
  ...DEFAULT_VALUE_MOVE_ORDER,
  ...DEFAULT_VALUE_MOVE_ORDER_INCLUDE,
  ...DEFAULT_VALUE_MOVE_ORDER_OPTION,
  ...DEFAULT_VALUE_MOVE_DEALS,
  ...DEFAULT_VALUE_MOVE_DEALS_INCLUDE,
  ...DEFAULT_VALUE_MOVE_DEALS_OPTION,
  ...DEFAULT_VALUE_CHANGE_SCALE,
  ...DEFAULT_VALUE_CHANGE_SCALE_INCLUDE,
  ...DEFAULT_VALUE_CHANGE_SCALE_OPTION,
  ...DEFAULT_VALUE_VOLUMES,
  ...DEFAULT_VALUE_VOLUMES_INCLUDE,
  ...DEFAULT_VALUE_VOLUMES_OPTION,
  countButton: 3
}

const LIST_MOVE_ORDER = [
  { id: TypeMoveOrder.ALL, label: 'settings-hotkey/label-move-drop-list-all' },
  { id: TypeMoveOrder.MAX, label: 'settings-hotkey/label-move-drop-list-max' },
  { id: TypeMoveOrder.MIN, label: 'settings-hotkey/label-move-drop-list-min' },
  { id: TypeMoveOrder.MAX_PROFIT, label: 'settings-hotkey/label-move-drop-list-max-profit' },
  { id: TypeMoveOrder.CURSOR_PRICE, label: 'settings-hotkey/label-move-drop-list-cursor-price' },
  { id: TypeMoveOrder.LAST_DELIVERED, label: 'settings-hotkey/label-move-drop-list-last' },
  // { id: TypeMoveOrder.LAST_EXCHANGE, label: 'settings-hotkey/label-move-drop-list-last-exchange' },
]

export {
  DEFAULT_VALUE_KEYBOARD,
  DEFAULT_VALUE_ORDER,
  DEFAULT_VALUE_ORDER_INCLUDE,
  DEFAULT_VALUE_ORDER_OPTION,
  DEFAULT_VALUE_DEALS,
  DEFAULT_VALUE_DEALS_INCLUDE,
  DEFAULT_VALUE_DEALS_OPTION,
  DEFAULT_VALUE_SCREEN,
  DEFAULT_VALUE_SCREEN_INCLUDE,
  DEFAULT_VALUE_SCREEN_OPTION,
  DEFAULT_VALUE_MOVE_ORDER,
  DEFAULT_VALUE_MOVE_ORDER_INCLUDE,
  DEFAULT_VALUE_MOVE_ORDER_OPTION,
  DEFAULT_VALUE_MOVE_DEALS,
  DEFAULT_VALUE_MOVE_DEALS_INCLUDE,
  DEFAULT_VALUE_MOVE_DEALS_OPTION,
  DEFAULT_VALUE_CHANGE_SCALE,
  DEFAULT_VALUE_CHANGE_SCALE_INCLUDE,
  DEFAULT_VALUE_CHANGE_SCALE_OPTION,
  LIST_MOVE_ORDER,
  HOTKEY_IN_CHAPTER,
  HOTKEYS_RULES_EVENT,
  HOTKEYS_KEY_LENGHT,
  OTHER_KEY,
  EXCEPTED_HOTKEY,
  EXCEPTED_KEY,
  DEFAULT_VALUE_VOLUMES,
  DEFAULT_VALUE_VOLUMES_INCLUDE,
  DEFAULT_VALUE_VOLUMES_OPTION,
  REQUIRED_HOTKEY,
  KeyboardChapter,
  TypeMoveOrder,
  HotKeyRules,
}
