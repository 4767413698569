import { BinanceCommissionEntity } from '../../entity'

import type {
  ICreateCommissionUseCase,
  IGetCommissionUseCase,
  IGetDiscountCommissionUseCase,
  ICommissionEntity
} from '@domain/stocks/interfaces/commission'

class CreateBinanceCommissionUseCase implements ICreateCommissionUseCase {

  private readonly _getCommissionUseCase: IGetCommissionUseCase

  private readonly _getDiscountUseCase: IGetDiscountCommissionUseCase

  constructor (getCommissionUseCase: IGetCommissionUseCase, getDiscountUseCase: IGetDiscountCommissionUseCase) {
    this._getCommissionUseCase = getCommissionUseCase
    this._getDiscountUseCase = getDiscountUseCase
  }

  public async execute (): Promise<ICommissionEntity> {
    const commission = await this._getCommissionUseCase.execute()
    const discount = await this._getDiscountUseCase.execute()

    const discountAsset = 'BNB'

    return BinanceCommissionEntity.new({
      id: 'BinanceCommissionEntity',
      commission,
      discount,
      discountAsset
    })
  }

}

export { CreateBinanceCommissionUseCase }
