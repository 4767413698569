import { useEffect, useState } from 'react'

import { loadUserData } from '@app/auth/request'

import { CookieService, HttpServiceV1, HttpServiceV2, JwtService } from '@data/common/services'
import AuthLoader from '@app/auth/loader'

import { CookieValues, HttpHeaders } from '@data/common/enums'

import type { UseAuthConfirmFn } from '@app/common/auth'

const useAuthConfirm: UseAuthConfirmFn = ({ setIsAuth, setUser, setStock, isAuth }) => {
  const [isLoading, setIsLoading] = useState(true)
  const TIMEOUT = 2000

  useEffect(() => {
    const timeoutAction = setTimeout(() => {
      setIsLoading(false)
    }, TIMEOUT)

    return () => {
      clearTimeout(timeoutAction)
    }
  }, [isAuth])

  const handleLoadUserData = (): void => {
    const accessToken = CookieService.get<string>(CookieValues.ACCESS_TOKEN)
    const ms = 1000
    const encodedToken = JwtService.encode<{ uid: string }>(accessToken)
    const exp = Number(encodedToken.exp) * ms
    const id = encodedToken.uid

    if (Date.now() > Number(exp)) {
      setIsAuth(false)
    } else {
      HttpServiceV1.setHeaders(HttpHeaders.AUTHORIZATION, `Bearer ${accessToken}`)
      HttpServiceV2.setHeaders(HttpHeaders.AUTHORIZATION, `Bearer ${accessToken}`)

      loadUserData({ id, setUser, setStock })
        .then(() => {
          setIsAuth(true)
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }

  useEffect(() => {
    try {
      handleLoadUserData()
    } catch {
      setIsAuth(false)
      setUser(null)
    }
  }, [])

  return { isLoading: isLoading, LoaderComponent: AuthLoader }
}

export default useAuthConfirm
