import { useMutation } from '@tanstack/react-query'

import { initOrderRepository } from '@data/repository/stocks/order'
import { initGetOpenOrderListUseCase } from '@domain/stocks/order'

import type { Stocks } from '@domain/common/enums'

import type { IOrderDTO, IGetOpenOrderListPort } from '@domain/stocks/order'

import type { UseMutationResult } from '@tanstack/react-query'

interface IGetOrderListPayload extends IGetOpenOrderListPort {
  stock: Stocks
}

const useGetOpenOrderListRequest = (): UseMutationResult<IOrderDTO[], never, IGetOrderListPayload> => {
  const callback = async (port: IGetOrderListPayload): Promise<IOrderDTO[]> => {
    const repository = initOrderRepository({ stock: port.stock })
    const useCase = initGetOpenOrderListUseCase({ stock: port.stock, repository })

    return useCase.execute(port)
      .then((response) => response)
      .catch(() => [])
  }

  return useMutation(callback)
}

export { useGetOpenOrderListRequest }
