import { HttpServiceV1 } from '@data/common/services'

import type { ICommissionRepository, ICommissionDTO } from '@domain/stocks/interfaces/commission'

import type { IBinanceCommission, IBinanceBurnStatus } from '../../interfaces'

class BinanceMainnetCommissionRepository implements ICommissionRepository {

  public async getCommission (): Promise<ICommissionDTO[]> {
    return HttpServiceV1.get<IBinanceCommission[]>('/binance-provider-account/trade-fee')
      .then((response) => {
        return response.map((item) => {
          return {
            symbol: item.symbol,
            commission: {
              taker: item.takerCommission,
              maker: item.makerCommission
            }
          }
        })
      })
  }

  public async getDiscount (): Promise<string> {
    return HttpServiceV1.get<IBinanceBurnStatus>('/binance-provider-account/bnb-burn')
      .then((response) => {
        return response.spotBnbBurn ? '25' : '0'
      })
  }

}

export { BinanceMainnetCommissionRepository }
