import type { IOrderDTO, IGetOpenOrderListPort, IGetOpenOrderListUseCase, IOrderRepository } from '../../../../common'

class GetOpenOrderListUseCase implements IGetOpenOrderListUseCase {

  protected repository: IOrderRepository

  constructor (repository: IOrderRepository) {
    this.repository = repository
  }

  public async execute (port: IGetOpenOrderListPort): Promise<IOrderDTO[]> {
    return this.repository.getOpenOrderList(port)
  }

}

export { GetOpenOrderListUseCase }
