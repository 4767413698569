import type {
  IGetCommissionUseCase,
  ICommissionRepository,
  ICommissionDTO
} from '@domain/stocks/interfaces/commission'

class GetBinanceCommissionUseCase implements IGetCommissionUseCase {

  private readonly _repository: ICommissionRepository

  constructor (repository: ICommissionRepository) {
    this._repository = repository
  }

  public async execute (): Promise<ICommissionDTO[]> {
    return this._repository.getCommission()
  }

}

export { GetBinanceCommissionUseCase }
