import { ExecutedDetectSocket } from '@data/socket/stocks/binance/detect'

import type { IUserEntity } from '@domain/user/interfaces'
import type { IDetectSettingDTO } from '@domain/setting-profile/detect'
import type { Stocks } from '@domain/common/enums'
import type { IExecutedDetectDTO } from '@domain/stocks/interfaces'
import type { IPairEntity } from '@domain/stocks/pair'

interface IShowMessagePayload {
  detect: IExecutedDetectDTO
  setting: Pick<IDetectSettingDTO, 'alertTime' | 'isEnableSound' | 'sound'>
}

interface IDetectNotificationsPresenterEvents {
  onUpdateExecutedDetectList (value: IExecutedDetectDTO[]): void
  onShowExecutedDetectMessage (value: IShowMessagePayload): void
}

class DetectNotificationsPresenter {

  private _pairEntity: IPairEntity | null

  private _events: IDetectNotificationsPresenterEvents | null

  private _detectSettingList: IDetectSettingDTO[]

  private _executedDetectList: IExecutedDetectDTO[]

  private _executedDetectSocket: ExecutedDetectSocket

  constructor () {
    this._pairEntity = null
    this._events = null

    this._detectSettingList = []
    this._executedDetectList = []

    this._executedDetectSocket = new ExecutedDetectSocket()
  }

  public setPairEntity (value: IPairEntity): void {
    this._pairEntity = value
  }

  public setEvents (value: IDetectNotificationsPresenterEvents): void {
    this._events = value
  }

  public setDetectSettingList (value: IDetectSettingDTO[]): void {
    this._detectSettingList = value
  }

  public setExecutedDetectList (value: IExecutedDetectDTO[]): void {
    this._executedDetectList = value
  }

  public subscribe (stock: Stocks, user: IUserEntity): void {
    this._executedDetectSocket.subscribe(stock, user.id)

    this._executedDetectSocket.onMessage(this._onMessage.bind(this))
  }

  public unsubscribe (): void {
    this._executedDetectSocket.unsubscribe()
  }

  private _onMessage (value: IExecutedDetectDTO): void {
    if (this._pairEntity === null || this._events === null) return

    const pair = this._pairEntity.getPairByTicker(value.symbol, 'symbol')
    const setting = this._detectSettingList.find((item) => item.id === value.id)

    if (pair && setting) {
      const detect = { ...value, symbol: pair.ticker.slash }

      this._executedDetectList.unshift(detect)

      this._events.onUpdateExecutedDetectList(this._executedDetectList)
      this._events.onShowExecutedDetectMessage({
        detect,
        setting: { alertTime: setting.alertTime, isEnableSound: setting.isEnableSound, sound: setting.sound }
      })
    }
  }

}

export { DetectNotificationsPresenter }
export type { IShowMessagePayload }
