import { INIT_STATE_SPECIFICATION } from '../constant'

import type {
  IValidationSpecification,
  IGetSpecificationUseCase,
  ISpecificationDTO,
  ISpecificationRepository
} from '../interface'
/* eslint-disable @typescript-eslint/ban-ts-comment, @typescript-eslint/no-extra-parens */
class GetSpecificationUseCase implements IGetSpecificationUseCase {

  private _repository: ISpecificationRepository

  private _validation: IValidationSpecification

  constructor (repository: ISpecificationRepository, validation: IValidationSpecification) {
    this._validation = validation
    this._repository = repository
  }

  public async execute (): Promise<ISpecificationDTO> {
    return this._repository.getSpecification()
      .then((response) => {
        const keys = Object.entries(response) as [keyof ISpecificationDTO, unknown][]

        for (const [key, value] of keys) {
          const result = this._validation.validate()[key]?.run(value).errors?.code

          if (
            result !== undefined ||
            (['orderSizeButtons', 'orderSizePercentButtons'].includes(key) && (value as number[]).length === 0)
          ) {
            // @ts-expect-error
            response[key] = INIT_STATE_SPECIFICATION[key]
          }
        }

        return response
      })
  }

}

export { GetSpecificationUseCase }
