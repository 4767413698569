import { Autocomplete, styled } from '@mui/material'

const INPUT_COMBOBOX = {
  ComboBox: styled(Autocomplete)<{ isMobile: boolean; isFullHeight: boolean }>`
    .MuiInputBase-sizeSmall {
      scrollbar-width: none;
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      position: initial;

      .MuiAutocomplete-tag {
        height: 20px;
        margin: 0 2px;
      }
    }
    
    .MuiInputBase-root {
      height: ${({ isFullHeight }): string => isFullHeight ? 'auto' : '30px'};
      flex-wrap: ${({ isFullHeight }): string => isFullHeight ? 'wrap' : 'nowrap'};
      row-gap: 5px;
      font-size: ${({ isMobile }): string => isMobile ? '12px' : '14px'};
    }

    .MuiInputBase-sizeSmall::-webkit-scrollbar {
      position: absolute;
      width: 1em;
      height: 0;
    }

    .MuiOutlinedInput-notchedOutline {
      outline-offset: 5px;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }

    .MuiInputBase-sizeSmall::-webkit-scrollbar-track {
      display: none;
    }

    .MuiInputBase-sizeSmall::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      border-radius: 10px;
    }

    .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
      background: ${({ theme }): string => theme.palette.bg.accent};
      right: -1px;
    }

    .MuiChip-label {
      line-height: 14px;
    }

    .MuiAutocomplete-endAdornment {
      top: 0;
      display: flex;
      align-items: center;
      justify-content: end;
      padding: 0 5px;
      width: 60px; 
      height: 100%;
      border-radius: 0 5px 5px 0;

      button {
        visibility: visible;
        padding: 5px;
      }

      .MuiSvgIcon-root {
        color: ${({ theme }): string => theme.palette.mode === 'light' ? '#222' : '#FAFAFA'};
      }
    }
  `,
}

export { INPUT_COMBOBOX }
