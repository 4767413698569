import type { IUseCase } from '@domain/common/interfaces'
import type { IRepositoryCookiesAccept } from '@domain/cookies-accept'

class GetCookiesAcceptUseCase implements IUseCase<never, boolean> {

  private _repository: IRepositoryCookiesAccept

  constructor (repository: IRepositoryCookiesAccept) {
    this._repository = repository
  }

  public async execute (): Promise<boolean> {
    return this._repository.getCookiesAccept()
  }

}

export { GetCookiesAcceptUseCase }
