interface IOutputGoogleAnalytics {
  addGoogleAnalytics(): void
  clearGoogleAnalytics(): void
}

const SETTINGS = {
  code: 'G-JG3MQ8046X'
}

const useGoogleAnalytics = (): IOutputGoogleAnalytics => {
  let remoteScript: HTMLScriptElement | null = null
  let localScript: HTMLScriptElement | null = null

  const addGoogleAnalytics = (): void => {
    remoteScript = document.createElement('script')
    remoteScript.async = true
    remoteScript.src = `https://www.googletagmanager.com/gtag/js?id=${SETTINGS.code}`

    localScript = document.createElement('script')
    localScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
  
      gtag('config', '${SETTINGS.code}');
    `
    document.head.appendChild(remoteScript)
    document.head.appendChild(localScript)
  }

  const clearGoogleAnalytics = (): void => {
    if (remoteScript !== null) document.head.removeChild(remoteScript)
    if (localScript !== null) document.head.removeChild(localScript)
  }

  return {
    addGoogleAnalytics,
    clearGoogleAnalytics
  }
}

export { useGoogleAnalytics }
