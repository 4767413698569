import type { IClipboardPayload } from '@data/common/interfaces'

class ClipboardService {

  public async copy (payload: IClipboardPayload): Promise<void> {
    await navigator.clipboard.write([
      new ClipboardItem({
        [payload.type]: payload.data
      })
    ])
  }

}

export default new ClipboardService()
