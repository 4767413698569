import { ExceptionService } from '@domain/common/services'
import { InternalCode } from '@domain/common/enums'

import { RequiredValidator, ValidationChain } from '@domain/common/utils/validators'

import { UniqueValidator } from '@domain/common/utils/validators/unique-validator'

import { DetectType } from '../../enums'

import type {
  ICreateDetectPort,
  ICreateDetectUseCase,
  IDetectSettingDTO,
  IDetectSettingRepository,
  IDetectValidation,
  ICreateFieldValidation,
  ICreateDetectErrors
} from '../../interface'

class CreateDetectUseCase implements ICreateDetectUseCase {

  private _repository: IDetectSettingRepository

  private _validation: IDetectValidation<ICreateFieldValidation>

  constructor (repository: IDetectSettingRepository, validation: IDetectValidation<ICreateFieldValidation>) {
    this._repository = repository
    this._validation = validation
  }

  public async execute (port: ICreateDetectPort): Promise<IDetectSettingDTO> {
    this._throwErrors(port)

    const isPump = port.detect.type === DetectType.PUMP

    const payload = {
      name: port.detect.name,
      type: isPump ? 'pump' : 'drop',
      soundOn: port.detect.isEnableSound,
      soundType: port.detect.sound,
      notificationDuration: Number(port.detect.alertTime),
      pairListId: port.detect.pairList,
      minVolume: port.detect.minVolumePerDay,
      maxVolume: this._transformToNullString(port.detect.maxVolumePerDay),
      minHourlyVolume: port.detect.minVolumePerHour,
      maxHourlyVolume: this._transformToNullString(port.detect.maxVolumePerHour),
      setting: {
        priceInterval: `${port.detect.priceInterval}s`,
        priceRaise: port.detect.priceChange,
        perSecMin: this._transformToNullNumber(port.detect.minTradesPerSec),
        perSecMax: this._transformToNullNumber(port.detect.maxTradesPerSec),
        perSecVolume: this._transformToNullString(port.detect.volumePerSec),
        emaVolumeRaise: this._transformToNullNumber(port.detect.volumeEMA)
      },
      delta: port.detect.delta
    }

    return this._repository.createDetect(payload)
  }

  private _transformToNullString (value: string): string | null {
    return value === '' ? null : value
  }

  private _transformToNullNumber (value: string): number | null {
    return value === '' ? null : Number(value)
  }

  private _throwErrors (port: ICreateDetectPort): void {
    const errors = this._validatePort(port)

    if (Object.keys(errors).length) {
      throw ExceptionService.new({
        status: {
          code: InternalCode.VALIDATION_ERROR,
          message: `Validation error in ${this.constructor.name}`
        },
        data: errors
      })
    }
  }

  private _validatePort (port: ICreateDetectPort): ICreateDetectErrors {
    const errors: ICreateDetectErrors = {}
    const keys = Object.entries(port.detect) as [keyof ICreateDetectPort['detect'], string][]

    for (const [key, value] of keys) {
      if (key === 'name') {
        const chain = new ValidationChain([
          new UniqueValidator([value, ...port.list.map((item) => item.name)])
        ])

        const error = chain.run(value).errors?.code

        if (error !== undefined) errors[key] = error
      }

      if (key === 'delta') {
        const errorsDelta: { index: number }[] = []

        port.detect.delta.forEach((delta, index) => {
          const _chain = new ValidationChain([
            new RequiredValidator()
          ])
          const keysDelta = Object.entries(delta)

          const errorDelta: Record<string, number> = {}
          for (const [keyDelta, keyValue] of keysDelta) {
            if (['min', 'max'].includes(keyDelta)) {
              const _error = _chain.run(keyValue).errors?.code
              if (_error !== undefined) {
                errorDelta[keyDelta] = _error
              }
            }
          }

          if (Object.keys(errorDelta).length !== 0) errorsDelta.push({ index, ...errorDelta })
        })
        if (errorsDelta.length > 0) errors.delta = errorsDelta

        continue
      }

      const validator = this._validation.validate()[key]

      if (validator) {
        const result = validator.run(value).errors?.code

        if (result !== undefined) errors[key] = result
      }
    }

    return errors
  }

}

export { CreateDetectUseCase }
