import { HotKeyEvents, HotKeyEventsInclude, HotKeyEventsOption } from '@domain/setting-profile/hot-keys'
import {
  DEFAULT_VALUE_DEALS,
  DEFAULT_VALUE_DEALS_INCLUDE,
  DEFAULT_VALUE_DEALS_OPTION,
  DEFAULT_VALUE_MOVE_ORDER,
  DEFAULT_VALUE_MOVE_ORDER_INCLUDE,
  DEFAULT_VALUE_MOVE_ORDER_OPTION,
  DEFAULT_VALUE_ORDER,
  DEFAULT_VALUE_ORDER_INCLUDE,
  DEFAULT_VALUE_ORDER_OPTION,
  DEFAULT_VALUE_SCREEN,
  DEFAULT_VALUE_SCREEN_INCLUDE,
  DEFAULT_VALUE_SCREEN_OPTION
} from '@app/pages/profile/settings/keyboard/components/_setting'

import type { IHotKeyEntity } from '@domain/setting-profile/hot-keys'

const INIT_STATE_HOT_KEY: IHotKeyEntity[] = [
  {
    event: HotKeyEvents.ORDER_BUY,
    command: DEFAULT_VALUE_ORDER[HotKeyEvents.ORDER_BUY].join(';'),
    option: DEFAULT_VALUE_ORDER_OPTION[HotKeyEventsOption.ORDER_BUY],
    isInclude: DEFAULT_VALUE_ORDER_INCLUDE[HotKeyEventsInclude.ORDER_BUY]
  },
  {
    event: HotKeyEvents.ORDER_CANCEL,
    command: DEFAULT_VALUE_ORDER[HotKeyEvents.ORDER_CANCEL].join(';'),
    option: DEFAULT_VALUE_ORDER_OPTION[HotKeyEventsOption.ORDER_CANCEL],
    isInclude: DEFAULT_VALUE_ORDER_INCLUDE[HotKeyEventsInclude.ORDER_CANCEL]
  },
  {
    event: HotKeyEvents.ORDER_SALE,
    command: DEFAULT_VALUE_ORDER[HotKeyEvents.ORDER_SALE].join(';'),
    option: DEFAULT_VALUE_ORDER_OPTION[HotKeyEventsOption.ORDER_SALE],
    isInclude: DEFAULT_VALUE_ORDER_INCLUDE[HotKeyEventsInclude.ORDER_SALE]
  },
  {
    event: HotKeyEvents.DEALS_PANIC,
    command: DEFAULT_VALUE_DEALS[HotKeyEvents.DEALS_PANIC].join(';'),
    option: DEFAULT_VALUE_DEALS_OPTION[HotKeyEventsOption.DEALS_PANIC],
    isInclude: DEFAULT_VALUE_DEALS_INCLUDE[HotKeyEventsInclude.DEALS_PANIC]
  },
  {
    event: HotKeyEvents.DEALS_DIVISION,
    command: DEFAULT_VALUE_DEALS[HotKeyEvents.DEALS_DIVISION].join(';'),
    option: DEFAULT_VALUE_DEALS_OPTION[HotKeyEventsOption.DEALS_DIVISION],
    isInclude: DEFAULT_VALUE_DEALS_INCLUDE[HotKeyEventsInclude.DEALS_DIVISION]
  },
  {
    event: HotKeyEvents.DEALS_CANCEL,
    command: DEFAULT_VALUE_DEALS[HotKeyEvents.DEALS_CANCEL].join(';'),
    option: DEFAULT_VALUE_DEALS_OPTION[HotKeyEventsOption.DEALS_CANCEL],
    isInclude: DEFAULT_VALUE_DEALS_INCLUDE[HotKeyEventsInclude.DEALS_CANCEL]
  },
  {
    event: HotKeyEvents.DEALS_UNION,
    command: DEFAULT_VALUE_DEALS[HotKeyEvents.DEALS_UNION].join(';'),
    option: DEFAULT_VALUE_DEALS_OPTION[HotKeyEventsOption.DEALS_UNION],
    isInclude: DEFAULT_VALUE_DEALS_INCLUDE[HotKeyEventsInclude.DEALS_UNION]
  },
  {
    event: HotKeyEvents.SCREEN_TAB,
    command: DEFAULT_VALUE_SCREEN[HotKeyEvents.SCREEN_TAB].join(';'),
    option: DEFAULT_VALUE_SCREEN_OPTION[HotKeyEventsOption.SCREEN_TAB],
    isInclude: DEFAULT_VALUE_SCREEN_INCLUDE[HotKeyEventsInclude.SCREEN_TAB]
  },
  {
    event: HotKeyEvents.SCREEN_SAVE,
    command: DEFAULT_VALUE_SCREEN[HotKeyEvents.SCREEN_SAVE].join(';'),
    option: DEFAULT_VALUE_SCREEN_OPTION[HotKeyEventsOption.SCREEN_SAVE],
    isInclude: DEFAULT_VALUE_SCREEN_INCLUDE[HotKeyEventsInclude.SCREEN_SAVE]
  },
  {
    event: HotKeyEvents.SCREEN_COPY,
    command: DEFAULT_VALUE_SCREEN[HotKeyEvents.SCREEN_COPY].join(';'),
    option: DEFAULT_VALUE_SCREEN_OPTION[HotKeyEventsOption.SCREEN_COPY],
    isInclude: DEFAULT_VALUE_SCREEN_INCLUDE[HotKeyEventsInclude.SCREEN_COPY]
  },
  {
    event: HotKeyEvents.MOVE_BUY_ORDER,
    command: DEFAULT_VALUE_MOVE_ORDER[HotKeyEvents.MOVE_BUY_ORDER].join(';'),
    option: DEFAULT_VALUE_MOVE_ORDER_OPTION[HotKeyEventsOption.MOVE_BUY_ORDER],
    isInclude: DEFAULT_VALUE_MOVE_ORDER_INCLUDE[HotKeyEventsInclude.MOVE_BUY_ORDER]
  },
  {
    event: HotKeyEvents.MOVE_SALE_ORDER,
    command: DEFAULT_VALUE_MOVE_ORDER[HotKeyEvents.MOVE_SALE_ORDER].join(';'),
    option: DEFAULT_VALUE_MOVE_ORDER_OPTION[HotKeyEventsOption.MOVE_SALE_ORDER],
    isInclude: DEFAULT_VALUE_MOVE_ORDER_INCLUDE[HotKeyEventsInclude.MOVE_SALE_ORDER]
  },
  {
    event: HotKeyEvents.ALTERNATIVE_MOVE_BUY_ORDER,
    command: DEFAULT_VALUE_MOVE_ORDER[HotKeyEvents.ALTERNATIVE_MOVE_BUY_ORDER].join(';'),
    option: DEFAULT_VALUE_MOVE_ORDER_OPTION[HotKeyEventsOption.ALTERNATIVE_MOVE_BUY_ORDER],
    isInclude: DEFAULT_VALUE_MOVE_ORDER_INCLUDE[HotKeyEventsInclude.ALTERNATIVE_MOVE_BUY_ORDER]
  },
  {
    event: HotKeyEvents.ALTERNATIVE_MOVE_SELL_ORDER,
    command: DEFAULT_VALUE_MOVE_ORDER[HotKeyEvents.ALTERNATIVE_MOVE_SELL_ORDER].join(';'),
    option: DEFAULT_VALUE_MOVE_ORDER_OPTION[HotKeyEventsOption.ALTERNATIVE_MOVE_SELL_ORDER],
    isInclude: DEFAULT_VALUE_MOVE_ORDER_INCLUDE[HotKeyEventsInclude.ALTERNATIVE_MOVE_SELL_ORDER]
  },
  {
    event: HotKeyEvents.CHANGE_SCALE,
    data: [],
    countButton: 3,
  },
  {
    event: HotKeyEvents.ORDER_SIZE,
    data: []
  }
]

export { INIT_STATE_HOT_KEY }
