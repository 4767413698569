import type { Theme } from '@mui/material'

import type { Components } from '@mui/material/styles/components'

const LIST_DEFAULT_STYLES: Components<Theme>['MuiList'] = {
  styleOverrides: {
    root: {
      padding: 0,
      '.MuiTypography-root, .MuiButtonBase-root': {
        fontSize: 14
      }
    }
  }
}

export { LIST_DEFAULT_STYLES }
