import { createContext, useContext, useMemo, useState } from 'react'

import type { ICommonReportDTO, IDetailReportDTO } from '@domain/stocks/report'

import type { FC, PropsWithChildren, Dispatch, SetStateAction } from 'react'

interface IReportContext {
  reportList: ICommonReportDTO[]
  detailReportList: IDetailReportDTO[]
  lastProfit: IDetailReportDTO | null
  setReportList: Dispatch<SetStateAction<ICommonReportDTO[]>>
  setDetailReportList: Dispatch<SetStateAction<IDetailReportDTO[]>>
  setLastProfit: Dispatch<SetStateAction<IDetailReportDTO | null>>
}

const defaultValue: IReportContext = {
  reportList: [],
  detailReportList: [],
  lastProfit: null,
  setReportList: (): void => {
    //
  },
  setDetailReportList: (): void => {
    //
  },
  setLastProfit: (): void => {
    //
  }
}

const ReportContext = createContext<IReportContext>(defaultValue)

const useReportContext = (): IReportContext => useContext(ReportContext)

const ReportProvider: FC<PropsWithChildren> = ({ children }) => {
  const [reportList, setReportList] = useState<ICommonReportDTO[]>([])
  const [detailReportList, setDetailReportList] = useState<IDetailReportDTO[]>([])
  const [lastProfit, setLastProfit] = useState<IDetailReportDTO | null>(null)

  const context = useMemo(() => ({
    reportList,
    detailReportList,
    lastProfit,
    setReportList,
    setDetailReportList,
    setLastProfit
  }), [
    reportList,
    detailReportList,
    lastProfit,
    setReportList,
    setDetailReportList,
    setLastProfit
  ])

  return (
    <ReportContext.Provider value={ context }>
      { children }
    </ReportContext.Provider>
  )
}

export { ReportProvider, useReportContext }
