import { CentrifugeService, STOCK_CONNECTION } from '@data/common/services'

import type { Stocks } from '@domain/common/enums'

class RateSocket {

  private _socket?: CentrifugeService<Record<string, string>>

  public subscribe (stock: Stocks): void {
    this._socket = new CentrifugeService('usdt_rates', STOCK_CONNECTION[stock])
  }

  public unsubscribe (): void {
    this._socket?.close()
  }

  public onMessage (callback: (message: Record<string, string>) => void): void {
    this._socket?.onMessage((message) => {
      callback(message)
    })
  }

}

export { RateSocket }
