import type { CreateWindowElementsCallback } from '@data/common/interfaces'

class WindowService {

  private readonly _window: Window

  private constructor () {
    this._window = window.open('about:blank', '_blank')!
  }

  public insertElementToWindow (callback: CreateWindowElementsCallback): void {
    const element = callback(this._window)
    this._window.document.write(element.outerHTML)
    WindowService._setWindowStyle(this._window)
  }

  public static openNewWindow (link: string): void {
    window.open(link)
  }

  public static new (): WindowService {
    return new WindowService()
  }

  private static _setWindowStyle (w: Window): void {
    w.document.body.setAttribute(
      'style',
      'margin: 0px; background: #0e0e0e; height: 100%; display: flex; align-items: center; justify-content: center;'
    )
  }

}

export { WindowService }
