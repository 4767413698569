import type { ILimitRepository, IGetLimitUseCase, ILimitDTO } from '@domain/stocks/interfaces'

class GetBinanceLimitUseCase implements IGetLimitUseCase {

  private _repository: ILimitRepository

  constructor (repository: ILimitRepository) {
    this._repository = repository
  }

  public async execute (): Promise<ILimitDTO> {
    return this._repository.getLimit()
  }

}

export { GetBinanceLimitUseCase }
