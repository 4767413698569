import { useEffect } from 'react'

import { useAuthContext } from '@app/common/auth'
import { useGetUserProfitRequest } from '@app/pages/trade/requests'
import { useInternetConnectionContext } from '@app/components/tools/components/internet-connection'

import { UserProfitSocket } from '@data/socket/user-profit'
import { useTradeContext } from '@app/pages/trade/trade-context'

const socket = new UserProfitSocket()

const useUserProfit = (): void => {
  const { stock, user } = useAuthContext()
  const { UserProfitEntity } = useTradeContext()
  const { status } = useInternetConnectionContext()

  const {} = useGetUserProfitRequest()

  useEffect(() => {
    socket.setEntity(UserProfitEntity)
  }, [UserProfitEntity])

  useEffect(() => {
    if (status === 'online' && user) socket.subscribe(stock, user.id)

    socket.onMessage()

    return () => {
      socket.unsubscribe()
    }
  }, [stock, status, user])
}

export { useUserProfit }
