/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/func-call-spacing */
/* eslint-disable no-unexpected-multiline */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react/jsx-handler-names */
import { Typography } from '@mui/material'

import { type FC, useMemo, useRef, useEffect } from 'react'

import Typed from 'typed.js'

import type { TypographyProps, TypographyVariant } from '@mui/material'

interface ITypingTextProps {
  text: string | string[]

  speed?: number
  backSpeed?: number
  delay?: number
  variant?: TypographyVariant

  PrefixComponent?: JSX.Element
  Component?: FC<TypographyProps>
}

const DEFAULT_TYPING_SPEED = 50
const DEFAULT_TYPING_BACK_SPEED = 50
const DEFAULT_TYPING_PAUSE = 5000

const TypingText = (props: ITypingTextProps): JSX.Element => {
  const {
    text,
    variant,
    speed = DEFAULT_TYPING_SPEED,
    delay = DEFAULT_TYPING_PAUSE,
    backSpeed = DEFAULT_TYPING_BACK_SPEED,
    Component = Typography,
    PrefixComponent
  } = props

  const $ref = useRef<HTMLDivElement>(null)

  const renderData = useMemo<string[]>(() => {
    return Array.isArray(text) ? text : [text]
  }, [text])

  useEffect(() => {
    const typed = new Typed($ref.current, {
      strings: renderData,
      typeSpeed: speed,
      backSpeed: backSpeed,
      backDelay: delay,
      loop: true,
    })

    return () => {
      typed.destroy()
    }
  }, [$ref, renderData, speed, backSpeed, delay])

  return (
    <Component variant={ variant }>
      { PrefixComponent }

      <span ref={ $ref } />
    </Component>
  )
}

export { TypingText }
