import { CentrifugeService, STOCK_CONNECTION } from '@data/common/services'

import { DetectType } from '@domain/setting-profile/detect'

import type { IExecutedDetectDTO } from '@domain/stocks/interfaces'
import type { Stocks } from '@domain/common/enums'

import type { IExecutedDetectSocketResponse } from './interfaces'

class ExecutedDetectSocket {

  private _socket?: CentrifugeService<IExecutedDetectSocketResponse>

  public subscribe (stock: Stocks, id: string): void {
    this._socket = new CentrifugeService(`signal_${id}`, STOCK_CONNECTION[stock])
  }

  public unsubscribe (): void {
    this._socket?.close()
  }

  public onMessage (callback: (value: IExecutedDetectDTO) => void): void {
    this._socket?.onMessage((message) => {
      callback({
        id: message.strategy_id,
        type: message.type === 'pump' ? DetectType.PUMP : DetectType.DUMP,
        name: message.name,
        symbol: message.symbol,
        price: message.price,
        percent: message.percent,
        volume: message.volume,
        impulse: message.volume,
        timestamp: message.timestamp * 1000
      })
    })
  }

}

export { ExecutedDetectSocket }
