import type { Components } from '@mui/material/styles/components'
import type { Theme } from '@mui/material'

const SVG_ICON_STYLES: Components<Theme>['MuiSvgIcon'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      transition: theme.transitions.create(['color', 'fill'], { duration: theme.transitions.duration.standard })
    })
  }
}

export { SVG_ICON_STYLES }
