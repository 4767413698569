import { Stocks } from '@domain/common/enums'

import { CreateBinanceApiKeyUseCase } from '../../stocks'

import type { IApiKeyRepository, ICreateApiKeyUseCase } from '../../common'

interface IPayload {
  stock: Stocks
  repository: IApiKeyRepository
}

const initCreateApiKeyUseCase = (payload: IPayload): ICreateApiKeyUseCase => {
  switch (payload.stock) {
    case Stocks.BINANCE: return new CreateBinanceApiKeyUseCase(payload.repository)

    default: return new CreateBinanceApiKeyUseCase(payload.repository)
  }
}

export { initCreateApiKeyUseCase }
