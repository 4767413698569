import { useTheme } from '@mui/material'

import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'

import { useLocation } from 'react-router-dom'

import { useAppInstallHandlerContext } from '@app/components/tools/components/handlers/app-install-handler/index'
import { ButtonSecondary } from '@app/components/ui/theme/ui/buttons'
import { ReactComponent as DownloadIcon } from '@app/asset/icon/global/download.svg'
import { ReactComponent as LogoIcon } from '@app/asset/icon/global/favicon.svg'
import { useResizePlatform } from '@app/pages/trade/components/trade-window/use-resize-platform'
import {
  APP_INSTALL_HANDLER_STYLES as Component
} from '@app/components/tools/components/handlers/app-install-handler/_styled'

const AppInstall = (): JSX.Element => {
  const { pathname } = useLocation()
  const { palette } = useTheme()
  const { isInstall, showPrompt, closePrompt } = useAppInstallHandlerContext()
  const { t } = useTranslation()
  const { isMobile } = useResizePlatform()

  const handleOpenPrompt = (): void => {
    showPrompt()
  }

  const handleClosePrompt = (): void => {
    closePrompt()
  }

  return (
    <>
      { isInstall
        ? <div />
        : isMobile
          ? (
            <Component.Wrapper
              isMobile={ pathname.includes('/app/trade') }
            >
              <Component.Icon><LogoIcon /></Component.Icon>

              <Component.Title>{ t('pwa-install') }</Component.Title>

              <Component.ContainerBtn>
                <ButtonSecondary
                  sx={{ 'path': { stroke: `${palette.text.primary}!important` }}}
                  onClick={ handleOpenPrompt }
                >
                  <DownloadIcon />
                </ButtonSecondary>

                <CloseIcon onClick={ handleClosePrompt } />
              </Component.ContainerBtn>
            </Component.Wrapper>
          )
          : <div /> }
    </>
  )
}

export default AppInstall
