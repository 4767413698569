import { useQuery } from '@tanstack/react-query'

import { useEffect } from 'react'

import { useAuthContext } from '@app/common/auth'
import { useTradeContext } from '@app/pages/trade/trade-context'

import { initAccountRepository } from '@data/repository/stocks/account'
import { initCreateAccountUseCase } from '@domain/stocks/account'

import type { IAccountEntity } from '@domain/stocks/account'

import type { UseQueryResult } from '@tanstack/react-query'

const useAccountRequest = (): UseQueryResult<IAccountEntity> => {
  const { stock } = useAuthContext()
  const { setAccountEntity } = useTradeContext()

  const callback = async (): Promise<IAccountEntity> => {
    const repository = initAccountRepository({ stock })
    const useCase = initCreateAccountUseCase({ stock, repository })

    return useCase.execute()
      .then((response) => {
        setAccountEntity(response)

        return response
      })
  }

  const request = useQuery(['getAccount'], callback)

  useEffect(() => {
    void request.refetch()
  }, [stock])

  return request
}

export { useAccountRequest }
