import { CreatePairUseCase, GetSelectedPairUseCase, GetPairListUseCase } from '../../_'

import type { ICreatePairUseCase, IPairRepository } from '../../../common'

class CreateBinancePairUseCase extends CreatePairUseCase implements ICreatePairUseCase {

  constructor (repository: IPairRepository) {
    const getPairListUseCase = new GetPairListUseCase(repository)
    const getSelectedPairUseCase = new GetSelectedPairUseCase(repository)

    super(getPairListUseCase, getSelectedPairUseCase)
  }

}

export { CreateBinancePairUseCase }
