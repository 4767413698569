import { useEffect } from 'react'

import { useTradeContext } from '@app/pages/trade/trade-context'
import { EventList, useEventContext } from '@app/services/event'
import { useAuthContext } from '@app/common/auth'

import { DEFAULT_PAIR } from '@domain/stocks/pair'

import { useInternetConnectionContext } from '@app/components/tools/components/internet-connection'

import { MarketOverviewBySymbolController } from './controller'

const marketOverviewBySymbolController = new MarketOverviewBySymbolController()

const useMarketOverview = (): void => {
  const { PairEntity } = useTradeContext()
  const { stock } = useAuthContext()
  const { notify } = useEventContext()
  const { status } = useInternetConnectionContext()

  marketOverviewBySymbolController.onMessage(async (message) => {
    await notify({ name: EventList.UPDATE_MARKET_OVERVIEW_BY_SYMBOL, data: message })
  })

  useEffect(() => {
    marketOverviewBySymbolController.updateStock(stock)
    marketOverviewBySymbolController.updateExcludedPairs([DEFAULT_PAIR])

    if (status === 'online') {
      marketOverviewBySymbolController.subscribe(DEFAULT_PAIR)
      marketOverviewBySymbolController.subscribe(PairEntity.pair)
    }

    return () => {
      marketOverviewBySymbolController.unsubscribe(PairEntity.pair)
    }
  }, [PairEntity.pair, status, stock])

  useEffect(() => {
    return () => {
      marketOverviewBySymbolController.updateExcludedPairs([])
      marketOverviewBySymbolController.unsubscribe(DEFAULT_PAIR)
    }
  }, [])
}

export { useMarketOverview }
