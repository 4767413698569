import { ExceptionService } from '@domain/common/services'
import { InternalCode } from '@domain/common/enums'

import type {
  IAxisDto,
  IAxisErrors,
  IAxisRepository,
  IAxisValidation,
  IUpdateAxis
} from '@domain/setting-profile/axis/interface'

class UpdateAxisUseCase implements IUpdateAxis {

  private _repository: IAxisRepository

  private _validation: IAxisValidation

  constructor (repository: IAxisRepository, validation: IAxisValidation) {
    this._repository = repository
    this._validation = validation
  }

  public async execute (port: IAxisDto): Promise<IAxisDto> {
    this._throwErrors(port)
    return this._repository.updateTradeAxis(port)
  }

  private _throwErrors (port: IAxisDto): void {
    const errors = this._validatePort(port)
    if (Object.keys(errors).length) {
      throw ExceptionService.new({
        status: {
          code: InternalCode.VALIDATION_ERROR,
          message: 'UpdateAxisUseCase throw errors'
        },
        data: errors
      })
    }
  }

  private _validatePort (port: IAxisDto): IAxisErrors {
    const errors: IAxisErrors = {}
    const keys = Object.entries(port) as [keyof IAxisDto, string][]

    for (const [key, value] of keys) {
      const result = this._validation.validate()[key].run(value).errors?.code

      if (result !== undefined) errors[key] = result
    }

    return errors
  }

}

export { UpdateAxisUseCase }
