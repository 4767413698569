import { createContext, useContext, useEffect, useMemo, useState } from 'react'

import { AppInstallHandler } from '@app/components/tools/components/handlers/app-install-handler/entity'

import AppInstallJSX from './app-install'

import type { FC, PropsWithChildren } from 'react'

interface IAppInstallHandlerContext {
  isInstall: boolean
  showPrompt(): void
  closePrompt(): void
}

const DEFAULT_APP_INSTALL: IAppInstallHandlerContext = {
  isInstall: false,
  showPrompt: () => {
    //
  },
  closePrompt: () => {
    //
  }
}

const AppInstallHandlerContext = createContext<IAppInstallHandlerContext>(DEFAULT_APP_INSTALL)

const AppInstall = new AppInstallHandler()

const useAppInstallHandlerContext = (): IAppInstallHandlerContext => useContext(AppInstallHandlerContext)

const AppInstallHandlerProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isInstall, setIsInstall] = useState(true)

  const showPrompt = (): void => {
    void AppInstall.showPrompt()
  }

  const closePrompt = (): void => {
    AppInstall.registerCookie()
  }

  const context = useMemo<IAppInstallHandlerContext>(() => ({
    isInstall: isInstall,
    showPrompt,
    closePrompt
  }), [isInstall])

  useEffect(() => {
    setIsInstall(AppInstall.checkInstall(setIsInstall))
  }, [])

  return (
    <AppInstallHandlerContext.Provider value={ context }>

      { children }

      <AppInstallJSX />
    </AppInstallHandlerContext.Provider>
  )
}

export { useAppInstallHandlerContext, DEFAULT_APP_INSTALL }

export default AppInstallHandlerProvider
