import { Box, Typography, styled } from '@mui/material'

import { createGlowComponentStyles } from '../../glow-effect'

import type { IGlowComponentProps } from '../../glow-effect'

const CommonStyles = `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  border-radius: 12px;
  
  height: 290px;
  width: 100%;
`

const Element = styled(Box)`
    width: 100%;
    transition: transform 0.5s;
`

const ElementGlowContainer = styled(Box)<IGlowComponentProps>`
  width: 100%;
  height: 100%;

  ${({ coordinates }): string => coordinates.x === null || coordinates.y === null ? '' : createGlowComponentStyles({ x: coordinates.x, y: coordinates.y })}
`

const Container = styled(Box)`
    outline: none;    
    position: relative;
    
    height: 290px;
    overflow-y: hidden;
    
    background-color: ${({ theme }): string => theme.palette.bg.secondary};
    border-radius: 12px;
    
    &.is-hovered .element-inner {
      transform: translateY(-290px);
    }
`

const ElementFront = styled(Box)`
   ${CommonStyles}
`

const ElementBack = styled(Box)`
    ${CommonStyles}
    position: absolute;
`
const Icon = styled(Box)`
    svg {
      path {
        transition: ${({ theme }): string => theme.transitions.create(['fill'], { duration: theme.transitions.duration.standard })};
        fill: ${({ theme }): string => theme.palette.mode === 'light' ? '#181616' : '#EBEBEE'};
      }
    }
  `
const Title = styled(Typography)`
    font-size: 20px;
    font-weight: 700;
    max-width: 220px;
    text-align: center;
  `
const Text = styled(Typography)`
    padding: 0 20px;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
  `

export const Component = {
  Element,
  Container,
  ElementFront,
  ElementBack,
  ElementGlowContainer,
  Icon,
  Title,
  Text
}
