import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'

import { useQuery } from '@tanstack/react-query'

import { InternalCode } from '@domain/common/enums'
import { ERROR_MESSAGES } from '@app/services/i18n/_messages'

import { SpecificationRepository } from '@data/repository/setting-profile/specification'

import { GetSpecificationUseCase } from '@domain/setting-profile/specification'

import { ValidationSpecification } from '@domain/setting-profile/specification/use-case/update-specification/validation'

import type { Dispatch, SetStateAction } from 'react'

import type { ExceptionService } from '@domain/common/services'
import type { ISpecificationDTO } from '@domain/setting-profile/specification'

import type { UseQueryResult } from '@tanstack/react-query'

type IUseGetSpecificationResults = UseQueryResult<ISpecificationDTO, ExceptionService<never>>

const useGetSpecification = (setter: Dispatch<SetStateAction<ISpecificationDTO>>):
IUseGetSpecificationResults => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const repository = new SpecificationRepository()
  const validation = new ValidationSpecification()
  const useCase = new GetSpecificationUseCase(repository, validation)

  const callback = async (): Promise<ISpecificationDTO> => {
    return useCase.execute()
  }

  return useQuery(
    ['get specification'],
    callback,
    {
      onSuccess: (value) => {
        setter(value)
      },
      onError: (error) => {
        if (error.code === InternalCode.SERVER_ERROR) {
          enqueueSnackbar(t(ERROR_MESSAGES[InternalCode.SERVER_ERROR]), { variant: 'error' })
        }
      },
      enabled: false
    }
  )
}

export { useGetSpecification }
