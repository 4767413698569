import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import { Box, Stack, useMediaQuery, useTheme } from '@mui/material'

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import RouterPath from '@app/router/router-path'

import FirstLandingBgLight from '@app/asset/img/first-landing-bg-light.png'
import FirstLandingBgDark from '@app/asset/img/first-landing-bg-dark.png'

import { useAuthContext } from '@app/common/auth'
import { ReactComponent as Cursor } from '@app/asset/icon/landing/cursor.svg'

import { ButtonSecondary } from '@app/components/ui/theme/ui/buttons'
import { LicenseContainer } from '@app/components/tools/components/license'

import { updateMeta } from '@app/services/seo/update-meta'

import TransitionElements from '@app/components/tools/components/transition-elements'

import { DEFAULT_PAGE as Component } from './_style'
import { DEFAULT_PAGE_FEATURE, DEFAULT_PAGE_TRADE, QUESTIONS } from './_setting'

import { TypingText } from './components/typing-text'
import { ExchangesContainer } from './components/exchanges'
import { WhyCard } from './components/why-cards'
import { TVContainer } from './components/tv-container'

import type React from 'react'

/*
  eslint-disable
  react/forbid-component-props,
  react/no-array-index-key,
  @typescript-eslint/no-magic-numbers,
  react/no-multi-comp,
  react/no-unstable-nested-components
*/

/* eslint-disable */
const Default = (): JSX.Element => {
  const { t, i18n } = useTranslation()
  const { isAuth } = useAuthContext()

  const theme = useTheme()
  // const isDotsSliderOne = !useMediaQuery('(min-width:1350px)')

  const isTablet = !useMediaQuery('(min-width:1025px)')
  const nav = useNavigate()

  const [expanded, setExpanded] = useState<number | false>(false)

  const handleChange = (panel: number) => (_: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false)
  }

  const handleRedirect = (): void => {
    nav(
      isAuth
        ? RouterPath.AuthRoute.TRADE
        : RouterPath.GuestRoute.SIGN_UP_ROUTE
    )
  }

  const handleGo = (): void => {
    window.scrollTo({
      top: document.querySelector('#Second-Block')?.offsetTop! - 50,
      behavior: 'smooth'
    })
  }

  const handleTariffCardRedirect = (): void => {
    nav(
      isAuth
        ? RouterPath.ProfileRoute.LICENSE_INDEX
        : `${RouterPath.GuestRoute.SIGN_IN_ROUTE}?redirect=${RouterPath.ProfileRoute.LICENSE_INDEX}`
    )
  }

  useEffect(() => {
    updateMeta({ title: t('seo/title') })
  }, [i18n.language])

  return (
    <Component.Wrapper>
      <Component.FirstBlock.Wrapper>
          <Component.FirstBlock.Banner>
            <TransitionElements
              sx={{ height: '100%' }}
              light={ (
                <Component.FirstBlock.Image
                  src={ FirstLandingBgLight }
                  alt="landing-bg"
                />
              ) }
              dark={ (
                <Component.FirstBlock.Image
                  src={ FirstLandingBgDark }
                  alt="landing-bg"
                />
              ) }
            />
          </Component.FirstBlock.Banner>

        <TypingText
          Component={Component.FirstBlock.Title}
          PrefixComponent={(
            <Component.TitleBlockColor>
              { t('default-page/first-block-prefix') }
            </Component.TitleBlockColor>
          )}
          variant='h1'
          text={[
            t('default-page/first-block-title-1'),
            t('default-page/first-block-title-2'),
          ]}
        />

        <Component.FirstBlock.SubTitle>
          { t('default-page/sub-title') }
        </Component.FirstBlock.SubTitle>

        <Component.FirstBlock.Action>
          <Component.FirstBlock.Button onClick={ handleRedirect } size="large">
            { t('default-page/start-button') }
          </Component.FirstBlock.Button>

          <ButtonSecondary size="large" onClick={ handleGo }>
            { t('default-page/more-button') }
          </ButtonSecondary>
        </Component.FirstBlock.Action>
      </Component.FirstBlock.Wrapper>

      <Component.SecondBlock.Wrapper id="Second-Block">
        <Component.TitleBlock variant="h2">
          { t('landing/key-features-1') }

          <Component.TitleBlockColor>
            { t('landing/key-features-2') }
          </Component.TitleBlockColor>

          { t('landing/key-features-3') }
        </Component.TitleBlock>

        <Component.SecondBlock.SubTitle.Wrapper>
          <Cursor fill={theme.palette.text.primary} />

          <Component.SecondBlock.SubTitle.Title>
            {!isTablet ? t('landing/key-features-subtitle') : t('landing/key-features-subtitle-mobile')}
          </Component.SecondBlock.SubTitle.Title>
        </Component.SecondBlock.SubTitle.Wrapper>

        <Component.SecondBlock.Questions.Wrapper>
          { DEFAULT_PAGE_FEATURE.map((item) => <WhyCard key={ item.title } { ...item } />) }
        </Component.SecondBlock.Questions.Wrapper>

        <ExchangesContainer />
      </Component.SecondBlock.Wrapper>

      <Component.FifthBlock.Wrapper id="Fifth-Block">
        <Component.FifthBlock.Title>
          <Box sx={{ display: 'flex', gridGap: '25px' }}>
            <Component.TitleBlock>
              { t('navigation-menu/tariffs-1') }

              <Component.TitleBlockColor>{ t('navigation-menu/tariffs-2') }</Component.TitleBlockColor>

              { t('navigation-menu/tariffs-3') }
            </Component.TitleBlock>
          </Box>
        </Component.FifthBlock.Title>

        <LicenseContainer onCardClick={ handleTariffCardRedirect } />

        <TVContainer />
      </Component.FifthBlock.Wrapper>

      <Component.FourthBlock.Wrapper>
        <Component.TitleBlock variant="h2">
          { t('landing/take-terminal-is-easy') }
        </Component.TitleBlock>

        <Component.FourthBlock.Box.Wrapper>
          <Component.FourthBlock.Box.Step.Wrapper>
            { DEFAULT_PAGE_TRADE.map((item, _key) => (
              <Component.FourthBlock.Box.Step.Item.Wrapper key={ item.title }>
                <Component.FourthBlock.Box.Step.Item.Icon>
                  { _key + 1 }
                </Component.FourthBlock.Box.Step.Item.Icon>

                <Stack spacing={1.5}>
                  <Component.FourthBlock.Box.Step.Item.Title variant="h3">
                    { t(item.title) }
                  </Component.FourthBlock.Box.Step.Item.Title>

                  <Component.FourthBlock.Box.Step.Item.Text>
                    { t(item.text) }
                  </Component.FourthBlock.Box.Step.Item.Text>
                </Stack>
              </Component.FourthBlock.Box.Step.Item.Wrapper>
            )) }
          </Component.FourthBlock.Box.Step.Wrapper>
        </Component.FourthBlock.Box.Wrapper>
      </Component.FourthBlock.Wrapper>

      <Component.SixthBlock.Wrapper>
        <Component.TitleBlock sx={{ textAlign: 'left' }}>
          <Component.TitleBlockColor>
            { t('landing/questions-title-1') }
          </Component.TitleBlockColor>

          { t('landing/questions-title-2') }
        </Component.TitleBlock>

        <Component.SecondBlock.SubTitle.Wrapper sx={{ justifyContent: 'flex-start' }}>
          <Component.SecondBlock.SubTitle.Title>
            { t('landing/questions-sub-title') }
          </Component.SecondBlock.SubTitle.Title>
        </Component.SecondBlock.SubTitle.Wrapper>

        <Component.SixthBlock.Questions.Wrapper>
          { QUESTIONS.map((item, index) => (
            <Component.SixthBlock.Questions.Accordion
              key={ item.title }
              expanded={ expanded === index }
              onChange={ handleChange(index) }
            >
              <Component.SixthBlock.Questions.AccordionSummary
                isActive={expanded === index}
                expandIcon={ <ArrowForwardIosIcon /> }
              >
                <Component.SixthBlock.Questions.Title>
                  { t(item.title) }
                </Component.SixthBlock.Questions.Title>
              </Component.SixthBlock.Questions.AccordionSummary>

              <Component.SixthBlock.Questions.AccordionDetails>
                <Component.SixthBlock.Questions.Text>
                  { t(item.content) }
                </Component.SixthBlock.Questions.Text>
              </Component.SixthBlock.Questions.AccordionDetails>
            </Component.SixthBlock.Questions.Accordion>
          )) }
        </Component.SixthBlock.Questions.Wrapper>
      </Component.SixthBlock.Wrapper>
    </Component.Wrapper>
  )
}

export default Default
