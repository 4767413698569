import {
  RequiredValidator,
  RangeValidation,
  ValidationChain,
  RegexValidator,
  LengthValidator
} from '@domain/common/utils/validators'

import type { IDetectValidation, ICreateFieldValidation } from '../../interface'

class CreateDetectValidation implements IDetectValidation<ICreateFieldValidation> {

  public validate (): ICreateFieldValidation {
    return {
      name: new ValidationChain([
        new RequiredValidator(),
        new LengthValidator({ min: 1, max: 32 }),
        new RegexValidator(/[а-яёА-ЯЁa-zA-Z0-9\s-]/g, true)
      ]),
      type: new ValidationChain([new RequiredValidator()]),
      isActive: new ValidationChain([new RequiredValidator()]),
      isEnableSound: new ValidationChain([new RequiredValidator()]),
      pairList: new ValidationChain([new RequiredValidator()]),
      alertTime: new ValidationChain([
        new RequiredValidator(),
        new RangeValidation({ min: 1, max: 60 })
      ]),
      minVolumePerHour: new ValidationChain([
        new RequiredValidator(),
        new RangeValidation({ min: 0 })
      ]),
      maxVolumePerHour: new ValidationChain([
        new RangeValidation({ min: 0 })
      ]),
      minVolumePerDay: new ValidationChain([
        new RequiredValidator(),
        new RangeValidation({ min: 0 })
      ]),
      maxVolumePerDay: new ValidationChain([
        new RangeValidation({ min: 0 })
      ]),
      priceInterval: new ValidationChain([
        new RequiredValidator(),
        new RangeValidation({ min: 0, max: 60 })
      ]),
      priceChange: new ValidationChain([new RequiredValidator()]),
    }
  }

}

export { CreateDetectValidation }
