import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'

import { useQuery } from '@tanstack/react-query'

import { InternalCode } from '@domain/common/enums'
import { ERROR_MESSAGES } from '@app/services/i18n/_messages'
import { AxisRepository } from '@data/repository/setting-profile/axis'

import { GetAxisUseCase } from '@domain/setting-profile/axis'

import { AxisValidation } from '@domain/setting-profile/axis/use-case/update-axis-use-case/validation'

import type { ExceptionService } from '@domain/common/services'

import type { IAxisDto } from '@domain/setting-profile/axis'
import type { Dispatch, SetStateAction } from 'react'
import type { UseQueryResult } from '@tanstack/react-query'

type IUseGetAxisResults = UseQueryResult<IAxisDto, ExceptionService<never>>
type SetterAxis = Dispatch<SetStateAction<IAxisDto>>
type SetterLoaded = Dispatch<SetStateAction<boolean>>

const useGetAxis = (setterAxis: SetterAxis, setterLoaded: SetterLoaded): IUseGetAxisResults => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const repository = new AxisRepository()
  const validation = new AxisValidation()
  const useCase = new GetAxisUseCase(repository, validation)

  const callback = async (): Promise<IAxisDto> => {
    return useCase.execute()
  }

  return useQuery(
    ['get axis'],
    callback,
    {
      onSuccess: (value) => {
        setterAxis(value)
        setterLoaded(true)
      },
      onError: (error) => {
        if (error.code === InternalCode.SERVER_ERROR) {
          enqueueSnackbar(t(ERROR_MESSAGES[InternalCode.SERVER_ERROR]), { variant: 'error' })
        }
      },
      enabled: false
    }
  )
}

export { useGetAxis }
