import type { ISocketService } from '@data/common/interfaces'

import type { Centrifuge, Subscription, SubscribedContext, UnsubscribedContext, Error } from 'centrifuge'

class CentrifugeService<Message> implements ISocketService<SubscribedContext, UnsubscribedContext, Message, Error> {

  private readonly _subscription: Subscription

  private readonly _centrifuge: Centrifuge

  constructor (channel: string, centrifuge: Centrifuge) {
    this._centrifuge = centrifuge
    this._subscription = this._centrifuge.newSubscription(channel)
    this._subscription.subscribe()
  }

  public send (data: string | ArrayBufferLike | Blob | ArrayBufferView): void {
    void this._subscription.publish(data)
  }

  public close (): void {
    this._subscription.unsubscribe()
    this._centrifuge.removeSubscription(this._subscription)
  }

  public onConnect (callback: (message: SubscribedContext) => void): void {
    this._subscription.on('subscribed', (data) => {
      callback(data)
    })
  }

  public onClose (callback: (message: UnsubscribedContext) => void): void {
    this._subscription.on('unsubscribed', (data) => {
      callback(data)
    })
  }

  public onMessage (callback: (message: Message) => void): void {
    this._subscription.on('publication', (data) => {
      callback(data.data as Message)
    })
  }

  public onError (callback: (message: Error) => void): void {
    this._subscription.on('error', (data) => {
      callback(data.error)
    })
  }

}

export { CentrifugeService }
