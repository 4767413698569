import { Stocks } from '@domain/common/enums'

import { BinancePairRepository } from '../stocks'

import type { IPairRepository } from '@domain/stocks/pair'

interface IPayload {
  stock: Stocks
}

const initPairRepository = (payload: IPayload): IPairRepository => {
  switch (payload.stock) {
    case Stocks.BINANCE: return new BinancePairRepository('binance-provider-trade')

    default: return new BinancePairRepository('binance-provider-trade')
  }
}

export { initPairRepository }
