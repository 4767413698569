import { SpecificationOrderType } from '@domain/setting-profile/specification/enum'
import { OrderType } from '@domain/stocks/order'

interface ITransformOrderType {
  from (orderType: SpecificationOrderType): OrderType
  to (orderType: OrderType): SpecificationOrderType
}

const transformOrderType = (): ITransformOrderType => {
  return {
    from (orderType: SpecificationOrderType): OrderType {
      switch (orderType) {
        case SpecificationOrderType.LIMIT: return OrderType.LIMIT
        case SpecificationOrderType.MARKET: return OrderType.MARKET
        case SpecificationOrderType.POST_ONLY: return OrderType.POST_ONLY
        case SpecificationOrderType.STOP_LIMIT: return OrderType.STOP_LIMIT
        case SpecificationOrderType.STOP_MARKET: return OrderType.STOP_MARKET
        default: return OrderType.LIMIT
      }
    },
    to (orderType: OrderType): SpecificationOrderType {
      switch (orderType) {
        case OrderType.LIMIT: return SpecificationOrderType.LIMIT
        case OrderType.MARKET: return SpecificationOrderType.MARKET
        case OrderType.POST_ONLY: return SpecificationOrderType.POST_ONLY
        case OrderType.STOP_LIMIT: return SpecificationOrderType.STOP_LIMIT
        case OrderType.STOP_MARKET: return SpecificationOrderType.STOP_MARKET
        default: return SpecificationOrderType.LIMIT
      }
    }
  }
}

export { transformOrderType }
