import { Button, styled } from '@mui/material'

import { LinkBehavior } from './links'

import type { Theme } from '@mui/material'

import type { Components } from '@mui/material/styles/components'

const BUTTON_DEFAULT_STYLES: Components<Theme>['MuiButton'] = {
  defaultProps: {
    LinkComponent: LinkBehavior
  },
  styleOverrides: {
    root: ({ theme }) => ({
      background: theme.palette.button.primary.default,
      fontWeight: 500,
      lineHeight: '15px',
      borderRadius: 5,
      color: theme.palette.button.primary.color,
      textTransform: 'none',
      transition: theme.transitions.create(['background', 'color'], { duration: theme.transitions.duration.standard }),
      '.MuiSvgIcon-root': {
        transition: 'none'
      },
      ':hover': {
        background: theme.palette.button.primary.hover,
        boxShadow: `0px 0px 6px ${theme.palette.button.primary.default}`
      },
      ':active': {
        background: theme.palette.button.primary.active,
      },
      ':disabled': {
        color: theme.palette.button.primary.color,
        background: theme.palette.button.primary.disabled,
        cursor: 'not-allowed',
        pointerEvents: 'auto',
        boxShadow: 'none'
      },
      ':focus': {
        background: theme.palette.button.primary.default,
        boxShadow: 'none'
      }
    }),
    sizeSmall: {
      padding: '3px 20px',
    },
    sizeMedium: {
      padding: '6px 20px',

      '@media (max-width: 1200px)': {
        padding: '6px 10px',
      }
    },
    sizeLarge: {
      padding: '13px 20px',
    }
  }
}

const ICON_BUTTON_STYLES: Components<Theme>['MuiIconButton'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      transition: theme.transitions.create(['color'], { duration: theme.transitions.duration.standard })
    })
  }
}

const ICON_BUTTON_BASE_STYLES: Components<Theme>['MuiButtonBase'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      transition: theme.transitions
        .create(['color', 'background-color'], { duration: theme.transitions.duration.standard })
    })
  }
}

const ButtonWarning = styled(Button)`
  background: ${({ theme }): string => theme.palette.button.orange.default};
  color: ${({ theme }): string => theme.palette.button.orange.color};
  
  :hover {
    background: ${({ theme }): string => theme.palette.button.orange.hover};
    box-shadow: 0 0 6px ${({ theme }): string => theme.palette.button.orange.hover};
  } 
  
  :active {
    background: ${({ theme }): string => theme.palette.button.orange.active};
  }
  
  :disabled {
    background: ${({ theme }): string => theme.palette.button.orange.disabled};
    box-shadow: none;
  }
  
  :focus {
    background: ${({ theme }): string => theme.palette.button.orange.default};
    box-shadow: none;
  }
`

const ButtonSecondary = styled(Button)`
  background: ${({ theme }): string => theme.palette.button.secondary.default};
  color: ${({ theme }): string => theme.palette.button.secondary.color};
    
  :hover {
    background: ${({ theme }): string => theme.palette.button.secondary.default};
    box-shadow: 0 0 6px ${({ theme }): string => theme.palette.button.secondary.hover};
  } 
  
  :active {
    background: ${({ theme }): string => theme.palette.button.secondary.active};
  }
  
  :disabled {
    background: ${({ theme }): string => theme.palette.button.secondary.disabled};
    box-shadow: none;
  }
  
  :focus {
    background: ${({ theme }): string => theme.palette.button.secondary.default};
    box-shadow: none;
  }
`

export { BUTTON_DEFAULT_STYLES, ICON_BUTTON_STYLES, ICON_BUTTON_BASE_STYLES, ButtonWarning, ButtonSecondary }
