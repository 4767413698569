import { SpecificationToolType } from '@domain/setting-profile/specification/enum'

import { OrderType } from '@domain/stocks/order'

import type { ISpecificationDTO } from '@domain/setting-profile/specification'

/* eslint-disable @typescript-eslint/no-magic-numbers */
const INIT_STATE_SPECIFICATION: ISpecificationDTO = {
  countPartsDealDivision: 3,
  distanceDealDivision: 0.5,
  activeZoneDealDivision: 20,
  stopPricePercent: 0,
  toolType: SpecificationToolType.BUTTONS,
  orderType: OrderType.LIMIT,
  selectedOrderSizeButton: 15,
  orderSizeButtonsCount: 4,
  orderSizeButtons: [15, 25, 50, 125],
  selectedOrderSizePercentButton: 25,
  orderSizePercentButtonsCount: 4,
  orderSizePercentButtons: [25, 50, 75, 100],
  isIncludeIceberg: false,
  isIncludeMultiOrders: false
}

export { INIT_STATE_SPECIFICATION }
