import { ExceptionService } from '@domain/common/services'
import { InternalCode } from '@domain/common/enums'

import { CreateOrderUseCase } from '../../../_'
import { CREATE_BINANCE_ORDER_VALIDATOR } from '../../validators'

import type { ICreateOrderUseCase, ICreateOrderPort, IOrderRepository } from '../../../../common'

class CreateBinanceOrderUseCase extends CreateOrderUseCase implements ICreateOrderUseCase {

  constructor (repository: IOrderRepository) {
    super(repository, CREATE_BINANCE_ORDER_VALIDATOR)
  }

  public override async execute (port: ICreateOrderPort): Promise<boolean> {
    const errors = this.validatePort(port)

    if (Object.keys(errors).length) {
      throw ExceptionService.new({
        status: {
          code: InternalCode.VALIDATION_ERROR,
          message: `${this.constructor.name} validation port error`
        },
        data: errors
      })
    }

    return this.repository.createOrder(port)
  }

}

export { CreateBinanceOrderUseCase }
