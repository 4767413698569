import { UpdateUserPairListUseCase } from '../update-list-use-case'

import type { IUpdateUserCustomPairListPort, IUserPairListEntity } from '../../interfaces'

class DeleteUserListPairUseCase extends UpdateUserPairListUseCase {

  public override async execute (port: IUpdateUserCustomPairListPort): Promise<IUserPairListEntity> {
    await this.repository.deletePair({ id: port.id, symbol: port.symbol })
    await this.entity.deletePairFromList(port.id, port.symbol)

    return super.execute(port)
  }

}

export { DeleteUserListPairUseCase }
