import { Stocks } from '@domain/common/enums'

import { BinanceBalanceSocket } from '../stocks'

import type { IBalanceSocket } from '../interfaces'

interface IPayload {
  stock: Stocks
}

const initBalanceSocket = (payload: IPayload): IBalanceSocket => {
  switch (payload.stock) {
    case Stocks.BINANCE: return new BinanceBalanceSocket(payload.stock)

    default: return new BinanceBalanceSocket(payload.stock)
  }
}

export { initBalanceSocket }
