import { CssBaseline } from '@mui/material'
import { I18nextProvider } from 'react-i18next'
import { QueryClientProvider } from '@tanstack/react-query'

import React from 'react'

import whyDidYouRender from '@welldone-software/why-did-you-render'

import ROUTES from '@app/router'

import ThemeMode from '@app/components/tools/components/theme-mode'
import FullscreenProvider from '@app/common/fullscreen-provider'

import { RouterProvider } from '@app/common/router'
import { AuthProvider } from '@app/common/auth'
import { EventProvider } from '@app/services/event'
import { TradeProvider } from '@app/pages/trade/trade-context'
import { SettingsProvider } from '@app/common/settings'
import { NotificationProvider } from '@app/common/notifications'
import { LicenseProvider } from '@app/common/license'
import { InternetConnectionProvider } from '@app/components/tools/components/internet-connection'

import { i18next } from '@app/services/i18n'

import useAuthConfirm from '@app/auth/hook'

import { useWakeLock, useConsoleWarning } from '@app/services/utils'
import { useSEO } from '@app/services/seo/metricks/metricks-inject'

import { QUERY_CLIENT } from '@app/query-client'
import { UserPairList } from '@app/pages/trade/trade-controller/utils'

if (process.env.REACT_APP_STAGE === 'QWE') {
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  })
}

const App = (): JSX.Element => {
  useConsoleWarning()
  useWakeLock()
  useSEO()

  return (
    <I18nextProvider i18n={ i18next }>
      <ThemeMode>
        <QueryClientProvider client={ QUERY_CLIENT }>
          <CssBaseline />

          <EventProvider>
            <AuthProvider useAuthConfirm={ useAuthConfirm }>
              <TradeProvider>
                <SettingsProvider>
                  <FullscreenProvider>
                    <InternetConnectionProvider>
                      <NotificationProvider>
                        <LicenseProvider>
                          <UserPairList />

                          <RouterProvider routes={ ROUTES } />
                        </LicenseProvider>
                      </NotificationProvider>
                    </InternetConnectionProvider>
                  </FullscreenProvider>
                </SettingsProvider>
              </TradeProvider>
            </AuthProvider>
          </EventProvider>
        </QueryClientProvider>
      </ThemeMode>
    </I18nextProvider>
  )
}

export default App
