import { useQuery } from '@tanstack/react-query'

import { GetCookiesAcceptUseCase } from '@domain/cookies-accept'
import { CookiesAcceptRepository } from '@data/repository/cookies-accept'

import type { UseQueryResult } from '@tanstack/react-query'

import type { ExceptionService } from '@domain/common/services'

type IUseGetCookiesAcceptResults = UseQueryResult<boolean | undefined, ExceptionService<never>>

const useGetCookiesAccept = (): IUseGetCookiesAcceptResults => {
  const repository = new CookiesAcceptRepository()
  const useCase = new GetCookiesAcceptUseCase(repository)

  const callback = async (): Promise<boolean | undefined> => {
    return useCase.execute()
  }

  return useQuery(
    ['get cookies accept'],
    callback
  )
}

export { useGetCookiesAccept }
