import { GetTradeHistoryListUseCase } from '../../stocks'

import type { ITradeHistoryRepository, IGetTradeHistoryListUseCase } from '../../common'

import type { Stocks } from '@domain/common/enums'

interface IPayload {
  stock: Stocks
  repository: ITradeHistoryRepository
}

const initGetTradeHistoryUseCase = (payload: IPayload): IGetTradeHistoryListUseCase => {
  return new GetTradeHistoryListUseCase(payload.repository)
}

export { initGetTradeHistoryUseCase }
