import { HttpServiceV1 } from '@data/common/services'

import { ExceptionService } from '@domain/common/services'
import { InternalCode } from '@domain/common/enums'
import { transformOrderType } from '@domain/setting-profile/specification/utils/transform-order-type'

import type { IOrderSpecificationResponse, ISpecificationResponseErrors, ISpecificationServerError } from './interface'

import type { IHttpError } from '@data/common/interfaces'

import type {
  ISpecificationDTO,
  ISpecificationErrors,
  ISpecificationRepository,
  IUpdateSpecificationPayload
} from '@domain/setting-profile/specification/interface'

class SpecificationRepository implements ISpecificationRepository {

  public async getSpecification (): Promise<ISpecificationDTO> {
    return HttpServiceV1.get<IOrderSpecificationResponse>('settings/order')
      .then((response) => {
        return {
          countPartsDealDivision: response.numberOfParts,
          distanceDealDivision: response.distance,
          activeZoneDealDivision: response.coreWidth,
          stopPricePercent: response.stopPricePercent,
          toolType: response.toolType,
          orderType: transformOrderType().from(response.orderType),
          selectedOrderSizeButton: response.lastOrderSize,
          orderSizeButtonsCount: response.buttonCount,
          orderSizeButtons: response.buttons,
          selectedOrderSizePercentButton: response.lastPercentOrderSize,
          orderSizePercentButtonsCount: response.percentButtonCount,
          orderSizePercentButtons: response.percentButtons,
          isIncludeIceberg: response.iceberg,
          isIncludeMultiOrders: response.multiOrdersInclude
        }
      })
      .catch(() => {
        throw ExceptionService.new({
          status: {
            code: InternalCode.SERVER_ERROR,
            message: 'Server return bad request'
          }
        })
      })
  }

  public async updateSpecification (payload: IUpdateSpecificationPayload): Promise<boolean> {
    return HttpServiceV1.put('settings/order', { body: { ...payload, asset: 'USDT' }})
      .then(() => true)
      .catch((error: IHttpError<ISpecificationResponseErrors | ISpecificationServerError>) => {
        throw this._throwError(error)
      })
  }

  // eslint-disable-next-line max-len
  private _throwError (error: IHttpError<ISpecificationResponseErrors | ISpecificationServerError>): ExceptionService<ISpecificationErrors> {
    const code = error.errors === 'server_error' ? InternalCode.SERVER_ERROR : InternalCode.VALIDATION_ERROR
    const message = 'Backand return errors'

    return ExceptionService.new({
      status: {
        code,
        message
      },
      data: {
        logical: error.errors === 'server_error'
          ? InternalCode.SERVER_ERROR
          : +error.errors.code
      }
    })
  }

}

export { SpecificationRepository }
