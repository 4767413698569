import { Box, FormControl, FormHelperText, InputLabel, Typography } from '@mui/material'
import { Controller } from 'react-hook-form'

import { useTranslation } from 'react-i18next'

import { INPUT_SELECT_STYLES as Component } from '@app/components/ui/inputs/select-input/_styles'

import { messageErrorTranslate } from '@app/services/i18n'

import type { SelectChangeEvent } from '@mui/material/Select/SelectInput'

import type { FormControlTypeMap } from '@mui/material/FormControl/FormControl'

import type { FormControlProps as IFormControlProps, CSSObject } from '@mui/material'

import type { SelectProps } from '@mui/material/Select/Select'

import type { IBaseControlledInput } from '@app/components/ui/inputs/types/base-controlled-input'

type OmittedFormControlProps = Omit<IFormControlProps, 'fullWidth' | 'error'>

const defaultFormControlProps: OmittedFormControlProps = {
  margin: 'dense'
}

const ellipseStyles = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  transition: 'none'
}

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
const SelectInput = <TControl, >({
  control,
  items,
  label,
  name,
  FormControlProps = {},
  ...props
}: ISelectInputProps<TControl> & Omit<SelectProps, 'label' | 'onChange'>): JSX.Element => {
  const { t } = useTranslation()

  const findLabelValue = (value: string): Partial<ISelectInputItem> => {
    const find = items.find((tt) => tt.id === value)
    if (find === undefined) return { label: `${value}.0 %`, id: '-' }
    return find
  }

  // eslint-disable-next-line react/no-multi-comp,react/no-unstable-nested-components
  const _htmlValue = (item: ISelectInputItem): JSX.Element => {
    return (
      <>
        { item.icon === undefined
          ? props.isTranslate ? t(item.label) : item.label
          : item.endIcon !== undefined && item.endIcon
            ? (
              <>
                <Typography sx={ ellipseStyles }>{ props.isTranslate ? t(item.label) : item.label }</Typography>

                { item.icon }
              </>
            )
            : (
              <>
                { item.icon }

                <Typography sx={ ellipseStyles }>{ props.isTranslate ? t(item.label) : item.label }</Typography>

              </>
            ) }
      </>
    )
  }

  const handleChange = (onChange: (value: unknown) => void) => (event: SelectChangeEvent<unknown>) => {
    if (props.onChangeItem) props.onChangeItem(event.target.value)
    onChange(event.target.value)
  }

  return (
    <Controller
      control={ control }
      name={ name }
      render={ ({ field: { value = [], onChange: handleOnChange }, fieldState: { error }}): JSX.Element => {
        const isError = error?.message !== undefined
        const errorObject = isError ? JSON.parse(String(error.message)) as Record<string, string> : {}

        return (
          <FormControl
            disabled={ props.disabled }
            fullWidth={ true }
            error={ error !== undefined }
            { ...{ ...defaultFormControlProps, ...FormControlProps } }
            sx={ props.sxForm }
          >
            <InputLabel>{ label }</InputLabel>

            <Component.Select
              disabled={ props.disabled }
              value={ value as unknown }
              label={ label }
              onChange={ handleChange(handleOnChange) }
              { ...props }
              renderValue={ (): JSX.Element => (
                <>
                  { _htmlValue(findLabelValue(value as string) as ISelectInputItem) }
                </>
              ) }
            >
              { items.length
                ? items.map((item) => (
                  <Component.Item
                    key={ item.id }
                    value={ item.id }
                    sx={ props.sxItem }
                  >
                    { _htmlValue(item) }
                  </Component.Item>
                ))
                : <Box sx={{ paddingX: 2 }}><Typography>{ t('common/not-found') }</Typography></Box> }
            </Component.Select>

            <FormHelperText>
              { isError
                ? t(errorObject.message, messageErrorTranslate(errorObject))
                : '' }
            </FormHelperText>
          </FormControl>
        )
      } }
    />

  )
}

interface ISelectInputItem {
  id: string | number
  label: string
  icon?: JSX.Element
  endIcon?: boolean
}

interface ISelectInputProps<TControl> extends IBaseControlledInput<TControl> {
  label: SelectProps['label']
  items: ISelectInputItem[]
  FormControlProps?: OmittedFormControlProps
  isTranslate: boolean
  variant?: FormControlTypeMap['props']['variant']
  sxItem?: CSSObject
  sxForm?: CSSObject
  onChangeItem?(value: unknown): void
}

export const makeSelectInputAttrs = <TControl,>(
  items: ISelectInputProps<TControl>['items'],
  label?: ISelectInputProps<TControl>['label'],
  isTranslate = false,
  variant: ISelectInputProps<TControl>['variant'] = 'outlined'
  // eslint-disable-next-line max-params
): Pick<ISelectInputProps<TControl>, 'items' | 'label' | 'isTranslate' | 'variant'> & Partial<SelectProps> => ({
  items,
  label,
  isTranslate,
  fullWidth: true,
  margin: 'dense',
  variant
})

export type { ISelectInputProps, ISelectInputItem }

export default SelectInput
