import { LocalStorageService } from '@data/common/services'
import { LocalStorageValues } from '@data/common/enums'

import type { IRepositoryCookiesAccept } from '@domain/cookies-accept'

class CookiesAcceptRepository implements IRepositoryCookiesAccept {

  public async getCookiesAccept (): Promise<boolean> {
    try {
      return await LocalStorageService.get(LocalStorageValues.COOKIES_ACCEPT)
    } catch (e: unknown) {
      return Promise.resolve(false)
    }
  }

  public async setCookiesAccept (): Promise<void> {
    LocalStorageService.set(LocalStorageValues.COOKIES_ACCEPT, true)

    return Promise.resolve()
  }

}

export { CookiesAcceptRepository }
