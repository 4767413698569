import type { PaletteOptions } from '@mui/material/styles/createPalette'

const DARK_PALETTE: PaletteOptions = {
  mode: 'dark',
  primary: {
    main: '#66CC33'
  },
  text: {
    primary: '#EBEBEE',
    secondary: '#AAAABB'
  },
  error: {
    main: '#F03A27'
  },
  success: {
    main: '#66CC33'
  },
  warning: {
    main: '#FF952D'
  },
  bg: {
    default: '#031424',
    secondary: '#101B2F',
    accent: '#30415D'
  },
  button: {
    primary: {
      default: '#66CC33',
      hover: '#66CC33',
      active: '#4A872B',
      disabled: '#336230',
      color: '#031424',
    },
    secondary: {
      default: '#30415D',
      hover: '#30415D',
      active: '#AAAABB',
      disabled: '#202e46',
      color: '#EBEBEE',
    },
    blue: {
      default: '#379BE7',
      hover: '#379BE7',
      active: '#377EB5',
      disabled: '#B1D5F0',
      color: '#031424',
    },
    orange: {
      default: '#FF952D',
      hover: '#FF952D',
      active: '#CA7A2D',
      disabled: '#704B2E',
      color: '#031424',
    },
    success: {
      default: '#66CC33',
      hover: '#66CC33',
      active: '#4A872B',
      disabled: '#9DE877',
      color: '#031424',
    },
    error: {
      default: '#F03A27',
      hover: '#F03A27',
      active: '#921515',
      disabled: '#F3AEA6',
      color: '#031424'
    }
  },
  favoriteStar: {
    background: '#101B2F',
    icon: '#EBEBEE',
    active: '#66CC33'
  },
  side: {
    buy: '#66CC33',
    sell: '#F03A27',
    color: '#031424'
  },
  chart: {
    buy: '#66CC33',
    sell: '#F03A27',
    buyShadow: 'rgba(102, 204, 51, 0.5)',
    sellShadow: 'rgba(255, 62, 44, 0.5)',
    priceLine: '#379BE7',
    takeProfitLine: '#379BE7',
    stopLossLine: '#FFF100',
    canceledLine: '#4d4d4d',
    gridLine: '#30415D',
    cursorLine: '#AAAABB',
    paintCursorLine: '#2962ff',
    volumeAxis: {
      color: '#AAAABB',
      tag: {
        color: '#AAAABB',
        border: '#AAAABB',
        background: '#031424'
      }
    },
    axis: {
      color: '#AAAABB',
      tag: {
        color: '#EBEBEE',
        border: '#AAAABB',
        background: '#AAAABB'
      }
    },
    historyLoader: {
      backgroundColor: '#EBEBEE',
      boxShadow: 'rgba(250,250,250,0.1)'
    },
    tradingView: {
      orderLine: {
        buyTagText: '#031424',
        sellTagText: '#ffffff',
        takeProfitTagText: '#ffffff',
        stopLossTagText: '#031424'
      },
      baseLine: {
        buyFillLine: { top: 'rgba(102, 204, 51, 0.24)', bottom: 'rgba(102, 204, 51, 0.05)' },
        sellFillLine: { top: 'rgba(240, 58, 39, 0.24)', bottom: 'rgba(240, 58, 39, 0.05)' },
      }
    }
  }
}

export { DARK_PALETTE }
