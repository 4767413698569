import type { Components } from '@mui/material/styles/components'
import type { Theme } from '@mui/material'

const TABLE_PAGINATION_STYLED: Components<Theme>['MuiTablePagination'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      transition: theme.transitions.create(['color', 'border'], { duration: theme.transitions.duration.standard }),
      '.MuiSvgIcon-root': {
        transition: 'none'
      },
      '.MuiInputBase-root': {
        '.MuiSvgIcon-root': {
          transition: theme.transitions.create(['color', 'fill'], { duration: theme.transitions.duration.standard })
        },
        '.MuiSelect-select': {
          transition: 'none'
        }
      }
    })
  }
}

export { TABLE_PAGINATION_STYLED }
