import { IsBoolean, IsEnum, IsString, ValidateNested } from 'class-validator'

import { Entity } from '@domain/common/entity'

import { LanguageResources } from '@app/services/i18n/language'

import type { IUserLicenseDTO } from '@domain/license'
import type { IApiKeyDTO } from '@domain/stocks/api-key'
import type { ICreateUserPayload, IEditUserPayload, IUserEntity } from '@domain/user/interfaces'

class User extends Entity<string> implements IUserEntity {

  @IsString()
  public firstName: string

  @IsString()
  public lastName: string

  @IsString()
  public middleName: string

  @IsString()
  public role: string

  @IsBoolean()
  public include2Fa: boolean

  @IsEnum(LanguageResources)
  public lang: LanguageResources

  @ValidateNested({ each: true })
  public apiKeys: IApiKeyDTO[]

  public license: IUserLicenseDTO | null

  constructor (payload: ICreateUserPayload) {
    super(payload.id)

    this.firstName = payload.firstName
    this.lastName = payload.lastName
    this.middleName = payload.middleName ?? ''
    this.role = payload.role ?? ''
    this.include2Fa = payload.include2Fa
    this.lang = payload.lang
    this.apiKeys = payload.apiKeys
    this.license = payload.license
  }

  public async edit (payload: IEditUserPayload): Promise<void> {
    if (payload.firstName !== undefined) this.firstName = payload.firstName
    if (payload.lastName !== undefined) this.lastName = payload.lastName
    if (payload.middleName !== undefined) this.middleName = payload.middleName
    if (payload.include2Fa !== undefined) this.include2Fa = payload.include2Fa
    if (payload.apiKeys !== undefined) this.apiKeys = payload.apiKeys
    if (payload.license !== undefined) this.license = payload.license

    await this.validate()
  }

  public async changeLanguage (lang: LanguageResources): Promise<void> {
    this.lang = lang
    await this.validate()
  }

  public async updateApiKeys (apiKeys: IApiKeyDTO[]): Promise<void> {
    this.apiKeys = apiKeys

    await this.validate()
  }

  public static async new (payload: ICreateUserPayload): Promise<User> {
    const user = new User(payload)
    await user.validate()

    return user
  }

}

export { User }
