interface IOutputYandexMetric {
  addYandexMetric(): void
  clearYandexMetric(): void
}

const SETTINGS = {
  code: 92381744,
  options: {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisior: true
  }
}

const useYandexMetrics = (): IOutputYandexMetric => {
  let localScript: HTMLScriptElement | null = null
  const addYandexMetric = (): void => {
    localScript = document.createElement('script')

    localScript.type = 'text/javascript'
    localScript.innerHTML = `
       (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
       m[i].l=1*new Date();
       for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
       k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
       (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
    
       ym(${SETTINGS.code}, "init", ${JSON.stringify(SETTINGS.options)});
      `
    document.head.appendChild(localScript)
  }
  const clearYandexMetric = (): void => {
    if (localScript !== null) document.head.removeChild(localScript)
  }

  return {
    addYandexMetric,
    clearYandexMetric
  }
}

export { useYandexMetrics }
