import { DEFAULT_DETECT_SETTINGS } from '../../constant'

import type {
  IResetDetectPort,
  IResetDetectUseCase,
  IDetectSettingDTO,
  ICreateDetectUseCase,
  IDeleteDetectUseCase
} from '../../interface'

class ResetDetectUseCase implements IResetDetectUseCase {

  private _createUseCase: ICreateDetectUseCase

  private _deleteUseCase: IDeleteDetectUseCase

  constructor (createUseCase: ICreateDetectUseCase, deleteUseCase: IDeleteDetectUseCase) {
    this._createUseCase = createUseCase
    this._deleteUseCase = deleteUseCase
  }

  public async execute (port: IResetDetectPort): Promise<IDetectSettingDTO> {
    await this._deleteUseCase.execute({ id: port.id })

    return this._createUseCase.execute({
      detect: {
        name: port.name,
        type: DEFAULT_DETECT_SETTINGS.type,
        isActive: DEFAULT_DETECT_SETTINGS.isActive,
        isEnableSound: DEFAULT_DETECT_SETTINGS.isEnableSound,
        sound: DEFAULT_DETECT_SETTINGS.sound,
        alertTime: DEFAULT_DETECT_SETTINGS.alertTime,
        pairList: DEFAULT_DETECT_SETTINGS.pairList,
        minVolumePerHour: DEFAULT_DETECT_SETTINGS.minVolumePerHour,
        maxVolumePerHour: DEFAULT_DETECT_SETTINGS.maxVolumePerHour,
        minVolumePerDay: DEFAULT_DETECT_SETTINGS.minVolumePerDay,
        maxVolumePerDay: DEFAULT_DETECT_SETTINGS.maxVolumePerDay,
        priceInterval: DEFAULT_DETECT_SETTINGS.settings.priceInterval,
        priceChange: DEFAULT_DETECT_SETTINGS.settings.priceChange,
        minTradesPerSec: DEFAULT_DETECT_SETTINGS.settings.minTradesPerSec,
        maxTradesPerSec: DEFAULT_DETECT_SETTINGS.settings.maxTradesPerSec,
        volumePerSec: DEFAULT_DETECT_SETTINGS.settings.volumePerSec,
        volumeEMA: DEFAULT_DETECT_SETTINGS.settings.volumeEMA,
        delta: DEFAULT_DETECT_SETTINGS.delta
      },
      list: []
    })
  }

}

export { ResetDetectUseCase }
