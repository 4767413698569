import { useEffect } from 'react'

let wakeLock: null | WakeLockSentinel = null

const isSupportWakeLock = (): boolean => 'wakeLock' in navigator

const startWakeLock = async (): Promise<void> => {
  if (isSupportWakeLock()) wakeLock = await navigator.wakeLock.request('screen')
}

const stopWakeLock = (): void => {
  void wakeLock?.release().then(() => {
    wakeLock = null
  })
}

const useWakeLock = (): void => {
  useEffect(() => {
    void startWakeLock()

    document.addEventListener('click', () => void startWakeLock())
    document.addEventListener('touchend', () => void startWakeLock())

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') void startWakeLock()
      if (document.visibilityState === 'hidden') stopWakeLock()
    })
  }, [])
}

export { useWakeLock }
