import { useEffect, useState } from 'react'

import { useTheme } from '@mui/material'

import { useTranslation } from 'react-i18next'

import {
  useGetCookiesAccept,
  useSetCookiesAccept
} from '@app/components/tools/components/handlers/cookies-accept-handler/hooks'
import {
  APP_INSTALL_HANDLER_STYLES as Component
} from '@app/components/tools/components/handlers/app-install-handler/_styled'
import { ReactComponent as LogoIcon } from '@app/asset/icon/global/favicon.svg'
import { ButtonSecondary } from '@app/components/ui/theme/ui/buttons'

const CookiesAcceptHandler = (): JSX.Element => {
  const { palette } = useTheme()
  const { t } = useTranslation()

  const { refetch: getCookies, data: isData, isSuccess } = useGetCookiesAccept()
  const { mutateAsync: setCookies } = useSetCookiesAccept()

  const [isVisible, setIsVisible] = useState(false)

  const handleSetCookies = (): void => {
    void setCookies({})
    setIsVisible(false)
  }

  useEffect(() => {
    setIsVisible(isData === true ? false : isSuccess)
  }, [isData, isSuccess])

  useEffect(() => {
    void getCookies()
  }, [])

  return (
    <Component.Wrapper
      sx={{
        visibility: isVisible ? 'visible' : 'hidden',
        display: 'flex',
        justifyContent: 'center',
        zIndex: 99999
      }}
    >
      <Component.Container>
        <Component.Icon><LogoIcon /></Component.Icon>

        <Component.Title>{ t('cookies-accept') }</Component.Title>

        <Component.ContainerBtn
          sx={{ '.MuiButtonBase-root': { width: 'initial' }}}
        >
          <ButtonSecondary
            sx={{ 'path': { stroke: `${palette.text.primary}!important` }}}
            onClick={ handleSetCookies }
          >
            { t('accept') }
          </ButtonSecondary>
        </Component.ContainerBtn>
      </Component.Container>
    </Component.Wrapper>
  )
}

export default CookiesAcceptHandler
