import { initMarketOverviewBySymbolSocket } from '@data/socket/stocks/market-overview'

import type { IMarketOverviewBySymbolSocket } from '@data/socket/stocks/market-overview'
import type { IMarketOverviewDTO } from '@domain/stocks/market-overview'
import type { IPairDTO } from '@domain/stocks/pair'
import type { Stocks } from '@domain/common/enums'

class MarketOverviewBySymbolController {

  private _subscribedPairs: IPairDTO[]

  private _excludedPairs: IPairDTO[]

  private _stock: Stocks | null

  private _sockets: Record<string, IMarketOverviewBySymbolSocket>

  constructor () {
    this._subscribedPairs = []
    this._excludedPairs = []

    this._stock = null

    this._sockets = {}
  }

  public updateStock (stock: Stocks): void {
    if (this._stock !== stock) this._unsubscribeAll()

    this._stock = stock
  }

  public updateExcludedPairs (pairs: IPairDTO[]): void {
    this._excludedPairs = pairs
  }

  public subscribe (pair: IPairDTO): void {
    if (this._stock === null || this._isSubscribedPair(pair)) return

    this._subscribedPairs.push(pair)

    this._sockets[pair.ticker.slash] = initMarketOverviewBySymbolSocket({ stock: this._stock })
    this._sockets[pair.ticker.slash].subscribe(pair, this._stock)
  }

  public unsubscribe (pair: IPairDTO): void {
    if (this._isExcludedPair(pair) || !this._isSubscribedPair(pair)) return

    this._subscribedPairs = this._subscribedPairs.filter((_pair) => _pair.ticker.slash !== pair.ticker.slash)

    this._sockets[pair.ticker.slash].unsubscribe()
  }

  public onMessage (callback: (message: IMarketOverviewDTO) => void): void {
    Object.values(this._sockets).forEach((socket) => {
      socket.onMessage(callback)
    })
  }

  private _unsubscribeAll (): void {
    Object.values(this._sockets).forEach((socket) => {
      socket.unsubscribe()
    })

    this._subscribedPairs = []
    this._excludedPairs = []

    this._sockets = {}
  }

  private _isSubscribedPair (pair: IPairDTO): boolean {
    return this._subscribedPairs.some((_pair) => _pair.ticker.slash === pair.ticker.slash)
  }

  private _isExcludedPair (pair: IPairDTO): boolean {
    return this._excludedPairs.some((_pair) => _pair.ticker.slash === pair.ticker.slash)
  }

}

export { MarketOverviewBySymbolController }
