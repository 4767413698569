import { BaseLicenseUseCase } from '@domain/license/common/use-case'

import type { ILicensePortWithId, IStartLicenseUseCase } from '@domain/license'

class StartLicenseUseCase extends BaseLicenseUseCase implements IStartLicenseUseCase {

  public async execute (port: ILicensePortWithId): Promise<void> {
    await this._repository.startLicense(port)
  }

}

export { StartLicenseUseCase }
