import type {
  IGetListReportPort,
  ICommonReportDTO,
  IReportRepository,
  IGetCommonReportListUseCase
} from '../../../../common'

class GetCommonReportListUseCase implements IGetCommonReportListUseCase {

  protected repository: IReportRepository

  constructor (repository: IReportRepository) {
    this.repository = repository
  }

  public async execute (port: IGetListReportPort): Promise<ICommonReportDTO[]> {
    return this.repository.getCommonReportList(port)
  }

}

export { GetCommonReportListUseCase }
