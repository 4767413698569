import { CentrifugeService, STOCK_CONNECTION } from '@data/common/services'

import type { Stocks } from '@domain/common/enums'

import type { IMarketOverviewByFiltersSocket } from '../../../interfaces'

import type {
  IMarketOverviewSocketResponse,
  IBinanceMarketOverviewSocketData,
  IOKXMarketOverviewSocketData
} from '../interfaces'

class MarketOverviewByFiltersSocket implements IMarketOverviewByFiltersSocket {

  protected socket: CentrifugeService<IBinanceMarketOverviewSocketData[] | IOKXMarketOverviewSocketData[]> | null

  protected stock: Stocks

  protected channel: string

  constructor (channel: string, stock: Stocks) {
    this.channel = channel
    this.stock = stock

    this.socket = null
  }

  public subscribe (id: string): void {
    this.socket = new CentrifugeService(`${this.channel}_${id}`, STOCK_CONNECTION[this.stock])
  }

  public unsubscribe (): void {
    this.socket?.close()
  }

  public onMessage (callback: (value: IMarketOverviewSocketResponse) => void): void {
    this.socket?.onMessage((data) => {
      callback({
        stock: this.stock,
        data
      })
    })
  }

}

export { MarketOverviewByFiltersSocket }
