import { useMediaQuery } from '@mui/material'
import { useEffect, useState } from 'react'

import ROUTES_PATH from '@app/router/router-path'

/* eslint-disable  @typescript-eslint/naming-convention */
const useResizePlatform = (): IUseResizePlatform => {
  const [isLoad, setIsLoad] = useState(false)

  const isTablet = !useMediaQuery('(min-width:1200px)')
  const isMobileLg = !useMediaQuery('(min-width: 820px)')
  const _isMobile = !useMediaQuery('(min-width: 820px)')

  const isLandscapeHeight = !useMediaQuery('(min-height: 576px)')
  const isLandscape = useMediaQuery('(orientation: landscape)')

  let isMobile: boolean

  if (isLandscape && isLandscapeHeight) isMobile = true
  else isMobile = _isMobile

  useEffect(() => {
    if (window.location.pathname === ROUTES_PATH.AuthRoute.TRADE && isLandscape && isLoad) window.location.reload()
  }, [isLandscape])

  useEffect(() => {
    setIsLoad(true)
  }, [])

  return {
    isTablet,
    isMobileLg,
    isMobile,
    isLandscape
  }
}

interface IUseResizePlatform {
  isMobileLg: boolean
  isMobile: boolean
  isTablet: boolean
  isLandscape: boolean
}

export { useResizePlatform }
