import { ExceptionService } from '@domain/common/services'
import { InternalCode } from '@domain/common/enums'

import type {
  IStopLossDto,
  IStopLossErrors,
  IStopLossRepository,
  IStopLossValidation,
  IUpdateStopLossUseCase
} from '@domain/setting-profile/stop-loss'

class UpdateStopLossUseCase implements IUpdateStopLossUseCase {

  private _repository: IStopLossRepository

  private _validation: IStopLossValidation

  constructor (repository: IStopLossRepository, validation: IStopLossValidation) {
    this._repository = repository
    this._validation = validation
  }

  public async execute (port: IStopLossDto): Promise<IStopLossDto> {
    this._throwErrors(port)
    await this._repository.updateStopLoss({
      ...port,
      delay: +port.delay,
      percentSl: +port.percentSl,
      spread: +port.spread,
      trailingSpread: +port.trailingSpread
    })
    return {
      ...port,
      delay: +port.delay,
      percentSl: +port.percentSl,
      spread: +port.spread,
      trailingSpread: +port.trailingSpread
    }
  }

  private _throwErrors (port: IStopLossDto): void {
    const errors = this._validatePort(port)
    if (Object.keys(errors).length) {
      throw ExceptionService.new({
        status: {
          code: InternalCode.VALIDATION_ERROR,
          message: 'Backend throw errors'
        },
        data: errors
      })
    }
  }

  private _validatePort (port: IStopLossDto): IStopLossErrors {
    const errors: IStopLossErrors = {}

    const keys = Object.entries(port) as [keyof IStopLossDto, string][]

    for (const [key, value] of keys) {
      const result = this._validation.validate()[key].run(value).errors?.code
      if (result !== undefined) {
        errors[key] = result
      }
    }

    return errors
  }

}

export { UpdateStopLossUseCase }
