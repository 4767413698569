import { useEffect, useState } from 'react'

import { useAuthContext } from '@app/common/auth'

import { useGetAxis } from '@app/common/settings/axis/request'

import { INIT_STATE_AXIS } from '@domain/setting-profile/axis'

import type { IAxisResult } from '@app/common/settings/axis/interface'
import type { IAxisDto } from '@domain/setting-profile/axis'

const useAxis = (): IAxisResult => {
  const [axis, setAxis] = useState<IAxisDto>(INIT_STATE_AXIS)
  const [isLoadedAxis, setIsLoadedAxis] = useState<boolean>(false)
  const { refetch } = useGetAxis(setAxis, setIsLoadedAxis)
  const { isAuth } = useAuthContext()

  useEffect(() => {
    if (isAuth) void refetch()
    else setAxis(INIT_STATE_AXIS)
  }, [isAuth])

  return {
    axis,
    setAxis,
    isLoadedAxis,
    setIsLoadedAxis,
    refetch
  }
}

export { useAxis }
