import { ExceptionService } from '@domain/common/services'
import { InternalCode } from '@domain/common/enums'

import { UpdateOrderUseCase } from '../../../_'

import type { IUpdateOrderUseCase, IUpdateOrderPort } from '../../../../common'

class UpdateBinanceOrderUseCase extends UpdateOrderUseCase implements IUpdateOrderUseCase {

  public override async execute (port: IUpdateOrderPort): Promise<boolean> {
    const errors = this.validatePort(port)

    if (Object.keys(errors).length) {
      throw ExceptionService.new({
        status: {
          code: InternalCode.VALIDATION_ERROR,
          message: `${this.constructor.name} validation port error`
        },
        data: errors
      })
    }

    return this.repository.updateOrder(port)
  }

}

export { UpdateBinanceOrderUseCase }
