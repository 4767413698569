import { HttpServiceV1 } from '@data/common/services'

import { ExceptionService } from '@domain/common/services'
import { InternalCode } from '@domain/common/enums'
import { deleteLastZero } from '@domain/common/utils'

import { PairRepository } from '../../_'
import { BinanceFilterType } from '../enums'

import type { IGetPairPort, ILimit, IPairDTO, IPairRepository, ITypeLimit } from '@domain/stocks/pair'

import type { BinanceFilters, IBinancePair, IFilterLotSize, IFilterMinNotional, IFilterPrice } from '../interface'

class BinancePairRepository extends PairRepository implements IPairRepository {

  public async getPairList (): Promise<IPairDTO[]> {
    return HttpServiceV1.get<IBinancePair[]>(`${this.provider}/pair-list`)
      .then((response) => {
        return response
          .filter((pair) => pair.Status === 'TRADING')
          .map((pair) => BinancePairRepository._formatPair(pair))
      })
      .catch(() => {
        throw ExceptionService.new({
          status: {
            code: InternalCode.SERVER_ERROR,
            message: 'Internal Server Error'
          }
        })
      })
  }

  public async getPair (payload: IGetPairPort): Promise<IPairDTO> {
    return HttpServiceV1.get<IBinancePair>(`${this.provider}/pair`, { params: { ticker: payload.symbol }})
      .then((response) => {
        return BinancePairRepository._formatPair(response)
      })
  }

  private static _formatPair (pair: IBinancePair): IPairDTO {
    const limits = BinancePairRepository._formatFilters(pair.Filters)

    const mainDigits = BinancePairRepository._getDigits(limits.limit.main.step)
    const lastDigits = BinancePairRepository._getDigits(limits.limit.price.step)

    return {
      ticker: {
        slash: `${pair.BaseAsset}/${pair.QuoteAsset}`,
        under: `${pair.BaseAsset}_${pair.QuoteAsset}`,
        symbol: pair.Symbol
      },
      mainAsset: {
        ticker: pair.BaseAsset,
        digits: mainDigits
      },
      lastAsset: {
        ticker: pair.QuoteAsset,
        digits: lastDigits
      },
      limits: limits
    }
  }

  private static _formatFilters (filters: BinanceFilters): ITypeLimit {
    const mainLimit =
      filters.find((item) => item.filterType === BinanceFilterType.MAIN_ASSET_AMOUNT) as IFilterLotSize
    const mainMarketLimit =
        filters.find((item) => item.filterType === BinanceFilterType.MAIN_ASSET_AMOUNT_MARKET) as IFilterLotSize
    const lastLimit =
      filters.find((item) => {
        return [BinanceFilterType.MIN_NOTIONAL, BinanceFilterType.NOTIONAL].includes(item.filterType)
      }) as IFilterMinNotional
    const priceLimit =
      filters.find((item) => item.filterType === BinanceFilterType.PRICE_FILTER) as IFilterPrice

    const priceLimits: ILimit = {
      min: priceLimit.minPrice,
      max: priceLimit.maxPrice,
      step: priceLimit.tickSize
    }

    return {
      limit: {
        price: priceLimits,
        main: {
          min: mainLimit.minQty,
          max: mainLimit.maxQty,
          step: mainLimit.stepSize
        },
        last: {
          min: lastLimit.minNotional
        }
      },
      market: {
        price: priceLimits,
        main: {
          min: Number(mainMarketLimit.minQty) === 0 ? mainLimit.minQty : mainMarketLimit.minQty,
          max: Number(mainMarketLimit.maxQty) === 0 ? mainLimit.maxQty : mainMarketLimit.maxQty,
          step: Number(mainMarketLimit.stepSize) === 0 ? mainLimit.stepSize : mainMarketLimit.stepSize
        },
        last: {
          min: lastLimit.minNotional
        }
      }
    }
  }

  private static _getDigits (step: string): number {
    const _step = deleteLastZero(step)

    return _step.includes('.')
      ? _step.split('.')[1].length
      : 0
  }

}

export { BinancePairRepository }
