import { RequiredValidator, ValidationChain } from '@domain/common/utils/validators'

import type { IUseCasePortValidator } from '@domain/common/interfaces/use-case'

import type { ICreateOrderPort } from '../../../common'

const CREATE_BINANCE_ORDER_VALIDATOR: IUseCasePortValidator<ICreateOrderPort> = {
  amount: new ValidationChain([new RequiredValidator()])
}

export { CREATE_BINANCE_ORDER_VALIDATOR }
