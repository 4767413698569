import { CentrifugeService, STOCK_CONNECTION } from '@data/common/services'

import type { IBalanceSocket } from '../../../interfaces'

import type { Stocks } from '@domain/common/enums'
import type { IBalance } from '@domain/stocks/account'

abstract class BalanceSocket<Data> implements IBalanceSocket {

  protected socket: CentrifugeService<Data> | null

  protected stock: Stocks

  protected uid: string

  protected channel: string

  protected constructor (channel: string, stock: Stocks) {
    this.channel = channel
    this.stock = stock

    this.socket = null
    this.uid = ''
  }

  public subscribe (id: string): void {
    this.uid = id
    this.socket = new CentrifugeService(`${this.channel}${this.uid}`, STOCK_CONNECTION[this.stock])
  }

  public unsubscribe (): void {
    this.socket?.close()
  }

  public abstract onMessage (callback: (message: IBalance[]) => void): void

}

export { BalanceSocket }
