import { Box } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { LicenseList } from '@app/components/tools/components/license/component/tools/list'
import { LicenseTabItem } from '@app/components/tools/components/license/component/tools/tab'

import { useLicenseContext } from '@app/common/license'

import { Components } from './_styles'

import type { ILicenseCard } from '../interfaces'

import type { ILicenseListProps } from '@app/components/tools/components/license/component/tools/list'

import type { FC } from 'react'

interface ILicenseContainerProps {
  licenseList?: FC<ILicenseListProps>
  onCardClick (value: ILicenseCard, type: 'buy' | 'gift'): void
}

const LicenseContainer = (props: ILicenseContainerProps): JSX.Element => {
  const { t } = useTranslation()

  const { licenseList } = useLicenseContext()

  const [selected, setSelected] = useState(1)

  const tabItems = useMemo(() => {
    const result: number[] = []

    licenseList.forEach((item) => {
      item.periods.forEach((period) => {
        if (!result.includes(period.dayCount)) result.push(period.dayCount)
      })
    })

    return result.sort((a, b) => a - b)
  }, [licenseList])

  const cards = useMemo<ILicenseCard[]>(() => {
    const result: ILicenseCard[] = []

    licenseList.forEach((item) => {
      item.periods.forEach((period) => {
        if (period.dayCount === selected) {
          result.push({
            id: item.id,
            periodId: period.id,
            name: item.name,
            price: period.price,
            discountPrice: period.discountPrice,
            discountPercent: period.discountPercent,
            discountStartDate: period.discountStartDate,
            discountEndDate: period.discountEndDate,
            settings: [
              t('license-container/count-pause', { count: period.pauseCount }),
              item.settings.isIncludeMultiOrders
                ? t('license-container/multi-order-enabled')
                : t('license-container/multi-order-disabled'),
              item.settings.isIncludeIceberg
                ? t('license-container/iceberg-enabled')
                : t('license-container/iceberg-disabled'),
              item.settings.orderCount === -1
                ? t('license-container/infinity-limit-order')
                : t('license-container/limit-order', { value: item.settings.orderCount }),
              item.settings.maxVolumePerOrder === -1
                ? t('license-container/infinity-max-order-volume')
                : t('license-container/max-order-volume', { value: item.settings.maxVolumePerOrder }),
              item.settings.maxVolume === -1
                ? t('license-container/infinity-max-amount')
                : t('license-container/max-amount', { value: item.settings.maxVolume }),
            ]
          })
        }
      })
    })

    return result.sort((a, b) => a.price - b.price)
  }, [selected, licenseList, t])

  const PropLicenseList = useMemo(() => props.licenseList ?? null, [props.licenseList])

  const handleCardClick = (id: number, type: 'buy' | 'gift'): void => {
    const card = cards.find((item) => item.id === id) ?? null

    if (card) props.onCardClick(card, type)
  }

  const handleClick = (value: number): void => {
    setSelected(value)
  }

  useEffect(() => {
    if (tabItems.length) setSelected(tabItems[0])
  }, [tabItems])

  return (
    <Components.Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Components.TypeBox>
          { tabItems.map((item) => (
            <LicenseTabItem
              key={ item }
              value={ item }
              currentValue={ selected }
              onClick={ handleClick }
            />
          )) }
        </Components.TypeBox>
      </Box>

      { PropLicenseList
        ? <PropLicenseList onCardClick={ handleCardClick } cards={ cards } />
        : <LicenseList onCardClick={ handleCardClick } cards={ cards } /> }
    </Components.Box>
  )
}

export { LicenseContainer }
