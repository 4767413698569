import { Stocks } from '@domain/common/enums'

import { MarketOverviewByFiltersSocket } from '../stocks'

import type { IMarketOverviewByFiltersSocket } from '../interfaces'

interface IPayload {
  stock: Stocks
}

const initMarketOverviewByFiltersSocket = (payload: IPayload): IMarketOverviewByFiltersSocket => {
  switch (payload.stock) {
    case Stocks.BINANCE: return new MarketOverviewByFiltersSocket('marketWindow', payload.stock)

    default: return new MarketOverviewByFiltersSocket('marketWindow', payload.stock)
  }
}

export { initMarketOverviewByFiltersSocket }
