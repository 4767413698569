import { GetBalanceUseCase } from '../../../_'

import type { IBalanceDTO, IGetBalanceUseCase } from '../../../../common'

class GetBinanceBalanceUseCase extends GetBalanceUseCase implements IGetBalanceUseCase {

  public async execute (): Promise<IBalanceDTO> {
    return this.repository.getBalance()
  }

}

export { GetBinanceBalanceUseCase }
