import { GetBinanceAccountUseCase } from '../get-account'
import { GetBinanceBalanceUseCase } from '../get-balance'
import { CreateAccountUseCase } from '../../../_'

import type { ICreateAccountUseCase, IAccountRepository } from '../../../../common'

class CreateBinanceAccountUseCase extends CreateAccountUseCase implements ICreateAccountUseCase {

  constructor (repository: IAccountRepository) {
    const getAccountUseCase = new GetBinanceAccountUseCase(repository)
    const getBalanceUseCase = new GetBinanceBalanceUseCase(repository)

    super(getAccountUseCase, getBalanceUseCase)
  }

}

export { CreateBinanceAccountUseCase }
