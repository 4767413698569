enum BinanceErrorMessage {
  INVALID_STOP_PRICE = 'Stop price would trigger immediately.',
  INVALID_BALANCE = 'Account has insufficient balance for requested action.'
}

enum BinanceFilterErrorMessage {
  PRICE_FILTER = 'Filter failure: PRICE_FILTER',
  PRICE_FILTER_MESSAGE = 'Price is over the symbol\'s maximum price.',
  PERCENT_PRICE = 'Filter failure: PERCENT_PRICE',
  MAX_NUM_ALGO_ORDERS = 'Filter failure: MAX_NUM_ALGO_ORDERS',
  PERCENT_PRICE_BY_SIDE = 'Filter failure: PERCENT_PRICE_BY_SIDE',
  LOT_SIZE = 'Filter failure: LOT_SIZE',
  MIN_NOTIONAL = 'Filter failure: MIN_NOTIONAL',
  ICEBERG_PARTS = 'Filter failure: ICEBERG_PARTS',
  MARKET_LOT_SIZE = 'Filter failure: MARKET_LOT_SIZE',
  MARKET_LOT_SIZE_MESSAGE = 'Precision is over the maximum defined for this asset.',
  MAX_POSITION = 'Filter failure: MAX_POSITION',
  MAX_NUM_ORDERS = 'Filter failure: MAX_NUM_ORDERS',
  MAX_NUM_ICEBERG_ORDERS = 'Filter failure: MAX_NUM_ICEBERG_ORDERS',
  TRAILING_DELTA = 'Filter failure: TRAILING_DELTA',
  EXCHANGE_MAX_NUM_ORDERS = 'Filter failure: EXCHANGE_MAX_NUM_ORDERS',
  EXCHANGE_MAX_NUM_ALGO_ORDERS = 'Filter failure: EXCHANGE_MAX_NUM_ALGO_ORDERS',
}

export { BinanceErrorMessage, BinanceFilterErrorMessage }
