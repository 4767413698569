import type { ICancelOrderPort, IOrderRepository, ICancelOrderUseCase } from '../../../../common'

class CancelOrderUseCase implements ICancelOrderUseCase {

  protected repository: IOrderRepository

  constructor (repository: IOrderRepository) {
    this.repository = repository
  }

  public async execute (port: ICancelOrderPort): Promise<boolean> {
    return this.repository.cancelOrder(port)
  }

}

export { CancelOrderUseCase }
