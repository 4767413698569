import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'

import { useQuery } from '@tanstack/react-query'

import { InternalCode } from '@domain/common/enums'
import { ERROR_MESSAGES } from '@app/services/i18n/_messages'

import { DetectSettingRepository } from '@data/repository/setting-profile/detect'
import { GetDetectListUseCase } from '@domain/setting-profile/detect'

import type { Dispatch, SetStateAction } from 'react'

import type { ExceptionService } from '@domain/common/services'
import type { IDetectSettingDTO } from '@domain/setting-profile/detect'

import type { UseQueryResult } from '@tanstack/react-query'

type IUseGetDetectSettingListResults = UseQueryResult<IDetectSettingDTO[], ExceptionService<never>>
type ISetter = Dispatch<SetStateAction<IDetectSettingDTO[]>>

const useGetDetectSettingList = (setter: ISetter): IUseGetDetectSettingListResults => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const repository = new DetectSettingRepository()
  const useCase = new GetDetectListUseCase(repository)

  const callback = async (): Promise<IDetectSettingDTO[]> => {
    return useCase.execute()
  }

  return useQuery(
    ['get detect setting list'],
    callback,
    {
      onSuccess: (value) => {
        setter(value)
      },
      onError: (error) => {
        if (error.code === InternalCode.SERVER_ERROR) {
          enqueueSnackbar(t(ERROR_MESSAGES[InternalCode.SERVER_ERROR]), { variant: 'error' })
        }
      },
      enabled: false
    }
  )
}

export { useGetDetectSettingList }
