import { EventList } from '@app/services/event'

import type { IEventObserver } from '@app/services/event'

import type { IMarketOverviewDTO } from '@domain/stocks/market-overview'

import type { IEventPayload } from '@app/services/event/event-publisher'

class MarketOverviewObserver implements IEventObserver {

  public name = EventList.UPDATE_MARKET_OVERVIEW_BY_SYMBOL

  private readonly _callback: (value: IMarketOverviewDTO) => void

  private readonly _updatedPairs: string[]

  constructor (updatedPairs: string[], callback: (value: IMarketOverviewDTO) => void) {
    this._callback = callback
    this._updatedPairs = updatedPairs
  }

  public async update (payload: IEventPayload<IMarketOverviewDTO>): Promise<void> {
    if (payload.data?.pair !== undefined && this._updatedPairs.includes(payload.data.pair.ticker.slash)) {
      this._callback(payload.data)
    }

    await Promise.resolve()
  }

}

export { MarketOverviewObserver }
