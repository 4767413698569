import { useState } from 'react'

import { IconButton } from '@mui/material'

import { VisibilityOff, Visibility } from '@mui/icons-material'

import Input from './input'

import type { InputProps } from './input'

const InputPassword = <TControl,>(props: InputPasswordProps<TControl>): JSX.Element => {
  const [isVisible, setIsVisible] = useState(false)

  const handleOnChange = (): void => {
    setIsVisible((prev) => !prev)
  }

  return (
    <Input
      { ...props }
      type={ isVisible ? 'text' : 'password' }
      InputProps={{
        endAdornment: (
          <IconButton onClick={ handleOnChange }>
            { isVisible
              ? <Visibility sx={{ transition: 'none' }} />
              : <VisibilityOff sx={{ transition: 'none' }} /> }
          </IconButton>
        )
      }}
    />
  )
}

export type InputPasswordProps<TControl> = Omit<InputProps<TControl>, 'type'>

export default InputPassword
