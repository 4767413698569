import { Stocks } from '@domain/common/enums'

import { BinanceApiKeyRepository } from '../stocks'

import type { IApiKeyRepository } from '@domain/stocks/api-key'

interface IPayload {
  stock: Stocks
}

const initApiKeyRepository = (payload: IPayload): IApiKeyRepository => {
  switch (payload.stock) {
    case Stocks.BINANCE: return new BinanceApiKeyRepository('binance-provider')

    default: return new BinanceApiKeyRepository('binance-provider')
  }
}

export { initApiKeyRepository }
