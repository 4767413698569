const ExceptedKeyboardValues: string[][] = [
  ['Enter'],
  ['Backspace'],
  /*
    Command: Ctrl + Alt + Delete
  */
  ['ControlLeft', 'AltLeft', 'Delete'],
  ['ControlRight', 'AltRight', 'Delete'],
  ['ControlLeft', 'AltRight', 'Delete'],
  ['ControlRight', 'AltLeft', 'Delete'],

  /*
   Command: Ctrl + Number
 */
  ['ControlLeft', 'Digit1'],
  ['ControlLeft', 'Digit2'],
  ['ControlLeft', 'Digit3'],
  ['ControlLeft', 'Digit4'],
  ['ControlLeft', 'Digit5'],
  ['ControlLeft', 'Digit6'],
  ['ControlLeft', 'Digit7'],
  ['ControlLeft', 'Digit8'],
  ['ControlLeft', 'Digit9'],
  ['ControlRight', 'Digit1'],
  ['ControlRight', 'Digit2'],
  ['ControlRight', 'Digit3'],
  ['ControlRight', 'Digit4'],
  ['ControlRight', 'Digit5'],
  ['ControlRight', 'Digit6'],
  ['ControlRight', 'Digit7'],
  ['ControlRight', 'Digit8'],
  ['ControlRight', 'Digit9'],

  /*
    Command: Ctrl + Shift + R
  */
  ['ControlLeft', 'ShiftLeft', 'KeyR'],
  ['ControlRight', 'ShiftRight', 'KeyR'],
  ['ControlLeft', 'ShiftRight', 'KeyR'],
  ['ControlRight', 'ShiftLeft', 'KeyR'],

  /*
    Command: Ctrl + Alt + F4
  */
  ['ControlLeft', 'AltLeft', 'F4'],
  ['ControlRight', 'AltRight', 'F4'],
  ['ControlLeft', 'AltRight', 'F4'],
  ['ControlRight', 'AltLeft', 'F4'],

  /*
  Command: Command + Option + Esc
  */
  ['MetaLeft', 'AltLeft', 'Escape'],
  ['MetaRight', 'AltRight', 'Escape'],
  ['MetaLeft', 'AltRight', 'Escape'],
  ['MetaRight', 'AltLeft', 'Escape'],

  /*
    Command: Alt + F4
  */
  ['AltLeft', 'F4'],
  ['AltRight', 'F4'],

  /*
    Command: Ctrl + Shift + Esc
  */
  ['ControlLeft', 'ShiftLeft', 'Escape'],
  ['ControlRight', 'ShiftRight', 'Escape'],

  /*
    Command: Ctrl + Esc
  */
  ['ControlLeft', 'Escape'],
  ['ControlRight', 'Escape'],

  /*
    Command: Win + L
  */
  ['MetaLeft', 'KeyL'],
  ['MetaRight', 'KeyL'],

  /*
    Command: F5
  */
  ['F5'],

  /*
  Command: Ctrl + R
  */
  ['ControlLeft', 'KeyR'],
  ['ControlRight', 'KeyR'],

  /*
  Command: Ctrl + R, Command + R
  */
  ['ControlLeft', 'KeyR'],
  ['ControlRight', 'KeyR'],
  ['MetaLeft', 'KeyR'],
  ['MetaRight', 'KeyR'],

  /*
  Command: Ctrl + R, Command + R
  */
  ['ControlLeft', 'KeyR'],
  ['ControlRight', 'KeyR'],
  ['MetaLeft', 'KeyR'],
  ['MetaRight', 'KeyR'],

  /*
  Command: Win + Pause, Win + Break
  */
  ['MetaLeft', 'Pause'],
  ['MetaRight', 'Pause'],
  ['MetaLeft', 'Break'],
  ['MetaLeft', 'Break'],

  /*
  Command: Win + D
  */
  ['MetaLeft', 'KeyD'],
  ['MetaRight', 'KeyD'],

  /*
  Command: Win + E
  */
  ['MetaLeft', 'KeyE'],
  ['MetaRight', 'KeyE'],

  /*
  Command: Win + R
  */
  ['MetaLeft', 'KeyR'],
  ['MetaRight', 'KeyR'],

  /*
  Command: Win + Tab
  */
  ['MetaLeft', 'Tab'],
  ['MetaRight', 'Tab'],

  /*
  Command: Alt + Tab
  */
  ['AltLeft', 'Tab'],
  ['AltRight', 'Tab'],

  /*
  Command: Win + F
  */
  ['MetaLeft', 'KeyF'],
  ['MetaRight', 'KeyF'],

  /*
  Command: Win + A
  */
  ['MetaLeft', 'KeyA'],
  ['MetaRight', 'KeyA'],

  /*
  Command: Shift + Tab
  */
  ['ShiftLeft', 'Tab'],
  ['ShiftRight', 'Tab'],

  /*
  Command: Control + F
  */
  ['ControlLeft', 'KeyF'],
  ['ControlRight', 'KeyF'],

  /*
  Command: F1
  */
  ['F1'],

  /*
  Command: Win + U
  */
  ['MetaLeft', 'KeyU'],
  ['MetaRight', 'KeyU'],

  /*
  Command: Win + X
  */
  ['MetaLeft', 'KeyX'],
  ['MetaRight', 'KeyX'],

  /*
  Command: Ctrl + F1
  */
  ['ControlLeft', 'F1'],
  ['ControlRight', 'F1'],

  /*
  Command: Shift + Command + ?
  */
  ['ShiftLeft', 'MetaRight'],
  ['ShiftRight', 'MetaLeft'],

  /*
  Command: Win + Home
  */
  ['MetaLeft', 'Home'],
  ['MetaRight', 'Home'],

  /*
  Command: Win + H/Command + H
  */
  ['MetaLeft', 'KeyH'],
  ['MetaRight', 'KeyH'],

  /*
  Command: Win + M/Command + M
  */
  ['MetaLeft', 'KeyM'],
  ['MetaRight', 'KeyM'],

  /*
  Command: Command + Option + M
  */
  ['MetaLeft', 'AltLeft', 'KeyM'],
  ['MetaRight', 'AltRight', 'KeyM'],

  /*
  Command: Command + Option + M
  */
  ['MetaLeft', 'Comma'],
  ['MetaRight', 'Comma'],

  /*
  Command: Shift + Command + Q
  */
  ['MetaLeft', 'Comma', 'KeyQ'],
  ['MetaRight', 'Comma', 'KeyQ'],

  /*
  Command: Option + F1
  */
  ['AltLeft', 'F1'],
  ['AltRight', 'F1'],
  ['AltLeft', 'F2'],
  ['AltRight', 'F2'],
  ['AltLeft', 'F3'],
  ['AltRight', 'F3'],
  ['AltLeft', 'F10'],
  ['AltRight', 'F10'],
  ['AltLeft', 'F11'],
  ['AltRight', 'F11'],
  ['AltLeft', 'F12'],
  ['AltRight', 'F12'],

  /*
  Command: Cmd + Option + Shift + Escape
  */
  ['MetaLeft', 'AltRight', 'ShiftLeft', 'Escape'],
  ['MetaRight', 'AltLeft', 'ShiftRight', 'Escape'],

  /*
  Command: Ctr + +
  */
  ['ControlLeft', 'Equal'],
  ['ControlRight', 'Equal'],

  /*
  Command: Ctr + -
  */
  ['ControlLeft', 'Minus'],
  ['ControlRight', 'Minus'],
]

const ExceptedFKeyboard = /F1/

export { ExceptedKeyboardValues, ExceptedFKeyboard }
