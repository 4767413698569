import { INIT_STATE_STOP_LOSE } from '../constant'

import type { IGetStopLossUseCase, IStopLossDto, IStopLossRepository, IStopLossValidation } from '../interface'

class GetStopLossUseCase implements IGetStopLossUseCase {

  private _repository: IStopLossRepository

  private _validation: IStopLossValidation

  constructor (repository: IStopLossRepository, validation: IStopLossValidation) {
    this._repository = repository
    this._validation = validation
  }

  public async execute (): Promise<IStopLossDto> {
    return this._repository.getStopLoss()
      .then((response) => {
        const keys = Object.entries(response) as [keyof IStopLossDto, unknown][]

        for (const [key, value] of keys) {
          const result = this._validation.validate()[key].run(value).errors?.code

          if (result !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            response[key] = INIT_STATE_STOP_LOSE[key]
          }
        }

        return response
      })
  }

}

export { GetStopLossUseCase }
