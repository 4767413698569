import { AddToUserPairListUseCase } from '../add-to-list-use-case'

import type { IAddToDefaultUserPairListPort } from '../../interfaces'

class AddUserBlackListPairUseCase extends AddToUserPairListUseCase {

  protected override async runActions (port: IAddToDefaultUserPairListPort): Promise<void> {
    for (const symbol of port.symbols) {
      await this.repository.addPair({ id: this.entity.blackList.id, symbol: symbol })
      await this.entity.addPairToBlackList(symbol)
      await this.entity.deletePairFromWhiteList(symbol)

      const ids = this.entity.findPairInAnyList(symbol)

      for (const id of ids) {
        await this.entity.deletePairFromList(id, symbol)
      }
    }
  }

}

export { AddUserBlackListPairUseCase }
