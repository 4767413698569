import { Stocks } from '@domain/common/enums'

import { CommissionRepository } from '@data/repository/stocks/controllers'
import { GetBinanceDiscountCommissionUseCase } from '@domain/stocks/binance/commission'

import type { IGetDiscountCommissionUseCase } from '@domain/stocks/interfaces/commission'

class GetDiscountCommissionUseCase {

  private readonly _useCases: Record<Stocks, IGetDiscountCommissionUseCase> = {
    [Stocks.BINANCE]:
      new GetBinanceDiscountCommissionUseCase(CommissionRepository.new(Stocks.BINANCE, [])),
  }

  public static new (stock: Stocks): IGetDiscountCommissionUseCase {
    return new GetDiscountCommissionUseCase()._useCases[stock]
  }

}

export { GetDiscountCommissionUseCase }
