import { UserPairListEntity } from '../../entity'

import type { IUserPairListEntity, IUserPairListRepository } from '../../interfaces'

import type { IUseCase } from '@domain/common/interfaces'

abstract class UpdateUserPairListUseCase implements IUseCase<unknown, IUserPairListEntity> {

  protected repository: IUserPairListRepository

  protected entity: IUserPairListEntity

  constructor (repository: IUserPairListRepository, entity: IUserPairListEntity) {
    this.repository = repository
    this.entity = entity
  }

  public async execute (_port: unknown): Promise<IUserPairListEntity> {
    return UserPairListEntity.new({
      id: '',
      whiteList: this.entity.whiteList,
      blackList: this.entity.blackList,
      list: this.entity.list,
    })
  }

}

export { UpdateUserPairListUseCase }
