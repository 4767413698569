import type {
  IGetListReportPort,
  IReportRepository,
  IGetDetailReportListUseCase,
  IDetailReportDTO
} from '../../../../common'

class GetDetailReportListUseCase implements IGetDetailReportListUseCase {

  protected repository: IReportRepository

  constructor (repository: IReportRepository) {
    this.repository = repository
  }

  public async execute (port: IGetListReportPort): Promise<IDetailReportDTO[]> {
    return this.repository.getDetailReportList(port)
  }

}

export { GetDetailReportListUseCase }
