import fileDownload from 'js-file-download'

import type { ICsvConfig, ICsvService } from './interfaces'

class CsvService implements ICsvService {

  private _fileName = ''

  private _delimiter = ','

  private _csvData = ''

  public generate (config: ICsvConfig): string {
    if (config.delimiter !== undefined) this._delimiter = config.delimiter

    this._fileName = `${config.fileName}.csv`

    const dataArray: string[] = []

    if (config.columns.length > 0) {
      dataArray.push(this._getRowData(config.columns))
    }

    config.rows.forEach((row) => {
      dataArray.push(this._getRowData(row))
    })

    this._csvData = dataArray.join('\r\n')

    return dataArray.join('\r\n')
  }

  public download (): void {
    fileDownload(this._csvData, this._fileName)
  }

  private _escapeCell (cellData: string): string {
    return `"${cellData.replace(/\"/g, '""')}"`
  }

  private _getRowData (row: string[]): string {
    return row.map(this._escapeCell.bind(this)).join(this._delimiter)
  }

}

export { CsvService }
