import { useMemo } from 'react'

import { useTheme } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { OrderSide } from '@domain/stocks/order'
import { useTradeContext } from '@app/pages/trade/trade-context'
import { useDealContext } from '@app/pages/trade/trade-tables/tables/deal-table/_context'
import { formatNumber } from '@app/services/utils'

interface ICalculateDealsProfitResult {
  resultAllTime: [number, number]
  resultToday: [number, number]
  result: [number, number]
  switchColor (value: number): string
  formatResult (value: [number, number]): string
}

const useCalculateDealsProfit = (): ICalculateDealsProfitResult => {
  const theme = useTheme()

  const { i18n } = useTranslation()

  const { CommissionEntity, UserProfitEntity, ratesUSDT } = useTradeContext()
  const { deals, currentDealPrice } = useDealContext()

  const resultAllTime = useMemo<[number, number]>(() => {
    return [Number(UserProfitEntity.profit.all.value), Number(UserProfitEntity.profit.all.percent)]
  }, [UserProfitEntity.profit.all.percent, UserProfitEntity.profit.all.value])

  const resultToday = useMemo<[number, number]>(() => {
    return [Number(UserProfitEntity.profit.today.value), Number(UserProfitEntity.profit.today.percent)]
  }, [UserProfitEntity.profit.today.percent, UserProfitEntity.profit.today.value])

  const switchColor = (value: number): string => {
    if (value < 0) return theme.palette.error.main
    if (value > 0) return theme.palette.success.main

    return theme.palette.text.primary
  }

  const formatResult = (value: [number, number]): string => {
    const resultUSDT = formatNumber(isNaN(value[0]) ? 0 : value[0], { lang: i18n.language, min: 2, max: 2 })
    const resultPercent = formatNumber(isNaN(value[1]) ? 0 : value[1], { lang: i18n.language, min: 2, max: 2 })

    return `${resultUSDT} USDT (${resultPercent}%)`
  }

  const result = useMemo<[number, number]>(() => {
    let resultUSDT = 0
    let allOpenAmountUSDT = 0

    for (const deal of deals) {
      const commission = CommissionEntity.getCommissionByTickerWithDiscount(deal.pair.ticker.symbol)?.commission
      const commissionPercent = Number(commission?.maker)
      const unionAmountUSDT = Number(deal.unionVolume)
      const unionCommissionUSDT = Number(deal.unionCommission)

      const isBuy = deal.side === OrderSide.BUY
      const currentPrice = currentDealPrice[deal.pair.ticker.symbol] ?? '0'
      const currentRateUSDT = ratesUSDT[deal.pair.mainAsset.ticker] ?? '0'

      let currentPriceUSDT = Number(currentRateUSDT)
      let currentAmountUSDT = currentPriceUSDT * Number(deal.amount)

      if (deal.pair.mainAsset.ticker === 'USDT') {
        currentPriceUSDT = 1 / Number(currentPrice)
        currentAmountUSDT = currentPriceUSDT * Number(currentPrice) * Number(deal.amount)
      }

      if (deal.pair.lastAsset.ticker === 'USDT') {
        currentPriceUSDT = Number(currentPrice)
        currentAmountUSDT = currentPriceUSDT * Number(deal.amount)
      }

      const currentCommissionUSDT = currentAmountUSDT * commissionPercent / 100

      resultUSDT += isBuy
        ? unionAmountUSDT - unionCommissionUSDT - currentAmountUSDT - currentCommissionUSDT
        : currentAmountUSDT - currentCommissionUSDT - unionAmountUSDT - unionCommissionUSDT
      allOpenAmountUSDT += unionAmountUSDT
    }

    const resultPercent = resultUSDT / allOpenAmountUSDT * 100

    return [resultUSDT, resultPercent]
  }, [deals, currentDealPrice, ratesUSDT, CommissionEntity])

  return {
    resultAllTime,
    resultToday,
    result,
    switchColor,
    formatResult
  }
}

export { useCalculateDealsProfit }
