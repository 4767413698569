import { Stocks } from '@domain/common/enums'

import { ChangePairUseCase } from '../../stock'

import type { IPairRepository, IChangePairUseCase, IPairEntity } from '../../common'

interface IPayload {
  stock: Stocks
  repository: IPairRepository
  entity: IPairEntity
}

const initChangePairUseCase = (payload: IPayload): IChangePairUseCase => {
  switch (payload.stock) {
    case Stocks.BINANCE: return new ChangePairUseCase(payload.repository, payload.entity)

    default: return new ChangePairUseCase(payload.repository, payload.entity)
  }
}

export { initChangePairUseCase }
