import { GetOpenOrderListUseCase } from '../../stocks'

import type { Stocks } from '@domain/common/enums'

import type { IOrderRepository, IGetOpenOrderListUseCase } from '../../common'

interface IPayload {
  stock: Stocks
  repository: IOrderRepository
}

const initGetOpenOrderListUseCase = (payload: IPayload): IGetOpenOrderListUseCase => {
  return new GetOpenOrderListUseCase(payload.repository)
}

export { initGetOpenOrderListUseCase }
