import { LengthValidator, RegexValidator, RequiredValidator, ValidationChain } from '@domain/common/utils/validators'

import type { IUseCasePortValidator } from '@domain/common/interfaces/use-case'

import type { ICreateApiKeyPort, IDeleteApiKeyPort, ISetActiveApiKeyPort } from '../../../common'

const CREATE_BINANCE_API_KEY_VALIDATOR: IUseCasePortValidator<ICreateApiKeyPort> = {
  name: new ValidationChain([
    new RequiredValidator(),
    new LengthValidator({ min: 1, max: 255 }),
    new RegexValidator(/[а-яёА-ЯЁa-zA-Z0-9()\s-]/g, true)
  ]),
  publicKey: new ValidationChain([
    new RequiredValidator(),
    new RegexValidator(/[a-zA-Z0-9]/g, true),
    new LengthValidator({ min: 64, max: 64 })
  ]),
  privateKey: new ValidationChain([
    new RequiredValidator(),
    new RegexValidator(/[a-zA-Z0-9]/g, true),
    new LengthValidator({ min: 64, max: 64 })
  ])
}

const DELETE_BINANCE_API_KEY_VALIDATOR: IUseCasePortValidator<IDeleteApiKeyPort> = {
  uuid: new ValidationChain([new RequiredValidator()])
}

const SET_ACTIVE_BINANCE_API_KEY_VALIDATOR: IUseCasePortValidator<ISetActiveApiKeyPort> = {
  uuid: new ValidationChain([new RequiredValidator()])
}

export {
  CREATE_BINANCE_API_KEY_VALIDATOR,
  DELETE_BINANCE_API_KEY_VALIDATOR,
  SET_ACTIVE_BINANCE_API_KEY_VALIDATOR
}
