import type { Theme } from '@mui/material'

import type { Components } from '@mui/material/styles/components'

const SLIDER_DEFAULT_STYLES: Components<Theme>['MuiSlider'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.mode === 'light' ? '#DBDBDB' : '#848E9C'
    }),
    thumb: ({ theme }) => ({
      color: theme.palette.primary.main,
      width: 15,
      height: 15
    })
  }
}

export { SLIDER_DEFAULT_STYLES }
