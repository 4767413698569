import {
  CombinationValidationHotKey,
  HotKeyEvents,
  NotEqualValidationHotKey,
  UniqueValidatorKeyboard,
  ExceptedKeyboardValues,
  HotKeyEventsInclude
} from '@domain/setting-profile/hot-keys'

import {
  EXCEPTED_HOTKEY,
  HOTKEYS_KEY_LENGHT,
  HOTKEYS_RULES_EVENT,
  REQUIRED_HOTKEY
} from '@app/pages/profile/settings/keyboard/components/_setting'

import { RequiredValidator, ValidationChain } from '@domain/common/utils/validators'

import {
  ExceptedValidationHotkey
} from '@domain/setting-profile/hot-keys/use-case/update-hot-keys/excepted-validation-hotkey'

import type { IValidator } from '@domain/common/utils/validators'

import type { IFieldValidate, IValidationHotKey } from '@domain/setting-profile/hot-keys'

import type { IIKeyboard, IIKeyboardKey } from '@app/pages/profile/settings/keyboard/components/_validation'

interface IMultipleKeys {
  event: HotKeyEvents
  include: HotKeyEventsInclude
}

/* eslint-disable @typescript-eslint/no-magic-numbers, max-len, max-params */
class ValidationHotKey implements IValidationHotKey {

  public validate (_port: IIKeyboard, index?: number): IFieldValidate {
    const _obj: Partial<IFieldValidate> = {}
    const port = (Object.entries((Object.values(HotKeyEvents) as HotKeyEvents[])
      .reduce((prev, key) => ({ ...prev, [key]: _port[key] }), {}) as IIKeyboardKey) as [HotKeyEvents, (string[] | string[][])][])
      .map((item) => [...item, _port[`${item[0]}_is_include`]]) as Port

    port.forEach(([key]) => {
      if (key === HotKeyEvents.CHANGE_SCALE) {
        _obj[key] = this._makeMultipleChain(_port, port, { event: HotKeyEvents.CHANGE_SCALE, include: HotKeyEventsInclude.CHANGE_SCALE }, index)
      } else if (key === HotKeyEvents.ORDER_SIZE) {
        _obj[key] = this._makeMultipleChain(_port, port, { event: HotKeyEvents.ORDER_SIZE, include: HotKeyEventsInclude.ORDER_SIZE }, index)
      } else {
        const _chain: ValidationChain | undefined = this._validationChain(_port, port, key, index)
        _obj[key] = _chain === undefined ? undefined : _chain
      }
    })

    return _obj as Required<IFieldValidate>
  }

  private _makeMultipleChain (_port: IIKeyboard, port: Port, keys: IMultipleKeys, index?: number): Partial<IFieldValidate> {
    let obj: Record<number, ValidationChain | undefined> = {}
    const { event } = keys

    for (let i = 0; i < _port[event].length; i++) {
      const chain = this._validationMultipleChain(_port, port, i, keys, index)
      obj = { ...obj, [i]: chain }
    }

    return obj
  }

  private _validationChain (_port: IIKeyboard, port: Port, key: HotKeyEvents, index?: number): ValidationChain | undefined {
    let _chain: IValidator[] | undefined

    if (_port[`${key}_is_include`] === true) _chain = this._getChain(key, port, index)
    else _chain = undefined

    return _chain === undefined ? undefined : new ValidationChain(_chain)
  }

  private _validationMultipleChain (_port: IIKeyboard, port: Port, elemId: number, keys: IMultipleKeys, index?: number): ValidationChain | undefined {
    let _chain: IValidator[] | undefined
    const { event, include } = keys

    if ((_port[include] as boolean[])[elemId]) _chain = this._getChain(event, port, index)
    return _chain === undefined ? undefined : new ValidationChain(_chain)
  }

  private _getChain (key: HotKeyEvents, port: Port, index?: number): IValidator[] {
    return [
      REQUIRED_HOTKEY[key] ? new RequiredValidator() : null,
      new CombinationValidationHotKey(HOTKEYS_RULES_EVENT[key], HOTKEYS_KEY_LENGHT[key]),
      new NotEqualValidationHotKey(ExceptedKeyboardValues),
      new UniqueValidatorKeyboard(port, key, index),
      new ExceptedValidationHotkey(EXCEPTED_HOTKEY[key])
    ].filter((tt) => tt !== null) as IValidator[]
  }

}

type Port = [HotKeyEvents, (string[] | string[][]), boolean][]

export { ValidationHotKey }
