enum BinanceErrorCode {
  INVALID_API_KEY = '-2015',
  INVALID_API_KEY_MESSAGE = 'binance api key not found',
  INVALID_ORDER_PARAMS = '-2010',
  INVALID_SYMBOL = '-1121',
  ERROR_API_LIMIT = '302',
  ACTIVE_ORDER_EXISTS = '310',
  ORDER_UPDATE = '-2011',
  API_KEY_NOT_FOUND = '300',
  FILTER_ORDER = '-1013',
  FILTER_ORDER_MESSAGE = '-1111',
  INVALID_DEAL_MIN_NOTIONAL = '308',
}

export { BinanceErrorCode }
