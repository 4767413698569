import { Stocks } from '@domain/common/enums'

import { initPairRepository } from '@data/repository/stocks/pair'
import { initGetPairPriceUseCase } from '@domain/stocks/pair'

import { initTradeSocket } from '@data/socket/stocks/trade'
import { SOCKET_CONNECTIONS } from '@app/components/tools/components/charts/trading-view/common/socket'

import type { ITradeSocket } from '@data/socket/stocks/trade'

import type { ITradeDTO } from '@domain/stocks/trade-history'
import type { IPairDTO } from '@domain/stocks/pair'

type OnUpdatePriceCallback = (value: Record<string, string>) => void

class DealsCurrentPriceService {

  private _pairs: IPairDTO[]

  private _price: Record<string, string>

  private _stock: Stocks

  private _updatePriceCallback: OnUpdatePriceCallback | null

  private _socket: Record<string, ITradeSocket>

  constructor () {
    this._pairs = []
    this._price = {}
    this._stock = Stocks.BINANCE

    this._updatePriceCallback = null

    this._socket = {}
  }

  public changeStock (stock: Stocks): void {
    this._stock = stock
    this._price = {}
  }

  public setUpdatePriceCallback (callback: OnUpdatePriceCallback): void {
    this._updatePriceCallback = callback
  }

  public subscribe (pair: IPairDTO): void {
    if (this._isSubscribedPair(pair)) return

    this._pairs.push(pair)

    this._initPairPrice(pair)
      .then(() => {
        this._socket[pair.ticker.symbol] = initTradeSocket({ stock: this._stock, delay: -1 })
        this._socket[pair.ticker.symbol].subscribe(pair, SOCKET_CONNECTIONS[this._stock])
        this._socket[pair.ticker.symbol].onMessage(this._onMessage.bind(this))
      })
      .catch(() => {
        //
      })
  }

  public unsubscribe (pair: IPairDTO): void {
    if (!this._isSubscribedPair(pair)) return

    this._pairs = this._pairs.filter((_pair) => _pair.ticker.symbol !== pair.ticker.symbol)

    this._socket[pair.ticker.symbol].unsubscribe()
  }

  public unsubscribeAll (): void {
    this._pairs.forEach((pair) => {
      this._socket[pair.ticker.symbol].unsubscribe()
    })

    this._pairs = []
  }

  private async _initPairPrice (pair: IPairDTO): Promise<void> {
    const repository = initPairRepository({ stock: this._stock })
    const useCase = initGetPairPriceUseCase({ stock: this._stock, repository })

    const dto = await useCase.execute({ symbol: pair.ticker.symbol })

    this._price[pair.ticker.symbol] = dto.price

    this._updatePrice()
  }

  private _onMessage (message: ITradeDTO): void {
    this._price[message.symbol] = message.price.toString()

    this._updatePrice()
  }

  private _updatePrice (): void {
    if (this._updatePriceCallback) this._updatePriceCallback(this._price)
  }

  private _isSubscribedPair (pair: IPairDTO): boolean {
    return this._pairs.some((_pair) => _pair.ticker.symbol === pair.ticker.symbol)
  }

}

export { DealsCurrentPriceService }
