import { Controller } from 'react-hook-form'

import { Slider as MUISlider, styled } from '@mui/material'

import { useMemo } from 'react'

import type { SliderProps, CSSObject } from '@mui/material'

import type { IBaseControlledInput } from '@app/components/ui/inputs/types/base-controlled-input'

const RangeSlider = <TControl, >({
  name,
  control,
  onCommittedChanges: handleCommittedChanges,
  ...props
}: IRangeSliderProps<TControl>): JSX.Element => {
  const marks = useMemo(() => props.marks === undefined
    ? false
    : [...Array(
      props.division === undefined
        ? props.max
        : Math.ceil(props.max / props.division)
    ) as unknown []].map((_, i) => ({ value: props.min + i, label: '' }))
  , [props.marks, props.max, props.min, props.division])

  const handleChange = (
    callback: (...values: unknown[]) => void
  ) => (
    event: Event,
    value: number | number[],
    activeThumb: number
  ): void => {
    if (props.onChange) {
      props.onChange(event, value, activeThumb)

      return
    }

    callback(event, value, activeThumb)
  }

  return (
    <Controller
      name={ name }
      control={ control }
      render={ ({ field: { onChange, value: currenValue }}): JSX.Element => (
        <Slider
          sx={ props.sx }
          disabled={ props.disabled }
          value={ currenValue as number | undefined ?? props.min }
          onChange={ handleChange(onChange) }
          onChangeCommitted={ handleCommittedChanges }
          min={ props.min }
          max={ props.max }
          step={ props.step }
          valueLabelDisplay={ props.valueLabelDisplay }
          marks={ marks }
        />
      ) }
    />
  )
}

const Slider = styled(MUISlider)`
  .MuiSlider-thumb {
    width: 12px;
    height: 12px;
    
    :active {
      width: 15px;
      height: 15px;
    }
    
    ::after {
      width: 20px;
      height: 20px;
    }
  }
`

interface IRangeSliderProps<TControl> extends IBaseControlledInput<TControl> {
  min: number
  max: number
  step: number
  marks?: boolean
  valueLabelDisplay?: SliderProps['valueLabelDisplay']
  disabled?: boolean
  onChange?: SliderProps['onChange']
  onCommittedChanges?: SliderProps['onChangeCommitted']
  division?: number
  sx?: CSSObject
}

export const makeRangeSliderAttr = <TControl, >(
  min: number,
  max: number,
  step: number,
  marks?: boolean,
  valueLabelDisplay?: SliderProps['valueLabelDisplay'],
  onCommittedChanges?: SliderProps['onChangeCommitted'],
  division?: number
  // eslint-disable-next-line max-params
): Omit<IRangeSliderProps<TControl>, 'name' | 'control'> => {
  return { min, max, step, marks, valueLabelDisplay, onCommittedChanges, division }
}

export default RangeSlider
