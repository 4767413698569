import type { IApiKeyRepository, ISetActiveApiKeyUseCase, ISetActiveApiKeyPort } from '../../../../common'

import type { IUseCasePortValidationError, IUseCasePortValidator } from '@domain/common/interfaces/use-case'
import type { Entries } from '@domain/common/utils'

abstract class SetActiveApiKeyUseCase implements ISetActiveApiKeyUseCase {

  protected repository: IApiKeyRepository

  protected validator: IUseCasePortValidator<ISetActiveApiKeyPort>

  constructor (repository: IApiKeyRepository, validator: IUseCasePortValidator<ISetActiveApiKeyPort>) {
    this.repository = repository
    this.validator = validator
  }

  public abstract execute (port: ISetActiveApiKeyPort): Promise<boolean>

  protected validatePort (port: ISetActiveApiKeyPort): IUseCasePortValidationError<ISetActiveApiKeyPort> {
    const errors: IUseCasePortValidationError<ISetActiveApiKeyPort> = {}
    const keys = Object.entries(port) as Entries<ISetActiveApiKeyPort>

    for (const [key, value] of keys) {
      const _error = this.validator[key]?.run(value.trim())

      if (_error?.errors !== undefined) errors[key] = _error.errors
    }

    return errors
  }

}

export { SetActiveApiKeyUseCase }
