import { useCallback, useEffect, useState } from 'react'

import { useSnackbar } from 'notistack'

import { loadUserData } from '@app/auth/request'

import { useAuthContext } from '@app/common/auth'

import { CookieValues } from '@data/common/enums'

import LogoImg from '@app/asset/img/logo/logo-light.png'

import {
  CookieService,
  JwtService,
  connectCentrifuge,
  disconnectCentrifuge,
} from '@data/common/services'

type DepsCreator = () => Promise<void>

interface IUseObserveInternetConnectionProps {
  externalDeps: DepsCreator[]
}

let pollingInterval: undefined | NodeJS.Timeout = undefined

/* eslint-disable @typescript-eslint/no-magic-numbers */
const useObserveInternetConnection = (props: IUseObserveInternetConnectionProps): { status: 'online' | 'offline' } => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [status, setStatus] = useState<'online' | 'offline'>('online')

  const { setUser, setStock } = useAuthContext()

  useEffect(() => {
    if (!window.navigator.onLine) {
      setStatus('offline')
      handleOnOffline()
    }
  }, [])

  const handleOnOffline = useCallback((): void => {
    disconnectCentrifuge()
  }, [enqueueSnackbar])

  const ping = async (url: string, timeout: number): Promise<boolean> => {
    return new Promise((resolve) => {
      const isOnline = (): void => {
        resolve(true)
      }
      const isOffline = (): void => {
        resolve(false)
      }
      const xhr = new XMLHttpRequest()
      xhr.onerror = isOffline
      xhr.ontimeout = isOffline
      xhr.onreadystatechange = (): void => {
        if (xhr.readyState === xhr.HEADERS_RECEIVED) {
          if (xhr.status) {
            isOnline()
          } else {
            isOffline()
          }
        }
      }
      xhr.open('GET', url)
      xhr.timeout = timeout
      xhr.send()
    })
  }

  const handleOnOnline = useCallback((): void => {
    const accessToken = CookieService.get<string>(CookieValues.ACCESS_TOKEN)
    const encodedToken = JwtService.encode<{ uid: string }>(accessToken)
    const id = encodedToken.uid

    void loadUserData({ id, setUser, setStock })
      .then(async () => {
        for (const fn of props.externalDeps) {
          await fn()
        }
      })

    connectCentrifuge()
  }, [closeSnackbar, props.externalDeps])

  useEffect(() => {
    const listenerOffline = (_e?: Event): void => {
      setStatus('offline')
      handleOnOffline()
    }

    const listenerOnline = (_e?: Event): void => {
      void ping(`${LogoImg}?_=${+new Date()}`, 5000).then((isOnline) => {
        if (isOnline) {
          if (status !== 'online') {
            setStatus('online')
            handleOnOnline()
          }
        } else {
          startPolling()
        }
      })
    }

    const startPolling = (): void => {
      pollingInterval = setInterval(() => {
        void ping(`${LogoImg}?_=${+new Date()}`, 5000).then((isOnline) => {
          if (isOnline) {
            if (status !== 'online') {
              setStatus('online')
              handleOnOnline()
              clearInterval(pollingInterval)
            }
          }
        })
      }, 5000)
    }

    window.addEventListener('offline', listenerOffline)
    window.addEventListener('online', listenerOnline)

    return () => {
      window.removeEventListener('offline', listenerOffline)
      window.removeEventListener('online', listenerOnline)

      clearInterval(pollingInterval)
    }
  }, [handleOnOffline, handleOnOnline, setStatus])

  return { status }
}

export type { DepsCreator }
export { useObserveInternetConnection }
