import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  styled,
  Typography
} from '@mui/material'

import type { AccordionProps } from '@mui/material'

const DEFAULT_PAGE = {
  Wrapper: styled(Box)`
    width: 100%;
    padding: 0 120px;

    @media (max-width: 1130px) {
      margin-top: 25px;
      padding: 0 36px;
    }
  `,
  TitleBlock: styled(Typography)`
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    
    @media (max-width: 760px) {
      font-size: 24px;
      line-height: 29px;
      text-align: center;
    }
  `,
  TitleBlockColor: styled('span')`
    color: ${({ theme }): string => theme.palette.primary.main};
  `,
  FirstBlock: {
    Banner: styled(Box)`
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      
      @media(max-width: 1130px) {
        height: 700px;
      }

      .Banner {
        height: 100%;
        width: 100%;
      }
    `,
    Image: styled('img')`
      width: 100%;
      height: 100%;

      object-fit: cover;
    `,
    Wrapper: styled(Box)`
      height: 800px;
      margin-top: -200px;
      margin-left: -120px;
      padding: 280px 220px 0 120px;
      width: calc(100% + 240px);
      // position: relative;
      
      @media(max-width: 1130px) {
        height: 800px;
      }

      @media(max-width: 1000px) {
        height: 700px;
      }

      @media(max-width: 835px) {
        padding-right: 180px;
      }
      
      @media(max-width: 760px) {
        padding-right: 36px;
        padding-left: 36px;
        width: calc(100% + 72px);
        margin-left: -36px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    `,
    Title: styled(Typography)`
      font-weight: 600;
      font-size: 48px;
      line-height: normal;
      max-width: 465px;
      height: 120px;

      @media (max-width: 900px) {
        height: 72px;
      }
      
      @media (max-width: 760px) {
        font-size: 26px;
        line-height: 31px;
        text-align: center;
        max-width: initial;
      }
    `,
    SubTitle: styled(Typography)`
      font-size: 22px;
      font-weight: 500;
      line-height: 26px;
      max-width: 500px;
      margin-top: 45px;
      @media (max-width: 760px) {
        text-align: center;
      }
    `,
    Action: styled(Box)`
      margin-top: 85px;
      display: flex;
      grid-gap: 10px;
      max-width: 380px;
      
      @media(max-width: 760px) {
        max-width: initial;
      }
    `,
    Button: styled(Button)``,
  },
  SecondBlock: {
    Wrapper: styled(Box)`
      display: flex;
      flex-direction: column;
      align-items: center;
    `,
    SubTitle: {
      Wrapper: styled(Box)`
        display: flex;
        justify-content: center;
        gap: 10px;
        margin-top: 10px;
      `,
      Title: styled(Typography)`
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @media(max-width: 760px) {
          width: 100%;
          text-align: center;
        }
      `
    },
    Questions: {
      Wrapper: styled(Box)`
        margin-top: 60px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 360px));
        grid-gap: 15px;
        justify-content: center;
        width: 100%;
        max-width: 1110px;

        & > *::after {
          opacity: 1;
        }
      `
    }
  },
  FourthBlock: {
    Wrapper: styled(Box)`
      margin-left: -120px;
      width: calc(100% + 240px);
      padding: 72px 120px 150px 120px;
      
      @media (max-width: 760px) {
        padding-right: 36px;
        padding-left: 36px;
        padding-bottom: 50px;
        width: calc(100% + 72px);
        margin-left: -36px;
      }
    `,
    Box: {
      Wrapper: styled(Box)`
        margin-top: 55px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        
        @media(max-width: 1350px) {
          flex-direction: column;
          grid-gap: 50px;
        }
      `,
      Image: styled(Box)`
        .Take-terminal {
          width: 100%;
          height: 100%;
        }
        @media(max-width: 1350px) {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
        
        @media( max-width: 760px) {
          
        }
      `,
      Step: {
        Wrapper: styled(Box)`
          display: flex;
          flex-direction: column;
          grid-gap: 60px;
        `,
        Item: {
          Wrapper: styled(Box)`
            display: flex;
            gap: 32px;
            position: relative;

            &:not(:first-child)::before {
              content: "";

              position: absolute;
              left: 25px;
              top: -100px;
              z-index: -1;

              height: 150px;
              width: 4px;
              
              background: ${({ theme }): string => theme.palette.bg.secondary}
            }

            @media(max-width: 760px) {
              flex-direction: column;

              &::before {
                display: none;
              }
            }
          `,
          Icon: styled(Typography)`
            background: ${({ theme }): string => theme.palette.button.primary.default};
            
            border-radius: 5px;
            color: ${({ theme }): string => theme.palette.mode === 'light' ? '#fff' : '#181A20'};
            
            font-size: 32px;
            line-height: 38px;
            
            width: 55px;
            min-width: 55px;
            height: 55px;

            display: flex;
            align-items: center;
            justify-content: center;
          `,
          Title: styled(Typography)`
            font-size: 22px;
            font-weight: 600;
            line-height: 26px;
          `,
          Text: styled(Typography)`
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            // max-width: 520px;
          `
        }
      }
    }
  },
  FifthBlock: {
    Wrapper: styled(Box)`
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 90px 0 90px 0;

      @media (max-width: 760px) {
        padding-right: 0;
        padding-left: 0;
        padding-bottom: 50px;
      }
    `,
    Title: styled(Box)`
      display: flex;
      flex-direction: column;
      align-items: center;
    `,
    Box: {
      Wrapper: styled(Box)`
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 490px;
        
        @media (max-width: 760px) {
          position: static;
        }
      `,
      Title: styled(Typography)`
        font-weight: 600;
        font-size: 48px;
        line-height: 58px;

        @media(max-width: 760px) {
          font-size: 24px;
          line-height: 29px;
        }
      `,
      Text: styled(Typography)`
        font-size: 15px;
        font-weight: 600;
        margin-top: 10px;
      `,
    }
  },
  SixthBlock: {
    Wrapper: styled(Box)`
      .MuiAccordion-root {
        background-color: ${({ theme }): string => theme.palette.bg.default};
        border-radius: 15px;
      }
    `,
    Questions: {
      Wrapper: styled(Box)`
        margin-top: 65px;
        display: flex;
        flex-direction: column;
        gap: 8px;
      `,
      Accordion: styled((props: AccordionProps) => (
        <Accordion
          disableGutters={ true }
          elevation={ 200 }
          square={ true }
          { ...props }
        />
      ))(() => ({
        borderRadius: '15px',
        '&:not(:last-child)': {
          borderBottom: 0,
        },
        '&:before': {
          display: 'none',
        },
      })),
      Title: styled(Typography)`
        font-size: 18px;
        font-weight: 600;
      `,
      Text: styled(Typography)`
        font-size: 15px;
        font-weight: 400;
      `,
      AccordionSummary: styled(AccordionSummary)<{ isActive: boolean }>`
        background-color: ${({ theme }): string => theme.palette.bg.secondary};
        transition: ${({ theme }): string => theme.transitions.create(['background-color'], { duration: theme.transitions.duration.standard })};
        flex-direction: row-reverse;
        padding: 20px 40px;
        
        .MuiAccordionSummary-expandIconWrapper {
          border-radius: 50%;
          background-color: ${({ theme, isActive }): string => isActive ? theme.palette.button.primary.default : theme.palette.button.secondary.default};
          color: ${({ isActive }): string => isActive ? '#000' : ''};
          transition: ${({ theme }): string => theme.transitions.create(['background-color', 'transform'], { duration: theme.transitions.duration.standard })};
          padding: 8px;
          transform: rotate(270deg);
          
          svg {
            width: 18px;
            height: 18px;
          }
        }
        
        .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
          transform: rotate(90deg);
        }
        
        & .MuiAccordionSummary-content {
          margin: 0 0 0 30px!important;
        }
      `,
      AccordionDetails: styled(AccordionDetails)`
        background-color: ${({ theme }): string => theme.palette.bg.secondary};
        transition: ${({ theme }): string => theme.transitions.create(['background-color'], { duration: theme.transitions.duration.standard })};
        padding-left: 104px;
      `,
    }
  }
}

export { DEFAULT_PAGE }
