import { InternalCode, ValidationCode } from '@domain/common/enums'

import { OrderType } from '../../../../common'

import type { IUpdateOrderPort, IUpdateOrderItem, IUpdateOrderUseCase, IOrderRepository } from '../../../../common'

import type { IUseCasePortValidationError } from '@domain/common/interfaces/use-case'

abstract class UpdateOrderUseCase implements IUpdateOrderUseCase {

  protected repository: IOrderRepository

  protected countDigitsMultiplier: number

  constructor (repository: IOrderRepository) {
    this.repository = repository

    this.countDigitsMultiplier = 100000000
  }

  public abstract execute (port: IUpdateOrderPort): Promise<boolean>

  protected validatePort (port: IUpdateOrderPort): IUseCasePortValidationError<IUpdateOrderPort> {
    const errors: IUseCasePortValidationError<IUpdateOrderPort> = {}
    const orders: IUseCasePortValidationError<IUpdateOrderPort>['orders'] = []

    port.orders.forEach((item) => {
      const isValidPrice = item.type === OrderType.LIMIT
        ? item.price !== undefined && Number(item.price) !== 0 && item.price.length !== 0
        : true

      const limitsError = this.validateLimits(item)

      const orderError: Record<string, unknown> = {}

      if (!isValidPrice) orderError.price = { code: InternalCode.PROPERTY_IS_REQUIRED }
      if (limitsError) orderError.limits = this.validateLimits(item)

      if (Object.keys(orderError).length) orders.push(orderError)
    })

    if (orders.length) errors.orders = orders

    return errors
  }

  protected validateLimits (port: IUpdateOrderItem): IUseCasePortValidationError<IUpdateOrderItem>['limits'] {
    const amount = Number(port.amount)

    const isValidStepAmount = this.validateStep(port.amount, port.limits.main.min, port.limits.main.step)
    const isValidMinAmount = this.validateMinLimit(port.amount, port.limits.main.min)
    const isValidMaxAmount = this.validateMaxLimit(port.amount, port.limits.main.max)

    const isValidStepPrice = port.type === OrderType.LIMIT && port.price !== undefined
      ? this.validateStep(port.price, port.limits.price.min, port.limits.price.step)
      : true
    const isValidMinPrice = port.type === OrderType.LIMIT && port.price !== undefined
      ? this.validateMinLimit(port.price, port.limits.price.min)
      : true
    const isValidMaxPrice = port.type === OrderType.LIMIT && port.price !== undefined
      ? this.validateMaxLimit(port.price, port.limits.price.max)
      : true

    const isValidLastAmount = amount * Number(port.price) >= Number(port.limits.last.min)

    const error = { code: ValidationCode.INVALID_LIMIT }

    const response = {
      price: {
        min: isValidMinPrice ? undefined : error,
        max: isValidMaxPrice ? undefined : error,
        step: isValidStepPrice ? undefined : error
      },
      main: {
        min: isValidMinAmount ? undefined : error,
        max: isValidMaxAmount ? undefined : error,
        step: isValidStepAmount ? undefined : error
      },
      last: {
        min: isValidLastAmount ? undefined : error
      }
    }

    if (
      isValidMinAmount &&
      isValidMaxAmount &&
      isValidStepAmount &&
      isValidLastAmount &&
      isValidMinPrice &&
      isValidMaxPrice &&
      isValidStepPrice
    ) return undefined

    return response
  }

  protected validateStep (value: string, min: string, step: string): boolean {
    const _value = Math.round(Number(value) * this.countDigitsMultiplier)
    const _min = Math.round(Number(min) * this.countDigitsMultiplier)
    const _step = Math.round(Number(step) * this.countDigitsMultiplier)

    return Math.round((_value - _min) % _step) === 0
  }

  protected validateMaxLimit (value: string, max: string): boolean {
    return Number(value) <= Number(max)
  }

  protected validateMinLimit (value: string, min: string): boolean {
    return Number(value) >= Number(min)
  }

}

export { UpdateOrderUseCase }
