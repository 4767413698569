import { useMemo, useState } from 'react'

/*
 eslint-disable
 react-hooks/exhaustive-deps,
 react/hook-use-state,
 @typescript-eslint/no-unnecessary-condition,
 @typescript-eslint/ban-ts-comment
*/

/**
 * @param { T extends object } target - Объект за свойствами которого надо наблюдать
 * @param { (keyof T)[] } keys - Свойства объекта, за которыми надо наблюдать
 *
 * @description - React, не следит за вложенными свойствами объекта,
 * этот хук позволяет увидеть фреймворку, что свойство обновилось, и необходимо вызвать отрисовку.
 */
const useWatch = <T extends object | null>(target: T, keys: (keyof T)[]): T => {
  const [_, updateChangeId] = useState(0)

  return useMemo(
    () => {
      if (target !== null) {
        const descriptor = keys.reduce((acc, key) => {
          const internalKey = `@@__${key as string}__` as keyof T
          const internalValue = (target as unknown as T)[key]

          // @ts-expect-error
          acc[key] = {
            enumerable: true,
            configurable: true,
            get (): unknown {
              return (target as unknown as T)[internalKey] ?? internalValue
            },
            set (value: T[keyof T]): void {
              if ((target as unknown as T)[internalKey] !== value) {
                (target as unknown as T)[internalKey] = value
                updateChangeId((id) => id + 1)
              }
            }
          }

          return acc
        }, {})

        return Object.defineProperties(target, descriptor)
      }

      return target
    },
    [target, ...keys]
  )
}

export { useWatch }
