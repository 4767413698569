import { BaseLicenseUseCase } from '@domain/license/common/use-case'

import type { ILicensePortWithId, IStopLicenseUseCase } from '@domain/license'

class StopLicenseUseCase extends BaseLicenseUseCase implements IStopLicenseUseCase {

  public async execute (port: ILicensePortWithId): Promise<void> {
    await this._repository.stopLicense(port)
  }

}

export { StopLicenseUseCase }
