import { BaseLicenseUseCase } from '@domain/license/common/use-case'

import type { IGetLicenseListUseCase, ILicenseDTO } from '@domain/license'

class GetLicenseListUseCase extends BaseLicenseUseCase implements IGetLicenseListUseCase {

  public async execute (): Promise<ILicenseDTO[]> {
    return this._repository.getLicenseList()
  }

}

export { GetLicenseListUseCase }
