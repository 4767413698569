import { BaseBinanceDealUpdateUseCase } from '../base-update-use-case'

import type {
  IDealRepository,
  IDealsUpdatePort,
  IDealUpdateUseCase,
  IDealValidation
} from '@domain/stocks/interfaces/deal'

class BinanceDealUpdateUseCase extends BaseBinanceDealUpdateUseCase implements IDealUpdateUseCase {

  private _repository: IDealRepository

  constructor (repository: IDealRepository, validation: IDealValidation) {
    super(validation)

    this._repository = repository
  }

  public async execute (port: IDealsUpdatePort): Promise<boolean> {
    super.throwErrors(port)

    return this._repository.updateDeal({
      id: Number(port.id),
      symbol: port.pair.ticker.symbol,
      tp: +port.takeProfit.percent,
      sl: +port.stopLoss.percent,
      ts: +port.trailingStop.percent,
      includeTp: port.takeProfit.isInclude,
      includeSl: port.stopLoss.isInclude,
      includeTs: port.trailingStop.isInclude
    })
  }

}

export { BinanceDealUpdateUseCase }
