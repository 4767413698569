import { ReactComponent as HandshakeIcon } from '@app/asset/icon/landing/handshake-svgrepo-com.svg'
import { ReactComponent as FunctionalIcon } from '@app/asset/icon/landing/key-features-functional.svg'
import { ReactComponent as GraphicIcon } from '@app/asset/icon/landing/key-features-graphic.svg'
import { ReactComponent as TradingViewIcon } from '@app/asset/icon/landing/tradingview-seeklogo.com 1.svg'
import { ReactComponent as DeviceIcon } from '@app/asset/icon/landing/key-features-device.svg'
import { ReactComponent as SafetyIcon } from '@app/asset/icon/landing/safety-svgrepo-com.svg'

/* eslint-disable max-len, @typescript-eslint/no-magic-numbers */

const DEFAULT_PAGE_FEATURE = [
  {
    icon: <FunctionalIcon />,
    title: 'landing/key-features-functional/title',
    text: 'landing/key-features-functional/text'
  },
  {
    icon: <HandshakeIcon width={ 102 } height={ 102 } transform="scale(1.2)" />,
    title: 'landing/key-features-partners/title',
    text: 'landing/key-features-partners/text'
  },
  {
    icon: <GraphicIcon />,
    title: 'landing/key-features-graphic/title',
    text: 'landing/key-features-graphic/text'
  },
  {
    icon: <TradingViewIcon transform="scale(1.5)" />,
    title: 'landing/key-features-trading-view/title',
    text: 'landing/key-features-trading-view/text'
  },
  {
    icon: <DeviceIcon />,
    title: 'landing/key-features-device/title',
    text: 'landing/key-features-device/text'
  },
  {
    icon: <SafetyIcon width={ 102 } height={ 102 } />,
    title: 'landing/key-features-protect/title',
    text: 'landing/key-features-protect/text',
  }
]

const DEFAULT_PAGE_TRADE = [
  { title: 'landing/take-terminal-is-easy/account-title', text: 'landing/take-terminal-is-easy/account-text' },
  { title: 'landing/take-terminal-is-easy/identity-title', text: 'landing/take-terminal-is-easy/identity-text' },
  { title: 'landing/take-terminal-is-easy/start-trading-title', text: 'landing/take-terminal-is-easy/start-trading-text' },
]

const QUESTIONS = [
  { title: 'landing/questions/title-1', content: 'landing/questions/content-1' },
  { title: 'landing/questions/title-2', content: 'landing/questions/content-2' },
  { title: 'landing/questions/title-3', content: 'landing/questions/content-3' },
  { title: 'landing/questions/title-4', content: 'landing/questions/content-4' },
  { title: 'landing/questions/title-5', content: 'landing/questions/content-5' },
]

const SETTING_SLIDER = {
  partialVisible: true,
  arrows: false
}

const SETTING_SLIDER_FEATURES = {
  ...SETTING_SLIDER,
  responsive: {
    resize_1: {
      breakpoint: { max: 6000, min: 1350 },
      items: 4
    },
    resize_2: {
      breakpoint: { max: 1350, min: 1200 },
      items: 3,
      partialVisibilityGutter: 40
    },
    resize_3: {
      breakpoint: { max: 1200, min: 1150 },
      items: 3,
      partialVisibilityGutter: 20
    },
    resize_4: {
      breakpoint: { max: 1150, min: 1050 },
      items: 3
    },
    resize_5: {
      breakpoint: { max: 1050, min: 1000 },
      items: 2,
      partialVisibilityGutter: 100
    },
    resize_6: {
      breakpoint: { max: 1000, min: 900 },
      items: 2,
      partialVisibilityGutter: 50
    },
    resize_7: {
      breakpoint: { max: 900, min: 500 },
      items: 2,
      partialVisibilityGutter: 20
    },
    resize_8: {
      breakpoint: { max: 850, min: 800 },
      items: 2
    },
    resize_9: {
      breakpoint: { max: 800, min: 760 },
      items: 1,
      partialVisibilityGutter: 230
    },
    resize_10: {
      breakpoint: { max: 760, min: 650 },
      items: 2,
    },
    resize_11: {
      breakpoint: { max: 650, min: 600 },
      items: 1,
      partialVisibilityGutter: 230
    },
    resize_12: {
      breakpoint: { max: 600, min: 550 },
      items: 1,
      partialVisibilityGutter: 180
    },
    resize_13: {
      breakpoint: { max: 550, min: 500 },
      items: 1,
      partialVisibilityGutter: 140
    },
    resize_14: {
      breakpoint: { max: 500, min: 450 },
      items: 1,
      partialVisibilityGutter: 100
    },
    resize_15: {
      breakpoint: { max: 450, min: 320 },
      items: 1
    }
  }
}

export {
  DEFAULT_PAGE_FEATURE,
  DEFAULT_PAGE_TRADE,
  SETTING_SLIDER_FEATURES,
  QUESTIONS
}
