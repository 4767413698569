import { OrderSide, OrderStatus, OrderType } from '@domain/stocks/order'

import { BinanceOrderSide, BinanceOrderStatus, BinanceOrderType } from '../../enums'

const transformSideFromBinance = (side: BinanceOrderSide): OrderSide => {
  switch (side) {
    case BinanceOrderSide.BUY: return OrderSide.BUY
    case BinanceOrderSide.SELL: return OrderSide.SELL
    default: return OrderSide.BUY
  }
}

const transformTypeFromBinance = (side: BinanceOrderType): OrderType => {
  switch (side) {
    case BinanceOrderType.LIMIT: return OrderType.LIMIT
    case BinanceOrderType.MARKET: return OrderType.MARKET
    case BinanceOrderType.POST_ONLY: return OrderType.POST_ONLY
    case BinanceOrderType.STOP_LIMIT: return OrderType.STOP_LIMIT
    case BinanceOrderType.STOP_MARKET: return OrderType.STOP_MARKET
    default: return OrderType.LIMIT
  }
}

const transformStatusFromBinance = (side: BinanceOrderStatus): OrderStatus => {
  switch (side) {
    case BinanceOrderStatus.NEW: return OrderStatus.NEW
    case BinanceOrderStatus.TRADE: return OrderStatus.TRADE
    case BinanceOrderStatus.COMPLETE: return OrderStatus.COMPLETE
    case BinanceOrderStatus.CANCELED: return OrderStatus.CANCELED
    case BinanceOrderStatus.EXPIRED: return OrderStatus.EXPIRED
    default: return OrderStatus.NEW
  }
}

const transformSideToBinance = (side: OrderSide): BinanceOrderSide => {
  switch (side) {
    case OrderSide.BUY: return BinanceOrderSide.BUY
    case OrderSide.SELL: return BinanceOrderSide.SELL
    default: return BinanceOrderSide.BUY
  }
}

const transformTypeToBinance = (side: OrderType): BinanceOrderType => {
  switch (side) {
    case OrderType.LIMIT: return BinanceOrderType.LIMIT
    case OrderType.MARKET: return BinanceOrderType.MARKET
    case OrderType.POST_ONLY: return BinanceOrderType.POST_ONLY
    case OrderType.STOP_LIMIT: return BinanceOrderType.STOP_LIMIT
    case OrderType.STOP_MARKET: return BinanceOrderType.STOP_MARKET
    default: return BinanceOrderType.LIMIT
  }
}

export {
  transformSideFromBinance,
  transformTypeFromBinance,
  transformStatusFromBinance,
  transformSideToBinance,
  transformTypeToBinance
}
