import { useQuery } from '@tanstack/react-query'

import { useTranslation } from 'react-i18next'

import { useSnackbar } from 'notistack'

import { GetHotKeysUseCase } from '@domain/setting-profile/hot-keys'

import { HotKeysRepository } from '@data/repository/setting-profile/hot-keys'

import { InternalCode } from '@domain/common/enums'

import { ERROR_MESSAGES } from '@app/services/i18n/_messages'

import type { Dispatch, SetStateAction } from 'react'

import type { IHotKeyEntity } from '@domain/setting-profile/hot-keys'

import type { IUseGetHotKeysResults } from '@app/common/settings'

const useGetHotKeys = (setter: Dispatch<SetStateAction<IHotKeyEntity[]>>): IUseGetHotKeysResults => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const repository = new HotKeysRepository()
  const useCase = new GetHotKeysUseCase(repository)

  const callback = async (): Promise<IHotKeyEntity[]> => {
    return useCase.execute()
  }

  return useQuery(
    ['get all hot keys'],
    callback,
    {
      onSuccess: (value) => {
        setter(value)
      },
      onError: (error) => {
        if (error.code === InternalCode.SERVER_ERROR) {
          enqueueSnackbar(t(ERROR_MESSAGES[InternalCode.SERVER_ERROR]), { variant: 'error' })
        }
      },
      enabled: false
    }
  )
}

export { useGetHotKeys }
