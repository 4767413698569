import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const useConsoleWarning = (): void => {
  const { t, i18n } = useTranslation()

  useEffect(() => {
    if (process.env.REACT_APP_STAGE === 'PROD') {
      console.log(`%c${t('console/warning')}`, 'font-size: 24px; color: red;')
    }
  }, [i18n.language])
}

export { useConsoleWarning }
