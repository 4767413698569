import { useEffect, useState } from 'react'

import { useAuthContext } from '@app/common/auth'

import { useGetBNBSettings } from '@app/pages/profile/settings/auto-buy-bnb/requests/use-get-bnb'

import { INIT_STATE_AUTOBUY_BNB } from '@domain/setting-profile/auto-boy-bnb/constant'

import type { IAutoBuySettingsDTO } from '@domain/setting-profile/auto-boy-bnb/interfaces/dto'
import type { IAutoBuyBnbResult } from '@app/common/settings/autobuy-bnb/interface'

const useAutoBuyBnb = (): IAutoBuyBnbResult => {
  const [autoBuyBnb, setAutoBuyBnb] = useState<IAutoBuySettingsDTO>(INIT_STATE_AUTOBUY_BNB)
  const { refetch } = useGetBNBSettings(setAutoBuyBnb)
  const { isAuth } = useAuthContext()

  useEffect(() => {
    if (isAuth) void refetch()
    else setAutoBuyBnb(INIT_STATE_AUTOBUY_BNB)
  }, [isAuth])

  return {
    autoBuyBnb,
    setAutoBuyBnb
  }
}

export { useAutoBuyBnb }
