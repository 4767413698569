import type { IAccountRepository, IGetAccountUseCase, IAccountDTO } from '../../../../common'

abstract class GetAccountUseCase implements IGetAccountUseCase {

  protected repository: IAccountRepository

  constructor (repository: IAccountRepository) {
    this.repository = repository
  }

  public abstract execute (): Promise<IAccountDTO>

}

export { GetAccountUseCase }
