import { useQuery } from '@tanstack/react-query'

import { useEffect } from 'react'

import { useAuthContext } from '@app/common/auth'
import { useTradeContext } from '@app/pages/trade/trade-context'
import { useInternetConnectionContext } from '@app/components/tools/components/internet-connection'

import { UserProfitRepository } from '@data/repository/user-profit'

import { GetUserProfitUseCase } from '@domain/user-profit'

import type { IUserProfitEntity } from '@domain/user-profit'

import type { UseQueryResult } from '@tanstack/react-query'

const repository = new UserProfitRepository()
const useCase = new GetUserProfitUseCase(repository)

const useGetUserProfitRequest = (): UseQueryResult<IUserProfitEntity> => {
  const { stock } = useAuthContext()
  const { setUserProfitEntity } = useTradeContext()
  const { status } = useInternetConnectionContext()

  const callback = async (): Promise<IUserProfitEntity> => {
    return useCase.execute()
      .then((response) => {
        setUserProfitEntity(response)

        return response
      })
  }

  const request = useQuery(['get user profit'], callback)

  useEffect(() => {
    if (status === 'online') void request.refetch()
  }, [stock, status])

  return request
}

export { useGetUserProfitRequest }
