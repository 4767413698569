import { ExceptionService } from '@domain/common/services'
import { InternalCode } from '@domain/common/enums'

import { UpdateUserPairListUseCase } from '../update-list-use-case'

import type {
  IAddToUserPairListErrors,
  IAddToDefaultUserPairListPort,
  IUserPairListEntity,
  IAddToUserPairListPort
} from '../../interfaces'

abstract class AddToUserPairListUseCase extends UpdateUserPairListUseCase {

  public override async execute (port: IAddToUserPairListPort): Promise<IUserPairListEntity> {
    if (port.symbols.length === 0) {
      throw ExceptionService.new<IAddToUserPairListErrors>({
        status: {
          code: InternalCode.VALIDATION_ERROR,
          message: 'Validation Error'
        },
        data: { symbols: InternalCode.PROPERTY_IS_REQUIRED }
      })
    }

    await this.runActions(port)

    return super.execute(port)
  }

  protected abstract runActions (port: IAddToDefaultUserPairListPort): Promise<void>

}

export { AddToUserPairListUseCase }
