import { AbstractValidator } from '@domain/common/utils/validators/abstract-validator'

import { ValidationCode } from '@domain/common/enums'

import type { IValidationError } from '@domain/common/utils/validators'

interface IRange {
  min?: number
  max?: number
}

class RangeValidation extends AbstractValidator {

  private _range: IRange

  constructor (range: IRange) {
    super()

    this._range = range
  }

  public override validate (value: string | number): IValidationError {
    if (Number(this._range.min) > Number(value) || Number(this._range.max) < Number(value)) {
      return {
        isSuccess: false,
        errors: {
          code: ValidationCode.INVALID_RANGE,
          data: this._range
        }
      }
    }

    return super.validate(value)
  }

}

export { RangeValidation }
