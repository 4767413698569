import { UpdateUserPairListUseCase } from '../update-list-use-case'

import type { IUpdateUserDefaultPairListPort, IUserPairListEntity } from '../../interfaces'

class DeleteUserFavoritePairUseCase extends UpdateUserPairListUseCase {

  public override async execute (port: IUpdateUserDefaultPairListPort): Promise<IUserPairListEntity> {
    await this.repository.deletePair({ id: this.entity.whiteList.id, symbol: port.symbol })
    await this.entity.deletePairFromWhiteList(port.symbol)

    return super.execute(port)
  }

}

export { DeleteUserFavoritePairUseCase }
