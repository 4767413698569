import type { IBalanceDTO, IGetBalanceUseCase, IAccountRepository } from '../../../../common'

abstract class GetBalanceUseCase implements IGetBalanceUseCase {

  protected repository: IAccountRepository

  constructor (repository: IAccountRepository) {
    this.repository = repository
  }

  public abstract execute (): Promise<IBalanceDTO>

}

export { GetBalanceUseCase }
