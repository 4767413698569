import { BaseLicenseUseCase } from '@domain/license/common/use-case'

import type { IGetUserLicenseListUseCase, IUserLicenseDTO } from '@domain/license'

class GetUserLicenseListUseCase extends BaseLicenseUseCase implements IGetUserLicenseListUseCase {

  public async execute (): Promise<IUserLicenseDTO[]> {
    return this._repository.getUserLicenseList()
  }

}

export { GetUserLicenseListUseCase }
