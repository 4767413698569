import { Stocks } from '@domain/common/enums'

import { CommissionRepository } from '@data/repository/stocks/controllers'
import { GetBinanceCommissionUseCase } from '@domain/stocks/binance/commission'

import type { IPairDTO } from '@domain/stocks/pair'
import type { IGetCommissionUseCase } from '@domain/stocks/interfaces/commission'

class GetCommissionUseCase {

  public static new (stock: Stocks, list: IPairDTO[]): IGetCommissionUseCase {
    const useCases: Record<Stocks, IGetCommissionUseCase> = {
      [Stocks.BINANCE]: new GetBinanceCommissionUseCase(CommissionRepository.new(Stocks.BINANCE, list)),
    }

    return useCases[stock]
  }

}

export { GetCommissionUseCase }
