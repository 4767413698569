import type { IGetPairPort, IGetPairUseCase, IPairDTO, IPairRepository } from '../../../../common'

class GetPairUseCase implements IGetPairUseCase {

  protected repository: IPairRepository

  constructor (repository: IPairRepository) {
    this.repository = repository
  }

  public async execute (port: IGetPairPort): Promise<IPairDTO> {
    return this.repository.getPair(port)
  }

}

export { GetPairUseCase }
