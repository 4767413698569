import { ExceptionService } from '@domain/common/services'
import { InternalCode } from '@domain/common/enums'

import type {
  ITakeProfitDTO,
  ITakeProfitErrors,
  ITakeProfitRepository,
  ITakeProfitValidation,
  IUpdateTakeProfitUseCase
} from '../../interface'

class UpdateTakeProfitUseCase implements IUpdateTakeProfitUseCase {

  private _repository: ITakeProfitRepository

  private _validation: ITakeProfitValidation

  constructor (repository: ITakeProfitRepository, validation: ITakeProfitValidation) {
    this._repository = repository
    this._validation = validation
  }

  public async execute (port: ITakeProfitDTO): Promise<ITakeProfitDTO> {
    this._throwErrors(port)

    await this._repository.updateTakeProfit({
      ...port,
      percentTp: +port.percentTp,
      limitDowngrade: +port.limitDowngrade,
      moveDowngrade: +port.moveDowngrade,
      timerDowngrade: +port.timerDowngrade,
    })

    return {
      ...port,
      percentTp: +port.percentTp,
      limitDowngrade: +port.limitDowngrade,
      moveDowngrade: +port.moveDowngrade,
      timerDowngrade: +port.timerDowngrade,
    }
  }

  private _throwErrors (port: ITakeProfitDTO): void {
    const errors = this._validatePort(port)

    if (Object.keys(errors).length) {
      throw ExceptionService.new({
        status: {
          code: InternalCode.VALIDATION_ERROR,
          message: 'UpdateTakeProfitUseCase throw errors'
        },
        data: errors
      })
    }
  }

  private _validatePort (port: ITakeProfitDTO): ITakeProfitErrors {
    const errors: ITakeProfitErrors = {}

    const keys = Object.entries(port) as [keyof ITakeProfitDTO, string][]

    for (const [key, value] of keys) {
      const result = this._validation.validate()[key].run(value).errors?.code

      if (result !== undefined) errors[key] = result
    }

    return errors
  }

}

export { UpdateTakeProfitUseCase }
