import { css, keyframes, styled } from '@mui/material'
import { get } from 'lodash'

import type { SerializedStyles } from '@emotion/react'

const circleAnimation = keyframes`
  0%{
    top:60px;
    height:5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }
  40%{
    height:20px;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100%{
    top: 0;
  }
`

const shadowAnimation = keyframes`
  0%{
    transform: scaleX(1.5);
  }
  40%{
    transform: scaleX(1);
    opacity: .7;
  }
  100%{
    transform: scaleX(.2);
    opacity: .4;
  }
`

const LOADER_COMPONENTS = {
  Wrap: styled('div')<{ isVisible: boolean }>`
    width: 100%;
    height: 100vh;
    z-index: 10000;

    display: flex;
    align-items: center;
    justify-content: center;

    position: fixed;
    top: 0;
    left: 0;

    background: ${({ theme }): string => theme.palette.bg.default};
    transition: opacity .25s ease, visibility .25s ease;
    
    ${(props): SerializedStyles | boolean => !props.isVisible && css`
      visibility: hidden;
      opacity: 0;
    `}
  `,
  Wrapper: styled('div')`
    width:200px;
    height:60px;
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);
  `,
  Circle: styled('div')`
    width:20px;
    height:20px;
    
    position: absolute;
    border-radius: 50%;
    background-color: ${({ theme }): string => get(theme, 'palette.primary.main', 'rgba(0,0,0,.5)')};
    left:15%;
    transform-origin: 50%;
    animation: ${circleAnimation} .5s alternate infinite ease;

    &:nth-child(2){
      left:45%;
      animation-delay: .2s;
    }
    &:nth-child(3){
      left:auto;
      right:15%;
      animation-delay: .3s;
    }
  `,
  Shadow: styled('div')`
    width:20px;
    height:4px;
    border-radius: 50%;
    background-color: rgba(0,0,0,.5);
    position: absolute;
    top:62px;
    transform-origin: 50%;
    z-index: -1;
    left:15%;
    filter: blur(1px);
    animation: ${shadowAnimation} .5s alternate infinite ease;

    &:nth-child(4){
      left: 45%;
      animation-delay: .2s
    }
    &:nth-child(5){
      left:auto;
      right:15%;
      animation-delay: .3s;
    }
  `
}

export { LOADER_COMPONENTS }
