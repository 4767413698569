import { BUTTON_DEFAULT_STYLES, ICON_BUTTON_BASE_STYLES, ICON_BUTTON_STYLES } from '@app/components/ui/theme/ui/buttons'
import { LINK_DEFAULT_STYLES } from '@app/components/ui/theme/ui/links'
import { TYPOGRAPHY_DEFAULT_STYLE } from '@app/components/ui/theme/ui/typography'
import { INPUTS_LABEL_DEFAULT_STYLES, INPUTS_OUTLINED_DEFAULT_STYLES } from '@app/components/ui/theme/ui/inputs'
import { LIST_DEFAULT_STYLES } from '@app/components/ui/theme/ui/menu-item'
import { SELECT_DEFAULT_STYLES } from '@app/components/ui/theme/ui/select'
import { AUTOCOMPLETE_DEFAULT_STYLES } from '@app/components/ui/theme/ui/autocomplete'
import { SLIDER_DEFAULT_STYLES } from '@app/components/ui/theme/ui/slider'
import { POPOVER_DEFAULT_STYLES } from '@app/components/ui/theme/ui/popover'
import { TOOLTIP_DEFAULT_STYLES } from '@app/components/ui/theme/ui/tololtip'
import { DIALOG_DEFAULT_STYLES } from '@app/components/ui/theme/ui/dialog'
import { SVG_ICON_STYLES } from '@app/components/ui/theme/ui/svg-icon'
import { DIVIDER_STYLES } from '@app/components/ui/theme/ui/divider'
import { TABLE_CELL_STYLES } from '@app/components/ui/theme/ui/table-cell'
import { TABLE_PAGINATION_STYLED } from '@app/components/ui/theme/ui/table-pagination'
import { FORM_CONTROL_STYLES } from '@app/components/ui/theme/ui/form-control'

import type { ThemeOptions } from '@mui/material/styles/createTheme'

const THEME: ThemeOptions = {
  transitions: {
    duration: {
      standard: 400
    }
  },
  shape: {
    borderRadius: 12
  },
  typography: {
    fontFamily: 'MyInter'
  },
  components: {
    MuiFormControl: FORM_CONTROL_STYLES,
    MuiButton: BUTTON_DEFAULT_STYLES,
    MuiIconButton: ICON_BUTTON_STYLES,
    MuiButtonBase: ICON_BUTTON_BASE_STYLES,
    MuiLink: LINK_DEFAULT_STYLES,
    MuiTypography: TYPOGRAPHY_DEFAULT_STYLE,
    MuiOutlinedInput: INPUTS_OUTLINED_DEFAULT_STYLES,
    MuiInputLabel: INPUTS_LABEL_DEFAULT_STYLES,
    MuiSelect: SELECT_DEFAULT_STYLES,
    MuiAutocomplete: AUTOCOMPLETE_DEFAULT_STYLES,
    MuiSlider: SLIDER_DEFAULT_STYLES,
    MuiPopover: POPOVER_DEFAULT_STYLES,
    MuiTooltip: TOOLTIP_DEFAULT_STYLES,
    MuiList: LIST_DEFAULT_STYLES,
    MuiDialog: DIALOG_DEFAULT_STYLES,
    MuiSvgIcon: SVG_ICON_STYLES,
    MuiDivider: DIVIDER_STYLES,
    MuiTableCell: TABLE_CELL_STYLES,
    MuiTablePagination: TABLE_PAGINATION_STYLED,
  },
}

export { THEME }
