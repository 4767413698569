import { ValidateNested } from 'class-validator'

import { Entity } from '@domain/common/entity'

import type { ICreateUserPairListEntityPayload, IUserPairListEntity, IUserPairListDTO } from '../interfaces'

class UserPairListEntity extends Entity<string> implements IUserPairListEntity {

  @ValidateNested({ each: true })
  private _list: IUserPairListDTO[]

  @ValidateNested()
  private _whiteList: IUserPairListDTO

  @ValidateNested()
  private _blackList: IUserPairListDTO

  constructor (payload: ICreateUserPairListEntityPayload) {
    super(payload.id)

    this._list = payload.list
    this._whiteList = payload.whiteList
    this._blackList = payload.blackList
  }

  get list (): IUserPairListDTO[] {
    return this._list
  }

  get whiteList (): IUserPairListDTO {
    return this._whiteList
  }

  get blackList (): IUserPairListDTO {
    return this._blackList
  }

  public isFavoritePair (value: string): boolean {
    return Boolean(this._whiteList.pairs.find((pair) => pair === value))
  }

  public isBlackListPair (value: string): boolean {
    return Boolean(this._blackList.pairs.find((pair) => pair === value))
  }

  public isInListPair (id: number, value: string): boolean {
    const list = this._list.find((l) => l.id === id)

    if (list === undefined) return false

    const index = list.pairs.findIndex((pair) => pair === value)

    return index !== -1
  }

  public findPairInAnyList (value: string): number[] {
    const ids = []

    for (const list of this._list) {
      if (this.isInListPair(list.id, value)) ids.push(list.id)
    }

    return ids
  }

  public async addPairToWhiteList (value: string): Promise<void> {
    if (this.isFavoritePair(value)) return

    this._whiteList.pairs.push(value)

    await this.validate()
  }

  public async deletePairFromWhiteList (value: string): Promise<void> {
    if (!this.isFavoritePair(value)) return

    this._whiteList.pairs = this._whiteList.pairs.filter((pair) => pair !== value)

    await this.validate()
  }

  public async addPairToBlackList (value: string): Promise<void> {
    if (this.isBlackListPair(value)) return

    this._blackList.pairs.push(value)

    await this.validate()
  }

  public async deletePairFromBlackList (value: string): Promise<void> {
    if (!this.isBlackListPair(value)) return

    this._blackList.pairs = this._blackList.pairs.filter((pair) => pair !== value)

    await this.validate()
  }

  public async addPairToList (id: number, value: string): Promise<void> {
    const index = this._list.findIndex((_list) => _list.id === id)

    if (index !== -1) this._list[index].pairs.push(value)

    await this.validate()
  }

  public async deletePairFromList (id: number, value: string): Promise<void> {
    const index = this._list.findIndex((_list) => _list.id === id)

    if (index !== -1) this._list[index].pairs = this._list[index].pairs.filter((pair) => pair !== value)

    await this.validate()
  }

  public async addList (value: IUserPairListDTO): Promise<void> {
    this._list.push(value)

    await this.validate()
  }

  public async deleteList (id: IUserPairListDTO['id']): Promise<void> {
    const idx = this._list.findIndex((i) => i.id === id)

    if (idx !== -1) {
      this._list = [
        ...this.list.slice(0, idx),
        ...this.list.slice(idx + 1),
      ]
    }

    await this.validate()
  }

  public static async new (payload: ICreateUserPairListEntityPayload): Promise<IUserPairListEntity> {
    const entity = new UserPairListEntity(payload)

    await entity.validate()

    return entity
  }

}

export { UserPairListEntity }
