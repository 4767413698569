import i18next, { t, use } from 'i18next'

import { initReactI18next } from 'react-i18next'

import { enUS, ruRU } from '@mui/x-data-grid'

import { enUS as enDateFns, ru as ruDateFns } from 'date-fns/locale'

import { Tooltip } from '@mui/material'

import en from '@data/locales/en.json'
import ru from '@data/locales/ru.json'
import { LocalStorageService } from '@data/common/services'
import { LocalStorageValues } from '@data/common/enums'

import { LanguageResources } from '@app/services/i18n/language'

import type { GridLocaleText } from '@mui/x-data-grid'
import type { ReactElement } from 'react'

const resources = { en, ru }

const dateFNSResources = {
  [LanguageResources.EN]: enDateFns,
  [LanguageResources.RU]: ruDateFns
}

const numChar = 2

const messageErrorLanguage = (messageObj: unknown): string => {
  if (typeof messageObj === 'string') {
    return JSON.stringify({ message: messageObj })
  }
  return JSON.stringify({ ...messageObj as object })
}

const messageErrorTranslate = (error: Record<string, string>): object => {
  if ('key' in error) {
    error.key = t(makeTranslationToKey(String(error.key)))
  }

  if ('characters' in error) {
    error.characters = t(String(error.characters))
  }

  return error
}

const setLangDataGrid = (lang: string): Partial<GridLocaleText> => {
  if (lang === LanguageResources.RU) return ruRU.components.MuiDataGrid.defaultProps.localeText
  return enUS.components.MuiDataGrid.defaultProps.localeText
}

const renderHeaderLanguage = (value: string, suffix?: string, tooltip?: string): ReactElement | string => {
  const label = suffix === undefined ? String(t(value)) : `${String(t(value))} ${suffix}`

  return (
    <Tooltip
      title={ suffix === undefined
        ? tooltip === undefined ? String(t(value)) : String(t(tooltip))
        : label }
    >
      <div>{ label }</div>
    </Tooltip>
  )
}

const renderCellLanguage = (callback: (lang: string) => string | number): ReturnType<typeof callback> => {
  const lang = getLanguage()
  return callback(lang)
}

const getLanguage = (): LanguageResources => {
  const languageSystem: LanguageResources = window.navigator.language.slice(0, numChar) as LanguageResources
  try {
    return LocalStorageService.get(LocalStorageValues.LANGUAGE)
  } catch (e) {
    LocalStorageService.set(LocalStorageValues.LANGUAGE, languageSystem)
    return languageSystem
  }
}

const getLocaleByAppLocale = (): Locale => {
  const lang = getLanguage()
  return dateFNSResources[lang]
}

void use(initReactI18next).init({
  resources,
  lng: getLanguage(),
  fallbackLng: getLanguage(),
  debug: false,
  interpolation: {
    escapeValue: false
  }
})

const makeTranslationToKey = (key: string): string => `key/${key}`

export {
  i18next,
  makeTranslationToKey,
  getLanguage,
  setLangDataGrid,
  renderHeaderLanguage,
  messageErrorLanguage,
  messageErrorTranslate,
  getLocaleByAppLocale,
  renderCellLanguage
}
