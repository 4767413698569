import { HttpServiceV1 } from '@data/common/services'

import { ExceptionService } from '@domain/common/services'
import { InternalCode } from '@domain/common/enums'

import type { UserResponse } from '@domain/user/interfaces'

import type {
  IGetProfileInfoResponse,
  IProfileRepository,
  IUpdateProfilePort
} from '@data/repository/profile/interfaces'

class ProfileRepository implements IProfileRepository {

  public async getInfo (): Promise<IGetProfileInfoResponse> {
    return HttpServiceV1.get<IGetProfileInfoResponse>('profile/profile')
      .then((response) => response)
      .catch(() => {
        throw ExceptionService.new({
          status: {
            code: InternalCode.SERVER_ERROR,
            message: 'Internal Server Error'
          }
        })
      })
  }

  public async updateInfo (port: IUpdateProfilePort): Promise<UserResponse> {
    return HttpServiceV1.patch<UserResponse>('profile/update', {
      body: {
        FirstName: port.firstName,
        LastName: port.lastName,
        MiddleName: port.middleName
      }
    })
      .then((res) => res)
      .catch(() => {
        throw ExceptionService.new({
          status: {
            code: InternalCode.SERVER_ERROR,
            message: 'Internal Server Error'
          }
        })
      })
  }

}

export { ProfileRepository }
