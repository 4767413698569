import { Box, styled, Typography } from '@mui/material'

const APP_INSTALL_HANDLER_STYLES = {
  Wrapper: styled(Box)<{ isMobile?: boolean }>`
    position: fixed;
    bottom: 0;
    z-index: 999;
    background-color: ${({ theme }): string => theme.palette.bg.default};
    transition: ${({ theme }): string => theme.transitions.create(['background-color'], { duration: theme.transitions.duration.standard })};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    width: ${({ isMobile }): string => isMobile === true ? 'calc(100% - 60px)' : '100%'};
    gap: 20px;
  `,
  Container: styled(Box)`
    max-width: 700px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 15px;
  `,
  Title: styled(Typography)`
    font-size: 13px;
  `,
  ContainerBtn: styled(Box)`
    display: flex;
    gap: 15px;
    align-items: center;
    
    .MuiButtonBase-root {
      width: 38px;
      height: 38px;
      min-width: initial;
      padding: 10px;
    }
  `,
  Icon: styled(Box)`
    height: 50px;

    svg {
      width: 50px;
      height: 50px;
      fill: ${({ theme }): string => theme.palette.bg.secondary};
      transition: ${({ theme }): string => theme.transitions.create(['fill'], { duration: theme.transitions.duration.standard })};
        
      #inner {
        fill: ${({ theme }): string => theme.palette.primary.main};
      }
    }
  `
}

export { APP_INSTALL_HANDLER_STYLES }
