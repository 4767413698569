import { LicenseCodes } from '@domain/common/enums'

import { LicenseErrorCodes } from '../enums'

type LicenceDescriptor = TypedPropertyDescriptor<any>
type LicenceDecorator<T> = (target: T, key: string, descriptor: LicenceDescriptor) => LicenceDescriptor

/**
 * Функция для обрабобтки ошибок лицензий
 *
 * @param keyElementIndex - Указывает на позицию аргумента, который является кодом ошибки
 * @constructor
 */

/* eslint-disable */
function LicenseErrorsHandler<T> (keyElementIndex = 0): LicenceDecorator<T> {
  return function (_target: T, _key: string, descriptor: LicenceDescriptor): LicenceDescriptor {
    const origin = descriptor.value

    descriptor.value = function (...args: LicenceDescriptor['value']): LicenseCodes | unknown {
      const key = args[keyElementIndex].toString()

      switch (key) {
        case LicenseErrorCodes.LICENSE_ACCESS_DENIED_COUNT_ORDERS:
          return LicenseCodes.MAX_COUNT_ORDERS
        case LicenseErrorCodes.LICENSE_ACCESS_DENIED_MAX_ORDER_VOLUME:
          return LicenseCodes.MAX_ORDER_VOLUME
        case LicenseErrorCodes.LICENSE_ACCESS_DENIED_MULTIORDERS:
          return LicenseCodes.ENABLE_MULTIORDERS
        case LicenseErrorCodes.LICENSE_ACCESS_DENIED_MAX_AMOUNT:
          return LicenseCodes.MAX_AMOUNT
        default:
          return origin.apply(this, args)
      }
    }

    return descriptor
  }
}

export { LicenseErrorsHandler }
