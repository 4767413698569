import { INIT_STATE_HOT_KEY } from '@domain/setting-profile/hot-keys'

import type {
  IGetHotKeysUseCase,
  IHotKeysRepository,
  IHotKeyEntity } from '@domain/setting-profile/hot-keys'

class GetHotKeysUseCase implements IGetHotKeysUseCase {

  private _repository: IHotKeysRepository

  constructor (repository: IHotKeysRepository) {
    this._repository = repository
  }

  public async execute (): Promise<IHotKeyEntity[]> {
    return this._repository.getAll()
      .then((response) => {
        if (response.length === 0) return INIT_STATE_HOT_KEY
        return response
      })
  }

}

export { GetHotKeysUseCase }
