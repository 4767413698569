import { AbstractValidator } from '@domain/common/utils/validators/abstract-validator'

import { ValidationCode } from '@domain/common/enums'

import type { IValidationError } from '@domain/common/utils/validators/interfaces/validator'

class EmailValidator extends AbstractValidator {

  public override validate (value: string): IValidationError {
    const isValidationSuccess = /^[\w-.]+@([\w-]+\.)+[\w-]{2,5}$/.test(value)

    if (!isValidationSuccess) {
      return {
        isSuccess: false,
        errors: {
          code: ValidationCode.INVALID_EMAIL,
        }
      }
    }
    return super.validate(value)
  }

}

export { EmailValidator }
