import { ValidateNested } from 'class-validator'

import { Entity } from '@domain/common/entity'

import type { ICreateUserProfitEntityPayload, IUserProfitEntity, IUserProfitDTO } from '../interfaces'

class UserProfitEntity extends Entity<string> implements IUserProfitEntity {

  @ValidateNested()
  private _profit: IUserProfitDTO

  constructor (payload: ICreateUserProfitEntityPayload) {
    super(payload.id)

    this._profit = payload.profit
  }

  get profit (): IUserProfitDTO {
    return this._profit
  }

  public async updateProfit (value: IUserProfitDTO): Promise<void> {
    this._profit = value

    await this.validate()
  }

  public static async new (payload: ICreateUserProfitEntityPayload): Promise<IUserProfitEntity> {
    const entity = new UserProfitEntity(payload)

    await entity.validate()

    return entity
  }

}

export { UserProfitEntity }
