import { INIT_STATE_AXIS } from '../constant'

import type { IAxisValidation, IGetAxis, IAxisDto, IAxisRepository } from '@domain/setting-profile/axis/interface'

class GetAxisUseCase implements IGetAxis {

  private _repository: IAxisRepository

  private _validation: IAxisValidation

  constructor (repository: IAxisRepository, validation: IAxisValidation) {
    this._repository = repository
    this._validation = validation
  }

  public async execute (): Promise<IAxisDto> {
    return this._repository.getTradeAxis()
      .then((response) => {
        const keys = Object.entries(response) as [keyof IAxisDto, unknown][]

        for (const [key, value] of keys) {
          const result = this._validation.validate()[key].run(value).errors?.code

          if (result !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            response[key] = INIT_STATE_AXIS[key]
          }
        }

        return response
      })
  }

}

export { GetAxisUseCase }
