import { CancelOrderUseCase } from '../../stocks'

import type { Stocks } from '@domain/common/enums'

import type { IOrderRepository, ICancelOrderUseCase } from '../../common'

interface IPayload {
  stock: Stocks
  repository: IOrderRepository
}

const initCancelOrderUseCase = (payload: IPayload): ICancelOrderUseCase => {
  return new CancelOrderUseCase(payload.repository)
}

export { initCancelOrderUseCase }
