import { useQuery } from '@tanstack/react-query'

import { LicenseRepository } from '@data/repository/license'
import { GetLicenseListUseCase } from '@domain/license'

import type { ExceptionService } from '@domain/common/services'
import type { ILicenseDTO } from '@domain/license'
import type { Dispatch, SetStateAction } from 'react'

import type { UseQueryResult } from '@tanstack/react-query'

type IUseGetLicenseListResult = UseQueryResult<ILicenseDTO[], ExceptionService<never>>
type SetterLicenseList = Dispatch<SetStateAction<ILicenseDTO[]>>

const useGetLicenseLisRequest = (setter: SetterLicenseList): IUseGetLicenseListResult => {
  const repository = new LicenseRepository()
  const useCase = new GetLicenseListUseCase(repository)

  const callback = async (): Promise<ILicenseDTO[]> => {
    return useCase.execute()
  }

  return useQuery(['get license list'], callback, {
    onSuccess: (value) => {
      setter(value)
    }
  })
}

export { useGetLicenseLisRequest }
