import { createContext, useCallback, useContext, useMemo } from 'react'

import { INIT_STATE_AXIS } from '@domain/setting-profile/axis'
import { INIT_STATE_CONTENT } from '@domain/setting-profile/content'
import { INIT_STATE_SPECIFICATION } from '@domain/setting-profile/specification'
import { INIT_STATE_STOP_LOSE } from '@domain/setting-profile/stop-loss'
import { INIT_STATE_TAKE_PROFIT } from '@domain/setting-profile/take-profit/constant'
import { INIT_STATE_HOT_KEY } from '@domain/setting-profile/hot-keys'
import { INIT_STATE_AUTOBUY_BNB } from '@domain/setting-profile/auto-boy-bnb/constant'

import { useHotKeys } from '@app/common/settings/hot-keys'
import { useSpecification } from '@app/common/settings/specification'
import { useAxis } from '@app/common/settings/axis'
import { useContent } from '@app/common/settings/content'
import { useStopLoss } from '@app/common/settings/stop-loss'
import { useTakeProfit } from '@app/common/settings/take-profit'
import { useDetectSettingList } from '@app/common/settings/detect'

import { useAutoBuyBnb } from '@app/common/settings/autobuy-bnb/hook'

import type { FC, PropsWithChildren } from 'react'

import type { ISettingsContext } from '@app/common/settings/interface'

const SettingsContext = createContext<ISettingsContext>({
  hotKeys: INIT_STATE_HOT_KEY,
  setHotKeys: () => {
    //
  },
  specification: INIT_STATE_SPECIFICATION,
  setSpecification: () => {
    //
  },
  axis: INIT_STATE_AXIS,
  setAxis: () => {
    //
  },
  isLoadedAxis: false,
  setIsLoadedAxis: () => {
    //
  },
  content: INIT_STATE_CONTENT,
  setContent: () => {
    //
  },
  isLoadedContent: false,
  setIsLoadedContent: () => {
    //
  },
  takeProfit: INIT_STATE_TAKE_PROFIT,
  setTakeProfit: () => {
    //
  },
  stopLoss: INIT_STATE_STOP_LOSE,
  setStopLoss: () => {
    //
  },
  setttingsRefetchCallback: async () => {
    //
  },
  autoBuyBnb: INIT_STATE_AUTOBUY_BNB,
  setAutoBuyBnb: () => {
    //
  },
  detectList: [],
  setDetectList: () => {
    //
  }
})

const useSettingsContext = (): ISettingsContext => useContext(SettingsContext)

const SettingsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { hotKeys, setHotKeys, refetch: hotKeysRefetch } = useHotKeys()
  const { specification, setSpecification, refetch: specificationRefetch } = useSpecification()
  const { takeProfit, setTakeProfit, refetch: takeProfitRefetch } = useTakeProfit()
  const { stopLoss, setStopLoss, refetch: stopLossRefetch } = useStopLoss()
  const { axis, setAxis, refetch: axisRefetch, isLoadedAxis, setIsLoadedAxis } = useAxis()
  const { content, setContent, refetch: contentRefetch, isLoadedContent, setIsLoadedContent } = useContent()
  const { autoBuyBnb, setAutoBuyBnb } = useAutoBuyBnb()
  const { detectList, setDetectList } = useDetectSettingList()

  const setttingsRefetchCallback = useCallback(async () => {
    await Promise.allSettled([
      hotKeysRefetch(),
      specificationRefetch(),
      takeProfitRefetch(),
      stopLossRefetch(),
      axisRefetch(),
      contentRefetch()
    ])
  }, [])

  const context = useMemo<ISettingsContext>(() => ({
    hotKeys,
    setHotKeys,
    specification,
    setSpecification,
    axis,
    setAxis,
    isLoadedAxis,
    setIsLoadedAxis,
    content,
    setContent,
    isLoadedContent,
    setIsLoadedContent,
    takeProfit,
    setTakeProfit,
    stopLoss,
    setStopLoss,
    setttingsRefetchCallback,
    autoBuyBnb,
    setAutoBuyBnb,
    detectList,
    setDetectList
  }), [
    hotKeys,
    specification,
    takeProfit,
    stopLoss,
    axis,
    isLoadedAxis,
    content,
    isLoadedContent,
    autoBuyBnb,
    detectList
  ])

  return (
    <SettingsContext.Provider value={ context }>
      { children }
    </SettingsContext.Provider>
  )
}

export { useSettingsContext, SettingsProvider }
