import { StorageService } from '@data/common/services/storages/storage-service'

import type { SessionStorageValues } from '@data/common/enums'
import type { IStorage } from '@data/common/interfaces'

class SessionStorageService
  extends StorageService<SessionStorageValues>
  implements IStorage<SessionStorageValues> {

  public override get<Response> (name: SessionStorageValues): Response {
    return super._get<Response>(name, sessionStorage)
  }

  public override set<Payload> (name: SessionStorageValues, payload: Payload): void {
    super._set<Payload>(name, payload, sessionStorage)
  }

  public override remove (name: SessionStorageValues): void {
    super._remove(name, sessionStorage)
  }

  public override clear (): void {
    super._clear(sessionStorage)
  }

}

export default new SessionStorageService()
