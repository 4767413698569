import { useTheme } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { Components } from './_styles'

interface ILicenseTabItemProps {
  value: number
  currentValue: number
  onClick (value: number): void
}

const LicenseTabItem = (props: ILicenseTabItemProps): JSX.Element => {
  const theme = useTheme()
  const { t } = useTranslation()

  const { onClick, currentValue, value } = props

  const handleClick = (): void => {
    onClick(value)
  }

  return (
    <Components.Button
      sx={{ background: currentValue === value ? theme.palette.bg.accent : theme.palette.bg.secondary }}
      size="large"
      onClick={ handleClick }
    >
      { `${value} ${t('license-container/day_duration', { count: value })}` }
    </Components.Button>
  )
}

export { LicenseTabItem }
