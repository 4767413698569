import { UpdateUserPairListUseCase } from '../update-list-use-case'

import type { IDeleteUserPairListPort, IDeleteUserPairListUseCase, IUserPairListEntity } from '../../interfaces'

class DeleteListOfPairsUseCase extends UpdateUserPairListUseCase implements IDeleteUserPairListUseCase {

  public override async execute (port: IDeleteUserPairListPort): Promise<IUserPairListEntity> {
    await this.repository.deleteList(port)
    await this.entity.deleteList(port.id)

    return super.execute(port)
  }

}

export { DeleteListOfPairsUseCase }
