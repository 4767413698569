import { useMemo } from 'react'

import { useSettingsContext } from '@app/common/settings'

import { HotKeyEvents } from '@domain/setting-profile/hot-keys'
import { DROP_LIST_DEFAULT_SCALE } from '@app/pages/profile/settings/content/components/form/_setting'

import type { IChangeScale } from '@domain/setting-profile/hot-keys'

interface IChartScaleList {
  id: number
  label: string
}

/* eslint-disable @typescript-eslint/no-magic-numbers */
const useChartScaleList = (): IChartScaleList[] => {
  const { hotKeys, content } = useSettingsContext()

  const isDecimalValue = (value: number): boolean => {
    return value.toString().includes('.')
  }

  return useMemo(() => {
    const hotKeyArray = (hotKeys.find((hotkeys) => hotkeys.event === HotKeyEvents.CHANGE_SCALE) as IChangeScale).data

    const selectValues: IChartScaleList[] = hotKeyArray.map((elem) => elem.option).map((item) => ({
      id: Number(item),
      label: `${item}.0%`
    }))

    const resultValues = [...new Map([...selectValues, ...DROP_LIST_DEFAULT_SCALE]
      .map((item) => [item.id, item])).values()]

    const isExist = resultValues.some((item) => item.id === content.defaultScale)

    if (!isExist) {
      const label = isDecimalValue(content.defaultScale)
        ? `${content.defaultScale}%`
        : `${content.defaultScale}.0%`

      resultValues.push({
        id: content.defaultScale,
        label: label
      })
    }

    const sortedValues = resultValues.sort((a, b) => {
      if (b.id === -1) return -1
      if (a.id === -1) return 1

      return a.id - b.id
    })

    return content.isMultiplierScale
      ? sortedValues.map((item) => {
        if (item.id !== -1) {
          const value = Number(item.id)

          const label = isDecimalValue(value)
            ? `${value * 10}%`
            : `${value * 10}.0%`

          return {
            id: value,
            label: label
          }
        }

        return item
      })
      : sortedValues
  }, [hotKeys, content.isMultiplierScale, content.defaultScale])
}

export { useChartScaleList }
