import type { Theme } from '@mui/material'

import type { Components } from '@mui/material/styles/components'

const AUTOCOMPLETE_DEFAULT_STYLES: Components<Theme>['MuiAutocomplete'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      'input': {
        background: theme.palette.bg.accent,
        transition: theme.transitions
          .create(['background-color', 'color'], { duration: theme.transitions.duration.standard })
      }
    }),
    input: ({ ownerState }) => ({
      padding: ownerState.size === 'small' ? '0 0 0 10px !important' : ''
    }),
    paper: ({ theme }) => ({
      background: theme.palette.bg.secondary,
      transition: theme.transitions
        .create(['background-color', 'color'], { duration: theme.transitions.duration.standard })
    }),
    listbox: {
      fontSize: 14
    },
    endAdornment: ({ theme }) => ({
      transition: theme.transitions.create(['background'], { duration: theme.transitions.duration.standard }),
      '.MuiSvgIcon-root': {
        fontSize: '20px !important',
        transition: `${theme.transitions
          .create(['color', 'fill'], { duration: theme.transitions.duration.standard })}!important`
      }
    })
  }
}

export { AUTOCOMPLETE_DEFAULT_STYLES }
