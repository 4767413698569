import { useEffect } from 'react'

import { useGetPairRequest, useGetUserPairListRequest } from '@app/pages/trade/requests'
import { useAuthContext } from '@app/common/auth'
import { useTradeContext } from '@app/pages/trade/trade-context'

const UserPairList = (): null => {
  const { stock, isAuth } = useAuthContext()
  const { PairEntity, setIsPairLoaded, setQuotedCoinList } = useTradeContext()

  const getUserPairListRequest = useGetUserPairListRequest()
  const getPairRequest = useGetPairRequest()

  useEffect(() => {
    if (isAuth) {
      setIsPairLoaded(false)

      void getPairRequest.mutateAsync({})
      void getUserPairListRequest.mutateAsync({})
    }
  }, [stock, isAuth])

  useEffect(() => {
    setQuotedCoinList([...new Set(PairEntity.list.map((item) => item.lastAsset.ticker))])
  }, [PairEntity.list])

  useEffect(() => {
    if (getPairRequest.isLoading) void PairEntity.clearPairList()
  }, [getPairRequest.isLoading])

  return null
}

export { UserPairList }
