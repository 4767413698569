enum EventList {
  UPDATE_PRICE = 'update-price',

  TRIGGER_SALE_ORDER = 'trigger_sale_order',
  TRIGGER_BUY_ORDER = 'trigger_buy_order',
  TRIGGER_CANCEL_ORDER = 'trigger_cancel_order',
  TRIGGER_CANCEL_ORDER_ALL = 'trigger_cancel_order_all',
  TRIGGER_SET_PRICE = 'trigger_set_price',
  TRIGGER_SAVE_SCREENSHOT = 'trigger_save_screenshot',
  TRIGGER_COPY_SCREENSHOT = 'trigger_copy_screenshot',
  TRIGGER_OPEN_SCREENSHOT = 'trigger_open_screenshot',

  TRIGGER_MOVE_ORDER = 'trigger_move_order',
  TRIGGER_MOVE_DEAL = 'trigger_move_deal',

  TRIGGER_SCALE_GRAPHIC = 'trigger_scale_graphic',
  TRIGGER_DEALS = 'trigger_deals',
  TRIGGER_CHANGE_VOLUME_GRAPHIC = 'trigger_change_volume_graphic',

  TRIGGER_CHANGE_ORDER_SIZE = 'trigger_change_order_size',

  UPDATE_MARKET_OVERVIEW_BY_SYMBOL = 'update_market_overview_by_symbol',

  UPDATE_DEALS_PAIR = 'update_deals_pair',
}

export { EventList }
