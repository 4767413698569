import type { ILicenseRepository } from '@domain/license'

class BaseLicenseUseCase {

  protected _repository: ILicenseRepository

  constructor (repository: ILicenseRepository) {
    this._repository = repository
  }

}

export { BaseLicenseUseCase }
