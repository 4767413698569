import { EventList } from '@app/services/event'

import type { IPairDTO } from '@domain/stocks/pair'

import type { IEventPayload } from '@app/services/event/event-publisher'
import type { IEventObserver } from '@app/services/event'

type Action = 'add' | 'delete'
type OnUpdateCallback = (data: IDealsPairObserverPayload) => void

interface IDealsPairObserverPayload {
  action: Action
  pair: IPairDTO
}

class DealsPairObserver implements IEventObserver {

  public name = EventList.UPDATE_DEALS_PAIR

  private readonly _callback: OnUpdateCallback

  constructor (callback: OnUpdateCallback) {
    this._callback = callback
  }

  public async update (payload: IEventPayload<IDealsPairObserverPayload>): Promise<void> {
    if (payload.data !== undefined) this._callback(payload.data)

    await Promise.resolve()
  }

}

export { DealsPairObserver }
export type { IDealsPairObserverPayload }
