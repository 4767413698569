import { Box, styled } from '@mui/material'

const LICENSE_LIST_STYLE = {
  Wrapper: styled(Box)`
    display: flex;
    flex-wrap: wrap;
    justify-items: center;
    justify-content: center;
    column-gap: 20px;
    row-gap: 20px;
    margin: 65px 0 0 0;
  `
}

export { LICENSE_LIST_STYLE }
