import { getNoExponentValue } from '../no-exponent'
import { isNotDefined } from '../helpers'

const trimNumber = (value: number | string, digits = 2): string => {
  if (isNotDefined(value)) return '0'

  value = getNoExponentValue(value)

  const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${digits}})?`)
  const match = value.match(regex)

  return match === null
    ? value.replace(/\.+$/, '')
    : match[0].replace(/\.+$/, '')
}

export { trimNumber }
