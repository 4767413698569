import { GetPairPriceUseCase } from '../../stock'

import type { Stocks } from '@domain/common/enums'

import type { IPairRepository, IGetPairPriceUseCase } from '../../common'

interface IPayload {
  stock: Stocks
  repository: IPairRepository
}

const initGetPairPriceUseCase = (payload: IPayload): IGetPairPriceUseCase => {
  return new GetPairPriceUseCase(payload.repository)
}

export { initGetPairPriceUseCase }
