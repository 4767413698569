import type { IActionDetectPort, IDetectSettingRepository, IStartDetectUseCase } from '../../interface'

class StopDetectUseCase implements IStartDetectUseCase {

  private _repository: IDetectSettingRepository

  constructor (repository: IDetectSettingRepository) {
    this._repository = repository
  }

  public async execute (port: IActionDetectPort): Promise<boolean> {
    for (const id of port.ids) {
      await this._repository.stopDetect({ id })
    }

    return true
  }

}

export { StopDetectUseCase }
