import {
  Box, Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled,
} from '@mui/material'

import { useState } from 'react'

import { useTranslation } from 'react-i18next'

import { ReactComponent as OfflineIcon } from '@app/asset/icon/global/offline.svg'
import { useResizePlatform } from '@app/pages/trade/components/trade-window/use-resize-platform'

interface IPropsModalInternetConnection {
  isOpen: boolean
  setIsOpen(value: boolean): void
}

/* eslint-disable @typescript-eslint/no-magic-numbers */
const ModalInternetConnectionError = ({ isOpen, setIsOpen }: IPropsModalInternetConnection): JSX.Element => {
  const { isMobile, isLandscape } = useResizePlatform()
  const { t } = useTranslation()
  const [isFetch, setIsFetch] = useState(false)

  const handleClickButton = (): void => {
    setIsFetch(true)

    setTimeout(() => {
      setIsFetch(false)

      if (navigator.onLine) {
        setIsOpen(false)
      }
    }, 2000)
  }

  return (
    <Dialog
      open={ isOpen }
      sx={{
        '.MuiPaper-root': {
          maxWidth: '700px'
        }
      }}
    >
      <Component.Icon isMobile={ isMobile }><OfflineIcon /></Component.Icon>

      <Component.Content isMobile={ isMobile }>
        <DialogTitle sx={{ justifyContent: 'center', fontSize: isMobile ? '16px' : '18px' }}>
          { t('internet-status-modal/title') }
        </DialogTitle>

        <DialogContent sx={{
          padding: isLandscape
            ? isMobile
              ? '0px 30px'
              : '0px 130px'
            : '0px 30px' }}
        >
          <DialogContentText sx={{ fontSize: isMobile ? '12px' : '14px' }}>
            { t('internet-status-modal/content') }
          </DialogContentText>
        </DialogContent>

        <Button
          onClick={ handleClickButton }
          disabled={ isFetch }
          sx={{
            fontSize: isMobile ? '12px' : '14px',
            margin: isMobile ? '20px 0 40px' : '43px 0 82px',
            padding: isMobile ? '9px 38px !important' : '13px 49px !important',
          }}
        >
          { isFetch && (
            <CircularProgress
              size={ 15 }
              sx={{ position: 'absolute', left: isMobile ? '15px' : '20px' }}
            />
          ) }

          { t('internet-status-modal/button') }
        </Button>

      </Component.Content>
    </Dialog>
  )
}

const Component = {
  Icon: styled(Box)<{ isMobile: boolean }>`
    display: flex;
    justify-content: center;
    svg {
      height: ${({ isMobile }): string => isMobile ? '48px' : '56px'};
      margin-top: ${({ isMobile }): string => isMobile ? '20px' : '62px'};
      fill: ${({ theme }): string => theme.palette.mode === 'light' ? '#000' : '#fff'}
    }
  `,
  Content: styled(Box)<{ isMobile: boolean }>`
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
  `
}

export { ModalInternetConnectionError }
