enum KeyMoseSingle {
  LKM = 'lkm',
  MKM = 'mkm',
  RKM = 'rkm'
}
enum KeyMoseDouble {
  D_LKM = 'd_lkm',
  D_MKM = 'd_mkm',
  D_RKM = 'd_rkm',
}

const DOUBLE_MOSE: Record<KeyMoseSingle, KeyMoseDouble> = {
  [KeyMoseSingle.LKM]: KeyMoseDouble.D_LKM,
  [KeyMoseSingle.MKM]: KeyMoseDouble.D_MKM,
  [KeyMoseSingle.RKM]: KeyMoseDouble.D_RKM
}

const COMPARISON_DOUBLE_MOSE: Record<KeyMoseDouble, KeyMoseDouble> = {
  [KeyMoseDouble.D_LKM]: KeyMoseDouble.D_LKM,
  [KeyMoseDouble.D_MKM]: KeyMoseDouble.D_MKM,
  [KeyMoseDouble.D_RKM]: KeyMoseDouble.D_RKM
}

const SINGLE_MOSE: Record<KeyMoseSingle, KeyMoseSingle> = {
  [KeyMoseSingle.LKM]: KeyMoseSingle.LKM,
  [KeyMoseSingle.MKM]: KeyMoseSingle.MKM,
  [KeyMoseSingle.RKM]: KeyMoseSingle.RKM
}

const COMPARISON_MOUSE: IComparisonMouse[] = [
  { key: '0', type: KeyMoseSingle.LKM },
  { key: '1', type: KeyMoseSingle.MKM },
  { key: '2', type: KeyMoseSingle.RKM }
]

const TRANSFORM_REQUEST_MOUSE: Record<KeyMoseSingle | KeyMoseDouble, string[]> = {
  [KeyMoseSingle.LKM]: ['0'],
  [KeyMoseSingle.MKM]: ['1'],
  [KeyMoseSingle.RKM]: ['2'],
  [KeyMoseDouble.D_LKM]: ['0', '0'],
  [KeyMoseDouble.D_MKM]: ['1', '1'],
  [KeyMoseDouble.D_RKM]: ['2', '2']
}

const KEY_MOUSE = {
  [KeyMoseSingle.LKM]: 'LMouseButton',
  [KeyMoseSingle.MKM]: 'AMouseButton',
  [KeyMoseSingle.RKM]: 'RMouseButton',
  [KeyMoseDouble.D_LKM]: 'DoubleLKM',
  [KeyMoseDouble.D_MKM]: 'DoubleMKM',
  [KeyMoseDouble.D_RKM]: 'DoubleRKM'
}

interface IComparisonMouse {
  key: string
  type: KeyMoseSingle
}

export type { IComparisonMouse }

export {
  KEY_MOUSE,
  COMPARISON_MOUSE,
  DOUBLE_MOSE,
  SINGLE_MOSE,
  COMPARISON_DOUBLE_MOSE,
  TRANSFORM_REQUEST_MOUSE,
  KeyMoseSingle,
  KeyMoseDouble
}
