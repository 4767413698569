import { SettingsAxisType } from '../enums'

import type { IAxisDto } from '../interface'

const INIT_STATE_AXIS: IAxisDto = {
  showTags: true,
  showLeftAxis: true,
  showTagLeftAxis: true,
  showRightAxis: true,
  showTagRightAxis: true,
  leftAxisType: SettingsAxisType.PERCENT,
  leftTagAxisType: SettingsAxisType.PERCENT,
  rightAxisType: SettingsAxisType.PRICE,
  rightTagAxisType: SettingsAxisType.PRICE,
}

export { INIT_STATE_AXIS }
