import type { IDetectSettingDTO, IGetDetectListUseCase, IDetectSettingRepository } from '../../interface'

class GetDetectListUseCase implements IGetDetectListUseCase {

  private _repository: IDetectSettingRepository

  constructor (repository: IDetectSettingRepository) {
    this._repository = repository
  }

  public async execute (): Promise<IDetectSettingDTO[]> {
    return this._repository.getDetectList()
  }

}

export { GetDetectListUseCase }
