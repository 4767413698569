import { utils, writeFile } from 'xlsx'

import type { WorkSheet, WorkBook } from 'xlsx'

import type { IXlsxConfig, IXlsxService } from './interfaces'

class XlsxService implements IXlsxService {

  private _workSheet: WorkSheet | null

  private _workBook: WorkBook | null

  private _rows: Record<string, string | undefined>[] | null

  private _config: IXlsxConfig | null

  constructor () {
    this._config = null
    this._rows = null
    this._workSheet = null
    this._workBook = null
  }

  public generate (rows: Record<string, string | undefined>[], config: IXlsxConfig): void {
    this._config = config
    this._rows = rows
    this._generateWorkSheet()
    this._generateWorkBook()
    if (this._workBook && this._workSheet)
      utils.book_append_sheet(this._workBook, this._workSheet, this._config.sheetName)
  }

  public download (): void {
    if (this._workBook && this._config)
      writeFile(this._workBook, `${this._config.fileName}.xlsx`, { compression: true })
  }

  private _generateWorkSheet (): void {
    if (this._rows && this._config) {
      this._workSheet = utils.json_to_sheet(this._rows)
      utils.sheet_add_aoa(this._workSheet, [[...this._config.columnNames]], {
        origin: 'A1',
      })
    }
  }

  private _generateWorkBook (): void {
    this._workBook = utils.book_new()
  }

}

export { XlsxService }
