import { useEffect, useState } from 'react'

import { INIT_STATE_CONTENT } from '@domain/setting-profile/content'

import { useAuthContext } from '@app/common/auth'

import { useGetContent } from '@app/common/settings/content/request'

import type { IContentDto } from '@domain/setting-profile/content'
import type { IContentResult } from '@app/common/settings/content/interface'

const useContent = (): IContentResult => {
  const [content, setContent] = useState<IContentDto>(INIT_STATE_CONTENT)
  const [isLoadedContent, setIsLoadedContent] = useState<boolean>(false)

  const { refetch } = useGetContent(setContent, setIsLoadedContent)
  const { isAuth } = useAuthContext()

  useEffect(() => {
    if (isAuth) void refetch()
    else setContent(INIT_STATE_CONTENT)
  }, [isAuth])

  return {
    content,
    setContent,
    isLoadedContent,
    setIsLoadedContent,
    refetch
  }
}

export { useContent }
