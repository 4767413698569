import { ExceptionService } from '@domain/common/services'
import { InternalCode } from '@domain/common/enums'

import type {
  IDealErrorsIds,
  IDealPanicUseCase,
  IDealRepository,
  IDealsCancelPort
} from '@domain/stocks/interfaces/deal'

class BinanceDealPanicUseCase implements IDealPanicUseCase {

  private _repository: IDealRepository

  constructor (repository: IDealRepository) {
    this._repository = repository
  }

  public async execute (port: IDealsCancelPort): Promise<boolean> {
    this._throwErrors(port)

    const deals = port.deals.map((item) => {
      return {
        symbol: item.symbol,
        ids: item.dealsIds
      }
    })

    for (const deal of deals) {
      await this._repository.panicDeal(deal)
    }

    return true
  }

  private _throwErrors (port: IDealsCancelPort): void {
    const errors = this._validatePort(port)

    if (Object.keys(errors).length) {
      throw ExceptionService.new({
        status: {
          code: InternalCode.VALIDATION_ERROR,
          message: `${this.constructor.name} validation port error`
        },
        data: errors
      })
    }
  }

  private _validatePort (port: IDealsCancelPort): IDealErrorsIds {
    const errors: IDealErrorsIds = {}

    port.deals.forEach((item) => {
      item.dealsIds.forEach((id) => {
        if (typeof id !== 'number') errors.ids = InternalCode.PROPERTY_IS_INVALID
      })
    })

    return errors
  }

}

export { BinanceDealPanicUseCase }
