import type { IApiKeyListDTO, IApiKeyRepository, IGetApiKeyListUseCase } from '../../../../common'

abstract class GetApiKeyListUseCase implements IGetApiKeyListUseCase {

  protected repository: IApiKeyRepository

  constructor (repository: IApiKeyRepository) {
    this.repository = repository
  }

  public abstract execute (): Promise<IApiKeyListDTO>

}

export { GetApiKeyListUseCase }
