import { useCallback, useEffect } from 'react'

import { useAuthContext } from '@app/common/auth'
import { useTradeContext } from '@app/pages/trade/trade-context'
import { useInternetConnectionContext } from '@app/components/tools/components/internet-connection'
import { useDealContext } from '@app/pages/trade/trade-tables/tables/deal-table/_context'
import { useEventContext, EventList } from '@app/services/event'

import { useGetOpenOrderListRequest } from '@app/pages/trade/requests/use-order-list-request'
import { useGetDealListRequest } from '@app/pages/trade/trade-tables/tables/deal-table/request/use-get-deals'
import { useGetLastProfitRequest } from '@app/pages/trade/trade-tables/tables/report-table/requests'

import { OrderStatus, OrderSource } from '@domain/stocks/order'

import { OrderDealSyncManager } from './sync'

import type { IDealsPairObserverPayload } from '@app/pages/trade/trade-controller/observers'

import type { Stocks } from '@domain/common/enums'

import type { IDealDTO, IClosedDealDTO } from '@domain/stocks/interfaces/deal'
import type { IOrderDTO } from '@domain/stocks/order'
import type { IPairDTO } from '@domain/stocks/pair'
import type { IHistoryOrderDTO } from '@domain/stocks/account-trade-history'

const syncManager = new OrderDealSyncManager()

/* eslint-disable @typescript-eslint/no-magic-numbers */
const useOrdersAndDeals = (): void => {
  const { status } = useInternetConnectionContext()
  const { stock, user } = useAuthContext()
  const { orderList, closedOrders, setOrderList, setClosedOrders, PairEntity, isPairLoaded } = useTradeContext()
  const { deals, closedDeals, setDeals, setClosedDeals } = useDealContext()
  const { notify } = useEventContext()

  const { mutateAsync: getOrderList } = useGetOpenOrderListRequest()
  const { mutateAsync: getDealList } = useGetDealListRequest()
  const { mutateAsync: getLastProfit } = useGetLastProfitRequest()

  const request = async (_stock: Stocks, list: IPairDTO[]): Promise<void> => {
    const dealListResponse = await getDealList({ stock: _stock, list })
    const orderListResponse = await getOrderList({ stock: _stock, list })

    const takeProfitOrderList = dealListResponse
      .map((item) => transformApiOrder(item, 'takeProfitOrder'))
      .filter((item) => item.takeProfit.isInclude)

    const stopLossOrderList = dealListResponse
      .map((item) => transformApiOrder(item, 'stopLossOrder'))
      .filter((item) => item.stopLoss.isInclude)

    dealListResponse.forEach((item) => {
      void notify({ name: EventList.UPDATE_DEALS_PAIR, data: { action: 'add', pair: item.pair }})
    })

    setDeals(dealListResponse)
    setOrderList([...takeProfitOrderList, ...stopLossOrderList, ...orderListResponse])
  }

  const onNotifyDealsPair = useCallback((value: IDealsPairObserverPayload) => {
    void notify({ name: EventList.UPDATE_DEALS_PAIR, data: value })
  }, [notify])

  const onUpdateOrderList = useCallback((value: IOrderDTO[]): void => {
    setOrderList(() => [...value])
  }, [setOrderList])

  const onUpdateClosedOrderList = useCallback((value: IHistoryOrderDTO[]): void => {
    setClosedOrders(() => [...value])
  }, [setClosedOrders])

  const onUpdateDealList = useCallback((value: IDealDTO[]): void => {
    setDeals(() => [...value])
  }, [setDeals])

  const onUpdateClosedDealList = useCallback((value: IClosedDealDTO[]): void => {
    setClosedDeals(() => [...value])
  }, [setClosedDeals])

  const onCompleteDeal = useCallback((): void => {
    void getLastProfit({ list: PairEntity.list, symbol: PairEntity.pair.ticker.symbol })
  }, [PairEntity.list, PairEntity.pair.ticker.symbol, getLastProfit])

  syncManager.setEvents({
    onUpdateOrderList: onUpdateOrderList,
    onUpdateClosedOrderList: onUpdateClosedOrderList,
    onUpdateDealList: onUpdateDealList,
    onUpdateClosedDealList: onUpdateClosedDealList,
    onNotifyDealsPair: onNotifyDealsPair,
    onCompleteDeal: onCompleteDeal
  })

  useEffect(() => {
    syncManager.setOrderList(orderList)
  }, [orderList])

  useEffect(() => {
    syncManager.setClosedOrderList(closedOrders)
  }, [closedOrders])

  useEffect(() => {
    syncManager.setDealList(deals)
  }, [deals])

  useEffect(() => {
    syncManager.setClosedDealList(closedDeals)
  }, [closedDeals])

  useEffect(() => {
    if (status === 'online' && user && isPairLoaded) syncManager.subscribe(stock, user, PairEntity.list)

    return () => {
      syncManager.unsubscribe()
    }
  }, [stock, user, status, PairEntity.list, isPairLoaded])

  useEffect(() => {
    if (status === 'online' && isPairLoaded) {
      request(stock, PairEntity.list)
        .catch(() => {
          setDeals([])
          setOrderList([])
        })
    }
  }, [stock, status, PairEntity.list, isPairLoaded])

  useEffect(() => {
    if (status === 'online' && isPairLoaded) {
      void getLastProfit({ list: PairEntity.list, symbol: PairEntity.pair.ticker.symbol })
    }
  }, [status, PairEntity.list, isPairLoaded, getLastProfit, PairEntity.pair.ticker.symbol])
}

const transformApiOrder = (value: IDealDTO, target: 'takeProfitOrder' | 'stopLossOrder'): IOrderDTO => {
  return {
    id: value[target].id.toString(),
    clientOrderId: '',
    dealId: value.id,
    pair: value.pair,
    status: OrderStatus.NEW,
    tradeType: '',
    source: OrderSource.VIRTUAL,
    type: value[target].type,
    side: value[target].side,
    amount: value[target].amount,
    executedAmount: value[target].executedAmount,
    icebergAmount: value[target].icebergAmount,
    price: value[target].price,
    stopPrice: value[target].price,
    createdDate: value[target].date,
    updatedDate: value[target].date,
    completePercent: value[target].completePercent,
    takeProfit: { isInclude: value.takeProfitOrder.isInclude, percent: value.takeProfitOrder.percent },
    stopLoss: { isInclude: value.stopLossOrder.isInclude, percent: value.stopLossOrder.percent }
  }
}

export { useOrdersAndDeals }
