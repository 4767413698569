import type { IPairDTO } from '../interfaces'

const DEFAULT_PAIR: IPairDTO = {
  ticker: { symbol: 'BTCUSDT', slash: 'BTC/USDT', under: 'BTC_USDT' },
  mainAsset: { ticker: 'BTC', digits: 8 },
  lastAsset: { ticker: 'USDT', digits: 2 },
  limits: {
    limit: {
      price: { min: '0.01000000', max: '1000000.00000000', step: '0.01000000' },
      main: { min: '0.00001000', max: '9000.00000000', step: '0.00001000' },
      last: { min: '10.00000000' }
    },
    market: {
      price: { min: '0.01000000', max: '1000000.00000000', step: '0.01000000' },
      main: { min: '0.00001000', max: '9000.00000000', step: '0.00001000' },
      last: { min: '10.00000000' }
    }
  }
}

export { DEFAULT_PAIR }
