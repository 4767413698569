export enum LocalStorageValues {
  PAIR = '__pair',
  LANGUAGE = '__language',
  THEME_MODE = '__theme-mode',
  COOKIES_ACCEPT = '__cookies_accept',
  // TODO: хранится будет на бэке, потом выпилить
  STOCK = '__stock',
  CANDLE_INTERVAL = '__candle-interval',
  TERMINAL_POSITION = '__terminal-position',
  TRADE_ACTIVE_TAB = '__trade-active-tab',
  TRADE_TABLE_STORAGE = '__trade-table-storage',

  CHART_DRAWINGS = '__chart__drawing'
}
