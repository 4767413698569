import { BaseLicenseUseCase } from '@domain/license/common/use-case'

import type { IGetActiveUserLicenseUseCase, IUserLicenseDTO } from '@domain/license'

class GetCurrentUserLicenseUseCase extends BaseLicenseUseCase implements IGetActiveUserLicenseUseCase {

  public async execute (): Promise<IUserLicenseDTO | null> {
    return this._repository.getCurrentUserLicense()
  }

}

export { GetCurrentUserLicenseUseCase }
