import { Stocks } from '@domain/common/enums'

import { createConnection } from '../create'

const STOCK_CONNECTION = {
  [Stocks.BINANCE]: createConnection('REACT_APP_BINANCE_MAINNET_CENTRIFUGE_WSS_DOMAIN')
}

const connectCentrifuge = (): void => {
  Object.values(STOCK_CONNECTION).forEach((item) => {
    item.connect()
  })
}
const disconnectCentrifuge = (): void => {
  Object.values(STOCK_CONNECTION).forEach((item) => {
    item.disconnect()
  })
}

connectCentrifuge()

export { connectCentrifuge, disconnectCentrifuge, STOCK_CONNECTION }
