import { CookieService } from '@data/common/services'
import { CookieValues } from '@data/common/enums'

import type { Dispatch, SetStateAction } from 'react'

/* eslint-disable @typescript-eslint/no-magic-numbers */

type Setter = Dispatch<SetStateAction<boolean>>

class AppInstallHandler {

  private _isInstall = true

  private _event: Event | undefined = undefined

  private _setter: Setter | undefined = undefined

  constructor () {
    this._register()
  }

  public checkInstall (setter: Setter): boolean {
    try {
      if (CookieService.get<boolean>(CookieValues.PROMPT_APP_INSTALL)) {
        this._isInstall = true
      }
    } catch (e) {
      //
    }
    this._setter = setter
    return this._isInstall
  }

  public async showPrompt (): Promise<void> {
    if (this._event) {
      this._event.prompt()
      const { outcome } = await this._event.userChoice
      if (outcome === 'dismissed') this.registerCookie()
      if (outcome === 'accepted' && this._setter) {
        this._isInstall = true
        this._setter(true)
      }
    }
  }

  public registerCookie (): void {
    CookieService.set(CookieValues.PROMPT_APP_INSTALL, true, {
      expires: new Date(Date.now() + 86400000).toUTCString()
    })
    if (this._setter) this._setter(true)
  }

  private _register (): void {
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault()
      this._isInstall = false
      this._event = event
    })
  }

}

export { AppInstallHandler }
