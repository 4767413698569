import { forwardRef } from 'react'

import {
  Link as RouterLink,
} from 'react-router-dom'

import { styled } from '@mui/material'

import type { LinkProps, Theme } from '@mui/material'

import type { Components } from '@mui/material/styles/components'

import type {
  LinkProps as RouterLinkProps } from 'react-router-dom'

// eslint-disable-next-line react/display-name
const LinkBehavior = forwardRef<
HTMLAnchorElement,
Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props
  // Map href (MUI) -> to (react-router)
  return (
    <RouterLink
      ref={ ref }
      to={ href }
      { ...other }
    />
  )
})

const LINK_DEFAULT_STYLES: Components<Theme>['MuiLink'] = {
  defaultProps: { component: LinkBehavior } as LinkProps,
  styleOverrides: {
    root: ({ theme }) => ({
      fontWeight: 600,
      textDecoration: 'none',
      fontSize: 16,
      transition: theme.transitions.create(['border', 'color']),
      borderBottom: '1px dashed transparent',

      '&:hover': {
        borderBottom: `1px dashed ${theme.palette.primary.main}`
      },

      '&:disabled': {
        color: 'rgba(0,0,0,0.24)'
      }
    })
  }
}

const Link = styled(RouterLink)`
  font-weight: 600;
  text-decoration: none;
  font-size: 16px;
  transition: ${({ theme }): string => theme.transitions.create(['border', 'color'])};
  border-bottom: 1px dashed transparent;

  '&:hover': {
    border-bottom: ${({ theme }): string => `1px dashed ${theme.palette.primary.main}`};
  }

  '&:disabled': {
    color: rgba(0,0,0,0.24);
  }
`

export { LinkBehavior, LINK_DEFAULT_STYLES, Link }
