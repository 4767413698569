import { createContext, useContext, useEffect, useMemo, useState } from 'react'

import { ModalInternetConnectionError } from '@app/components/tools/components/internet-connection/component'

import { useObserveInternetConnection } from './observer'

import type { PropsWithChildren } from 'react'

import type { DepsCreator } from './observer'

type AddDependenciesFunction = (id: number, deps: DepsCreator) => void
type RemoveFunction = (id: number) => void

interface IInternetConnectionContext {
  addNewDependencies: AddDependenciesFunction
  removeDependencies: RemoveFunction
  status: 'online' | 'offline'
}

const InternetConnectionContext = createContext<IInternetConnectionContext>({
  addNewDependencies: () => {
    //
  },
  removeDependencies: () => {
    //
  },
  status: 'online'
})

const useInternetConnectionContext = (): IInternetConnectionContext => useContext(InternetConnectionContext)

const InternetConnectionProvider = ({ children }: PropsWithChildren): JSX.Element => {
  const [deps, setDeps] = useState<Record<number, DepsCreator>>({})

  const { status } = useObserveInternetConnection({
    externalDeps: Object.values(deps).flat(),
  })

  const [isOpenModal, setIsOpenModal] = useState(status === 'offline')

  useEffect(() => {
    if (status === 'offline') setIsOpenModal(true)
    if (status === 'online') setIsOpenModal(false)
  }, [status])

  const addNewDependencies = (id: number, newDeps: DepsCreator): void => {
    setDeps((val) => ({ ...val, [id]: newDeps }))
  }

  const removeDependencies = (id: number): void => {
    setDeps((val) => {
      delete val[id]
      return val
    })
  }

  const context = useMemo(() => ({
    addNewDependencies,
    removeDependencies,
    status
  }), [status])

  return (
    <InternetConnectionContext.Provider value={ context }>
      <ModalInternetConnectionError isOpen={ isOpenModal } setIsOpen={ setIsOpenModal } />

      { children }
    </InternetConnectionContext.Provider>
  )
}

export { InternetConnectionProvider, useInternetConnectionContext }
