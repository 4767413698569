enum HotKeyEvents {
  ORDER_BUY = 'buy_order',
  ORDER_SALE = 'sale_order',
  ORDER_CANCEL = 'cancel_order',
  ORDER_CANCEL_ALL = 'cancel_all_orders',
  ORDER_SIZE = 'change_order_size',

  DEALS_PANIC = 'panic_deals',
  DEALS_DIVISION = 'division_deals',
  DEALS_CANCEL = 'cancel_deals',
  DEALS_UNION = 'union_deals',
  DEALS_ZERO_PROFIT = 'zero_profit',

  SCREEN_TAB = 'tab_screen',
  SCREEN_SAVE = 'save_screen',
  SCREEN_COPY = 'copy_screen',

  MOVE_TP_DEAL = 'move_tp_order',
  MOVE_SL_DEAL = 'move_sl_order',
  ALTERNATIVE_MOVE_TP_DEAL = 'alternative_move_tp_order',
  ALTERNATIVE_MOVE_SL_DEAL = 'alternative_move_sl_order',

  MOVE_BUY_ORDER = 'move_buy_order',
  MOVE_SALE_ORDER = 'move_sale_order',
  ALTERNATIVE_MOVE_BUY_ORDER = 'alternative_move_buy_order',
  ALTERNATIVE_MOVE_SELL_ORDER = 'alternative_move_sale_order',

  CHANGE_SCALE = 'change_scale',

  HORISONTAL_VOLUMES = 'horizontal_volumes',
  VERTICAL_VOLUMES = 'vertical_volumes',
}

enum HotKeyEventsInclude {
  ORDER_BUY = 'buy_order_is_include',
  ORDER_SALE = 'sale_order_is_include',
  ORDER_CANCEL = 'cancel_order_is_include',
  ORDER_CANCEL_ALL = 'cancel_all_orders_is_include',
  ORDER_SIZE = 'change_order_size_is_include',

  DEALS_PANIC = 'panic_deals_is_include',
  DEALS_DIVISION = 'division_deals_is_include',
  DEALS_CANCEL = 'cancel_deals_is_include',
  DEALS_UNION = 'union_deals_is_include',
  DEALS_ZERO_PROFIT = 'zero_profit_is_include',

  SCREEN_TAB = 'tab_screen_is_include',
  SCREEN_SAVE = 'save_screen_is_include',
  SCREEN_COPY = 'copy_screen_is_include',

  MOVE_TP_DEAL = 'move_tp_order_is_include',
  MOVE_SL_DEAL = 'move_sl_order_is_include',
  ALTERNATIVE_MOVE_TP_DEAL = 'alternative_move_tp_order_is_include',
  ALTERNATIVE_MOVE_SL_DEAL = 'alternative_move_sl_order_is_include',

  MOVE_BUY_ORDER = 'move_buy_order_is_include',
  MOVE_SALE_ORDER = 'move_sale_order_is_include',
  ALTERNATIVE_MOVE_BUY_ORDER = 'alternative_move_buy_order_is_include',
  ALTERNATIVE_MOVE_SELL_ORDER = 'alternative_move_sale_order_is_include',

  CHANGE_SCALE = 'change_scale_is_include',

  HORISONTAL_VOLUMES = 'horizontal_volumes_is_include',
  VERTICAL_VOLUMES = 'vertical_volumes_is_include',
}

enum HotKeyEventsOption {
  ORDER_BUY = 'buy_order_option',
  ORDER_SALE = 'sale_order_option',
  ORDER_CANCEL = 'cancel_order_option',
  ORDER_CANCEL_ALL = 'cancel_all_orders_option',
  ORDER_SIZE = 'change_order_size_option',

  DEALS_PANIC = 'panic_deals_option',
  DEALS_DIVISION = 'division_deals_option',
  DEALS_CANCEL = 'cancel_deals_option',
  DEALS_UNION = 'union_deals_option',
  DEALS_ZERO_PROFIT = 'zero_profit_option',

  SCREEN_TAB = 'tab_screen_option',
  SCREEN_SAVE = 'save_screen_option',
  SCREEN_COPY = 'copy_screen_option',

  MOVE_BUY_ORDER = 'move_buy_order_option',
  MOVE_SALE_ORDER = 'move_sale_order_option',
  ALTERNATIVE_MOVE_BUY_ORDER = 'alternative_move_buy_order_option',
  ALTERNATIVE_MOVE_SELL_ORDER = 'alternative_move_sale_order_option',

  MOVE_TP_DEAL = 'move_tp_order_option',
  MOVE_SL_DEAL = 'move_sl_order_option',
  ALTERNATIVE_MOVE_TP_DEAL = 'alternative_move_tp_order_option',
  ALTERNATIVE_MOVE_SL_DEAL = 'alternative_move_sl_order_option',

  CHANGE_SCALE = 'change_scale_option',

  HORISONTAL_VOLUMES = 'horizontal_volumes_option',
  VERTICAL_VOLUMES = 'vertical_volumes_option',
}

export { HotKeyEvents, HotKeyEventsInclude, HotKeyEventsOption }
