import type {
  IPairPriceDTO,
  IGetPairPricePort,
  IGetPairPriceUseCase,
  IPairRepository
} from '../../../../common'

class GetPairPriceUseCase implements IGetPairPriceUseCase {

  protected repository: IPairRepository

  constructor (repository: IPairRepository) {
    this.repository = repository
  }

  public async execute (port: IGetPairPricePort): Promise<IPairPriceDTO> {
    return this.repository.getPairPrice(port)
  }

}

export { GetPairPriceUseCase }
