import { Stocks } from '@domain/common/enums'

import { DealRepository } from '@data/repository/stocks/controllers/deal'
import { BinanceDealUpdateUseCase } from '@domain/stocks/binance/deal'

import { DealValidation } from '@domain/stocks/binance/deal/use-case/update-use-case/validation'

import type { IDealUpdateUseCase } from '@domain/stocks/interfaces/deal'

class DealUpdateUseCase {

  private _validation = new DealValidation()

  private readonly _useCases: Record<Stocks, IDealUpdateUseCase> = {
    [Stocks.BINANCE]: new BinanceDealUpdateUseCase(DealRepository.new(Stocks.BINANCE), this._validation),
  }

  public static new (stock: Stocks): IDealUpdateUseCase {
    return new DealUpdateUseCase()._useCases[stock]
  }

}

export { DealUpdateUseCase }
