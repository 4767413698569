import { ValidationCode } from '@domain/common/enums'

import { AbstractValidator } from '@domain/common/utils/validators/abstract-validator'

import { EXCEPTED_KEY } from '@app/pages/profile/settings/keyboard/components/_setting'

import type { IValidationError } from '@domain/common/utils/validators'

class ExceptedValidationHotkey extends AbstractValidator {

  private _excepted: string[][]

  constructor (excepted: string[][]) {
    super()
    this._excepted = [...EXCEPTED_KEY, ...excepted]
  }

  public override validate (value: string[]): IValidationError {
    if (value.length === 0) return super.validate(value)

    if (this._validate(value)) {
      return {
        isSuccess: false,
        errors: {
          code: ValidationCode.INVALID_EXCEPTED_VALUE
        }
      }
    }
    return super.validate(value)
  }

  private _validate (value: string[]): boolean {
    for (const exceptions of this._excepted) {
      let count = 0
      value.some((vItem, vIndex) => {
        if (exceptions[0] === vItem) {
          exceptions.forEach((excepted, eIndex) => {
            if (excepted === value[vIndex + eIndex]) count += 1
          })
          return true
        }
        return false
      })
      if (count === exceptions.length) return true
    }
    return false
  }

}

export { ExceptedValidationHotkey }
