import type { IGetDiscountCommissionUseCase, ICommissionRepository } from '@domain/stocks/interfaces/commission'

class GetBinanceDiscountCommissionUseCase implements IGetDiscountCommissionUseCase {

  private readonly _repository: ICommissionRepository

  constructor (repository: ICommissionRepository) {
    this._repository = repository
  }

  public async execute (): Promise<string> {
    return this._repository.getDiscount()
  }

}

export { GetBinanceDiscountCommissionUseCase }
