import { CentrifugeService, STOCK_CONNECTION } from '@data/common/services'

import Intervals from '@data/common/enums/intervals'

import { MarketOverviewBySymbolSocket } from '../../_'

import type { IBinanceMarketOverviewSocketResponse, IBinanceMarketOverviewSocketError } from '../interfaces'
import type { IMarketOverviewBySymbolSocket } from '../../../interfaces'

import type { IPairDTO } from '@domain/stocks/pair'
import type { Stocks } from '@domain/common/enums'
import type { IMarketOverviewDTO } from '@domain/stocks/market-overview'

type IntervalTypes = Exclude<Intervals, Intervals.D1>

class BinanceMarketOverviewBySymbolSocket
  extends MarketOverviewBySymbolSocket<IBinanceMarketOverviewSocketResponse>
  implements IMarketOverviewBySymbolSocket {

  constructor () {
    super('marketWindow')
  }

  public override subscribe (pair: IPairDTO, stock: Stocks): void {
    this.pair = pair
    this.socket = new CentrifugeService(`${this.channel}_${pair.ticker.symbol}`, STOCK_CONNECTION[stock])
  }

  public override onMessage (callback: (message: IMarketOverviewDTO) => void): void {
    this.socket?.onMessage((data) => {
      if (this.pair === null || this._isError(data)) return

      const row: Partial<IMarketOverviewDTO> = {
        id: this.pair.ticker.slash,
        pair: this.pair,
        price: data.price,
        priceChangePercent_1hOKA: data.quoteVolume1h,
        priceChangePercent_24hOKA: data.quoteVolume24h
      }

      data.intervals.forEach((elem) => {
        Object.values(Intervals).forEach((interval) => {
          if (elem.interval === interval) {
            if (elem.interval === Intervals.D1) interval = Intervals.H24

            row[`priceChangePercent_o${interval as IntervalTypes}`] = elem.changePricePercent
            row[`priceChangePercent_${interval as IntervalTypes}`] = elem.delta

            if (interval === Intervals.H24) {
              row.priceChangePercent_24hPick = elem.highest
            }

            if (interval === Intervals.H1) {
              row.priceChangePercent_1hPick = elem.highest
            }
          }
        })
      })

      callback({ ...row as IMarketOverviewDTO })
    })
  }

  private _isError (data: IBinanceMarketOverviewSocketResponse): data is IBinanceMarketOverviewSocketError {
    return 'error' in data
  }

}

export { BinanceMarketOverviewBySymbolSocket }
