import { createContext, useContext, useEffect, useMemo, useState } from 'react'

import { DEFAULT_PAIR } from '@domain/stocks/pair'

import { useWatch } from '@app/services/utils'

import { TradeTypes } from '@domain/common/enums'

import { LocalStorageValues } from '@data/common/enums'

import { LocalStorageService } from '@data/common/services'

import { type IUserPairListEntity, type IUserPairListDTO, UserPairListType } from '@domain/user-pair-list'

import type { GridApiCommunity } from '@mui/x-data-grid/internals'

import type { PartialRecord } from '@domain/common/utils'

import type { IUserProfitEntity, IUserProfitDTO } from '@domain/user-profit'

import type { ICommissionDTO, ICommissionEntity } from '@domain/stocks/interfaces/commission'
import type { IOrderDTO } from '@domain/stocks/order'
import type { IAccountEntity, IBalance } from '@domain/stocks/account'
import type { IHistoryOrderDTO } from '@domain/stocks/account-trade-history'
import type { IPairDTO, IPairEntity } from '@domain/stocks/pair'

import type { FC, PropsWithChildren, Dispatch, SetStateAction, MutableRefObject } from 'react'
/* eslint-disable */
interface ITradeContext {
  AccountEntity: IAccountEntity
  PairEntity: IPairEntity
  isPairLoaded: boolean
  CommissionEntity: ICommissionEntity
  UserPairListEntity: IUserPairListEntity
  UserProfitEntity: IUserProfitEntity
  activeTab: number
  activeTableRef: MutableRefObject<GridApiCommunity> | null
  orderList: IOrderDTO[]
  closedOrders: IHistoryOrderDTO[]
  ratesUSDT: PartialRecord<string, string>
  quotedCoinList: string[]
  setRatesUSDT: Dispatch<SetStateAction<PartialRecord<string, string>>>
  setActiveTab: Dispatch<SetStateAction<number>>
  setActiveTableRef: Dispatch<SetStateAction<MutableRefObject<GridApiCommunity> | null>>
  setOrderList: Dispatch<SetStateAction<IOrderDTO[]>>
  setAccountEntity (value: IAccountEntity): void
  setClosedOrders: Dispatch<SetStateAction<IHistoryOrderDTO[]>>
  setQuotedCoinList: Dispatch<SetStateAction<string[]>>
  setPairEntity (value: IPairEntity): void
  setIsPairLoaded(value: boolean): void
  setCommissionEntity (value: ICommissionEntity): void
  setUserPairListEntity (value: IUserPairListEntity): void
  setUserProfitEntity (value: IUserProfitEntity): void
}

interface InternalCommissionEntity extends ICommissionEntity {
  _discount: string
  _discountAsset: string
  _list: ICommissionDTO[]
}

interface InternalUserPairListEntity extends IUserPairListEntity {
  _list: IUserPairListDTO[]
  _whiteList: IUserPairListDTO
  _blackList: IUserPairListDTO
}

interface InternalUserProfitEntity extends IUserProfitEntity {
  _profit: IUserProfitDTO
}

const defaultValue: ITradeContext = {
  AccountEntity: {
    id: '',
    accountType: TradeTypes.SPOT,
    permission: [TradeTypes.SPOT],
    balance: [],
    updateBalance (): void {
      //
    },
    getBalanceByAsset (): IBalance | undefined {
      return undefined
    }
  },
  PairEntity: {
    pair: DEFAULT_PAIR,
    mainAsset: DEFAULT_PAIR.mainAsset,
    lastAsset: DEFAULT_PAIR.lastAsset,
    list: [],
    getPairByTicker (): IPairDTO | undefined {
      return undefined
    },
    async changePair (): Promise<void> {
      //
    },
    async clearPairList (): Promise<void> {
      //
    }
  },
  CommissionEntity: {
    discount: '0',
    discountAsset: '',
    commission: [],
    getCommissionByTicker (): ICommissionDTO | undefined {
      return undefined
    },
    getCommissionByTickerWithDiscount (): ICommissionDTO | undefined {
      return undefined
    },
    async clearCommission (): Promise<void> {
      //
    }
  },
  UserPairListEntity: {
    id: '',
    list: [],
    whiteList: { id: 2, type: UserPairListType.WHITE, name: '', pairs: []},
    blackList: { id: 1, type: UserPairListType.BLACK, name: '', pairs: []},
    
    isFavoritePair(): boolean {
      return false
    },
    isBlackListPair(): boolean {
      return false
    },
    isInListPair(): boolean {
      return false
    },

    findPairInAnyList (): number[] {
      return []
    },

    async addPairToWhiteList (): Promise<void> {
      //
    },
    async deletePairFromWhiteList (): Promise<void> {
      //
    },

    async addPairToBlackList (): Promise<void> {
      //
    },
    async deletePairFromBlackList (): Promise<void> {
      //
    },

    async addPairToList (): Promise<void> {
      //
    },
    async deletePairFromList (): Promise<void> {
      //
    },

    async addList (): Promise<void> {
      //
    },
    async deleteList (): Promise<void> {
      //
    }
  },
  UserProfitEntity: {
    id: '',
    profit: { all: { value: '', percent: ''}, today: { value: '', percent: ''}},
    async updateProfit (): Promise<void> {
      //
    }
  },
  isPairLoaded: false,
  activeTab: 0,
  orderList: [],
  closedOrders: [],
  quotedCoinList: [],
  ratesUSDT: {},
  activeTableRef: null,
  setRatesUSDT: (): void => {
    //
  },
  setActiveTab: (): void => {
    //
  },
  setIsPairLoaded: (): void => {
    //
  },
  setQuotedCoinList: (): void => {
    //
  },
  setAccountEntity: (): void => {
    //
  },
  setPairEntity: (): void => {
    //
  },
  setCommissionEntity: (): void => {
    //
  },
  setUserPairListEntity: (): void => {
    //
  },
  setUserProfitEntity: (): void => {
    //
  },
  setOrderList: (): void => {
    //
  },
  setClosedOrders: (): void => {
    //
  },
  setActiveTableRef: (): void => {
    //
  }
}

const TradeContext = createContext<ITradeContext>(defaultValue)

const useTradeContext = (): ITradeContext => useContext(TradeContext)

const TradeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [AccountEntity, setAccountEntity] = useState(defaultValue.AccountEntity)
  const [PairEntity, setPairEntity] = useState(defaultValue.PairEntity)
  const [CommissionEntity, setCommissionEntity] = useState(defaultValue.CommissionEntity)
  const [UserPairListEntity, setUserPairListEntity] = useState(defaultValue.UserPairListEntity)
  const [UserProfitEntity, setUserProfitEntity] = useState(defaultValue.UserProfitEntity)

  const [ratesUSDT, setRatesUSDT] = useState(defaultValue.ratesUSDT)
  const [activeTab, setActiveTab] = useState<number>(0)
  const [activeTableRef, setActiveTableRef] = useState<MutableRefObject<GridApiCommunity> | null>(null)
  const [orderList, setOrderList] = useState<ITradeContext['orderList']>([])
  const [closedOrders, setClosedOrders] = useState<IHistoryOrderDTO[]>([])
  const [quotedCoinList, setQuotedCoinList] = useState<string[]>([])
  const [isPairLoaded, setIsPairLoaded] = useState(false)

  useWatch(AccountEntity, ['balance', 'permission', 'accountType'])
  useWatch(PairEntity, ['pair', 'list'])
  useWatch<InternalCommissionEntity>(CommissionEntity as InternalCommissionEntity, ['_discount', '_list', '_discountAsset'])
  useWatch<InternalUserPairListEntity>(UserPairListEntity as InternalUserPairListEntity, ['_list', '_whiteList', '_blackList'])
  useWatch<InternalUserProfitEntity>(UserProfitEntity as InternalUserProfitEntity, ['_profit'])

  useEffect(() => {
    try {
      const activeTabStorage: number = LocalStorageService.get(LocalStorageValues.TRADE_ACTIVE_TAB)
      if (activeTabStorage) setActiveTab(activeTabStorage)
    } catch (e) {
      setActiveTab(0)
    }
  }, [])

  useEffect(() => {
    LocalStorageService.set(LocalStorageValues.TRADE_ACTIVE_TAB, activeTab)
  }, [activeTab])


  const context = useMemo(() => ({
    AccountEntity,
    PairEntity,
    CommissionEntity,
    UserPairListEntity,
    UserProfitEntity,
    activeTab,
    activeTableRef,
    orderList,
    quotedCoinList,
    ratesUSDT,
    closedOrders,
    isPairLoaded,

    setActiveTab,
    setActiveTableRef,
    setRatesUSDT,
    setAccountEntity,
    setQuotedCoinList,
    setClosedOrders,
    setPairEntity,
    setCommissionEntity,
    setUserPairListEntity,
    setUserProfitEntity,
    setOrderList,
    setIsPairLoaded
  }), [
    AccountEntity,
    AccountEntity.balance,
    AccountEntity.permission,
    AccountEntity.accountType,
    PairEntity,
    PairEntity.pair,
    PairEntity.list,
    CommissionEntity,
    CommissionEntity.discount,
    CommissionEntity.commission,
    UserPairListEntity,
    UserPairListEntity.list,
    UserPairListEntity.whiteList,
    UserPairListEntity.blackList,
    UserProfitEntity,
    UserProfitEntity.profit,
    activeTab,
    setActiveTab,
    orderList,
    ratesUSDT,
    setRatesUSDT,
    quotedCoinList,
    setQuotedCoinList,
    setAccountEntity,
    closedOrders,
    setClosedOrders,
    setPairEntity,
    setCommissionEntity,
    setUserPairListEntity,
    setUserProfitEntity,
    setOrderList,
    isPairLoaded,
    setIsPairLoaded,
    activeTableRef,
    setActiveTableRef
  ])

  return (
    <TradeContext.Provider value={ context }>
      { children }
    </TradeContext.Provider>
  )
}

export { TradeProvider, useTradeContext }
