/* eslint-disable import/no-named-as-default-member */
import pdfMake from 'pdfmake/build/pdfmake'

import { pdfFonts } from './fonts/vfs_fonts'

import type { TCreatedPdf } from 'pdfmake/build/pdfmake'

import type { IPdfService, IPdfConfig } from './interfaces'

class PdfService implements IPdfService {

  private _fileName = ''

  private _pdf: TCreatedPdf | null = null

  public generate (config: IPdfConfig): void {
    this._fileName = `${config.fileName}.pdf`

    pdfMake.fonts = {
      Inter: {
        normal: 'Inter-Regular.ttf',
        bold: 'Inter-Medium.ttf',
      }
    }
    pdfMake.vfs = pdfFonts

    this._pdf = pdfMake.createPdf({
      pageOrientation: config.orientation,
      content: config.content,
      styles: config.styles,
      defaultStyle: { font: 'Inter' }
    })
  }

  public download (): void {
    if (this._pdf) this._pdf.download(this._fileName)
  }

}

export { PdfService }
