import { HttpServiceV1 } from '@data/common/services'
import { HttpCode } from '@data/common/enums'
import { ExceptionService } from '@domain/common/services'
import { InternalCode } from '@domain/common/enums'
import { ApiKeyError } from '@domain/stocks/api-key'

import { ApiKeyRepository } from '../../_'
import { BinanceApiKeyRepositoryErrorCode } from '../enums'

import type { IBinanceKeyItem } from '../interfaces'
import type { IApiKeyHttpError } from '../../_'

import type { IHttpError } from '@data/common/interfaces'

import type {
  IApiKeyListDTO,
  IApiKeyRepository,
  ICreateApiKeyPort,
  IDeleteApiKeyPort,
  ISetActiveApiKeyPort
} from '@domain/stocks/api-key'

class BinanceApiKeyRepository extends ApiKeyRepository implements IApiKeyRepository {

  public override async getApiKeyList (): Promise<IApiKeyListDTO> {
    return HttpServiceV1.get<IBinanceKeyItem[] | null>(`/${this.provider}/get-api-key-list`)
      .then((response) => {
        const apiKeyList = response?.map((item) => {
          return {
            id: item.uuid,
            name: item.description,
            isMain: item.isMain ?? false,
            isActive: item.isActive ?? false,
            publicKey: item.publicKey
          }
        }) ?? []

        return {
          total: apiKeyList.length,
          list: apiKeyList
        }
      })
  }

  public override async createApiKey (port: ICreateApiKeyPort): Promise<boolean> {
    return HttpServiceV1.post(`/${this.provider}/set-api-key`, {
      body: {
        description: port.name,
        pubKey: port.publicKey,
        privKey: port.privateKey,
      }
    })
      .then(() => true)
      .catch((error: IHttpError<IApiKeyHttpError>) => {
        const data = error.errors.code
          ? this.transformError(+error.errors.code, error.errors.message)
          : error.errors.pubKey === undefined
            ? InternalCode.SERVER_ERROR
            : this.transformError(+error.errors.pubKey)

        throw ExceptionService.new({
          status: {
            code: HttpCode.BAD_REQUEST,
            message: 'Backend throw errors'
          },
          data: { logical: data }
        })
      })
  }

  public override async setActiveApiKey (port: ISetActiveApiKeyPort): Promise<boolean> {
    return HttpServiceV1.post(`/${this.provider}/set-main-api-key`, { body: { apiKeyUuid: port.uuid }})
      .then(() => true)
  }

  public override async deleteApiKey (port: IDeleteApiKeyPort): Promise<boolean> {
    return HttpServiceV1.delete(`/${this.provider}/delete-api-key`, { body: { apiKeyUuid: port.uuid }})
      .then(() => true)
      .catch((error: IHttpError<IApiKeyHttpError>) => {
        throw ExceptionService.new({
          status: {
            code: HttpCode.BAD_REQUEST,
            message: 'Backend throw errors'
          },
          data: { logical: error.errors.code ? error.errors.code : InternalCode.SERVER_ERROR }
        })
      })
  }

  protected override transformError (code: number, message?: string): InternalCode | ApiKeyError {
    if (code === BinanceApiKeyRepositoryErrorCode.API_KEY_NOT_FOUND) {
      return ApiKeyError.API_KEY_NOT_FOUND
    }

    return super.transformError(code, message)
  }

}

export { BinanceApiKeyRepository }
