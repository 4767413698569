import { TradeTypes } from '@domain/common/enums'

import { AccountEntity } from '../../../../common'

import type { IAccountEntity, ICreateAccountUseCase, IGetAccountUseCase, IGetBalanceUseCase } from '../../../../common'

class CreateAccountUseCase implements ICreateAccountUseCase {

  protected readonly _getAccountUseCase: IGetAccountUseCase

  protected readonly _getBalanceUseCase: IGetBalanceUseCase

  constructor (getAccountUseCase: IGetAccountUseCase, getBalanceUseCase: IGetBalanceUseCase) {
    this._getAccountUseCase = getAccountUseCase
    this._getBalanceUseCase = getBalanceUseCase
  }

  public async execute (): Promise<IAccountEntity> {
    try {
      const account = await this._getAccountUseCase.execute()
      const balance = await this._getBalanceUseCase.execute()

      return await AccountEntity.new({ id: '', ...balance, ...account })
    } catch {
      return AccountEntity.new({
        id: '',
        balance: [],
        accountType: TradeTypes.SPOT,
        permission: []
      })
    }
  }

}

export { CreateAccountUseCase }
