import { GetDetailReportListUseCase } from '../../stocks'

import type { Stocks } from '@domain/common/enums'

import type { IReportRepository, IGetDetailReportListUseCase } from '../../common'

interface IPayload {
  stock: Stocks
  repository: IReportRepository
}

const initGetDetailReportListUseCase = (payload: IPayload): IGetDetailReportListUseCase => {
  return new GetDetailReportListUseCase(payload.repository)
}

export { initGetDetailReportListUseCase }
