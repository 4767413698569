import { LOADER_COMPONENTS as Components } from './_styles'

import type { FC } from 'react'

const AuthLoader: FC<{ isVisible: boolean }> = ({ isVisible }) => {
  return (
    <Components.Wrap isVisible={ isVisible }>
      <Components.Wrapper>
        <Components.Circle />

        <Components.Circle />

        <Components.Circle />

        <Components.Shadow />

        <Components.Shadow />

        <Components.Shadow />
      </Components.Wrapper>
    </Components.Wrap>
  )
}

export default AuthLoader
