import { useEffect, useState } from 'react'

import { useAuthContext } from '@app/common/auth'

import { useGetDetectSettingList } from './request'

import type { IUseDetectSettingListResult } from './interface'

import type { IDetectSettingDTO } from '@domain/setting-profile/detect'

const useDetectSettingList = (): IUseDetectSettingListResult => {
  const [detectList, setDetectList] = useState<IDetectSettingDTO[]>([])

  const { refetch } = useGetDetectSettingList(setDetectList)
  const { isAuth } = useAuthContext()

  useEffect(() => {
    if (isAuth) void refetch()
    else setDetectList([])
  }, [isAuth])

  return {
    detectList,
    setDetectList,
    refetch
  }
}

export { useDetectSettingList }
