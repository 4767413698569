interface IFormatNumberOptions {
  lang?: string
  min?: number
  max?: number
}

const formatNumber = (value: string | number, options?: IFormatNumberOptions): string => {
  value = value.toString()
  const minDefault = 2

  let max = options?.max
  let min = options?.min

  if (options?.min !== undefined) {
    if (value.includes('.')) {
      const pattern = /(0+$)|(\.+$)/

      const splitZeros = value.replace(pattern, '')
      const lengthZeros = splitZeros.split('.')[1].length

      min = lengthZeros < options.min ? lengthZeros : options.min
      min = min < minDefault ? minDefault : min
    } else {
      if (min !== undefined) min = minDefault
    }
  }

  if (options?.max !== undefined) {
    if (value.indexOf('.') + 1) {
      const findIndex: number | undefined = value.split('.')[1].match(/[1-9]/)?.index

      max = findIndex !== undefined && findIndex + 1 > options.max
        ? findIndex + 1
        : options.max
    }
  }

  min = min !== undefined && max !== undefined && min > max ? max : min

  const formatter = Intl.NumberFormat(options?.lang ?? 'en', {
    minimumFractionDigits: min,
    maximumFractionDigits: max
  })

  return formatter.format(Number(value))
}

export { formatNumber }
