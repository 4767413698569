import { Box, styled } from '@mui/material'

const Components = {
  Box: styled(Box)`
    max-width: 1250px;
    width: 100%;
    
    @media(max-width: 830px) {
      padding: 0 20px;
    }
    
    @media(max-width: 760px) {
      padding: 0;
    }
  `,
  TypeBox: styled(Box)`
    width: 100%;
    margin: 40px 0 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    row-gap: 10px;
  `,
}

export { Components }
