import { CentrifugeService, STOCK_CONNECTION } from '@data/common/services'

import type { IUserProfitDTO, IUserProfitEntity } from '@domain/user-profit'
import type { Stocks } from '@domain/common/enums'

import type { IUserProfitResponseSocket } from './interfaces'

class UserProfitSocket {

  private _socket?: CentrifugeService<IUserProfitResponseSocket>

  private _entity: IUserProfitEntity | null

  constructor () {
    this._entity = null
  }

  public subscribe (stock: Stocks, id: string): void {
    this._socket = new CentrifugeService(`profit_${id}`, STOCK_CONNECTION[stock])
  }

  public unsubscribe (): void {
    this._socket?.close()
  }

  public onMessage (callback?: (value: IUserProfitDTO) => void): void {
    this._socket?.onMessage(async (message) => {
      const profit = {
        all: { value: message.allUSDT, percent: message.allPercent },
        today: { value: message.todayUSDT, percent: message.todayPercent }
      }

      if (callback) callback(profit)

      if (this._entity) await this._entity.updateProfit(profit)
    })
  }

  public setEntity (entity: IUserProfitEntity): void {
    this._entity = entity
  }

}

export { UserProfitSocket }
