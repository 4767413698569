import { AbstractValidator } from '@domain/common/utils/validators/abstract-validator'

import { HOTKEY_IN_CHAPTER } from '@app/pages/profile/settings/keyboard/components/_setting'

import { HotKeyEvents } from '@domain/setting-profile/hot-keys'

import { KeyboardCode, ValidationCode } from '@domain/common/enums/internal-code'

import type { KeyboardChapter } from '@app/pages/profile/settings/keyboard/components/_setting'

import type { IValidationError } from '@domain/common/utils/validators'

/* eslint-disable @typescript-eslint/no-magic-numbers, max-len, max-params */

class UniqueValidatorKeyboard extends AbstractValidator {

  public _hotkeyChapter = HOTKEY_IN_CHAPTER

  public _name: HotKeyEvents

  public _port: Port

  public _index?: number

  constructor (port: Port, name: HotKeyEvents, index?: number) {
    super()
    this._port = port
    this._name = name
    this._index = index
  }

  public override validate (value: string[] | string[][]): IValidationError {
    if (value.length === 0) return super.validate(value)
    const isError: IError = this._validateDefault(value)

    if (isError.code !== undefined) {
      return {
        isSuccess: false,
        errors: {
          code: isError.code,
          data: isError.data
        }
      }
    }
    return super.validate(value)
  }

  private _validateDefault (value: string[] | string[][]): IError {
    const isErrorRepeat = this._validateRepeat(value)
    if (isErrorRepeat !== false)
      return { code: ValidationCode.PROPERTY_IS_NOT_UNIQUE, data: isErrorRepeat as KeyboardChapter }
    const isErrorInit = this._validationInit(value)
    if (isErrorInit !== false)
      return { code: KeyboardCode.INVALID_INIT_COMBINATION, data: isErrorInit as KeyboardChapter }
    return {}
  }

  private _validateRepeat (value: string[] | string[][]): boolean | KeyboardChapter {
    for (const [key, portValue, isInclude] of this._port) {
      if (key === HotKeyEvents.CHANGE_SCALE || key === HotKeyEvents.ORDER_SIZE) {
        let index = 0
        for (const item of portValue) {
          if (value.join('') === (item as string[]).join('') && this._index !== index && isInclude)
            return this._hotkeyChapter[key]
          index += 1
        }
      }
      if (value.join('') === portValue.join('') && key !== this._name && isInclude)
        return this._hotkeyChapter[key]
    }
    return false
  }

  private _validationInit (value: string[] | string[][]): boolean | KeyboardChapter {
    for (const [key, portValue, isInclude] of this._port) {
      if (key === HotKeyEvents.CHANGE_SCALE || key === HotKeyEvents.ORDER_SIZE) {
        for (const item of portValue) {
          const isError = this._init(value as string[], item as string[], key, isInclude)
          if (isError !== false) return isError
        }
      } else {
        const isError = this._init(value as string[], portValue as string[], key, isInclude)
        if (isError !== false) return isError
      }
    }
    return false
  }

  private _init (value: string[], portValue: string[], key: HotKeyEvents, isInclude: boolean): boolean | KeyboardChapter {
    let count = 0
    let index = 0

    const isMultiple = (_key: HotKeyEvents): boolean => this._name === _key && key === _key

    for (const item of value) {
      if (item === '0' || item === '1' || item === '2') continue
      if (isMultiple(HotKeyEvents.CHANGE_SCALE) || isMultiple(HotKeyEvents.ORDER_SIZE)) {
        if (item === portValue[index] && value.join('') !== portValue.join('')) count += 1
      } else if (item === portValue[index] && key !== this._name) count += 1
      index += 1
    }
    if (value.length === count && value.length !== 0 && portValue.length !== 0 && isInclude) return this._hotkeyChapter[this._name]
    if (portValue.length === count && value.length !== 0 && portValue.length !== 0 && isInclude) return this._hotkeyChapter[key]
    return false
  }

}

type Port = [HotKeyEvents, (string[] | string[][]), boolean][]

interface IError {
  code?: number
  data?: KeyboardChapter
}

export { UniqueValidatorKeyboard }
