import type {
  IGetLastReportPort,
  IReportRepository,
  IGetLastReportUseCase,
  IDetailReportDTO
} from '../../../../common'

class GetLastReportUseCase implements IGetLastReportUseCase {

  protected repository: IReportRepository

  constructor (repository: IReportRepository) {
    this.repository = repository
  }

  public async execute (port: IGetLastReportPort): Promise<IDetailReportDTO | null> {
    const response = await this.repository.getDetailReportList({ symbols: [port.symbol], list: port.list })

    let result = null

    for (const item of response) {
      if (result === null || item.completeDate > result.completeDate) {
        result = item
      }
    }

    return result
  }

}

export { GetLastReportUseCase }
