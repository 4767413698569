import type {
  ITradeDTO,
  IGetTradeHistoryListPort,
  IGetTradeHistoryListUseCase,
  ITradeHistoryRepository
} from '../../../../common'

class GetTradeHistoryListUseCase implements IGetTradeHistoryListUseCase {

  protected repository: ITradeHistoryRepository

  constructor (repository: ITradeHistoryRepository) {
    this.repository = repository
  }

  public async execute (port: IGetTradeHistoryListPort): Promise<ITradeDTO[]> {
    return this.repository.getTradeList(port)
  }

}

export { GetTradeHistoryListUseCase }
