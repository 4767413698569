import { RequiredValidator, ValidationChain } from '@domain/common/utils/validators'

import type { IAxisValidation, IFieldValidate } from '@domain/setting-profile/axis'

class AxisValidation implements IAxisValidation {

  public validate (): IFieldValidate {
    return {
      showTags: new ValidationChain([new RequiredValidator()]),
      showLeftAxis: new ValidationChain([new RequiredValidator()]),
      showTagLeftAxis: new ValidationChain([new RequiredValidator()]),
      showRightAxis: new ValidationChain([new RequiredValidator()]),
      showTagRightAxis: new ValidationChain([new RequiredValidator()]),
      leftAxisType: new ValidationChain([new RequiredValidator()]),
      leftTagAxisType: new ValidationChain([new RequiredValidator()]),
      rightAxisType: new ValidationChain([new RequiredValidator()]),
      rightTagAxisType: new ValidationChain([new RequiredValidator()])
    }
  }

}

export { AxisValidation }
