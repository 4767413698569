const deleteLastZero = (value: string): string => {
  const isDecimal = value.includes('.')

  if (isDecimal) {
    return value.replace(/(\.0+$)|(0+$)|(\.+$)/, '')
  }

  return value
}

export { deleteLastZero }
