import { useMutation } from '@tanstack/react-query'

import { DealGetUseCase } from '@domain/stocks/controllers/deal'

import type { Stocks } from '@domain/common/enums'

import type { UseMutationResult } from '@tanstack/react-query'
import type { IGetDealListPort, IDealDTO } from '@domain/stocks/interfaces/deal'

interface IGetGetDealsPort extends IGetDealListPort {
  stock: Stocks
}

const useGetDealListRequest = (): UseMutationResult<IDealDTO[], never, IGetGetDealsPort> => {
  const callback = async (port: IGetGetDealsPort): Promise<IDealDTO[]> => {
    return DealGetUseCase.new(port.stock).execute(port)
      .then((response) => response)
      .catch(() => [])
  }

  return useMutation(['getDeals'], callback)
}

export { useGetDealListRequest }
