import { useEffect, useState } from 'react'

import { useGetLicenseLisRequest } from '@app/common/license/requests'
import { useInternetConnectionContext } from '@app/components/tools/components/internet-connection'

import type { Dispatch, SetStateAction } from 'react'

import type { ILicenseDTO } from '@domain/license'

interface ILicenseListResult {
  licenseList: ILicenseDTO[]
  setLicenseList: Dispatch<SetStateAction<ILicenseDTO[]>>
}

const useLicenseList = (): ILicenseListResult => {
  const [licenseList, setLicenseList] = useState<ILicenseDTO[]>([])

  const { status } = useInternetConnectionContext()
  const { refetch } = useGetLicenseLisRequest(setLicenseList)

  useEffect(() => {
    if (status === 'online') void refetch()
  }, [status])

  return { licenseList, setLicenseList }
}

export { useLicenseList }
