import { AudioContext } from 'standardized-audio-context'

import type { IAudioServiceCreatePayload, IAudioServicePlayPayload } from '@data/common/interfaces'

/**
 * Usage Example
 * new AudioService({ src: '/sounds/notify.mp3' })
 */

class AudioService {

  private _context: AudioContext

  private _audio: HTMLAudioElement

  constructor (payload?: IAudioServiceCreatePayload) {
    this._context = new AudioContext()
    this._audio = new Audio(payload?.src)
  }

  public async play (payload?: IAudioServicePlayPayload): Promise<void> {
    if (payload !== undefined) this._audio.src = payload.src

    try {
      if (this._context.state === 'suspended') {
        await this._context.resume()
      }

      if (this._context.state === 'running') {
        await this._audio.play()
      }
    } catch {
      //
    }
  }

}

export { AudioService }
