import { CentrifugeService, STOCK_CONNECTION } from '@data/common/services'

import type { Stocks } from '@domain/common/enums'

import type { ISocketData } from '../interfaces'
import type { IMarketOverviewByLastAssetSocket } from '../../../interfaces'

import type { IMarketOverviewSocketResponse } from '@data/socket/stocks/market-overview/by-filters/stocks'

class MarketOverviewByLastAssetSocket implements IMarketOverviewByLastAssetSocket {

  protected socket: ISocketData[]

  protected stock: Stocks | null

  protected channel: string

  constructor (channel: string) {
    this.channel = channel

    this.socket = []
    this.stock = null
  }

  public subscribe (lastAsset: string, stock: Stocks): void {
    this.stock = stock

    this.socket.push({
      lastAsset: lastAsset,
      lastAssetData: { data: []},
      socket: new CentrifugeService(`${this.channel}_${lastAsset}`, STOCK_CONNECTION[stock])
    })
  }

  public unsubscribe (lastAsset: string): void {
    this.socket.forEach((socket) => {
      if (socket.lastAsset === lastAsset) {
        socket.socket.close()
        socket.lastAssetData.data = []
      }
    })
  }

  public unsubscribeAll (): void {
    this.socket.forEach((socket) => {
      socket.socket.close()
      socket.lastAssetData.data = []
    })

    this.socket = []
  }

  public onMessage (callback: (value: IMarketOverviewSocketResponse) => void): void {
    this.socket.forEach((socket) => {
      socket.socket.onMessage((data) => {
        socket.lastAssetData.data = data

        if (this.stock === null) return

        const result: IMarketOverviewSocketResponse['data'] = []

        this.socket.forEach((item) => {
          result.push(...item.lastAssetData.data)
        })

        callback({
          stock: this.stock,
          data: result
        })
      })
    })
  }

}

export { MarketOverviewByLastAssetSocket }
