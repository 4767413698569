import { WebsocketService } from '@data/common/services'

import type { IPairDTO } from '@domain/stocks/pair'
import type { ITradeDTO } from '@domain/stocks/trade-history'

import type { IBinanceTradeSocketResponse } from '../interfaces'
import type { ITradeSocket } from '../../../interfaces'

class BinanceTradeSocket implements ITradeSocket {

  private _socket?: WebsocketService<IBinanceTradeSocketResponse>

  private readonly _delay: number

  constructor (delay?: number) {
    this._delay = delay ?? 100
  }

  public subscribe (pair: IPairDTO, connection: string): void {
    const symbol = pair.ticker.symbol.toLowerCase()
    this._socket = new WebsocketService(connection, `${symbol}@trade`)
  }

  public unsubscribe (): void {
    this._socket?.close()
  }

  public onMessage (callback: (message: ITradeDTO) => void): void {
    let lastTradeDeal: IBinanceTradeSocketResponse | null = null

    this._socket?.onMessage((message) => {
      if (lastTradeDeal === null || message.T - lastTradeDeal.T > this._delay) {
        lastTradeDeal = message

        callback({
          id: message.t,
          symbol: message.s,
          timestamp: message.T,
          price: +message.p,
          amount: +message.q,
          lastAssetAmount: +message.p * +message.q,
          isBuyerMaker: Boolean(message.m)
        })
      }
    })
  }

}

export { BinanceTradeSocket }
