import { createTheme } from '@mui/material'

import type { Theme } from '@mui/material'

import type { Components } from '@mui/material/styles/components'

const defaultTheme = createTheme({})

const TYPOGRAPHY_DEFAULT_STYLE: Components<Theme>['MuiTypography'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.text.primary,
      transition: theme.transitions.create(['color'], { duration: theme.transitions.duration.standard })
    }),
    h1: {
      fontSize: 43,
      fontWeight: 700,
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: 30
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: 24
      }
    },
    h2: {
      fontSize: 26,
      fontWeight: 700,
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: 22
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: 18
      }
    },
    caption: {
      fontSize: 16,
      fontWeight: 400,
      color: '#A7A3A3',
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: 14
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: 12
      }
    }
  }
}

export { TYPOGRAPHY_DEFAULT_STYLE }
