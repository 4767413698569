import type { IHTMLFullscreenElement } from '@data/common/interfaces'

class FullscreenService {

  public async open (value: IHTMLFullscreenElement): Promise<void> {
    if (value.requestFullscreen) await value.requestFullscreen()
    if (value.msRequestFullscreen) await value.msRequestFullscreen()
    if (value.mozRequestFullscreen) await value.mozRequestFullscreen()
    if (value.webkitRequestFullscreen) await value.webkitRequestFullscreen()
  }

  public async close (): Promise<void> {
    if (document.exitFullscreen) await document.exitFullscreen()
    if (document.webkitExitFullscreen) await document.webkitExitFullscreen()
  }

}

export default new FullscreenService()
