import { Box, styled, Typography } from '@mui/material'

const STYLES_SNACKBAR = {
  Wrapper: styled(Box)`
    display: flex;
    align-items: center;
    grid-gap: 10px;
    padding: 10px 20px;
    border-radius: 5px;
    max-width: 425px;
  `,
  Title: styled(Typography)`
    color: ${({ theme }): string => theme.palette.text.primary};
    font-size: 14px;
  `
}

export { STYLES_SNACKBAR }
