import { IsEnum } from 'class-validator'

import { Entity } from '@domain/common/entity'
import { TradeTypes } from '@domain/common/enums'

import type { IAccountEntity, ICreateAccountPayload, IBalance } from '../interfaces'

class AccountEntity extends Entity<string> implements IAccountEntity {

  @IsEnum(TradeTypes)
  public accountType: TradeTypes

  public balance: IBalance[]

  @IsEnum(TradeTypes, { each: true })
  public permission: TradeTypes[]

  constructor (payload: ICreateAccountPayload) {
    super(payload.id)

    this.accountType = payload.accountType
    this.balance = payload.balance
    this.permission = payload.permission
  }

  public getBalanceByAsset (asset: string): IBalance | undefined {
    return this.balance.find((item) => item.asset === asset)
  }

  public updateBalance (balances: IBalance[]): void {
    balances.forEach((item) => {
      const asset = this.getBalanceByAsset(item.asset)

      if (asset === undefined) this.balance.push(item)
      else {
        const index = this.balance.findIndex((balance) => balance.asset === item.asset)

        if (index !== -1) this.balance[index] = item
      }
    })

    this.balance = [...this.balance]
  }

  public static async new (payload: ICreateAccountPayload): Promise<IAccountEntity> {
    const entity = new AccountEntity(payload)

    await entity.validate()

    return entity
  }

}

export { AccountEntity }
