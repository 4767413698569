import { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { useGoogleAnalytics, useYandexMetrics } from '@app/services/seo/metricks'
import { updateMeta } from '@app/services/seo/update-meta'

const useSEO = (): void => {
  const { t, i18n } = useTranslation()
  const { addGoogleAnalytics, clearGoogleAnalytics } = useGoogleAnalytics()
  const { addYandexMetric, clearYandexMetric } = useYandexMetrics()

  useEffect(() => {
    updateMeta({
      description: t('seo/description'),
      keywords: t('seo/keywords') })
  }, [i18n.language])

  useEffect(() => {
    if (process.env.REACT_APP_STAGE === 'PROD') {
      addGoogleAnalytics()
      addYandexMetric()
    }

    return () => {
      clearGoogleAnalytics()
      clearYandexMetric()
    }
  }, [])
}

export { useSEO }
