enum ApiKeyError {
  INVALID_PASSPHRASE = 6000,
  INVALID_API_KEY = 6001,

  ALREADY_EXIST = 6002,
  ALREADY_EXIST_API_KEY = 6003,
  ALREADY_EXIST_NAME = 6004,

  API_KEY_NOT_FOUND = 6005
}

export { ApiKeyError }
