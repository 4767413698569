import { HttpServiceV1 } from '@data/common/services'

import { ExceptionService } from '@domain/common/services'
import { InternalCode, TradeTypes } from '@domain/common/enums'

import { BinanceTradeType } from '@domain/stocks/binance/common/enums'
import { BinanceErrorCode } from '@data/stocks/binance/enums'

import { AccountRepository } from '../../_'

import type { IBinanceAccount, IBinanceBalance } from '../interfaces'

import type { IBalanceDTO, IAccountDTO, IAccountRepository } from '@domain/stocks/account'

import type { IBinanceHttpError } from '@data/stocks/binance/interfaces'
import type { IHttpError } from '@data/common/interfaces'

class BinanceAccountRepository extends AccountRepository implements IAccountRepository {

  public override async getAccount (): Promise<IAccountDTO> {
    return HttpServiceV1.get<IBinanceAccount>(`/${this.provider}/account-info`)
      .then((response) => {
        return {
          accountType: BinanceAccountRepository._transformTradeType(response.AccountType),
          permission: response.permission.map((item) => BinanceAccountRepository._transformTradeType(item)),
        }
      })
      .catch((error: IHttpError<IBinanceHttpError>) => {
        throw ExceptionService.new({
          status: {
            code: BinanceAccountRepository._transformError(error.errors.code),
            message: error.errors.message
          }
        })
      })
  }

  public override async getBalance (): Promise<IBalanceDTO> {
    return HttpServiceV1.get<IBinanceBalance[] | null>('/binance-order/virtual-balance')
      .then((response) => {
        if (response === null) return { balance: []}

        return {
          balance: response.map((item) => {
            return {
              asset: item.Ticker,
              free: item.free,
              locked: item.locked,
              all: item.total
            }
          })
        }
      })
      .catch((error: IHttpError<IBinanceHttpError>) => {
        throw ExceptionService.new({
          status: {
            code: BinanceAccountRepository._transformError(error.errors.code),
            message: error.errors.message
          }
        })
      })
  }

  private static _transformTradeType (type: BinanceTradeType): TradeTypes {
    switch (type) {
      case BinanceTradeType.SPOT: return TradeTypes.SPOT
      case BinanceTradeType.MARGIN: return TradeTypes.MARGIN
      case BinanceTradeType.FUTURES: return TradeTypes.FUTURES
      default: return TradeTypes.SPOT
    }
  }

  private static _transformError (code: string): InternalCode {
    switch (code) {
      case BinanceErrorCode.INVALID_API_KEY: return InternalCode.INVALID_API_KEY
      case BinanceErrorCode.API_KEY_NOT_FOUND: return InternalCode.API_KEY_NOT_FOUND
      default: return InternalCode.SERVER_ERROR
    }
  }

}

export { BinanceAccountRepository }
