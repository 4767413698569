import { RequiredValidator, ValidationChain } from '@domain/common/utils/validators'
import { RangeValidation } from '@domain/common/utils/validators/range-validator'

import type { IContentValidation, IFieldValidation } from '@domain/setting-profile/content'

class ContentValidation implements IContentValidation {

  public validate (): IFieldValidation {
    return {
      bestAsk: new ValidationChain([new RequiredValidator()]),
      bestBid: new ValidationChain([new RequiredValidator()]),
      tickPrice: new ValidationChain([new RequiredValidator()]),
      showForm: new ValidationChain([new RequiredValidator()]),
      widthVolume: new ValidationChain([
        new RequiredValidator(),
        new RangeValidation({ min: 10, max: 75 })
      ]),
      heightVolume: new ValidationChain([
        new RequiredValidator(),
        new RangeValidation({ min: 10, max: 40 })
      ]),
      tickSize: new ValidationChain([
        new RequiredValidator(),
        new RangeValidation({ min: 3, max: 10 })
      ]),
      defaultScale: new ValidationChain([new RequiredValidator()]),
      axisVolume: new ValidationChain([new RequiredValidator()]),
      sideHorizontalVolume: new ValidationChain([new RequiredValidator()]),
      defaultGraphType: new ValidationChain([new RequiredValidator()]),
      tickVariant: new ValidationChain([new RequiredValidator()]),
      tickVolumeMode: new ValidationChain([new RequiredValidator()]),
      candleVariant: new ValidationChain([new RequiredValidator()]),
      candleInterval: new ValidationChain([new RequiredValidator()]),
      isShowHorizontalVolume: new ValidationChain([new RequiredValidator()]),
      isShowVerticalVolume: new ValidationChain([new RequiredValidator()]),
      isMultiplierScale: new ValidationChain([new RequiredValidator()]),
      isInputOutputLines: new ValidationChain([new RequiredValidator()]),
      showMeasureTool: new ValidationChain([new RequiredValidator()]),
      measureToolInterval: new ValidationChain([new RequiredValidator()]),
      measureToolOpacity: new ValidationChain([
        new RequiredValidator(),
        new RangeValidation({ min: 0, max: 80 })
      ]),
      orderLevelMode: new ValidationChain([new RequiredValidator()])
    }
  }

}

export { ContentValidation }
