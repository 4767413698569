enum GuestRoute {
  SIGN_UP_ROUTE = '/sign-up',
  SIGN_IN_ROUTE = '/sign-in',
  RECOVERY_PASSWORD_ROUTE = '/recovery-password'
}

enum PublicRoute {
  DEFAULT_ROUTE = '/',
  INFO_DOCS = '/info/:doc'
}

enum AuthRoute {
  TRADE = '/app/trade'
}

enum ProfileRoute {
  PROFILE = '/app/profile',

  LICENSE_INDEX = '/app/profile/licence',

  SETTINGS_REFERRAL_PROGRAM = '/not-found',
  SETTINGS = '/app/profile/settings',
  SETTINGS_GA = '/app/profile/settings/ga',
  SETTINGS_TP = '/app/profile/settings/take-profit-stop-loss',
  SETTINGS_SPECIFICATION = '/app/profile/settings/specification',
  SETTINGS_CONTENT = '/app/profile/settings/content',
  SETTINGS_KEYBOARD_ORDER = '/app/profile/settings/keyboard-order',
  SETTINGS_KEYBOARD_DEALS = '/app/profile/settings/keyboard-deals',
  SETTINGS_KEYBOARD_SCREENSHOT = '/app/profile/settings/keyboard-screenshot',
  SETTINGS_KEYBOARD_GRAPHIC = '/app/profile/settings/keyboard-graphic',
  SETTINGS_AXIS = '/app/profile/settings/axis',
  SETTINGS_TOOLS = '/app/profile/settings/tools',
  SETTINGS_AUTO_BUY = '/app/profile/settings/auto-buy',
  SETTINGS_AUTO_BUY_BNB = '/app/profile/settings/bnb-auto-buy',
  SETTINGS_DETECT = '/app/profile/settings/detect',
  SETTINGS_PAIR_LISTS = '/app/profile/settings/pair-lists',

  API_SETTINGS_INDEX = '/app/profile/api-settings',
  API_SETTINGS_CREATE = '/app/profile/api-settings/create'
}

enum ErrorRoute {
  NOT_FOUND = '/not-found'
}

const ROUTES_PATH = { AuthRoute, PublicRoute, ProfileRoute, GuestRoute, ErrorRoute }

export default ROUTES_PATH
