enum ApiKeyRepositoryErrors {
  API_KEY_EXIST = 'api key already exist',
  DESCRIPTION_EXIST = 'description already exist'
}

enum ApiKeyRepositoryErrorCode {
  INVALID_API_KEY = 200,
  API_KEY_EXIST = 301
}

export { ApiKeyRepositoryErrors, ApiKeyRepositoryErrorCode }
