import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { Controller } from 'react-hook-form'

import { useTranslation } from 'react-i18next'

import type { IBaseControlledInput } from '@app/components/ui/inputs/types/base-controlled-input'

const RadioButton = <TControl, >({
  control,
  items,
  name,
  horizontally = true
}: IRadioButtonProps<TControl>): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Controller
      name={ name }
      control={ control }
      render={ ({ field: { onChange: handleOnChange, value: currentValue }}): JSX.Element => (
        <RadioGroup
          row={ horizontally }
          value={ currentValue ?? '' }
          onChange={ handleOnChange }
        >
          { items.map((item) => (
            <FormControlLabel key={ item.id }
              value={ item.id }
              control={ <Radio /> }
              label={ t(item.label) }
            />
          )) }
        </RadioGroup>
      ) }
    />
  )
}

interface IRadioButtonItem {
  id: string | number
  label: string
}

interface IRadioButtonProps<TControl> extends IBaseControlledInput<TControl> {
  items: IRadioButtonItem[]
  horizontally?: boolean
}

export const makeRadioButtonAttr = <TControl, >(
  items: IRadioButtonProps<TControl>['items'],
  horizontally?: IRadioButtonProps<TControl>['horizontally']
): Pick<IRadioButtonProps<TControl>, 'items' | 'horizontally'> => ({
  items,
  horizontally
})

export type RadioButtonProps<TControl> = IRadioButtonProps<TControl>

export default RadioButton
