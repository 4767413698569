import { forwardRef, useCallback } from 'react'

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import WarningIcon from '@mui/icons-material/Warning'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import InfoIcon from '@mui/icons-material/Info'

import { useSnackbar } from 'notistack'

import { useTheme } from '@mui/material'

import { STYLES_SNACKBAR as Component } from '@app/components/tools/components/notistack-bar/_styles'

import type { CustomContentProps } from 'notistack'

// eslint-disable-next-line react/display-name
const NotistackBar = forwardRef<HTMLDivElement, CustomContentProps>((props, ref) => {
  const { closeSnackbar } = useSnackbar()
  const theme = useTheme()

  const _getBGColor = useCallback((variant: CustomContentProps['variant']): string => {
    switch (variant) {
      case 'success': return theme.palette.primary.main
      case 'error': return theme.palette.error.main
      case 'warning': return theme.palette.warning.main
      default: return theme.palette.info.main
    }
  }, [theme])

  // eslint-disable-next-line react/no-multi-comp,react/no-unstable-nested-components
  const _icon = useCallback((variant: CustomContentProps['variant']): JSX.Element => {
    switch (variant) {
      case 'success': return <CheckCircleOutlineIcon sx={{ color: theme.palette.text.primary }} />
      case 'error': return <WarningIcon sx={{ color: theme.palette.text.primary }} />
      case 'warning': return <HelpOutlineOutlinedIcon sx={{ color: theme.palette.text.primary }} />
      case 'info': return <InfoIcon sx={{ color: theme.palette.text.primary }} />
      default: return <div />
    }
  }, [theme])

  const handleClose = (): void => {
    closeSnackbar(props.id)
  }

  return (
    <Component.Wrapper
      ref={ ref }
      sx={{ backgroundColor: _getBGColor(props.variant), cursor: 'pointer' }}
      onClick={ handleClose }
    >
      { _icon(props.variant) }

      <Component.Title>{ props.message }</Component.Title>

      { typeof props.action === 'function' && props.action(props.id) }
    </Component.Wrapper>
  )
})

export default NotistackBar
