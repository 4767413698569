/* eslint-disable import/max-dependencies, max-len */
import { lazy } from 'react'

import { Navigate } from 'react-router-dom'

import ROUTES_PATH from '@app/router/router-path'
import { LoadComponent, GUARDS } from '@app/common/router'

import DefaultPage from '@app/pages/default'

import type { RouteObject } from 'react-router-dom'

const MainLayout = LoadComponent(lazy(async () => import('@app/components/tools/layout/app/main')))
const AuthLayout = LoadComponent(lazy(async () => import('@app/components/tools/layout/auth')))
const ProfileLayout = LoadComponent(lazy(async () => import('@app/components/tools/layout/app/profile')))

const InfoDocs = LoadComponent(lazy(async () => import('@app/pages/default/components/info-docs')))

const NotFoundPage = LoadComponent(lazy(async () => import('@app/pages/errors/not-found')))

const LoginPage = LoadComponent(lazy(async () => import('@app/pages/auth/login')))
const Registration = LoadComponent(lazy(async () => import('@app/pages/auth/registration')))
const RecoveryPassword = LoadComponent(lazy(async () => import('@app/pages/auth/recovery-password')))

const ProfileIndexPage = LoadComponent(lazy(async () => import('@app/pages/profile/index')))

const CreateProfileApiPage = LoadComponent(lazy(async () => import('@app/pages/profile/api-settings/create')))
const IndexProfileApiPage = LoadComponent(lazy(async () => import('@app/pages/profile/api-settings/index')))

const IndexLicensePage = LoadComponent(lazy(async () => import('@app/pages/profile/license')))

const SettingsGA = LoadComponent(lazy(async () => import('@app/pages/profile/settings/ga')))
const SettingsTP = LoadComponent(lazy(async () => import('@app/pages/profile/settings/tp')))
const SettingsSpecification = LoadComponent(lazy(async () => import('@app/pages/profile/settings/specifications')))
const SettingsContent = LoadComponent(lazy(async () => import('@app/pages/profile/settings/content')))
const SettingsAxis = LoadComponent(lazy(async () => import('@app/pages/profile/settings/axis')))
const SettingsTools = LoadComponent(lazy(async () => import('@app/pages/profile/settings/tools')))
const SettingsAutoBuyBNB = LoadComponent(lazy(async () => import('@app/pages/profile/settings/auto-buy-bnb')))
const SettingKeyboardOrder = LoadComponent(lazy(async () => import('@app/pages/profile/settings/keyboard/components/order')))
const SettingKeyboardDeals = LoadComponent(lazy(async () => import('@app/pages/profile/settings/keyboard/components/deals')))
const SettingKeyboardScreenshot = LoadComponent(lazy(async () => import('@app/pages/profile/settings/keyboard/components/screenshot')))
const SettingKeyboardGraphic = LoadComponent(lazy(async () => import('@app/pages/profile/settings/keyboard/components/graphic')))
const SettingsDetect = LoadComponent(lazy(async () => import('@app/pages/profile/settings/detect')))
const SettingsPairLists = LoadComponent(lazy(async () => import('@app/pages/profile/settings/pair-lists')))

const Trade = LoadComponent(lazy(async () => import('@app/pages/trade')))

const ROUTES: RouteObject[] = [
  {
    path: ROUTES_PATH.PublicRoute.DEFAULT_ROUTE,
    element: <MainLayout needShowFooter={ true } />,
    children: [
      { index: true, element: <DefaultPage /> },
      { path: ROUTES_PATH.PublicRoute.INFO_DOCS, element: <InfoDocs /> },
      { path: '*', element: <Navigate replace={ true } to={ ROUTES_PATH.ErrorRoute.NOT_FOUND } /> },
      { path: ROUTES_PATH.ErrorRoute.NOT_FOUND, element: <NotFoundPage /> }
    ]
  },
  {
    path: '',
    element: <MainLayout />,
    children: [
      {
        path: '',
        element: <AuthLayout />,
        children: [
          {
            path: '',
            element: <GUARDS.GuestGuard navigateToFail={ ROUTES_PATH.PublicRoute.DEFAULT_ROUTE } />,
            children: [
              { path: ROUTES_PATH.GuestRoute.SIGN_UP_ROUTE, element: <Registration /> },
              { path: ROUTES_PATH.GuestRoute.SIGN_IN_ROUTE, element: <LoginPage /> },
              { path: ROUTES_PATH.GuestRoute.RECOVERY_PASSWORD_ROUTE, element: <RecoveryPassword /> },
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/app',
    element: <GUARDS.AuthGuard navigateToFail={ ROUTES_PATH.GuestRoute.SIGN_IN_ROUTE } />,
    children: [
      {
        path: ROUTES_PATH.ProfileRoute.PROFILE,
        element: <ProfileLayout />,
        children: [
          { path: ROUTES_PATH.ProfileRoute.PROFILE, element: <ProfileIndexPage /> },
          { path: ROUTES_PATH.ProfileRoute.API_SETTINGS_CREATE, element: <CreateProfileApiPage /> },
          { path: ROUTES_PATH.ProfileRoute.API_SETTINGS_INDEX, element: <IndexProfileApiPage /> },
          { path: ROUTES_PATH.ProfileRoute.LICENSE_INDEX, element: <IndexLicensePage /> },
          { path: ROUTES_PATH.ProfileRoute.SETTINGS_GA, element: <SettingsGA /> },
          { path: ROUTES_PATH.ProfileRoute.SETTINGS_TP, element: <SettingsTP /> },
          { path: ROUTES_PATH.ProfileRoute.SETTINGS_SPECIFICATION, element: <SettingsSpecification /> },
          { path: ROUTES_PATH.ProfileRoute.SETTINGS_CONTENT, element: <SettingsContent /> },
          { path: ROUTES_PATH.ProfileRoute.SETTINGS_DETECT, element: <SettingsDetect /> },
          { path: ROUTES_PATH.ProfileRoute.SETTINGS_PAIR_LISTS, element: <SettingsPairLists /> },
          { path: ROUTES_PATH.ProfileRoute.SETTINGS_KEYBOARD_ORDER, element: <SettingKeyboardOrder /> },
          { path: ROUTES_PATH.ProfileRoute.SETTINGS_KEYBOARD_DEALS, element: <SettingKeyboardDeals /> },
          { path: ROUTES_PATH.ProfileRoute.SETTINGS_KEYBOARD_SCREENSHOT, element: <SettingKeyboardScreenshot /> },
          { path: ROUTES_PATH.ProfileRoute.SETTINGS_KEYBOARD_GRAPHIC, element: <SettingKeyboardGraphic /> },
          { path: ROUTES_PATH.ProfileRoute.SETTINGS_AXIS, element: <SettingsAxis /> },
          { path: ROUTES_PATH.ProfileRoute.SETTINGS_TOOLS, element: <SettingsTools /> },
          { path: ROUTES_PATH.ProfileRoute.SETTINGS_AUTO_BUY_BNB, element: <SettingsAutoBuyBNB /> },
        ]
      },
      {
        path: ROUTES_PATH.AuthRoute.TRADE,
        children: [
          { index: true, element: <Trade /> },
          { path: ':pair', element: <Trade /> }
        ]
      }
    ]
  }
]

export default ROUTES
