import { PairEntity } from '../../../../common'

import type {
  ICreatePairUseCase,
  IGetPairListUseCase,
  IGetSelectedPairUseCase,
  IPairEntity
} from '../../../../common'

class CreatePairUseCase implements ICreatePairUseCase {

  protected getPairListUseCase: IGetPairListUseCase

  protected getSelectedPairUseCase: IGetSelectedPairUseCase

  constructor (getPairListUseCase: IGetPairListUseCase, getSelectedPairUseCase: IGetSelectedPairUseCase) {
    this.getPairListUseCase = getPairListUseCase
    this.getSelectedPairUseCase = getSelectedPairUseCase
  }

  public async execute (): Promise<IPairEntity> {
    const list = await this.getPairListUseCase.execute()
    const pair = await this.getSelectedPairUseCase.execute(list)

    return PairEntity.new({ id: '', pair, list })
  }

}

export { CreatePairUseCase }
