import { useQuery } from '@tanstack/react-query'

import { LicenseRepository } from '@data/repository/license'
import { GetUserLicenseListUseCase } from '@domain/license'

import type { Dispatch, SetStateAction } from 'react'

import type { IUserLicenseDTO } from '@domain/license'
import type { ExceptionService } from '@domain/common/services'

import type { UseQueryResult } from '@tanstack/react-query'

type GetUserLicenseResult = UseQueryResult<IUserLicenseDTO[], ExceptionService<unknown>>
type SetterLicenseList = Dispatch<SetStateAction<IUserLicenseDTO[]>>

const useGetUserLicenseRequest = (setter: SetterLicenseList): GetUserLicenseResult => {
  const repository = new LicenseRepository()
  const useCase = new GetUserLicenseListUseCase(repository)

  const callback = async (): Promise<IUserLicenseDTO[]> => {
    return useCase.execute()
  }

  return useQuery(['get user license'], callback, {
    onSuccess: (value) => {
      setter(value)
    },
    enabled: false
  })
}

export { useGetUserLicenseRequest }
