import { styled, useTheme } from '@mui/material'

import { useEffect, useState } from 'react'

import type { CSSObject } from '@mui/material'

import type { FC } from 'react'

interface ITransitionElements {
  light: JSX.Element
  dark: JSX.Element
  sx?: CSSObject
}

/* eslint-disable @typescript-eslint/no-magic-numbers */
const TransitionElements: FC<ITransitionElements> = ({ light, dark, sx }) => {
  const { transitions: { duration: { standard: delay }}, palette: { mode }} = useTheme()
  const [isShow, setIsShow] = useState(false)
  const [prevState, setPrevState] = useState(mode === 'light' ? light : dark)
  const [isLoad, setIsLoad] = useState(false)

  useEffect(() => {
    if (isLoad) {
      setIsShow(true)
      setTimeout(() => {
        setPrevState(mode === 'light' ? light : dark)
        setIsShow(false)
      }, delay / 2.5)
    }
  }, [mode])

  useEffect(() => {
    if (!isLoad) setIsLoad(true)
  }, [])

  return (
    <Component.Wrapper sx={{ opacity: isShow ? 0 : 1, ...sx }}>
      { prevState }
    </Component.Wrapper>
  )
}

const Component = {
  Wrapper: styled('div')`
    transition: ${({ theme }): string => theme.transitions.create(['opacity'], { duration: theme.transitions.duration.standard / 2.5 })};
    display: flex;
    align-items: center;
    flex-direction: column;
  `
}

export default TransitionElements
