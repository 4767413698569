import { HttpServiceV1 } from '@data/common/services'

import { ExceptionService } from '@domain/common/services'
import { InternalCode } from '@domain/common/enums'

import type { IHttpError } from '@data/common/interfaces'
import type { IAxisResponseErrors, IAxisServerError } from '@data/repository/setting-profile/axis/interface'

import type { IAxisDto, IAxisErrors, IAxisRepository } from '@domain/setting-profile/axis'

class AxisRepository implements IAxisRepository {

  public async getTradeAxis (): Promise<IAxisDto> {
    return HttpServiceV1.get<IAxisDto>('settings/axis')
      .catch(() => {
        throw ExceptionService.new({
          status: {
            code: InternalCode.SERVER_ERROR,
            message: 'Server return bad request'
          }
        })
      })
  }

  public async updateTradeAxis (port: IAxisDto): Promise<IAxisDto> {
    return HttpServiceV1.put('settings/axis', { body: port })
      .then(() => port)
      .catch((error: IHttpError<IAxisResponseErrors | IAxisServerError>) => {
        throw this._throwError(error)
      })
  }

  // eslint-disable-next-line max-len
  private _throwError (error: IHttpError<IAxisResponseErrors | IAxisServerError>): ExceptionService<IAxisErrors> {
    const code = error.errors === 'server_error' ? InternalCode.SERVER_ERROR : InternalCode.VALIDATION_ERROR
    const message = 'Backand return errors'

    return ExceptionService.new({
      status: {
        code,
        message
      },
      data: {
        logical: error.errors === 'server_error'
          ? InternalCode.SERVER_ERROR
          : +error.errors.code
      }
    })
  }

}

export { AxisRepository }
