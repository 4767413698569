import type { IProfileRepository } from '@data/repository/profile/interfaces'

class BaseProfileUseCase {

  protected readonly _repository: IProfileRepository

  constructor (repository: IProfileRepository) {
    this._repository = repository
  }

}

export { BaseProfileUseCase }
