import { Stocks } from '@domain/common/enums'
import { BinanceDealGetUseCase } from '@domain/stocks/binance/deal'
import { DealRepository } from '@data/repository/stocks/controllers/deal'

import type { IDealGetUseCase } from '@domain/stocks/interfaces/deal'

class DealGetUseCase {

  private readonly _useCases: Record<Stocks, IDealGetUseCase> = {
    [Stocks.BINANCE]: new BinanceDealGetUseCase(DealRepository.new(Stocks.BINANCE))
  }

  public static new (stock: Stocks): IDealGetUseCase {
    return new DealGetUseCase()._useCases[stock]
  }

}

export { DealGetUseCase }
