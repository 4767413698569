import { Stocks } from '@domain/common/enums'

import { BinanceAccountRepository } from '../stocks'

import type { IAccountRepository } from '@domain/stocks/account'

interface IPayload {
  stock: Stocks
}

const initAccountRepository = (payload: IPayload): IAccountRepository => {
  switch (payload.stock) {
    case Stocks.BINANCE: return new BinanceAccountRepository('binance-provider-account')

    default: return new BinanceAccountRepository('binance-provider')
  }
}

export { initAccountRepository }
