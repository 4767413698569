import { AddToUserPairListUseCase } from '../add-to-list-use-case'

import type { IAddToCustomUserPairListPort } from '../../interfaces'

class AddUserListPairUseCase extends AddToUserPairListUseCase {

  protected override async runActions (port: IAddToCustomUserPairListPort): Promise<void> {
    for (const symbol of port.symbols) {
      await this.repository.addPair({ id: port.id, symbol: symbol })
      await this.entity.addPairToList(port.id, symbol)
    }
  }

}

export { AddUserListPairUseCase }
