enum Intervals {
  M1 = '1m',
  M3 = '3m',
  M5 = '5m',
  M15 = '15m',
  M30 = '30m',
  H1 = '1h',
  H24 = '24h',
  D1 = '1d',
}

export default Intervals
