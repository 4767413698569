import type { Components } from '@mui/material/styles/components'
import type { Theme } from '@mui/material'

const DIVIDER_STYLES: Components<Theme>['MuiDivider'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderColor: theme.palette.bg.accent,
      transition: theme.transitions.create(['border'], { duration: theme.transitions.duration.standard })
    })
  }
}

export { DIVIDER_STYLES }
