import { useEffect } from 'react'

import { useAuthContext } from '@app/common/auth'
import { useInternetConnectionContext } from '@app/components/tools/components/internet-connection'
import { useTradeContext } from '@app/pages/trade/trade-context'

import { RateSocket } from '@data/socket/stocks/binance/rate'

const socketRateUSDT = new RateSocket()

const useRates = (): void => {
  const { stock } = useAuthContext()
  const { setRatesUSDT } = useTradeContext()
  const { status } = useInternetConnectionContext()

  useEffect(() => {
    if (status === 'online') {
      socketRateUSDT.subscribe(stock)

      socketRateUSDT.onMessage((value) => {
        setRatesUSDT(value)
      })
    }

    return () => {
      socketRateUSDT.unsubscribe()
    }
  }, [stock, status])
}

export { useRates }
