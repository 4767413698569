import { HttpServiceV1 } from '@data/common/services'

import { ExceptionService } from '@domain/common/services'
import { InternalCode } from '@domain/common/enums'

import { BinanceErrorCode, transformSideFromBinance } from '@data/stocks/binance'

import { ReportRepository } from '../../_'

import type { IPairDTO } from '@domain/stocks/pair'

import type { IDetailReportDTO, IReportRepository, ICommonReportDTO, IGetListReportPort } from '@domain/stocks/report'

import type { IBinanceDetailReport, IBinanceReport, IGetReportListPayload } from '../interfaces'

import type { IHttpError } from '@data/common/interfaces'
import type { IBinanceHttpError } from '@data/stocks/binance/interfaces'

class BinanceReportRepository extends ReportRepository implements IReportRepository {

  public override async getCommonReportList (port: IGetListReportPort): Promise<ICommonReportDTO[]> {
    const payload = this._transformPort(port)

    return HttpServiceV1.get<IBinanceReport[] | null>(`${this.provider}/common`, { params: payload })
      .then((response) => {
        if (response === null) return []

        return this._transformCommonReport(response, port.list)
      })
      .catch((error: IHttpError<IBinanceHttpError>) => {
        throw ExceptionService.new({
          status: {
            code: BinanceReportRepository._transformError(error.errors.code),
            message: error.errors.message
          }
        })
      })
  }

  public override async getDetailReportList (port: IGetListReportPort): Promise<IDetailReportDTO[]> {
    const payload = this._transformPort(port)

    return HttpServiceV1.get<IBinanceDetailReport[] | null>(`${this.provider}/detail`, { params: payload })
      .then((response) => {
        if (response === null) return []

        return this._transformDetailReport(response, port.list)
      })
      .catch((error: IHttpError<IBinanceHttpError>) => {
        throw ExceptionService.new({
          status: {
            code: BinanceReportRepository._transformError(error.errors.code),
            message: error.errors.message
          }
        })
      })
  }

  private _transformPort (port: IGetListReportPort): IGetReportListPayload {
    return {
      symbols: port.symbols ?? null,
      dateFrom: port.from ?? null,
      dateTo: port.to ?? null
    }
  }

  private _transformCommonReport = (payload: IBinanceReport[], list: IPairDTO[]): ICommonReportDTO[] => {
    const reports: ICommonReportDTO[] = []

    payload.forEach((item) => {
      const pair = list.find((_pair) => _pair.ticker.symbol === item.symbol)

      if (pair) {
        reports.push({
          ...item,
          pair: pair,
          dateStart: item.firstDate * 1000,
          dateEnd: item.lastDate * 1000,
          countDeals: item.dealsNum,
          volume: item.volumeUsdt,
          result: { value: item.profitUsdt, percent: item.profitPercent }
        })
      }
    })

    return reports
  }

  private _transformDetailReport = (payload: IBinanceDetailReport[], list: IPairDTO[]): IDetailReportDTO[] => {
    const reports: IDetailReportDTO[] = []

    payload.forEach((item) => {
      const pair = list.find((_pair) => _pair.ticker.symbol === item.symbol)

      if (pair) {
        reports.push({
          pair: pair,
          side: transformSideFromBinance(item.side),
          createDate: item.dealCreateTime * 1000,
          completeDate: item.dealExecTime * 1000,
          statusType: item.statusType,
          price: {
            open: item.openPrice,
            close: item.closePrice,
          },
          amount: {
            value: item.quantity,
            executed: item.execQuantity,
            executedUSDT: item.execUsdtQuantity,
          },
          result: { value: item.profitUsdt, percent: item.profitPercent },
          commission: { value: item.commissionUsdt, percent: item.commissionPercent },
        })
      }
    })

    return reports
  }

  private static _transformError (code: string): InternalCode {
    switch (code) {
      case BinanceErrorCode.ERROR_API_LIMIT: return InternalCode.LIMIT_ERROR
      default: return InternalCode.SERVER_ERROR
    }
  }

}

export { BinanceReportRepository }
