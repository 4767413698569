enum OrderSide {
  BUY = 0,
  SELL = 1
}

enum OrderStatus {
  NEW = 0,
  TRADE = 1,
  COMPLETE = 2,
  CANCELED = 3,
  EXPIRED = 4
}

enum OrderType {
  LIMIT = 0,
  MARKET = 1,
  POST_ONLY = 2,
  STOP_LIMIT = 3,
  STOP_MARKET = 4
}

enum OrderSource {
  ORIGIN = 0,
  VIRTUAL = 1
}

export { OrderSide, OrderStatus, OrderType, OrderSource }
