import type { Theme } from '@mui/material'

import type { Components } from '@mui/material/styles/components'

const INPUTS_OUTLINED_DEFAULT_STYLES: Components<Theme>['MuiOutlinedInput'] = {
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      padding: '13px 15px',
      borderRadius: '5px !important',
      border: 'none',
      height: 45,
      fontSize: 16,
      background: theme.palette.bg.accent,
      transition: theme.transitions.create(['background-color'], { duration: theme.transitions.duration.standard }),
      '.MuiOutlinedInput-notchedOutline': {
        borderWidth: ownerState.error === true ? '2px' : 0
      },
      '.Mui-disabled': {
        cursor: 'not-allowed'
      },
    }),
    input: {
      padding: 0,
    },
    sizeSmall: {
      padding: '6px 14px',
      fontSize: 14,
      height: 30
    },
    notchedOutline: {
      borderWidth: 0
    },
    adornedEnd: ({ theme, ownerState }) => ({
      padding: '13px 15px',
      '.MuiTypography-root': {
        color: theme.palette.text.primary,
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        fontSize: ownerState.size === 'small' ? 14 : 16,
      }
    })
  }
}

const INPUTS_LABEL_DEFAULT_STYLES: Components['MuiInputLabel'] = {
  styleOverrides: {
    root: {
      top: -3,
      '&.Mui-focused': {
        top: 0
      },
    },
    shrink: {
      top: 0
    }
  }
}

export { INPUTS_OUTLINED_DEFAULT_STYLES, INPUTS_LABEL_DEFAULT_STYLES }
